import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    padding: '8px 16px 7px 16px',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  title: {
    marginBottom: 16,
  },
  label: {
    marginBottom: 10,
  },
});
