import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 260,
    marginBottom: 15,
    marginRight: 24,
  },
});
