import Axios, { AxiosResponse } from 'axios';
import { config } from 'app/shared/config';
import { StatusCodes } from 'http-status-codes';

const { baseUrl, legacyUrl } = config;

export interface IQueryParams {
  [param: string]: string | string[] | boolean | number | undefined;
}

export interface IApiClientOptions {
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  data?: Record<string, any>;
  queryParameters?: IQueryParams;
  contentType?: string;
}

interface IApiClientOptionsWithToken extends IApiClientOptions {
  getAccessToken: () => Promise<string | undefined>;
}

export async function apiClient(
  endpoint: string,
  {
    method = 'GET',
    getAccessToken,
    data,
    queryParameters,
    contentType = 'application/json',
  }: IApiClientOptionsWithToken,
  legacy?: boolean,
) {
  const queryParams = queryParameters
    ? `${Object.keys(queryParameters)
        .map(key => {
          if (!queryParameters[key] && queryParameters[key] !== false) {
            return;
          } else {
            return (
              key +
              '=' +
              queryParameters[key]?.toString().replaceAll(',', `&${key}=`)
            );
          }
        })
        .join('&')}`
    : '';

  const apiUrl = legacy ? legacyUrl : baseUrl;

  const url = queryParameters
    ? `${apiUrl}${endpoint}?${queryParams}`
    : `${apiUrl}${endpoint}`;

  const accessToken = await getAccessToken();

  const requestConfig = {
    method,
    url,
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'Content-Type': contentType,
    },
    data,
    validateStatus: () => true,
  };

  const createErrorObject = (response: AxiosResponse<any>) => {
    return {
      statusCode: response.status,
      message: response.data?.messages
        ? response.data?.messages[0]
        : response.data?.title
        ? response.data.title
        : response.data?.message,
    };
  };

  const response = await Axios(requestConfig);

  if (response.status === StatusCodes.UNAUTHORIZED) {
    return Promise.reject(createErrorObject(response));
  }

  if (response.status === StatusCodes.NOT_FOUND) {
    return Promise.reject(createErrorObject(response));
  }

  if (response.status === StatusCodes.BAD_REQUEST) {
    return Promise.reject(createErrorObject(response));
  }

  if (
    response.status >= StatusCodes.OK &&
    response.status <= StatusCodes.PARTIAL_CONTENT
  ) {
    return response.data;
  }

  return Promise.reject(createErrorObject(response));
}
