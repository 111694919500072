import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  INotificationEntity,
  INotificationEntitiesPayload,
  INotificationEntityCard,
  IMetaData,
  IPourEventCard,
} from 'types';
import { useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';
import { IQueryParams } from '../services';

const notificationEntityQueryKey = 'getNotificationEntities';
const relatedNotificationEntityQueryKey = 'getRelatedNotificationEntities';
const notificationEntityCardsQueryKey = 'getNotificationEntityCards';
const pourEventCardsQueryKey = 'getPourEventCards';

const notificationUnassignedEntityQueryKey =
  'getUnassignedNotificationEntities';

const defaultSkip = 0;
const emptyArrayLength = 0;
const oneElement = 1;
const defaultTake = 50;

export function useNotificationEntityApi({
  queryParameters,
  organizationId,
  entityType,
  isInfiniteScrolling = true,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const queryClient = useQueryClient();

  const [entities, setEntities] = useState<INotificationEntity[]>([]);
  const [relatedNotificationEntities, setRelatedNotificationEntities] =
    useState<INotificationEntity[]>([]);
  const [hasMoreRelatedEntities, setHasMereRelatedEntities] = useState(true);
  const [lastTicketDispatchDateTimeUtc, setLastTicketDispatchDateTimeUtc] =
    useState<string | undefined>('');
  const [
    lastTicketDispatchDatePourTimeUtc,
    setLastTicketDispatchDatePourTimeUtc,
  ] = useState<string | undefined>('');

  const getNotificationEntityUrl = useMemo(() => {
    return `/api/v1/${organizationId}/NotificationEntity/${entityType}/get-notification-entities`;
  }, [entityType, organizationId]);

  const getRelatedNotificationEntitiesUrl = useMemo(() => {
    return `/api/v1/${organizationId}/NotificationEntity/${entityType}/get-related-notification-entities`;
  }, [entityType, organizationId]);

  const notificationEntityCardsUrl = useMemo(
    () =>
      `/api/v1/${organizationId}/NotificationEntity/${entityType}/get-notification-entity-cards`,
    [organizationId, entityType],
  );

  const pourEventCardsUrl = useMemo(
    () =>
      `/api/v1/${organizationId}/NotificationEntity/${entityType}/get-pour-event-cards`,
    [organizationId, entityType],
  );

  const {
    data: notificationEntityCardsResponse,
    refetch: fetchNotificationEntityCards,
    isLoading: isLoadingNotificationEntityCards,
    isFetching: isFetchingNotificationEntityCards,
    error: fetchNotificationEntityCardsError,
  } = useQuery<IResponseData<INotificationEntityCard[]>, IError>(
    [notificationEntityCardsQueryKey, entityType],
    async () => {
      return await authClient(`${notificationEntityCardsUrl}`, {
        queryParameters: {
          ...queryParameters,
          lastElementDateTimeUtc:
            queryParameters?.skip !== emptyArrayLength.toString()
              ? lastTicketDispatchDatePourTimeUtc
              : '',
        },
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: pourEventCardsResponse,
    refetch: fetchPourEventCards,
    isLoading: isLoadingPourEventCards,
    isFetching: isFetchingPourEventCards,
    error: fetchPourEventCardsError,
  } = useQuery<IResponseData<IPourEventCard[]>, IError>(
    [pourEventCardsQueryKey, entityType],
    async () => {
      return await authClient(`${pourEventCardsUrl}`, {
        queryParameters: {
          ...queryParameters,
          lastElementDateTimeUtc:
            queryParameters?.skip !== emptyArrayLength.toString()
              ? lastTicketDispatchDateTimeUtc
              : '',
        },
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: relatedNotificationEntitiesResponse,
    mutateAsync: fetchRelatedNotificationEntities,
    isLoading: isFetchingRelatedNotificationEntities,
  } = useMutation(
    (payload: INotificationEntitiesPayload) =>
      authClient(getRelatedNotificationEntitiesUrl, {
        method: 'POST',
        data: payload,
        queryParameters,
      }).then(res => {
        setRelatedNotificationEntities(prevState => {
          if (
            !payload.lastElementDateTimeUtc ||
            (payload.query.length && !prevState)
          ) {
            return res.data;
          } else {
            return prevState.concat(res.data);
          }
        });

        if (res.data.length < defaultTake) {
          setHasMereRelatedEntities(false);
        }
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries([
          relatedNotificationEntityQueryKey,
          entityType,
        ]);
        return queryClient.getQueryData<IResponseData<any>>([
          relatedNotificationEntityQueryKey,
          entityType,
        ]);
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            [relatedNotificationEntityQueryKey, entityType],
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.getRelatedNotificationEntities,
          error,
          {
            relatedNotificationEntityQueryKey,
            variables: JSON.stringify(variables),
          },
        );
        openModal(error);
      },
    },
  );

  const useNotificationEntitiesQuery = (
    queryKey: string,
    queryParams?: IQueryParams,
  ) => {
    return useQuery<IResponseData<INotificationEntity[]>, IError>(
      [queryKey, entityType, { queryParameters }],
      async () => {
        return await authClient(`${getNotificationEntityUrl}`, {
          queryParameters: {
            ...queryParams,
            ...queryParameters,
            lastElementDateTimeUtc:
              queryParameters?.skip !== emptyArrayLength && entities.length
                ? entities[entities.length - oneElement]
                    .lastTicketDispatchDateTimeUtc
                : '',
          },
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          firebaseAnalytics.logErrorEvent(
            errors.getUnassignedNotificationEntities,
            error,
            {
              getNotificationEntityUrl,
              ...queryParameters,
            },
          );
          openModal(error);
        },
      },
    );
  };

  const {
    data: notificationEntitiesResponse,
    refetch: fetchNotificationEntities,
    isFetching: isFetchingNotificationEntities,
  } = useNotificationEntitiesQuery(notificationEntityQueryKey, {
    unassignedOnly: false,
  });

  const {
    data: unassignedNotificationEntitiesResponse,
    refetch: fetchUnassignedNotificationEntities,
    isFetching: isFetchingUnassignedNotificationEntities,
  } = useNotificationEntitiesQuery(notificationUnassignedEntityQueryKey, {
    unassignedOnly: true,
  });

  useEffect(() => {
    if (!isFetchingNotificationEntities) {
      setEntities((prevState: INotificationEntity[]) => {
        return queryParameters?.skip !== defaultSkip && isInfiniteScrolling
          ? notificationEntitiesResponse?.data
            ? [...prevState, ...notificationEntitiesResponse.data]
            : prevState
          : notificationEntitiesResponse?.data || [];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationEntitiesResponse?.data]);

  const unassignedNotificationEntities = useMemo<
    INotificationEntity[] | undefined
  >(
    () => unassignedNotificationEntitiesResponse?.data,
    [unassignedNotificationEntitiesResponse?.data],
  );

  const resetRelatedNotificationEntitiesQuery = useCallback(async () => {
    await queryClient.resetQueries([
      relatedNotificationEntityQueryKey,
      entityType,
    ]);
  }, [queryClient, entityType]);

  const notificationEntityCards = useMemo<
    INotificationEntityCard[] | undefined
  >(() => {
    setLastTicketDispatchDateTimeUtc(
      notificationEntityCardsResponse?.data[
        notificationEntityCardsResponse?.data.length - oneElement
      ]?.lastTicketDispatchDateTimeUtc || undefined,
    );

    return notificationEntityCardsResponse?.data;
  }, [notificationEntityCardsResponse]);

  const notificationEntityCardsMetadata = useMemo<IMetaData | undefined>(
    () => notificationEntityCardsResponse?.metadata,
    [notificationEntityCardsResponse],
  );

  const pourEventCards = useMemo<IPourEventCard[] | undefined>(() => {
    setLastTicketDispatchDatePourTimeUtc(
      pourEventCardsResponse?.data[
        pourEventCardsResponse?.data.length - oneElement
      ]?.lastTicketDispatchDateTimeUtc || undefined,
    );

    return pourEventCardsResponse?.data;
  }, [pourEventCardsResponse]);

  const pourEventCardsMetadata = useMemo<IMetaData | undefined>(
    () => pourEventCardsResponse?.metadata,
    [pourEventCardsResponse],
  );

  return {
    notificationEntities: entities,
    fetchNotificationEntities,
    isFetchingNotificationEntities,
    unassignedNotificationEntities,
    fetchUnassignedNotificationEntities,
    isFetchingUnassignedNotificationEntities,
    notificationEntitiesResponse,
    resetRelatedNotificationEntitiesQuery,
    fetchRelatedNotificationEntities,
    isFetchingRelatedNotificationEntities,
    relatedNotificationEntities,
    relatedNotificationEntitiesResponse,
    hasMoreRelatedEntities,
    notificationEntityCards,
    fetchNotificationEntityCards,
    isLoadingNotificationEntityCards,
    isFetchingNotificationEntityCards,
    fetchNotificationEntityCardsError,
    notificationEntityCardsMetadata,
    pourEventCards,
    pourEventCardsMetadata,
    fetchPourEventCards,
    isLoadingPourEventCards,
    isFetchingPourEventCards,
    fetchPourEventCardsError,
  };
}
