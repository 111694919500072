import { Checkbox, Modal } from '@material-ui/core';
import { ModalHeader, ModalVariant } from '../ModalHeader';
import { useStyles } from './UserAssignmentModal.styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from '../Table/Table';
import { CellAlignment, CellSize, TableHeaderType } from '../Table/Table.types';
import {
  IGetUserNotificationAssignmentsResponse,
  INotification,
  ITeamAssignments,
  NotificationEntityType,
} from 'types';
import { getNotificationEntityName, getUserInitials } from 'app/shared/utils';
import { Avatar } from '../Avatar/Avatar';
import { CustomTypography } from '../Typography/Typography';
import { themeColors } from 'app/shared/theme';
import { useAuth, useNotificationSettings } from 'app/shared/providers';
import { useParams } from 'react-router-dom';
import { useNotificationSettingsGroupApi } from 'app/shared/hooks';

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  notification: INotification | undefined;
  isLoading: boolean;
  changeUsersNotificationStatusHandler: (
    users: {
      userId: string;
      notificationTypeId: string;
      isAssigned: boolean;
    }[],
    type?: NotificationEntityType,
    notificationEntityId?: string,
  ) => void;
  tableData?: IGetUserNotificationAssignmentsResponse[];
  teamAssignment: ITeamAssignments | undefined;
}

const zero = 0;

export function UserAssignmentModal({
  closeHandler,
  isOpen,
  isLoading,
  tableData,
  changeUsersNotificationStatusHandler,
  notification,
  teamAssignment,
}: IProps) {
  const classes = useStyles();
  const { selectedEntity, entityType } = useNotificationSettings();
  const { userId } = useAuth();
  const { organizationId }: { organizationId: string } = useParams();
  const groupQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId,
        userId: userId,
      },
    };
  }, [
    organizationId,
    entityType,
    selectedEntity?.notificationEntityGroupId,
    userId,
  ]);
  const { changeUsersStatusNotificationGroup } =
    useNotificationSettingsGroupApi(groupQueryParams);
  const [cacheTableData, setCacheTableData] = useState<
    | (IGetUserNotificationAssignmentsResponse & { isAssigned?: boolean })[]
    | undefined
  >(
    tableData
      ?.filter(item => !item.notificationEntityGroupId)
      .map(item => ({ ...item, isAssigned: true })),
  );
  const [groups, setGroups] = useState<
    Record<
      string,
      {
        name: string;
        id: string;
        isAssigned: boolean;
        entityType: NotificationEntityType;
      }
    >
  >(
    tableData
      ?.filter(item => item.notificationEntityGroupId)
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr.notificationEntityGroupId]: {
            name: curr.notificationEntityGroupName,
            id: curr.notificationEntityGroupId,
            isAssigned: true,
            entityType: curr.notificationEntityType,
          },
        };
      }, {}) || {},
  );

  useEffect(() => {
    setCacheTableData(
      tableData
        ?.filter(item => !item.notificationEntityGroupId)
        .map(item => ({ ...item, isAssigned: true })),
    );
    setGroups(
      tableData
        ?.filter(item => item.notificationEntityGroupId)
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.notificationEntityGroupId]: {
              name: curr.notificationEntityGroupName,
              id: curr.notificationEntityGroupId,
              isAssigned: true,
              entityType: curr.notificationEntityType,
            },
          };
        }, {}) || {},
    );
  }, [tableData]);

  const columns = [
    {
      id: 'notificationEntityType',
      label: 'Entity Type',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'externalId',
      label: 'External ID',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'name',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'isAssigned',
      label: '',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'isAssigned') {
        return (
          <div className={classes.checkboxCell}>
            <Checkbox
              disableRipple
              size="small"
              classes={{
                colorSecondary: classes.icon,
                checked: classes.checkedIcon,
              }}
              onChange={e => {
                changeUsersNotificationStatusHandler(
                  [
                    {
                      isAssigned: e.target.checked,
                      notificationTypeId:
                        notification?.notificationTypeId || '',
                      userId: teamAssignment?.userId || '',
                    },
                  ],
                  rowData.notificationEntityType,
                  rowData.internalId,
                );

                setCacheTableData(prev =>
                  prev?.map(item =>
                    item.internalId === rowData.internalId
                      ? { ...item, isAssigned: e.target.checked }
                      : item,
                  ),
                );
              }}
              checked={rowData[cellId]}
            />
          </div>
        );
      }
      if (cellId === 'notificationEntityType') {
        return <div>{getNotificationEntityName(rowData[cellId])}</div>;
      }
      return <div>{rowData[cellId]}</div>;
    },
    [
      changeUsersNotificationStatusHandler,
      classes.checkboxCell,
      classes.checkedIcon,
      classes.icon,
      notification?.notificationTypeId,
      teamAssignment?.userId,
    ],
  );

  const cellContent = teamAssignment?.isAdmin
    ? [teamAssignment?.firstName, teamAssignment?.lastName, '(Admin)']
    : [teamAssignment?.firstName, teamAssignment?.lastName];

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.container} style={{ maxHeight: '700px' }}>
        <ModalHeader
          variant={ModalVariant.SMALL}
          header={
            notification?.notificationCode?.replace(/[A-Z]/g, ' $&').trim() ||
            ''
          }
          closeHandler={closeHandler}
        />
        <div className={classes.contentWrapper}>
          <div>
            <strong>Description:</strong> {notification?.notificationText}
          </div>
          <div className={classes.info}>
            <Avatar
              content={
                cellContent[0] &&
                cellContent[1] &&
                getUserInitials(cellContent[0], cellContent[1])
              }
              color={'highlightBlue'}
              size={32}
            />
            <div className={classes.name}>
              <div>{cellContent.join(' ')}</div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '12px',
                marginLeft: '12px',
                flexWrap: 'wrap',
              }}
            >
              {teamAssignment
                ? teamAssignment.roleNames.map(role => (
                    <div key={role} className={classes.label}>
                      {role}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className={classes.body} style={{ maxHeight: '500px' }}>
          <CustomTypography
            color="accentDark"
            variant="bodyRegular"
            bold
            style={{
              marginLeft: '12px',
              fontWeight: 'bold',
              marginBottom: '8px',
            }}
          >
            Groups:
          </CustomTypography>
          {Object.values(groups).map((group, index) => (
            <div
              key={group.id}
              className={classes.group}
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? themeColors.greyscale4
                    : themeColors.greyscale5,
              }}
            >
              <CustomTypography color="greyscale1" variant="bodyRegular" bold>
                {group.name}
              </CustomTypography>
              <div className={classes.checkboxCell}>
                <Checkbox
                  disableRipple
                  size="small"
                  classes={{
                    colorSecondary: classes.icon,
                    checked: classes.checkedIcon,
                  }}
                  onChange={e => {
                    changeUsersStatusNotificationGroup({
                      users: [
                        {
                          isAssigned: e.target.checked,
                          notificationTypeId:
                            notification?.notificationTypeId || '',
                          userId: teamAssignment?.userId || '',
                        },
                      ],
                      type: group.entityType,
                      groupId: group.id,
                    });
                    setGroups(prev => ({
                      ...prev,
                      [group.id]: { ...group, isAssigned: e.target.checked },
                    }));
                  }}
                  checked={group.isAssigned}
                />
              </div>
            </div>
          ))}
          <div style={{ height: '16px' }} />
          <Table
            columns={columns}
            emptyTableText={'There is no other assignments' || undefined}
            tableData={cacheTableData}
            withCheckbox={false}
            rowsTotal={cacheTableData?.length || zero}
            renderCell={renderCell}
            headerType={TableHeaderType.DARK}
            withBorders={false}
            isLoading={isLoading}
            isScrollable={false}
            overflow="visible"
          />
        </div>
      </div>
    </Modal>
  );
}
