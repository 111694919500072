import { translations } from 'app/locales/i18n';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProbeRanges } from 'types';
import * as yup from 'yup';

const defaultMinForAge = 0;
const minAge = 1;
const maxHours = 24;
const hours = 1;
const defaultAgeType = 0;

export const useCompressiveStrengthValidation = (probeRanges: IProbeRanges) => {
  const { t } = useTranslation();
  const [ageType, setAgeType] = useState(defaultAgeType);

  const maxAge = useMemo(() => {
    return ageType === hours ? maxHours : probeRanges.maxAge;
  }, [ageType, probeRanges.maxAge]);

  const validationSchema = yup.object().shape({
    age: yup
      .number()
      .integer(t(translations.errors.thisFieldMustBeAnInteger))
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(maxAge, `${t(translations.errors.thisFieldCanNotExceed)} ${maxAge}`)
      .min(minAge, `${t(translations.errors.minValue)} ${defaultMinForAge}`)
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
    compressiveStrength: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges?.compressiveStrRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges?.compressiveStrRange.maxValue
        }`,
      )
      .min(
        probeRanges?.compressiveStrRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges?.compressiveStrRange.minValue
        }`,
      )
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
  });

  return {
    validationSchema,
    ageType,
    setAgeType,
  };
};
