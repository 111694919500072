import React, { useCallback, useState } from 'react';
import { InfoCard } from 'app/shared/components/generic-ui/InfoCard/InfoCard';
import {
  IOrganization,
  NotificationEntityStatus,
  NotificationEntityType,
  Routes,
} from 'types';
import { useStyles } from './OrganizationCard.styles';
import { ProjectStatusLabel } from 'app/shared/components/generic-ui/ProjectStatusLabel/ProjectStatusLabel';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import briefcase from 'assets/briefcase.svg';
import project_team from 'assets/project_team.svg';
import {
  ActionButton,
  ActionButtonStyle,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useOrganizationsApi } from 'app/shared/hooks';

import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { firebaseAnalytics } from 'app/shared/analytics';
import { TabIds } from 'app/shared/components/layout/TabNavigation/TabNavigation.types';
import { CustomTeamModal } from 'app/shared/components/generic-ui';

interface IOrganizationCard {
  organization: IOrganization;
}

const columns = [
  {
    id: 'name',
    label: 'Name',
    alignment: CellAlignment.LEFT,
    size: CellSize.MEDIUM,
  },
  {
    id: 'email',
    label: 'Email',
    alignment: CellAlignment.RIGHT,
    size: CellSize.MEDIUM,
  },
];

const noAdministrators = 0;

export const OrganizationCard = ({ organization }: IOrganizationCard) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const { fetchOrganizationAdmins, organizationAdmins } = useOrganizationsApi({
    organizationId: organization.supplierId,
  });

  const action: IAction[] = [
    {
      label: t(translations.organizationCard.editOrganization),
      onClick: () => {
        firebaseAnalytics.logPressEvent(
          Routes.Organizations,
          'Organization card edit action',
        );

        localStorage.setItem('PLANT_GROUP_ID', '');

        history.push(
          Routes.OrganizationDetails.replace(
            ':organizationId/:tab/:page/:perPage',
            `${organization.supplierId}/${TabIds.ORGANIZATION_INFO}/1/20`,
          ),
        );
      },
    },
  ];

  const handleMembersClick = useCallback(async () => {
    firebaseAnalytics.logPressEvent(
      Routes.Organizations,
      'Organization card members button',
    );

    await fetchOrganizationAdmins();
    setIsAdminModalOpen(true);
  }, [fetchOrganizationAdmins]);

  const handleCardClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(Routes.Organizations, 'Organization card');

    history.push(
      generatePath(Routes.NotificationEntityPage, {
        organizationId: organization.supplierId,
        entityType: NotificationEntityType.PROJECT,
      }),
    );
  }, [organization, history]);

  return (
    <>
      {isAdminModalOpen && (
        <CustomTeamModal
          team={organizationAdmins}
          isOpen={isAdminModalOpen}
          header={t(translations.organizationCard.organizationMembers)}
          closeHandler={() => {
            setIsAdminModalOpen(false);
          }}
          columns={columns}
        />
      )}
      <InfoCard testId="info-card">
        <div className={classes.actionButton}>
          <ActionButton style={ActionButtonStyle.LIGHT} options={action} />
        </div>
        <div onClick={handleCardClick}>
          <div className={classes.organizationCardHeader} data-testid="status">
            <ProjectStatusLabel
              status={
                organization.active
                  ? NotificationEntityStatus.ACTIVE
                  : NotificationEntityStatus.INACTIVE
              }
            />
          </div>

          <div className={classes.organizationCardBody}>
            <div className={classes.nameContainer}>
              <CustomTypography variant="header5" bold color="accentDark">
                {organization.supplierName || '-'}
              </CustomTypography>
            </div>

            <div className={classes.textContainer}>
              <img src={briefcase} className={classes.icon} />
              <CustomTypography variant="bodySmall" color="greyscale1">
                {`${t(translations.organizationCard.description)}: ${
                  organization.description || '-'
                }`}
              </CustomTypography>
            </div>
          </div>
        </div>

        <div className={classes.administrators}>
          <img src={project_team} className={classes.icon} />
          <CustomTypography variant="bodySmall" color="greyscale1">
            {`${t(translations.organizationCard.administrators)}:`}
          </CustomTypography>
          <div className={classes.members} onClick={handleMembersClick}>
            <CustomTypography
              variant="bodySmall"
              bold
              color={
                organization.assignedAdminsCount === noAdministrators
                  ? 'greyscale3'
                  : 'mbsBlue'
              }
            >
              {`${organization.assignedAdminsCount} ${t(
                translations.organizationCard.members,
              )}`}
            </CustomTypography>
          </div>
        </div>
      </InfoCard>
    </>
  );
};
