import { Popover } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';

import { useStyles } from './NotificationItem.styles';

export enum PopoverType {
  INFO,
  MIX_ID,
}

interface INotificationPopoverProps {
  title?: string;
  open: boolean;
  anchor: HTMLElement | null;
  text: string;
  type?: PopoverType;
}

export const NotificationPopover = ({
  title,
  open,
  anchor,
  text,
}: INotificationPopoverProps) => {
  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      className={classes.popover}
      disableRestoreFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.body}>
        {title && (
          <CustomTypography variant="caption1" bold color="accentDark">
            {title}
          </CustomTypography>
        )}
        <div className={classes.textContainer}>
          <CustomTypography variant="caption1" color="greyscale1">
            {text}
          </CustomTypography>
        </div>
      </div>
    </Popover>
  );
};
