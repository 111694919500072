import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const history = useHistory();
  const searchQuery = new URLSearchParams(useLocation().search).get('search');
  const notificationTab = new URLSearchParams(location.search).get('tab');
  const sortType = new URLSearchParams(location.search).get('sort');
  const eventStatusCode = new URLSearchParams(location.search).get('code');

  const searchParams = useCallback(() => {
    return new URLSearchParams({
      ['search']: searchQuery || '',
      ['tab']: notificationTab || '',
      ['sort']: sortType || 'asc',
      ['code']: eventStatusCode || '',
    });
  }, [eventStatusCode, notificationTab, searchQuery, sortType]);

  const sortHandler = useCallback(() => {
    const params = new URLSearchParams({
      ['search']: searchQuery || '',
      ['tab']: notificationTab || '',
      ['sort']: sortType === 'desc' ? 'asc' : 'desc',
      ['code']: eventStatusCode || '',
    });

    history.push({
      pathname: location.pathname,
      search: `${params}`,
    });
  }, [eventStatusCode, history, notificationTab, searchQuery, sortType]);

  return {
    searchParams,
    sortHandler,
    sortType,
    searchQuery,
  };
};
