import React, { useEffect, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './FullFormBody.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import { Control, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { FieldsMaxLength } from '../CreateProjectModal';
import { IProjectInformation } from 'types';
import 'react-phone-input-2/lib/style.css';
import { CustomPhoneInput } from 'app/shared/components/generic-ui/CustomPhoneInput/CustomPhoneInput';

interface IFullFormBodyProps {
  errors: DeepMap<FieldValues, FieldError>;
  control: Control<IProjectInformation>;
  showError: boolean;
}

export const FullFormBody = ({
  errors,
  control,
  showError,
}: IFullFormBodyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [customErrorState, setCustomErrorState] = useState<
    Record<string, boolean>
  >({
    email: false,
    phoneNumber: false,
  });

  useEffect(() => {
    setCustomErrorState({
      email: true,
      phoneNumber: true,
    });
  }, [showError]);

  return (
    <div className={classes.container}>
      <div className={classes.subSection}>
        <div className={classes.subtitleVariant}>
          <CustomTypography variant="header6" bold color="accentDark">
            {t(translations.projectModal.clientContact)}
          </CustomTypography>
        </div>
        <div className={classes.section}>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.contactName)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={t(translations.projectModal.contactName)}
                name="clientContactName"
                error={!!errors?.contactName}
                control={control}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.emailAddress)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={t(translations.projectModal.emailAddress)}
                name="email"
                onChangeHandler={() => {
                  setCustomErrorState({ ...customErrorState, email: false });
                }}
                control={control}
                error={!!errors?.email && customErrorState.email}
                maxLength={FieldsMaxLength.maxEmailLength}
                helperText={
                  errors?.email?.message && customErrorState.email
                    ? errors.email.message
                    : ''
                }
              />
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.phoneNumber)}
              </CustomTypography>
            </div>

            <div>
              <CustomPhoneInput
                fieldName="phoneNumber"
                control={control}
                isShowError={customErrorState}
                setIsShowError={setCustomErrorState}
                errors={errors}
                placeholder={t(translations.projectModal.phoneNumber)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.subSection}>
        <div className={classes.subtitle}>
          <CustomTypography variant="header6" bold color="accentDark">
            {t(translations.projectModal.projectTeam)}
          </CustomTypography>
        </div>
        <div className={classes.section}>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.generalContractor)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={`${t(
                  translations.projectModal.generalContractor,
                )} ${t(translations.projectModal.name)}`}
                name="generalContractor"
                control={control}
                error={!!errors?.generalContractor}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.architect)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={`${t(translations.projectModal.architect)} ${t(
                  translations.projectModal.name,
                )}`}
                name="architect"
                control={control}
                error={!!errors?.architect}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.engineerOfRecord)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={`${t(
                  translations.projectModal.engineerOfRecord,
                )} ${t(translations.projectModal.name)}`}
                name="engineerOfRecord"
                control={control}
                error={!!errors?.engineerOfRecord}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.finisher)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={`${t(translations.projectModal.finisher)} ${t(
                  translations.projectModal.name,
                )}`}
                name="finisher"
                control={control}
                error={!!errors?.finisher}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.fieldsWrapperShort}>
            <div className={classes.label}>
              <CustomTypography variant="caption1" bold color="greyscale6">
                {t(translations.projectModal.subContractor)}
              </CustomTypography>
            </div>

            <div>
              <TextInput
                placeholder={`${t(translations.projectModal.subContractor)} ${t(
                  translations.projectModal.name,
                )}`}
                name="subContractor"
                control={control}
                error={!!errors?.subContractor}
                maxLength={FieldsMaxLength.maxNameLength}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.subtitle}>
        <CustomTypography variant="header6" bold color="accentDark">
          {t(translations.projectModal.additionalNotes)}
        </CustomTypography>
      </div>
      <div className={classes.multilineContainer}>
        <div className={classes.label}>
          <CustomTypography variant="caption1" bold color="greyscale6">
            {t(translations.projectModal.notes)}
          </CustomTypography>
        </div>

        <div>
          <TextInput
            multiline
            placeholder={t(translations.projectModal.notesAboutTheProject)}
            name="notes"
            control={control}
            error={!!errors?.notes}
            maxLength={FieldsMaxLength.maxNotesLength}
          />
        </div>
      </div>
    </div>
  );
};
