import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  switchTrack: {
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  switchBase: {
    opacity: 1,
    height: 16,
    left: 4,
    top: 5,
    '& .Mui-checked': {
      transform: 'translateX(27px)',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  rootSwitch: {
    width: 70,
    marginRight: '-10px',
  },
  toggle: {
    '& .Mui-checked': {
      color: themeColors.white,
      transform: 'translateX(27px)',
    },
  },
});
