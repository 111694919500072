import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { INotificationEntity, NotificationEntityType } from 'types';

export const NotificationSettingsContext =
  createContext<INotificationSettingsContext | null>(null);

NotificationSettingsContext.displayName = 'NotificationSettingsContext';

export interface INotificationSettingsContext {
  isLoading: boolean;
  isDeletingGroup: boolean;
  deleteGroupHandler: () => void;
  selectedEntity?: INotificationEntity;
  entityType: NotificationEntityType;
  changeSelectedEntityHandler: (
    currentEntityType: NotificationEntityType,
    entity?: INotificationEntity,
  ) => void;
  resetSelectedEntityHandler: (
    currentEntityType: NotificationEntityType,
  ) => void;
}

export function NotificationSettingsProvider({
  children,
}: // eslint-disable-next-line @typescript-eslint/ban-types
PropsWithChildren<{}>) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEntity, setSelectedEntity] = useState<
    INotificationEntity | undefined
  >();
  const [isDeletingGroup, setIsDeletingGroup] = useState(false);

  const [entityType, setEntityType] = useState<NotificationEntityType>(
    NotificationEntityType.ORGANIZATION,
  );

  const getEntityFromLocalStorage = useCallback(
    (currentEntityType: NotificationEntityType) => {
      return localStorage.getItem(currentEntityType?.toString() || '')
        ? JSON.parse(
            localStorage.getItem(currentEntityType?.toString() || '') || '',
          )
        : undefined;
    },
    [],
  );

  const deleteGroupHandler = useCallback(() => {
    setIsDeletingGroup(prevState => !prevState);
  }, []);

  const changeSelectedEntityHandler = useCallback(
    (
      currentEntityType: NotificationEntityType,
      entity?: INotificationEntity,
    ) => {
      setIsLoading(true);
      if (!entity) {
        setEntityType(currentEntityType);
      }
      setSelectedEntity(entity || getEntityFromLocalStorage(currentEntityType));

      localStorage.setItem(
        currentEntityType.toString(),
        JSON.stringify(
          entity ||
            (getEntityFromLocalStorage(currentEntityType)
              ? getEntityFromLocalStorage(currentEntityType)
              : null),
        ),
      );

      setIsLoading(false);
    },
    [getEntityFromLocalStorage],
  );

  const resetSelectedEntityHandler = useCallback(
    (currentEntityType: NotificationEntityType) => {
      setSelectedEntity(undefined);

      localStorage.setItem(currentEntityType.toString(), JSON.stringify(null));
    },
    [],
  );

  const contextValue = useMemo(() => {
    return {
      isLoading,
      selectedEntity,
      entityType,
      isDeletingGroup,
      changeSelectedEntityHandler,
      resetSelectedEntityHandler,
      deleteGroupHandler,
    };
  }, [
    changeSelectedEntityHandler,
    entityType,
    isLoading,
    isDeletingGroup,
    resetSelectedEntityHandler,
    selectedEntity,
    deleteGroupHandler,
  ]);

  return (
    <NotificationSettingsContext.Provider value={contextValue}>
      {children}
    </NotificationSettingsContext.Provider>
  );
}

export function useNotificationSettings() {
  const context = useContext(NotificationSettingsContext);

  if (!context) {
    throw new Error(
      'NotificationSettingsContext must be in scope when useNotificationSettings is used.',
    );
  }

  return context;
}
