import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: themeColors.white,

    marginBottom: 8,
    boxShadow: themeShadows.shadowSmall,
  },
  header: {
    padding: '12px 11px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  valueContainer: {
    padding: '0px 8px',
  },
  inputsContainer: {
    margin: 9,
    paddingTop: 18,
  },
  cancel: {
    width: 'calc(100% - 6px)',
    marginRight: 6,
  },
  batchAge: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: 50,
    // eslint-disable-next-line no-magic-numbers
    [theme.breakpoints.down(430)]: {
      marginRight: 20,
    },
  },
  icons: { display: 'flex', gap: '16px' },
  ageContainerLight: {
    padding: '4px 8px',
    margin: '4px 16px 0px 0px',
    borderRadius: 2,
    backgroundColor: themeColors.greyscale4_50,
    float: 'left',
    width: 'fit-content',
  },
  arrowContainer: {
    width: 24,
    borderLeft: `2px solid ${themeColors.greyscale4}`,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: 22,
  },
  headerSections: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandIcon: {
    width: 22,
    marginRight: '-8px',
    color: themeColors.mbsBlue,
    cursor: 'pointer',
  },
  body: {
    borderTop: `1px solid ${themeColors.greyscale4}`,
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  values: {
    padding: '10px 16px',
  },
  notificationIcon: {
    marginRight: 5,
    alignItems: 'center',
    display: 'flex',
  },
  notificationDescription: {
    padding: '8px 16px 5px 16px',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconPadding: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    height: 46,
    padding: '12px 11px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  progress: {
    height: 508,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  manualDataLabel: {
    padding: '0px 8px',
    backgroundColor: themeColors.accentLight,
    borderRadius: 2,
  },
  manualData: {
    paddingLeft: 8,
    marginLeft: 8,
    borderLeft: `2px solid ${themeColors.accentDark14}`,
  },
  validity: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  labelWrapper: {
    marginRight: 10,
  },
  switchWrapper: {
    marginRight: 5,
  },
}));
