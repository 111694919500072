import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  pourEventHistoryContainer: {
    marginTop: '10px',
    width: '100%',
  },
  empty: {
    paddingBottom: '14px',
  },
  cellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  ageContainerDark: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.accentLight,
  },
  ageContainerLight: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.greyscale4_50,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  filterCardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    width: 245,
  },
  popover: {
    zIndex: 1000,
    backgroundColor: themeColors.white,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '11px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  icon: {
    marginRight: '10px',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '8px 16px 7px 16px',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  title: {
    marginBottom: 16,
  },
  label: {
    fontSize: 12,
    fontWeight: 700,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 238,
    marginTop: 15,
  },
});
