import React, { useCallback, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ISectionValue } from '../ProfileSection/ProfileSection';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Grid } from 'app/shared/components/generic-ui';
import { useStyles } from './EditProfileForm.styles';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useHistory } from 'react-router-dom';
import { IRole, Routes } from 'types';
import { CustomPhoneInput } from 'app/shared/components/generic-ui/CustomPhoneInput/CustomPhoneInput';
import { useProfileApi } from 'app/shared/hooks';
import {
  getFormattedPhoneNumber,
  teamsEmailValidationHelper,
} from 'app/shared/utils';
import { firebaseAnalytics } from 'app/shared/analytics';

interface IEditProfile {
  values: ISectionValue[];
  organizationRoles: IRole[];
  userId: string;
  organizationId: string;
}

const maxName = 200;
const maxPhone = 20;

export enum FieldsMaxLength {
  maxNameLength = maxName,
  maxPhoneLength = maxPhone,
}

const phoneRegExp = /^[0-9]{11}$/;

export const EditProfileForm = ({
  values,
  organizationRoles,
  userId,
  organizationId,
}: IEditProfile) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { mutateProfile } = useProfileApi({
    organizationId: organizationId,
  });

  const [isShowError, setIsShowError] = useState<Record<string, boolean>>({
    phoneNumber: false,
  });

  const formDefaultValues = {
    firstName: values.find(el => el.id === 'firstName')?.value ?? '',
    lastName: values.find(el => el.id === 'lastName')?.value ?? '',
    email: values.find(el => el.id === 'email')?.value ?? '',
    phoneNumber:
      getFormattedPhoneNumber(
        values.find(el => el.id === 'phoneNumber')?.value,
      ) ?? '',
    notificationEmails:
      values.find(el => el.id === 'notificationEmails')?.value ?? '',
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`)
      .max(
        FieldsMaxLength.maxNameLength,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          FieldsMaxLength.maxNameLength
        } ${t(translations.errors.characters)}.`,
      ),
    lastName: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`)
      .max(
        FieldsMaxLength.maxNameLength,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          FieldsMaxLength.maxNameLength
        } ${t(translations.errors.characters)}.`,
      ),

    phoneNumber: yup
      .string()
      .matches(phoneRegExp, {
        message: `${t(translations.errors.invalidPhoneNumber)}`,
        excludeEmptyString: true,
      })
      .max(
        FieldsMaxLength.maxPhoneLength,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          FieldsMaxLength.maxPhoneLength
        } ${t(translations.errors.characters)}.`,
      ),

    notificationEmails: yup.string().matches(teamsEmailValidationHelper(), {
      message: `${t(translations.errors.invalidMsEmail)}`,
      excludeEmptyString: true,
    }),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data: any) => {
      firebaseAnalytics.logPressEvent(Routes.EditProfile, 'Save button');

      const updatedProfileData = {
        userId: userId,
        referenceRoleId:
          organizationRoles.find(
            role => role.roleName === data.referenceRoleName,
          )?.roleId || null,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        notificationEmails: data.notificationEmails.length
          ? [data.notificationEmails]
          : [],
      };

      await mutateProfile(updatedProfileData);

      reset();

      history.goBack();
    },
    [history, mutateProfile, organizationRoles, reset, userId],
  );

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container item spacing={3} md={10} lg={7}>
          {values.map(el => {
            return (
              el.id !== 'roleNames' && (
                <Grid item key={el.id}>
                  <CustomTypography
                    variant="buttonText"
                    bold
                    color="greyscale2"
                  >
                    {el.key}
                  </CustomTypography>

                  <div className={classes.inputContainer} key={el.id}>
                    {el.id === 'phoneNumber' && (
                      <CustomPhoneInput
                        fieldName="phoneNumber"
                        isShowError={isShowError}
                        setIsShowError={setIsShowError}
                        errors={errors}
                        placeholder={t(translations.profilePage.phoneNumber)}
                        control={control}
                      />
                    )}
                    {el.id === 'email' && (
                      <TextInput disabled name="email" control={control} />
                    )}

                    {el.id !== 'phoneNumber' && el.id !== 'email' && (
                      <TextInput
                        key={el.id}
                        placeholder={el.key || ''}
                        name={el.id}
                        control={control}
                        error={!!errors[el.id]}
                        helperText={errors[el.id]?.message || ''}
                        maxLength={FieldsMaxLength.maxNameLength}
                        backgroundColor="white"
                      />
                    )}
                  </div>
                </Grid>
              )
            );
          })}
        </Grid>

        <div className={classes.buttons}>
          <div className={classes.button}>
            <Button
              variant="primaryProfile"
              type="button"
              onClick={() => history.goBack()}
            >
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.profilePage.cancel)}
              </CustomTypography>
            </Button>
          </div>
          <div className={classes.button}>
            <Button
              variant="secondarySave"
              type="submit"
              onClick={() => {
                setIsShowError({
                  phoneNumber: true,
                });
              }}
            >
              <CustomTypography variant="subtitle2" bold color="white">
                {t(translations.profilePage.save)}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
