import React, { useCallback, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './FilterBlock.styles';
import { DatePicker } from 'app/shared/components/generic-ui/DatePicker/DatePicker';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useScreen } from 'app/shared/hooks';
import { themeLayout } from 'app/shared/theme/theme';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/types';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';

interface IFilterBlockProps {
  applyHandler: (
    from?: Date | null,
    to?: Date | null,
    userAssigned?: boolean,
  ) => void;
}

export const FilterBlock = ({ applyHandler }: IFilterBlockProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isSideNavOpen = useSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );
  const { entityType }: { organizationId: string; entityType: string } =
    useParams();
  const from = localStorage.getItem(`${entityType}:from`);
  const to = localStorage.getItem(`${entityType}:to`);
  const userAssigned = localStorage.getItem(`${entityType}:userAssigned`);

  const [startDate, setStartDate] = useState<Date | undefined | null>(
    from === null || from === 'null' ? null : from ? new Date(from) : null,
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    to === null || !to ? null : new Date(to),
  );
  const [userAssignedTickets, setUserAssignedTickets] = useState<boolean>(
    !!userAssigned,
  );
  const { isMobile } = useScreen(themeLayout.breakpointBig);

  const applyFilterHandler = useCallback(() => {
    localStorage.setItem(`${entityType}:from`, startDate?.toISOString() || '');
    localStorage.setItem(`${entityType}:to`, endDate?.toISOString() || '');
    localStorage.setItem(
      `${entityType}:userAssigned`,
      `${userAssignedTickets ? '1' : ''}` || '',
    );

    applyHandler(startDate, endDate, userAssignedTickets);
  }, [applyHandler, endDate, entityType, startDate, userAssignedTickets]);

  const changeHandler = useCallback((from?: Date | null, to?: Date | null) => {
    setStartDate(from);
    setEndDate(to);
  }, []);

  return (
    <div className={classes.wrapper}>
      <CustomTypography variant="header6" bold color="accentDark">
        {t(translations.filterBlock.filter)}
      </CustomTypography>

      <div
        className={
          isSideNavOpen ? classes.containerSideNavOpen : classes.container
        }
      >
        <div className={classes.radioContainer}>
          <div className={classes.checkbox}>
            <Checkbox
              checkedIcon={<RadioIcon />}
              icon={<RadioEmpty />}
              onChange={() => setUserAssignedTickets(curr => !curr)}
              checked={userAssignedTickets}
            />

            <CustomTypography variant="caption2" color="accentDark" bold>
              {t(translations.filterBlock.myAssignments)}
            </CustomTypography>
          </div>
        </div>

        <div
          className={
            isSideNavOpen ? classes.datePicker : classes.datePickerContainer
          }
        >
          <DatePicker
            changeDateHandler={changeHandler}
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
          />
        </div>

        {!isMobile && (
          <div className={classes.buttonContainer}>
            <Button
              onClick={applyFilterHandler}
              height={42}
              variant={'secondaryLight'}
            >
              <CustomTypography
                variant="buttonTextSmall"
                bold
                color={'mbsBlue'}
              >
                {t(translations.datePicker.apply)}
              </CustomTypography>
            </Button>
          </div>
        )}
      </div>

      {isMobile && (
        <div className={classes.buttonContainer}>
          <Button
            onClick={applyFilterHandler}
            height={42}
            variant={'secondaryLight'}
          >
            <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
              {t(translations.datePicker.apply)}
            </CustomTypography>
          </Button>
        </div>
      )}
    </div>
  );
};
