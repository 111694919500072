import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  INotification,
  IMetaData,
  ITeamAssignments,
  IEnableNotification,
  IEnableNotificationAllGroup,
  IUpdateNotificationStatus,
  IUpdateNotificationStatusAllGroup,
  IUpdateNotificationProperty,
  IChangeUserNotificationStatusPayload,
  IProjectMembers,
  IUserNotificationSettings,
  NotificationEntityType,
} from 'types';

import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const notificationSettingsQueryKey = 'notificationSettingsEntity';
const teamAssignmentsQueryKey = 'notificationEntityTeamAssignments';
const notificationQueryKey = 'notificationEntity';
const searchTeamAssignments = 'searchTeamAssignmentsEntity';
const changeUserNotificationStatusQueryKey =
  'changeUserNotificationStatusEntity';
const userNotificationSettingsQueryKey = 'userNotificationSettings';

export function useNotificationSettingsEntityApi({
  queryParameters,
  organizationId,
  entityType,
}: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getNotificationSettingsEntityURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/get-notification-settings`;
  }, [entityType, currentSupplierId]);

  const getNotificationSettingsAssignmentsEntityURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/get-notification-settings-assignments`;
  }, [entityType, currentSupplierId]);

  const getUserNotificationSettingsAssignmentsURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/get-user-notification-settings-assignments`;
  }, [entityType, currentSupplierId]);

  const changeNotificationStatusUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/change-status`;
  }, [entityType, currentSupplierId]);

  const changeNotificationStatusAllUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/change-status-all-group`;
  }, [currentSupplierId, entityType]);

  const enableNotificationUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/enable`;
  }, [entityType, currentSupplierId]);

  const enableNotificationAllUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/enable-all-group`;
  }, [currentSupplierId, entityType]);

  const changeNotificationPropertyUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/change-property-value`;
  }, [currentSupplierId, entityType]);

  const changeUsersStatusEntityNotificationUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/change-notification-assignment-status`;
  }, [currentSupplierId, entityType]);

  const changeUserAllNotificationStatusesUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/change-all-notification-assignment-status`;
  }, [currentSupplierId, entityType]);

  const getActiveAssignmentsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${entityType}/get-active-assignments`;
  }, [currentSupplierId, entityType]);

  const {
    data: notificationSettingsEntityResponse,
    refetch: fetchNotificationSettingsEntity,
    isFetching: isFetchingNotificationSettingsEntity,
    error: fetchNotificationSettingsEntityError,
  } = useQuery<IResponseData<INotification[]>, IError>(
    [notificationSettingsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationSettingsEntityURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityNotificationSettings,
          error,
          {
            getNotificationSettingsEntityURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: userNotificationSettingsResponse,
    refetch: fetchUserNotificationSettings,
    isFetching: isFetchingUserNotificationSettings,
    error: fetchUserNotificationSettingsError,
  } = useQuery<IResponseData<IUserNotificationSettings>, IError>(
    [userNotificationSettingsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getUserNotificationSettingsAssignmentsURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getUserNotificationSettings,
          error,
          {
            getUserNotificationSettingsAssignmentsURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: activeEntityAssignmentsResponse,
    refetch: fetchActiveEntityAssignments,
    isFetching: isFetchingActiveEntityAssignments,
    error: fetchActiveEntityAssignmentsError,
  } = useQuery<IResponseData<IProjectMembers[]>, IError>(
    [notificationSettingsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getActiveAssignmentsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getEntityMembers, error, {
          getActiveAssignmentsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: notificationSettingsAssignmentsResponse,
    refetch: fetchNotificationSettingsAssignments,
    isFetching: isFetchingNotificationSettingsAssignments,
    error: fetchNotificationSettingsAssignmentsError,
  } = useQuery<IResponseData<ITeamAssignments[]>, IError>(
    [teamAssignmentsQueryKey, { queryParameters }],
    async () => {
      return await authClient(
        `${getNotificationSettingsAssignmentsEntityURL}`,
        {
          queryParameters,
          method: 'GET',
        },
      );
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityUserNotificationAssignments,
          error,
          {
            getNotificationSettingsAssignmentsEntityURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: changeNotificationStatusEntity,
    isLoading: isChangingNotificationStatusEntity,
    error: changeNotificationStatusEntityError,
  } = useMutation(
    (payload: IUpdateNotificationStatus) =>
      authClient(changeNotificationStatusUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityNotificationStatus,
          error,
          {
            changeNotificationStatusUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: changeNotificationStatusAllGroup,
    isLoading: isChangingNotificationStatusAllGroup,
    error: changeNotificationStatusAllGroupError,
  } = useMutation(
    (payload: IUpdateNotificationStatusAllGroup) =>
      authClient(changeNotificationStatusAllUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationSettingsQueryKey);
        return queryClient.getQueryData<IResponseData<INotification[]>>(
          notificationSettingsQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification[]>>(
            notificationSettingsQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          queryParameters?.description
            ? errors.changeEntityNotificationStatusAllGroup
            : errors.changeEntityNotificationStatusAll,
          error,
          {
            changeNotificationStatusAllUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: enableNotificationEntity,
    isLoading: isEnablingNotificationEntity,
    error: enableNotificationEntityError,
  } = useMutation(
    (payload: IEnableNotification) =>
      authClient(enableNotificationUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.enableEntityNotification,
          error,
          {
            enableNotificationUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: enableNotificationAllGroup,
    isLoading: isEnablingNotificationAllGroup,
    error: enableNotificationAllGroupError,
  } = useMutation(
    (payload: IEnableNotificationAllGroup) =>
      authClient(enableNotificationAllUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationSettingsQueryKey);
        return queryClient.getQueryData<IResponseData<INotification[]>>(
          notificationSettingsQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification[]>>(
            notificationSettingsQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          queryParameters?.description
            ? errors.enableEntityNotificationAllGroup
            : errors.enableEntityNotificationAll,
          error,
          {
            enableNotificationAllUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: changeNotificationPropertyEntity,
    isLoading: isChangingNotificationPropertyEntity,
    error: changeNotificationPropertyEntityError,
  } = useMutation(
    (payload: IUpdateNotificationProperty) =>
      authClient(changeNotificationPropertyUrl, {
        method: 'PATCH',
        queryParameters,
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityNotificationPropertyValue,
          error,
          {
            changeNotificationPropertyUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    data: searchTeamAssignmentsEntityResponse,
    refetch: fetchSearchTeamAssignmentsEntity,
    isFetching: isFetchingSearchTeamAssignmentsEntity,
    error: fetchSearchTeamAssignmentsEntityError,
  } = useQuery<IResponseData<ITeamAssignments[]>, IError>(
    [searchTeamAssignments, { queryParameters }],
    async () => {
      return await authClient(
        `${getNotificationSettingsAssignmentsEntityURL}`,
        {
          queryParameters,
          method: 'GET',
        },
      );
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityUserNotificationAssignments,
          error,
          {
            getNotificationSettingsAssignmentsEntityURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: changeUsersStatusNotificationEntity,
    isLoading: isChangingUsersStatusNotificationEntity,
    error: changeUsersStatusNotificationEntityError,
    isSuccess: isChangingUsersStatusNotificationEntitySuccess,
  } = useMutation(
    (payload: {
      users: IChangeUserNotificationStatusPayload[];
      type?: NotificationEntityType;
      notificationEntityId?: string;
    }) =>
      authClient(
        payload.type !== undefined
          ? `/api/v1/${currentSupplierId}/NotificationSettingsEntity/${payload.type}/change-notification-assignment-status`
          : changeUsersStatusEntityNotificationUrl,
        {
          method: 'POST',
          data: payload.users,
          queryParameters: {
            ...queryParameters,
            notificationEntityId:
              payload.notificationEntityId ||
              queryParameters?.notificationEntityId,
          },
        },
      ),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(changeUserNotificationStatusQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          changeUserNotificationStatusQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            changeUserNotificationStatusQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityUserNotificationAssignments,
          error,
          {
            changeUserNotificationStatusQueryKey,
            variables: JSON.stringify(variables),
          },
        );
        queryClient.refetchQueries(teamAssignmentsQueryKey);
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(changeUserNotificationStatusQueryKey);
      },
    },
  );

  const {
    mutateAsync: changeUserAllNotificationStatuses,
    isLoading: isChangingUserAllNotificationStatuses,
    error: changeUserAllNotificationStatusesError,
    isSuccess: isChangingUserAllNotificationStatusesSuccess,
  } = useMutation(
    (payload: IChangeUserNotificationStatusPayload[]) =>
      authClient(changeUserAllNotificationStatusesUrl, {
        method: 'POST',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(changeUserNotificationStatusQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          changeUserNotificationStatusQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            changeUserNotificationStatusQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityUserNotificationAssignments,
          error,
          {
            changeUserNotificationStatusQueryKey,
            variables: JSON.stringify(variables),
          },
        );
        queryClient.refetchQueries(teamAssignmentsQueryKey);
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(changeUserNotificationStatusQueryKey);
      },
    },
  );

  const notificationSettingsEntity = useMemo<INotification[] | undefined>(
    () => notificationSettingsEntityResponse?.data,
    [notificationSettingsEntityResponse],
  );

  const userNotificationSettings = useMemo<
    IUserNotificationSettings | undefined
  >(
    () => userNotificationSettingsResponse?.data,
    [userNotificationSettingsResponse],
  );

  const activeEntityAssignments = useMemo<IProjectMembers[] | undefined>(
    () => activeEntityAssignmentsResponse?.data,
    [activeEntityAssignmentsResponse],
  );

  const notificationSettingsAssignments = useMemo<
    ITeamAssignments[] | undefined
  >(
    () => notificationSettingsAssignmentsResponse?.data,
    [notificationSettingsAssignmentsResponse],
  );

  const searchNotificationSettingsAssignments = useMemo<
    ITeamAssignments[] | undefined
  >(
    () => searchTeamAssignmentsEntityResponse?.data,
    [searchTeamAssignmentsEntityResponse],
  );

  const notificationSettingsAssignmentsMetadata = useMemo<
    IMetaData | undefined
  >(
    () => notificationSettingsAssignmentsResponse?.metadata,
    [notificationSettingsAssignmentsResponse],
  );

  return {
    notificationSettingsEntity,
    fetchNotificationSettingsEntity,
    isFetchingNotificationSettingsEntity,
    fetchNotificationSettingsEntityError,
    fetchNotificationSettingsAssignments,
    isFetchingNotificationSettingsAssignments,
    fetchNotificationSettingsAssignmentsError,
    notificationSettingsAssignments,
    notificationSettingsAssignmentsMetadata,
    changeNotificationStatusEntity,
    isChangingNotificationStatusEntity,
    changeNotificationStatusEntityError,
    changeNotificationStatusAllGroup,
    isChangingNotificationStatusAllGroup,
    changeNotificationPropertyEntity,
    isChangingNotificationPropertyEntity,
    changeNotificationPropertyEntityError,
    fetchSearchTeamAssignmentsEntity,
    isFetchingSearchTeamAssignmentsEntity,
    fetchSearchTeamAssignmentsEntityError,
    searchNotificationSettingsAssignments,
    changeUsersStatusNotificationEntity,
    isChangingUsersStatusNotificationEntity,
    changeUsersStatusNotificationEntityError,
    isChangingUsersStatusNotificationEntitySuccess,
    changeUserAllNotificationStatuses,
    isChangingUserAllNotificationStatuses,
    changeUserAllNotificationStatusesError,
    isChangingUserAllNotificationStatusesSuccess,
    changeNotificationStatusAllGroupError,
    fetchActiveEntityAssignments,
    isFetchingActiveEntityAssignments,
    fetchActiveEntityAssignmentsError,
    activeEntityAssignments,
    userNotificationSettings,
    fetchUserNotificationSettings,
    isFetchingUserNotificationSettings,
    fetchUserNotificationSettingsError,
    enableNotificationEntity,
    isEnablingNotificationEntity,
    enableNotificationEntityError,
    enableNotificationAllGroup,
    isEnablingNotificationAllGroup,
    enableNotificationAllGroupError,
  };
}
