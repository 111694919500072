import React, { useCallback, useMemo } from 'react';
import { InfoCard } from 'app/shared/components/generic-ui/InfoCard/InfoCard';
import {
  Routes,
  PourEventStatus,
  NotificationEntityType,
  IPourEventCard,
} from 'types';
import { ProjectStatusLabel } from 'app/shared/components/generic-ui/ProjectStatusLabel/ProjectStatusLabel';
import { useStyles } from './PourEventCard.styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  EscapeAction,
  escapePercentSign,
  getNotificationSettingsTab,
} from 'app/shared/utils';
import { firebaseAnalytics } from 'app/shared/analytics';
import { ReactComponent as NotificationSettingsIcon } from 'assets/settings.svg';
import { themeColors } from 'app/shared/theme';
import { PourEventCardBody } from './PourEventCardBody/PourEventCardBody';

interface IPourEventCardProps {
  pourEventCard: IPourEventCard;
}

function sameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export const PourEventCard = ({ pourEventCard }: IPourEventCardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    notificationEntityId,
    organizationId,
  }: { id: string; organizationId: string; notificationEntityId: string } =
    useParams();

  const isCompleted = !sameDay(
    new Date(pourEventCard.lastTicketDispatchDateTimeUtc),
    new Date(),
  );

  const name = useMemo(() => {
    return (
      pourEventCard.name ||
      `${pourEventCard.externalId || '-'}/${pourEventCard.name || '-'}`
    );
  }, [pourEventCard.name, pourEventCard.externalId]);

  const onClickHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPourEventsPage,
      'Pour event card',
    );

    const formattedId = encodeURIComponent(pourEventCard.internalId);
    const formattedName = encodeURIComponent(
      escapePercentSign(EscapeAction.ENCODE, name),
    );

    const statusParam = new URLSearchParams({
      ['status']: isCompleted
        ? PourEventStatus.COMPLETED
        : PourEventStatus.ACTIVE,
    });

    history.push({
      pathname: Routes.SinglePourEvent.replace(
        ':organizationId/:id/:pourEventId/:externalProductId/:name/:page/:perPage',
        `${organizationId}/${notificationEntityId}/${formattedId}/${pourEventCard.mixDesigns[0].externalProductId}/${formattedName}/1/20`,
      ),
      search: `${statusParam}`,
    });

    return null;
  }, [
    pourEventCard.internalId,
    pourEventCard.mixDesigns,
    name,
    isCompleted,
    history,
    organizationId,
    notificationEntityId,
  ]);

  const notificationSettingsClickHandler = useCallback(() => {
    localStorage.setItem(
      NotificationEntityType.POUR_EVENT.toString(),
      JSON.stringify(pourEventCard),
    );

    history.push(
      Routes.NotificationSettings.replace(
        ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
        `${organizationId}/${getNotificationSettingsTab(
          NotificationEntityType.POUR_EVENT,
        )}/1/20/1/20`,
      ),
    );
  }, [history, organizationId, pourEventCard]);

  return (
    <InfoCard testId="info-card">
      <div className={classes.pourEventCardHeader} data-testid="status">
        <ProjectStatusLabel
          status={
            isCompleted ? PourEventStatus.COMPLETED : PourEventStatus.ACTIVE
          }
        />

        {!isCompleted ? (
          <NotificationSettingsIcon
            stroke={themeColors.mbsBlue}
            className={classes.sentNotificationsLink}
            onClick={notificationSettingsClickHandler}
          />
        ) : null}
      </div>

      <PourEventCardBody
        pourEventCard={pourEventCard}
        onClickHandler={onClickHandler}
      />
    </InfoCard>
  );
};
