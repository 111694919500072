import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  pourEventHistoryContainer: {
    marginTop: '10px',
    width: '100%',
  },
  empty: {
    paddingBottom: '14px',
  },
  cellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  ageContainerDark: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.accentLight,
  },
  ageContainerLight: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.greyscale4_50,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 9999,
  },
  projectCardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    width: 222,
  },
  mixIdCardBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    width: 222,
    wordWrap: 'break-word',
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 25,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '11px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: '10px',
  },
  productName: {
    textOverflow: 'ellipsis',
    width: 140,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  valueContainerDark: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.accentLight,
    width: 'fit-content',
    color: themeColors.white,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  container: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: themeColors.greyscale4_50,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
