import React, { useCallback, useMemo, useState } from 'react';
import {
  CustomTeamModal,
  Grid,
  GridVariant,
} from 'app/shared/components/generic-ui';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { INotificationEntityCard, Routes } from 'types';
import { useStyles } from './NotificationEntityCardListItem.styles';
import project_id_icon from 'assets/project_id_icon.svg';
import { NotificationEntityCardTeamSection } from '../NotificationEntityCardCard/NotificationEntityCardTeamSection';
import { StatusSection } from '../NotificationEntityCardCard/StatusSection';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useNotificationSettingsEntityApi } from 'app/shared/hooks';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import users from 'assets/users.svg';
import calendarIcon from 'assets/calendar.svg';
import { format } from 'date-fns';
import { getNotificationEntityName } from 'app/shared/utils';

interface INotificationEntityCardListItemProps {
  notificationEntityCard: INotificationEntityCard;
}

export const NotificationEntityCardListItem = ({
  notificationEntityCard,
}: INotificationEntityCardListItemProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    organizationId,
    entityType,
  }: { organizationId: string; entityType: string } = useParams();
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const eventDate = useMemo(
    () =>
      format(
        new Date(notificationEntityCard.lastTicketDispatchDateTimeUtc),
        'MMM d, y - hh:mm bb',
      ),
    [notificationEntityCard],
  );
  const entityName = getNotificationEntityName(Number(entityType));

  const notificationEntityCardMembersQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: Number(entityType),
      queryParameters: {
        sortBy: 'firstname,lastname',
        sortAsc: true,
        notificationEntityId: notificationEntityCard.internalId,
      },
    };
  }, [organizationId, notificationEntityCard.internalId, entityType]);

  const {
    fetchActiveEntityAssignments: fetchNotificationEntityMembers,
    isFetchingActiveEntityAssignments: isFetchingProjectMembers,
    activeEntityAssignments: projectMembers,
  } = useNotificationSettingsEntityApi(
    notificationEntityCardMembersQueryParams,
  );

  const onClickHandler = (id: string, externalId: string) => {
    history.push(
      generatePath(Routes.NotificationEntityPourEventsPage, {
        entityType,
        notificationEntityId: id,
        organizationId,
        page: 1,
        perPage: 20,
        name: notificationEntityCard.name,
        externalId,
      }),
    );
  };

  const onClickMembers = useCallback(() => {
    setIsTeamModalOpen(true);
    fetchNotificationEntityMembers();
  }, [fetchNotificationEntityMembers]);

  return (
    <>
      {isTeamModalOpen && (
        <CustomTeamModal
          isOpen={isTeamModalOpen}
          closeHandler={() => {
            setIsTeamModalOpen(false);
          }}
          team={projectMembers}
          isLoading={isFetchingProjectMembers}
        />
      )}

      <div className={classes.projectCardBody}>
        <Grid container variant={GridVariant.NONE}>
          <Grid
            item
            md={4}
            lg={4}
            sm={4}
            onClick={() =>
              onClickHandler(
                notificationEntityCard.internalId,
                notificationEntityCard.externalId,
              )
            }
          >
            <div className={classes.name}>
              <CustomTypography variant="header5" bold color="greyscale1">
                {notificationEntityCard?.name || '-'}
              </CustomTypography>
            </div>
          </Grid>

          <Grid
            item
            md={3}
            lg={3}
            sm={3}
            onClick={() =>
              onClickHandler(
                notificationEntityCard.internalId,
                notificationEntityCard.externalId,
              )
            }
          >
            <div className={classes.textContainer}>
              <img src={project_id_icon} className={classes.icon} />
              <CustomTypography variant="bodySmall" color="greyscale1">
                {`${t(translations.notificationEntityCardCard.entityID, {
                  entity: entityName,
                })}: ${notificationEntityCard.externalId || '-'}`}
              </CustomTypography>
            </div>
            <NotificationEntityCardTeamSection
              teammatesCount={
                notificationEntityCard.notificationEntitySummaryModel
                  ?.assignedUsersCount
              }
              showTeamModal={onClickMembers}
            />
          </Grid>

          <Grid item md={4} lg={4} sm={4}>
            {notificationEntityCard.notificationEntityGroupName && (
              <div className={classes.textContainer}>
                <img src={users} className={classes.icon} />
                <CustomTypography variant="bodySmall" color="greyscale1">
                  {`${t(translations.notificationEntityCardCard.group)}: ${
                    notificationEntityCard.notificationEntityGroupName || '-'
                  }`}
                </CustomTypography>
              </div>
            )}
            <div className={classes.textContainer}>
              <img src={calendarIcon} className={classes.icon} />
              <CustomTypography variant="bodySmall" color="greyscale1">
                {t(translations.notificationEntityCardCard.lastDispatch)}:{' '}
                {eventDate || '-'}
              </CustomTypography>
            </div>
          </Grid>

          <Grid item md={1} lg={1} sm={1}>
            <StatusSection
              notificationEntityBaseModel={notificationEntityCard}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
