import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GeneralOptions.styles';
import { Checkbox } from '@material-ui/core';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  PopoverSize,
  Select,
  useSelectWithSearch,
} from 'app/shared/components/generic-ui';
import { useParams } from 'react-router-dom';
import { useNotificationEntityApi } from 'app/shared/hooks';
import { NotificationEntityType } from 'types';
import { useNotificationSettings } from 'app/shared/providers';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';

const skipOnePage = 1;
const dropdownPerPage = 50;
const initialPage = 1;

export const GeneralOptions = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [hasCustomAssignments, setHasCustomAssignments] = useState(false);
  const { organizationId }: { organizationId: string } = useParams();
  const {
    tab,
  }: {
    tab: string;
  } = useParams();
  const {
    changeSelectedEntityHandler,
    entityType,
    selectedEntity,
    isDeletingGroup,
    resetSelectedEntityHandler,
  } = useNotificationSettings();

  const {
    loadMoreHandler,
    searchHandler,
    page,
    searchQuery,
    setPage: changePageHandler,
  } = useSelectWithSearch();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        query: searchQuery,
        skip: (page - skipOnePage) * dropdownPerPage,
        take: dropdownPerPage,
        hasCustomAssignments: hasCustomAssignments,
      },
    };
  }, [entityType, organizationId, page, searchQuery, hasCustomAssignments]);

  const {
    fetchNotificationEntities,
    notificationEntities,
    isFetchingNotificationEntities,
    notificationEntitiesResponse,
  } = useNotificationEntityApi(queryParams);

  const showPaginationLoader: boolean | undefined = useMemo(() => {
    return notificationEntities?.length &&
      notificationEntitiesResponse?.metadata?.totalCount
      ? notificationEntitiesResponse?.metadata?.totalCount >
          notificationEntities?.length
      : false;
  }, [
    notificationEntities?.length,
    notificationEntitiesResponse?.metadata?.totalCount,
  ]);

  useEffect(() => {
    changePageHandler(initialPage);
  }, [changePageHandler, entityType, isDeletingGroup]);

  useEffect(() => {
    if (entityType !== NotificationEntityType.ORGANIZATION)
      fetchNotificationEntities();
  }, [
    changePageHandler,
    entityType,
    fetchNotificationEntities,
    queryParams,
    isDeletingGroup,
    hasCustomAssignments,
  ]);

  const selectedValues = useMemo(() => {
    return selectedEntity?.internalId
      ? [selectedEntity?.internalId]
      : undefined;
  }, [selectedEntity?.internalId]);

  const columns = [
    {
      id: 'externalId',
      label: 'ID',
      alignment: CellAlignment.LEFT,
      size: CellSize.SMALL,
    },
    {
      id: 'name',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.SMALL,
    },
    {
      id: 'notificationEntityGroupName',
      label: 'Group Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const renderCell = useCallback((rowData: any, cellId: string) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{rowData[cellId]}</div>;
  }, []);

  const changeSelectedValueHandler = useCallback(
    (value: string) => {
      if (!value) {
        resetSelectedEntityHandler(entityType);
      } else {
        changeSelectedEntityHandler(
          entityType,
          notificationEntities?.find(el => el.internalId === value),
        );
      }
    },
    [
      resetSelectedEntityHandler,
      entityType,
      changeSelectedEntityHandler,
      notificationEntities,
    ],
  );

  const customAssignmentsChangeHadler = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setHasCustomAssignments(event.target.checked as boolean);
  };

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  return (
    <div>
      <div className={classes.headerContainer}>
        <CustomTypography variant="buttonTabText" color="greyscale2" bold>
          {t(translations.notificationSettingsPage.generalOptions)}
        </CustomTypography>
      </div>
      <div className={classes.formControl}>
        <Select.Search
          className={classes.selectSearch}
          searchQuery={
            entityType === NotificationEntityType.VEHICLE
              ? selectedEntity?.externalId
              : selectedEntity?.name || ''
          }
          isLoading={isFetchingNotificationEntities}
          valueId={'internalId'}
          labelId={
            entityType === NotificationEntityType.VEHICLE
              ? 'externalId'
              : 'name'
          }
          options={notificationEntities}
          selectedValues={selectedValues}
          onChangeSelectedValue={changeSelectedValueHandler}
          showLoader={showPaginationLoader}
          loadMoreHandler={loadMoreHandler}
          withPagination={true}
          onSearchHandler={searchHandler}
          popoverSize={PopoverSize.BIG}
          isMultiSelect={false}
          renderCell={renderCell}
          columns={columns}
        />
        <div style={{ display: 'flex', marginLeft: 10, alignItems: 'center' }}>
          <div className={classes.checkbox}>
            <Checkbox
              checkedIcon={<RadioIcon />}
              icon={<RadioEmpty />}
              onChange={customAssignmentsChangeHadler}
              checked={hasCustomAssignments}
            />

            <CustomTypography variant="bodySmall" color="accentDark">
              {t(translations.notificationSettingsPage.hasCustomSettings, {
                entity: entityName,
              })}
            </CustomTypography>
          </div>
        </div>
      </div>
    </div>
  );
};
