import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './ProjectInfoPage.styles';
import {
  IProjectInfoSectionProps,
  ProjectInfoSection,
} from './ProjectInfoSection/ProjectInfoSection';
import { CreateProjectModal } from '../projects/components/CreateProjectModal';
import {
  ClientProjectMemberRoles,
  Routes,
  NotificationEntityStatus,
} from 'types';
import { useProjectsApi } from 'app/shared/hooks/useProjectsApi';
import { formatPhoneNumber } from 'app/shared/utils/formatPhoneNumber';
import { useOwnProjectsApi } from 'app/shared/hooks/useOwnProjectsApi';
import { firebaseAnalytics } from 'app/shared/analytics';
import { PageHeader } from 'app/shared/components/generic-ui/PageHeader/PageHeader';
import { useAuth } from 'app/shared/providers';

const activeProjectStatus = 0;

export const ProjectInfoPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMember } = useAuth();
  const history = useHistory();
  const { id, organizationId }: { id: string; organizationId: string } =
    useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refetchData, setRefetchData] = useState(false);

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        projectId: id,
      },
    };
  }, [id, organizationId]);

  const { fetchProject, project, deleteProject } = useProjectsApi(queryParams);
  const { fetchOwnProjects } = useOwnProjectsApi();

  const editProjectHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.ProjectInfo,
      'Edit project details action',
    );

    setIsModalOpen(true);
  }, []);

  const deleteProjectHandler = useCallback(async () => {
    firebaseAnalytics.logPressEvent(
      Routes.ProjectInfo,
      'Delete project action',
    );

    const deleteQuery = await deleteProject();
    fetchOwnProjects();
    if (!deleteQuery.isError) {
      history.push(Routes.Projects);
    }
  }, [deleteProject, fetchOwnProjects, history]);

  const preparedProjectMembers = useMemo(
    () =>
      project?.clientProjectMembers?.reduce<
        Record<ClientProjectMemberRoles, string>
      >((result, member) => {
        result[member.role] = member.name || '';

        return result;
      }, {} as any),
    [project],
  );

  const sectionsProps: IProjectInfoSectionProps[] = useMemo(() => {
    return [
      {
        header: t(translations.projectInfo.projectDetails),
        values: [
          {
            key: t(translations.projectInfo.client),
            value: project?.customerName,
          },
          {
            key: t(translations.projectInfo.projectId),
            value: project?.externalProjectId,
          },
        ],
      },
      {
        header: t(translations.projectInfo.clientContact),
        values: [
          {
            key: t(translations.projectInfo.nameAndTitle),
            value: project?.clientContactName,
          },
          {
            key: t(translations.projectInfo.emailAddress),
            value: project?.clientEmail,
          },
          {
            key: t(translations.projectInfo.phoneNumber),
            value: formatPhoneNumber(project?.clientPhone || ''),
          },
        ],
      },
      {
        header: t(translations.projectInfo.projectTeam),
        values: [
          {
            key: t(translations.projectInfo.generalContractor),
            value: preparedProjectMembers
              ? preparedProjectMembers[ClientProjectMemberRoles.CONTRACTOR]
              : '',
          },
          {
            key: t(translations.projectInfo.architect),
            value: preparedProjectMembers
              ? preparedProjectMembers[ClientProjectMemberRoles.ARCHITECT]
              : '',
          },
          {
            key: t(translations.projectInfo.engineer),
            value: preparedProjectMembers
              ? preparedProjectMembers[ClientProjectMemberRoles.ENGINEER]
              : '',
          },
          {
            key: t(translations.projectInfo.finisher),
            value: preparedProjectMembers
              ? preparedProjectMembers[ClientProjectMemberRoles.FINISHER]
              : '',
          },
          {
            key: t(translations.projectInfo.subContractor),
            value: preparedProjectMembers
              ? preparedProjectMembers[ClientProjectMemberRoles.SUB_CONTRACTOR]
              : '',
          },
        ],
      },
      {
        header: t(translations.projectInfo.additionalNotes),
        values: [
          {
            key: t(translations.projectInfo.notes),
            value: project?.description,
          },
        ],
      },
    ];
  }, [preparedProjectMembers, project, t]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject, queryParams, refetchData]);

  return (
    <PageHeader
      header={project?.externalProjectName || ''}
      status={
        project?.projectStatus === activeProjectStatus
          ? NotificationEntityStatus.ACTIVE
          : NotificationEntityStatus.UPCOMING
      }
      editHandler={editProjectHandler}
      deleteHandler={deleteProjectHandler}
      route={Routes.ProjectEvents}
      withActions={!isMember}
    >
      {isModalOpen && (
        <CreateProjectModal
          isOpen={isModalOpen}
          closeHandler={() => {
            setIsModalOpen(false);
          }}
          setRefetchData={setRefetchData}
          project={project}
        />
      )}

      <div className={classes.sections}>
        {sectionsProps.map((section, index) => (
          <ProjectInfoSection
            key={index}
            header={section.header}
            values={section.values}
          />
        ))}
      </div>
    </PageHeader>
  );
};
