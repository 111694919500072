import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { usePlantApi } from 'app/shared/hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './PlantSettings.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  CellAlignment,
  CellSize,
  PaginationType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { Routes, IPlant } from 'types';
import { Switch } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useAuth } from 'app/shared/providers';
import { firebaseAnalytics } from 'app/shared/analytics';

const skipOnePage = 1;

export const PlantSettings = () => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuth();

  const history = useHistory();
  const classes = useStyles();
  const {
    page,
    perPage,
    tab,
    organizationId,
  }: {
    page: string;
    perPage: string;
    orderType: string;
    organizationId: string;
    tab: string;
  } = useParams();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      isInfiniteScrolling: false,
      queryParameters: {
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        sortBy: 'name',
        sortAsc: true,
      },
    };
  }, [organizationId, page, perPage]);

  const {
    plants,
    fetchPlants,
    isFetchingPlants,
    plantsResponse,
    changePlantNotificationState,
  } = usePlantApi(queryParams);

  useEffect(() => {
    fetchPlants();
  }, [fetchPlants, queryParams]);

  const updatePlantNotifications = useCallback(
    (notifications?: boolean, visibility?: boolean, items?: IPlant[]) => {
      const payload = items?.map(plant => {
        return {
          plantOriginId: plant.originId,
          enableNotifications:
            visibility === false
              ? false
              : notifications === undefined
              ? plant.enableNotifications
              : notifications,
          enableVisibility:
            visibility === undefined ? plant.enableVisibility : visibility,
        };
      });

      payload && changePlantNotificationState(payload);
    },
    [changePlantNotificationState],
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'plantName',
        label: t(translations.plantListing.plants),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'enableVisibility',
        label: t(translations.plantListing.visibility),
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
      {
        id: 'enableNotifications',
        label: t(translations.plantListing.notifications),
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
    ];
  }, [t]);

  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'enableNotifications' || cellId === 'enableVisibility') {
        return (
          <div className={classes.switchContainer}>
            {!isSuperAdmin && (
              <CustomTypography
                variant="subtitle1"
                bold
                color={rowData[cellId] ? 'mbsBlue' : 'greyscale2'}
              >
                {rowData[cellId]
                  ? t(translations.plantListing.on)
                  : t(translations.plantListing.off)}
              </CustomTypography>
            )}

            <Switch
              disabled={!isSuperAdmin}
              checked={rowData[cellId]}
              onChange={() => {
                firebaseAnalytics.logPressEvent(
                  Routes.OrganizationDetails,
                  'Enable visibility and notifications for Plant',
                );

                updatePlantNotifications(
                  cellId === 'enableNotifications'
                    ? !rowData.enableNotifications
                    : undefined,
                  cellId === 'enableVisibility'
                    ? !rowData.enableVisibility
                    : undefined,
                  [rowData],
                );
              }}
              color="primary"
              className={classes.toggle}
              classes={{
                root: classes.rootSwitch,
                switchBase: classes.switchBase,
                track: classes.switchTrack,
                thumb: classes.thumb,
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        );
      } else {
        return <div>{rowData[cellId] || ''}</div>;
      }
    },
    [
      classes.rootSwitch,
      classes.switchBase,
      classes.switchContainer,
      classes.switchTrack,
      classes.thumb,
      classes.toggle,
      isSuperAdmin,
      t,
      updatePlantNotifications,
    ],
  );

  const contactUsHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.OrganizationDetails,
      'Contact us Button from Plant settings',
    );

    return history.push(
      Routes.ContactUs.replace(':organizationId', organizationId),
    );
  }, [history, organizationId]);

  return (
    <div>
      <SectionSpoiler
        header={t(translations.plantListing.plantListing)}
        withIcons={true}
      >
        {!isSuperAdmin && (
          <div className={classes.alertText}>
            <CustomTypography variant="subtitle1" color="accentDark">{`${t(
              translations.plantListing.noPermissions,
            )}`}</CustomTypography>
            <div className={classes.link} onClick={contactUsHandler}>
              <CustomTypography bold variant="subtitle1" color="mbsBlue">
                {t(translations.plantListing.contactUs)}
              </CustomTypography>
            </div>
          </div>
        )}

        {isSuperAdmin && (
          <div className={classes.actions}>
            <div
              className={classes.activateAll}
              onClick={() => {
                firebaseAnalytics.logPressEvent(
                  Routes.OrganizationDetails,
                  'Activate all(notifications and visibility) in Plant settings',
                );

                updatePlantNotifications(true, true, plants);
              }}
            >
              <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
                {t(translations.truckListing.activateAll)}
              </CustomTypography>
            </div>
            <div
              className={classes.deactivateAll}
              onClick={() => {
                firebaseAnalytics.logPressEvent(
                  Routes.OrganizationDetails,
                  'Deactivate all(notifications and visibility) in Plant settings',
                );

                updatePlantNotifications(false, false, plants);
              }}
            >
              <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
                {t(translations.truckListing.deactivateAll)}
              </CustomTypography>
            </div>
          </div>
        )}

        <Table
          withHover={true}
          withPerPageCount={true}
          withPagination={true}
          columns={columns}
          tableData={plants}
          withCheckbox={false}
          isLoading={isFetchingPlants}
          emptyTableText={`${t(translations.plantGroupSection.noPlants)}.`}
          baseURL={Routes.OrganizationDetails.replace(
            ':organizationId/:tab',
            `${organizationId}/${tab}`,
          )}
          paginationType={PaginationType.MAIN}
          rowsTotal={plantsResponse?.metadata?.totalCount}
          renderCell={renderCell}
          withPadding={false}
        />
      </SectionSpoiler>
    </div>
  );
};
