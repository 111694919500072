import { firebaseAnalytics } from 'app/shared/analytics';
import { RoleAssignments } from 'app/shared/components/generic-ui/RoleAssignments';
import { useOrganizationTeamApi } from 'app/shared/hooks/useOrganizationTeamApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IProjectMemberRequest, IProjectMembers, Routes } from 'types';
import { IUsersAssigned } from '../OrganizationTeamAssignments';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

export const OrganizationRoleAssignments = () => {
  const { t } = useTranslation();

  const { organizationId }: { organizationId: string } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const {
    fetchOrganizationRoles,
    organizationRoles,
    addUsersToRole,
    removeUserFromRole,
  } = useOrganizationTeamApi({
    organizationId: organizationId,
  });

  useEffect(() => {
    fetchOrganizationRoles();
  }, [fetchOrganizationRoles]);

  const preparedRoles = useMemo(() => {
    return organizationRoles?.map(role => ({
      roleId: role.roleId,
      roleName: role.roleName,
    }));
  }, [organizationRoles]);

  const preparedUsersAssigned = useMemo(() => {
    let usersAssigned: IUsersAssigned = {};

    organizationRoles?.map(el => {
      usersAssigned = { ...usersAssigned, [el.roleName]: [] };
      el.assignedUsers.forEach(user => {
        usersAssigned[el.roleName].push(user);
      });
    });

    return usersAssigned;
  }, [organizationRoles]);

  const preparedMembers = useMemo(() => {
    const usersAssigned: IProjectMembers[] = [];

    organizationRoles?.map(el => {
      el.assignedUsers.map(user => {
        if (!usersAssigned.find(el => el.userId === user.userId)) {
          usersAssigned.push({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            roleNames: [el.roleName],
          });
        } else {
          usersAssigned
            .find(el => el.userId === user.userId)
            ?.roleNames?.push(el.roleName);
        }
      });
    });

    return usersAssigned;
  }, [organizationRoles]);

  const addMembersHandler = useCallback(
    async (members: IProjectMemberRequest[]) => {
      firebaseAnalytics.logPressEvent(
        Routes.OrganizationDetails,
        'Team assignments card add teammate button',
      );

      setIsLoading(true);

      await addUsersToRole(members).catch(() => setIsLoading(false));
      fetchOrganizationRoles().then(() => setIsLoading(false));
    },
    [addUsersToRole, fetchOrganizationRoles],
  );

  const removeMemberHandler = useCallback(
    async user => {
      firebaseAnalytics.logPressEvent(
        Routes.OrganizationDetails,
        'Team assignments card remove teammate',
      );

      setIsLoading(true);

      await removeUserFromRole(user).catch(() => setIsLoading(false));
      fetchOrganizationRoles().then(() => setIsLoading(false));
    },
    [fetchOrganizationRoles, removeUserFromRole],
  );

  return (
    <RoleAssignments
      roles={preparedRoles}
      members={preparedMembers}
      usersAssigned={preparedUsersAssigned}
      header={t(translations.organizationDetailsPage.teamAssignments)}
      modalHeader={t(translations.organizationDetailsPage.addTeammates)}
      addMembersHandler={addMembersHandler}
      deleteMemberHandler={removeMemberHandler}
      isLoading={isLoading}
    />
  );
};
