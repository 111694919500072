import React, { useMemo } from 'react';
import { IHistoryRecord, NotificationCategory } from 'types';
import { themeColors } from 'app/shared/theme/theme';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Grid, GridVariant } from 'app/shared/components/generic-ui';
import { useStyles } from './CardProbeDataSection.styles';

interface ICardProbeDataSectionProps {
  batchEvent: IHistoryRecord;
}

interface IValuesConfig {
  header: string;
  color?: keyof typeof themeColors;
  value: string;
}

export const CardProbeDataSection = ({
  batchEvent,
}: ICardProbeDataSectionProps) => {
  const { t } = useTranslation();
  const { renderProbeData } = useProbeDataFormatter();
  const classes = useStyles();

  const valuesConfig: IValuesConfig[] = useMemo(() => {
    const manualTestDataConfig = [
      {
        header: t(translations.batchEventCard.slumpFlow),
        value: batchEvent?.slumpFlow
          ? renderProbeData(batchEvent.slumpFlow)
          : '-',
      },
      {
        header: t(translations.batchEventCard.airContent),
        value: batchEvent.airContentPercentage
          ? `${renderProbeData(batchEvent.airContentPercentage)} %`
          : '-',
      },
      {
        header: t(translations.batchEventCard.unitWtDensity),
        value: batchEvent.density?.value
          ? `${renderProbeData(batchEvent.density)}`
          : '-',
      },
    ];

    const revolutions = {
      header: t(translations.batchEventCard.revolutions),
      value: renderProbeData(batchEvent.revolutions),
      color:
        batchEvent.notificationCategory === NotificationCategory.REVOLUTIONS
          ? 'systemRed'
          : 'accentLight',
    };

    const defaultValues = [
      {
        header: t(translations.batchEventCard.slump),
        value: renderProbeData(batchEvent.workability),
        color:
          batchEvent.notificationCategory === NotificationCategory.SLUMP
            ? 'systemRed'
            : 'accentLight',
      },
      {
        header: t(translations.batchEventCard.concreteTemp),
        value: renderProbeData(batchEvent.probeTemperature),
        color:
          batchEvent.notificationCategory === NotificationCategory.TEMPERATURE
            ? 'systemRed'
            : 'accentLight',
      },
      {
        header: !batchEvent.isManualProbe
          ? t(translations.batchEventCard.pressure)
          : t(translations.batchEventCard.slumpMeter),
        value: renderProbeData(batchEvent.pressure),
      },
    ];

    return !batchEvent.isManualProbe
      ? [...defaultValues, revolutions]
      : [...defaultValues, ...manualTestDataConfig];
  }, [batchEvent, renderProbeData, t]);

  return (
    <Grid container spacing={0} lg={12} md={12} className={classes.values}>
      {valuesConfig.map((item, index) => (
        <Grid
          variant={
            batchEvent.isManualProbe ? GridVariant.SMALL : GridVariant.MEDIUM
          }
          item
          container
          key={index}
          className={classes.value}
        >
          <Grid item md={12} lg={12}>
            <CustomTypography variant="caption1" color="greyscale2">
              {item.header}
            </CustomTypography>
          </Grid>
          <Grid item md={12} lg={12}>
            <CustomTypography
              variant="header5"
              bold
              color={item.color || 'accentLight'}
            >
              {item.value}
            </CustomTypography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
