import React, { useEffect, useState, useCallback } from 'react';
import { useStyles } from './Search.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase';
import { CellAlignment, CellSize, TableHeaderType } from '../Table/Table.types';
import { Table } from '../Table/Table';
import { CustomTypography } from '../Typography/Typography';
import search_icon from 'assets/search_icon.svg';
import { ClickAwayListener } from '@material-ui/core';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import throttle from 'lodash.throttle';
import { CircularProgress } from './../CircularProgress';
import { firebaseAnalytics } from '../../../analytics';
import { EscapeAction, escapePercentSign } from 'app/shared/utils';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store/slices';

interface ISearchProps {
  columns?: {
    id: string;
    label: string;
    alignment: CellAlignment;
    size: CellSize;
    sortable?: undefined;
  }[];
  withButton?: boolean;
  entries?: any[] | null;
  onChange?: (value: string) => void;
  onSearch?: () => void;
  isLoading?: boolean;
  placeholder: string;
  onClickRow?: (value: any) => void;
  getRowId?: string;
  analyticsReference?: string;
  disableEnter?: boolean;
  resetValue?: boolean;
  withBorder?: boolean;
  withCheckbox?: boolean;
  selectedItemsHandler?: (itemIds: string[]) => void;
  selectedItems?: string[];
  height?: number;
  changeSelectedState?: (ids: string[]) => void;
  renderData?: (rowData: any, cellId: string) => void;
  searchParams?: URLSearchParams;
  valueRef?: React.MutableRefObject<string>;
  onValueChange?: (value: string) => void;
}

const notInTheString = -1;
const timeout = 1000;
const firstElementInArray = 0;
const secondElement = 1;

export const Search = ({
  columns,
  entries,
  onChange,
  onSearch,
  isLoading,
  placeholder,
  onClickRow,
  getRowId,
  withButton = true,
  analyticsReference,
  disableEnter = false,
  resetValue,
  withBorder = false,
  withCheckbox = false,
  selectedItemsHandler,
  height,
  selectedItems,
  changeSelectedState,
  renderData,
  searchParams,
  valueRef,
  onValueChange,
}: ISearchProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('search');

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(searchQuery || '');
  const { page, perPage }: { page: string; perPage: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (resetValue !== undefined) {
      setValue('');
    }
  }, [resetValue]);

  useEffect(() => {
    onValueChange?.(value);
    if (valueRef?.current !== undefined) {
      valueRef.current = value;
    }
  }, [value, valueRef, onValueChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleSave = useCallback(
    throttle(nextValue => onChange && onChange(nextValue), timeout),
    [],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!event.target.value && !disableEnter) {
        dispatch(actions.setNotificationEntitySectionDefaultState());
        if (searchParams) {
          searchParams.set('search', event.target.value);
        }

        const currentSearchParams =
          searchParams ||
          new URLSearchParams({
            ['search']: event.target.value,
          });

        history.push({
          pathname: location.pathname,
          search: `${currentSearchParams}`,
        });
      }

      setIsOpen(true);
      setValue(escapePercentSign(EscapeAction.ENCODE, event.target.value));
      throttleSave(event.target.value);
    },
    [
      disableEnter,
      dispatch,
      history,
      location.pathname,
      searchParams,
      throttleSave,
    ],
  );

  const onSubmitHandler = useCallback(() => {
    setIsOpen(false);

    onSearch && onSearch();

    if (searchParams) {
      searchParams.set('search', escapePercentSign(EscapeAction.ENCODE, value));
    }

    const currentSearchParams =
      searchParams ||
      new URLSearchParams({
        ['search']: escapePercentSign(EscapeAction.ENCODE, value),
      });

    history.push({
      pathname: location.pathname.replace(
        `/${page}/${perPage}`,
        `/1/${perPage}`,
      ),
      search: `${currentSearchParams}`,
    });
  }, [
    onSearch,
    searchParams,
    value,
    history,
    location.pathname,
    page,
    perPage,
  ]);

  const onClickHandler = useCallback(
    (value: any) => {
      firebaseAnalytics.logPressEvent(
        analyticsReference ?? '',
        'Smart search item',
      );

      searchQuery && setValue(searchQuery);
      disableEnter && setValue(value.externalProjectGID);
      setIsOpen(false);
      onClickRow && onClickRow(value);
    },
    [analyticsReference, searchQuery, disableEnter, onClickRow],
  );

  const getSearchMatchIndex = useCallback(
    (cellContent: string) => {
      return cellContent
        .toLowerCase()
        .search(
          new RegExp(
            value.toLowerCase().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'),
          ),
        );
    },
    [value],
  );

  const renderCell = (rowData: any, cellId: string) => {
    let cellContent =
      cellId === 'name' && rowData.firstName && rowData.lastName
        ? `${rowData.firstName} ${rowData.lastName}`
        : Array.isArray(rowData[cellId])
        ? rowData[cellId].join(' ,')
        : rowData[cellId];

    if (cellContent && value) {
      const index = getSearchMatchIndex(cellContent);

      cellContent =
        index !== notInTheString
          ? [
              cellContent.substring(firstElementInArray, index),
              cellContent.substring(index, index + value.length),
              cellContent.substring(index + value.length),
            ]
          : cellContent;
    }

    return (
      <div className={classes.cellContainer}>
        {value && Array.isArray(cellContent) ? (
          <div className={withCheckbox ? classes.wrapContent : ''}>
            {cellContent.map((el: string, index: number) => {
              return (
                <span key={index}>
                  {index === secondElement ? <b>{el}</b> : <span>{el}</span>}
                </span>
              );
            })}
          </div>
        ) : (
          <div className={withCheckbox ? classes.wrapContent : ''}>
            {cellContent}
          </div>
        )}
      </div>
    );
  };

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !disableEnter) {
        firebaseAnalytics.logPressEvent(
          analyticsReference ?? '',
          'Search field key "Enter"',
          value,
        );

        onSubmitHandler();
      }
    },
    [disableEnter, analyticsReference, value, onSubmitHandler],
  );

  const handleSearchIconClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      analyticsReference ?? '',
      'Search field loupe icon',
      value,
    );

    onSubmitHandler();
  }, [onSubmitHandler, analyticsReference, value]);

  useEffect(() => {
    if (searchQuery) {
      setValue(searchQuery);
      onSearch && onSearch();
      onChange && onChange(searchQuery);
      setIsOpen(false);
    } else {
      setValue('');
      onChange && onChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      <div
        style={{ height: height || '' }}
        className={
          !withButton
            ? classes.withoutButton
            : withBorder
            ? classes.withBorder
            : columns
            ? classes.searchContainer
            : classes.secondarySearchContainer
        }
      >
        <InputBase
          data-testid="input"
          placeholder={placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={escapePercentSign(
            EscapeAction.DECODE,
            decodeURIComponent(value),
          )}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleOnChange}
          onKeyPress={handleKeyPress}
        />
        {withButton && (
          <button
            type="button"
            className={classes.searchIcon}
            onClick={handleSearchIconClick}
          >
            <img
              src={search_icon}
              data-testid="search_icon"
              alt="search"
              className={classes.root}
            />
          </button>
        )}

        {(entries?.length || isLoading) && isOpen && (
          <ClickAwayListener
            onClickAway={() => {
              setIsOpen(false);
            }}
          >
            <div
              className={
                entries && entries.length
                  ? classes.tableContainer
                  : classes.emptyContainer
              }
            >
              {entries && entries.length && !isLoading && columns ? (
                <Table
                  columns={columns}
                  tableData={entries}
                  withCheckbox={withCheckbox}
                  onClickRow={onClickHandler}
                  renderCell={renderData || renderCell}
                  headerType={TableHeaderType.LIGHT}
                  withBorders={false}
                  rowId={getRowId}
                  withPadding={!withCheckbox}
                  sendSelectedItems={selectedItemsHandler}
                  selectedData={selectedItems}
                  changeSelectedState={changeSelectedState}
                />
              ) : (
                <div style={{ padding: 16 }}>
                  {isLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <CustomTypography variant="bodySmall" color="greyscale2">
                      {t(translations.errors.noResultsFound)}
                    </CustomTypography>
                  )}
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
};
