import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

const mobile = 585;

export const useStyles = makeStyles(theme => ({
  span: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  popover: {
    width: 258,
    boxSizing: 'border-box',
    maxHeight: 178,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  marginsSmall: {
    marginLeft: 17,
    marginTop: 10,
  },
  marginsBig: {
    [theme.breakpoints.up(mobile)]: {
      marginLeft: 38,
      marginTop: 5,
    },
    [theme.breakpoints.down(mobile)]: {
      marginLeft: 35,
      marginTop: 10,
    },
  },
  bigPopover: {
    overflowY: 'scroll',

    [theme.breakpoints.up(mobile)]: {
      width: 520,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: themeColors.greyscale4,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: themeColors.greyscale3,
      },
    },
    [theme.breakpoints.down(mobile)]: {
      width: 258,
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
        backgroundColor: themeColors.greyscale4,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: themeColors.greyscale3,
      },
    },
    boxSizing: 'border-box',
    maxHeight: 178,
  },
  biggerPopover: {
    overflowY: 'scroll',
    position: 'relative',

    [theme.breakpoints.up(mobile)]: {
      width: 516,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: themeColors.greyscale4,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: themeColors.greyscale3,
      },
    },
    [theme.breakpoints.down(mobile)]: {
      width: 258,
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
        backgroundColor: themeColors.greyscale4,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: themeColors.greyscale3,
      },
    },
    boxSizing: 'border-box',
    maxHeight: 300,
  },

  menuList: { marginTop: 4, width: 'fit-content' },
  menuListSmall: { marginTop: 4, width: '100%' },
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  checkbox: {
    padding: '5px 14px 5px 0px',
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  menuItem: {
    backgroundColor: `${themeColors.white} !important`,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&:focus': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&.Mui-active': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  loading: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  selectAllButton: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    zIndex: 9999,
    backgroundColor: themeColors.white,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '13px 23px',
    boxSizing: 'border-box',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));
