import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  infoCard: {
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: 281,
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      width: themeLayout.infoCardWidth,
    },
    boxSizing: 'border-box',
    padding: '16px',
    border: `1px solid ${themeColors.white}`,
    borderRadius: '2px',
    boxShadow: themeShadows.shadowMedium,
    margin: '10px',
    backgroundColor: themeColors.white,
    cursor: 'pointer',
    position: 'relative',
  },
}));
