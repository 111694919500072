import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IApiParams,
  IChangeProbeValidity,
  ICreateManualProbe,
  IError,
  IProbeRanges,
  IResponseData,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const manualProbeRangesQueryKey = 'getProbeRanges';
const manualProbeDataQueryKey = 'manualProbeQueryKey';
const invalidateProbeQueryKey = 'invalidateProbe';

export function useManualProbeApi({
  organizationId,
  queryParameters,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getProbeRangesUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/probes/get-ranges`;
  }, [currentSupplierId]);

  const createManualProbeUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/probes/create-manual-probe`;
  }, [currentSupplierId]);

  const invalidateManualProbeUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/probes/change-validity`;
  }, [currentSupplierId]);

  const {
    data: probeRangesResponse,
    refetch: fetchProbeRanges,
    isLoading: isFetchingProbeRanges,
    error: fetchProbeRangesError,
  } = useQuery<IResponseData<IProbeRanges>, IError>(
    manualProbeRangesQueryKey,
    async () => {
      return await authClient(`${getProbeRangesUrl}`, {
        method: 'GET',
        queryParameters,
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getProbeRanges, error, {
          getProbeRangesUrl: getProbeRangesUrl,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: createManualProbe,
    isLoading: isCreateManualProbe,
    error: createManualProbeError,
  } = useMutation(
    (payload: ICreateManualProbe) =>
      authClient(createManualProbeUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(manualProbeDataQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          manualProbeDataQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            manualProbeDataQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.createManualProbe, error, {
          createManualProbeUrl,
          ...variables,
        });
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(manualProbeDataQueryKey);
        await queryClient.refetchQueries(manualProbeDataQueryKey);
      },
    },
  );

  const {
    mutateAsync: invalidateManualProbe,
    isLoading: isInvalidateManualProbe,
    error: invalidateManualProbeError,
  } = useMutation(
    (payload: IChangeProbeValidity) =>
      authClient(invalidateManualProbeUrl, {
        method: 'PATCH',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(invalidateProbeQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          invalidateProbeQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            invalidateProbeQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.invalidateManualProbe, error, {
          invalidateManualProbeUrl,
          ...variables,
        });
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(manualProbeDataQueryKey);
        await queryClient.refetchQueries(manualProbeDataQueryKey);
      },
    },
  );

  const probeRanges = useMemo<IProbeRanges | undefined>(
    () => probeRangesResponse?.data,
    [probeRangesResponse],
  );

  return {
    probeRanges,
    fetchProbeRanges,
    isFetchingProbeRanges,
    fetchProbeRangesError,
    createManualProbe,
    isCreateManualProbe,
    createManualProbeError,
    invalidateManualProbe,
    isInvalidateManualProbe,
    invalidateManualProbeError,
  };
}
