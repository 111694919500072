import { TabIds } from 'app/shared/components/layout/TabNavigation/TabNavigation.types';

export const getNotificationsDefaultTab = (tab: string) => {
  switch (tab) {
    case TabIds.POUR_EVENT:
    case TabIds.CUSTOMER: {
      return 'Slump';
    }
    case TabIds.VEHICLE:
    case TabIds.DRIVER: {
      return 'Truck';
    }
    default:
      return 'Batched Materials';
  }
};
