import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IMetaData,
  IPourEvent,
  IMixDesign,
  IRenamePourEvent,
  IPourEventHistory,
  IError,
  IApiParams,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const pourEventsQueryKey = 'getPourEvents';
const pourEventHistoryKey = 'getPourEventHistory';
const pourEventMixDesignQueryKey = 'getPourEventMixDesign';
const pourEventQueryKey = 'renamePourEvent';

export function usePourEventsApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getPourEventsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/PourEvents/get-project-pour-events-paged`;
  }, [currentSupplierId]);

  const getPourEventHistoryUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/PourEvents/get-pour-event-history`;
  }, [currentSupplierId]);

  const getPourEventMixDesigns = useMemo(() => {
    return `/api/v1/${currentSupplierId}/PourEvents/get-mix-design-details`;
  }, [currentSupplierId]);

  const renamePourEventUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/PourEvents/rename-pour-event`;
  }, [currentSupplierId]);

  const {
    mutateAsync: mutatePourEvent,
    isLoading: isMutatingPourEvent,
    error: mutatePourEventError,
  } = useMutation(
    (payload: IRenamePourEvent) =>
      authClient(renamePourEventUrl, { method: 'PATCH', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(pourEventQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          pourEventQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            pourEventQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.renamePourEvent, error, {
          renamePourEventUrl,
          ...variables,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(pourEventQueryKey);
      },
    },
  );

  const {
    data: pourEventMixDesignResponse,
    refetch: fetchPourEventMixDesign,
    isLoading: isFetchingPourEventMixDesign,
    error: fetchPourEventMixDesignError,
  } = useQuery<IResponseData<IMixDesign[]>, IError>(
    [pourEventMixDesignQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getPourEventMixDesigns}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (error: IError) => {
        firebaseAnalytics.logErrorEvent(errors.getPourEventMixDesigns, error, {
          getPourEventMixDesigns,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: pourEventsResponse,
    refetch: fetchPourEvents,
    isLoading: isFetchingPourEvents,
    error: fetchingPourEventError,
  } = useQuery<IResponseData<IPourEvent[]>, IError>(
    [pourEventsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getPourEventsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getPourEventsList, error, {
          getPourEventsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: pourEventHistoryResponse,
    refetch: fetchPourHistory,
    isFetching: isFetchingPourEventHistory,
    error: fetchPourEventHistoryError,
  } = useQuery<IResponseData<IPourEventHistory>, IError>(
    [pourEventHistoryKey, { queryParameters }],
    async () => {
      return await authClient(`${getPourEventHistoryUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getPourEventHistory, error, {
          getPourEventHistoryUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const pourEvents = useMemo<IPourEvent[] | undefined>(
    () => pourEventsResponse?.data,
    [pourEventsResponse],
  );

  const pourEventHistory = useMemo<IPourEventHistory | undefined>(
    () => pourEventHistoryResponse?.data,
    [pourEventHistoryResponse],
  );

  const pourEventMixDesign = useMemo<IMixDesign[] | undefined>(
    () => pourEventMixDesignResponse?.data,
    [pourEventMixDesignResponse],
  );

  const pourEventsMetadata = useMemo<IMetaData | undefined>(
    () => pourEventsResponse?.metadata,
    [pourEventsResponse],
  );

  return {
    pourEventsMetadata,
    pourEvents,
    fetchPourEvents,
    isFetchingPourEvents,
    pourEventHistoryResponse,
    fetchPourHistory,
    pourEventHistory,
    isFetchingPourEventHistory,
    fetchPourEventMixDesign,
    isFetchingPourEventMixDesign,
    pourEventMixDesignResponse,
    pourEventMixDesign,
    mutatePourEvent,
    isMutatingPourEvent,
    mutatePourEventError,
    fetchingPourEventError,
    fetchPourEventMixDesignError,
    fetchPourEventHistoryError,
  };
}
