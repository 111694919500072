import React, { useCallback, useMemo, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useRoleApi } from 'app/shared/hooks/useRoleApi';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './InviteTeammateForm.styles';
import { IRole, Routes, UserB2cRoles } from 'types';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import { validationHelper } from 'app/shared/utils';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useTeammateInvitationApi } from 'app/shared/hooks';
import { useParams } from 'react-router-dom';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';

const maxSymbols = 200;

interface IInviteTeammateFormProps {
  inviteHandler: (url: string, email: string) => void;
  isAdmin: boolean;
}

export const InviteTeammateForm = ({
  isAdmin,
  inviteHandler,
}: IInviteTeammateFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const { roles } = useRoleApi(organizationId);

  const { inviteTeammate, isInvitingTeammate } = useTeammateInvitationApi({
    organizationId: organizationId,
  });
  const [isMember, setIsMember] = useState(!isAdmin);

  const formDefaultValues = {
    isAdmin: isAdmin ? UserB2cRoles.ADMIN : UserB2cRoles.MEMBER,
    roleName: '',
    email: '',
  };

  const validationSchema = yup.object().shape({
    isAdmin: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),

    roleName: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
    email: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`)
      .matches(validationHelper(), {
        message: `${t(translations.errors.invalidEmailAddress)}`,
        excludeEmptyString: true,
      })
      .max(
        maxSymbols,
        `${t(translations.errors.thisFieldCanNotExceed)} ${maxSymbols} ${t(
          translations.errors.characters,
        )}.`,
      ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const isDisabled = useMemo(() => {
    return !dirtyFields.email || !dirtyFields.roleName || isInvitingTeammate;
  }, [dirtyFields.email, dirtyFields.roleName, isInvitingTeammate]);

  const onSubmit = useCallback(
    (data: any) => {
      firebaseAnalytics.logPressEvent(
        Routes.OrganizationDetails,
        'Send invitation button',
      );

      const formattedData = {
        inviteEmail: data.email,
        isAdmin: data.isAdmin === UserB2cRoles.ADMIN,
        roleId:
          roles?.find(role => role.roleName === data.roleName)?.roleId || '',
      };

      inviteTeammate(formattedData).then(response => {
        inviteHandler(response.data, data.email);
        reset();
      });
    },
    [inviteHandler, inviteTeammate, reset, roles],
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.labelContainer}>
          <CustomTypography color="accentDark" variant="bodyRegular">
            {`${t(translations.inviteTeammateModal.inviteAs)}:`}
          </CustomTypography>
        </div>

        <Controller
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="isAdmin"
                value={value}
                onChange={e => {
                  setIsMember(prevState => !prevState);
                  onChange(e);
                }}
              >
                <FormControlLabel
                  value={UserB2cRoles.MEMBER}
                  control={
                    <Radio
                      color="primary"
                      checkedIcon={<RadioIcon />}
                      icon={<RadioEmpty />}
                    />
                  }
                  label={
                    <CustomTypography color="accentDark" variant="bodySmall">
                      {t(translations.inviteTeammateModal.member)}
                    </CustomTypography>
                  }
                />

                <FormControlLabel
                  value={UserB2cRoles.ADMIN}
                  control={
                    <Radio
                      color="primary"
                      checkedIcon={<RadioIcon />}
                      icon={<RadioEmpty />}
                    />
                  }
                  label={
                    <CustomTypography color="accentDark" variant="bodySmall">
                      {t(translations.inviteTeammateModal.administrator)}
                    </CustomTypography>
                  }
                />
              </RadioGroup>
            </FormControl>
          )}
          name={'isAdmin'}
          control={control}
        />

        <div className={classes.label}>
          <CustomTypography color="greyscale1" variant="caption1">
            {t(translations.inviteTeammateModal.role)}
          </CustomTypography>
        </div>

        <Controller
          render={({ field: { onChange, value } }) => (
            <FormControl variant="outlined" className={classes.formControl}>
              {value === '' && (
                <InputLabel
                  shrink={false}
                  disableAnimation={true}
                  classes={{ outlined: classes.labelRoot }}
                >
                  <CustomTypography variant="bodySmall" color="greyscale2">
                    {t(translations.inviteTeammateModal.selectRole)}
                  </CustomTypography>
                </InputLabel>
              )}

              <Select
                labelId="select-outlined-label"
                id="select-outlined"
                value={value}
                onChange={onChange}
                placeholder={t(translations.inviteTeammateModal.selectRole)}
                error={!!errors.roleName}
                classes={{
                  outlined: classes.select,
                }}
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
              >
                {roles &&
                  roles.map((role: IRole) => (
                    <MenuItem value={role.roleName} key={role.roleId}>
                      <CustomTypography variant="subtitle1" color="accentDark">
                        {role.roleName}
                      </CustomTypography>
                    </MenuItem>
                  ))}
              </Select>

              {errors?.roleName?.message && (
                <FormHelperText className={classes.helperText}>
                  {errors?.roleName?.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
          name={'roleName'}
          control={control}
        />

        <div className={classes.label}>
          <CustomTypography color="greyscale1" variant="caption1">
            {t(translations.inviteTeammateModal.emailAddress)}
          </CustomTypography>
        </div>

        <TextInput
          placeholder={t(translations.inviteTeammateModal.typeEmailHere)}
          name="email"
          helperText={errors?.email?.message}
          control={control}
          error={!!errors?.email}
          maxLength={maxSymbols}
        />

        <div className={classes.labelContainer}>
          <CustomTypography variant="subtitle1" color="greyscale2">
            {isMember
              ? t(translations.inviteTeammateModal.inviteMembers)
              : t(translations.inviteTeammateModal.inviteAdmins)}
          </CustomTypography>
        </div>

        <div className={classes.saveButton}>
          <Button type="submit" variant="primarySave" disabled={isDisabled}>
            <CustomTypography variant="buttonText" bold color="white">
              {t(translations.inviteTeammateModal.sendInvitation)}
            </CustomTypography>
          </Button>
        </div>
      </form>
    </div>
  );
};
