export const createPaginationUrl = (
  baseUrl: string,
  page: string | number,
  perPage: string | number,
  alternativePage?: string | number,
  alternativePerPage?: string | number,
  orderType?: string,
) => {
  return baseUrl.replace(
    orderType && !baseUrl.includes('alternativePage')
      ? ':page/:perPage/:orderType'
      : !orderType && baseUrl.includes('alternativePage')
      ? ':page/:perPage/:alternativePage/:alternativePerPage'
      : ':page/:perPage',
    `${page}/${perPage}${orderType ? `/${orderType}` : ''}${
      alternativePage ? '/' + alternativePage : ''
    }${alternativePerPage ? '/' + alternativePerPage : ''}`,
  );
};
