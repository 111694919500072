import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { useStyles, StyledTextField } from './TimePicker.styles';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';

interface ITimePicker<TName extends string> {
  name: TName;
  onChange?: (value: any) => void;
  minTime?: Date;
  maxTime?: Date;
  error?: boolean;
  control?: Control<any, any>;
  helperText?: React.ReactNode;
  setError?: (name: TName, data: { message?: string }) => void;
}

export const TimePicker = <TName extends string>({
  error,
  helperText,
  name,
  control,
  maxTime,
  minTime,
  onChange,
  setError,
}: ITimePicker<TName>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {minTime?.getDate() !== maxTime?.getDate() ? (
          <Controller
            render={({ field: { onChange: change, value } }) => (
              <DateTimePicker
                value={value}
                minDateTime={minTime}
                maxDateTime={maxTime}
                onChange={value => {
                  let newValue = value;
                  let error = false;

                  if (minTime && value && minTime.getTime() > value.getTime()) {
                    newValue = minTime;
                    setError?.(name, {
                      message: t(
                        translations.addManualTestData.timeBeforeError,
                      ),
                    });
                    error = true;
                  }
                  if (maxTime && value && value.getTime() > maxTime.getTime()) {
                    newValue = maxTime;
                    setError?.(name, {
                      message: t(translations.addManualTestData.timeAfterError),
                    });
                    error = true;
                  }

                  if (newValue && isNaN(newValue.getTime())) {
                    setError?.(name, {
                      message: t(translations.addManualTestData.invalidDate),
                    });
                    error = true;
                  }
                  if (!error) {
                    setError?.(name, { message: '' });
                  }

                  change(newValue);
                  onChange?.(newValue);
                }}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    classes={{ root: classes.root }}
                    error={error}
                    helperText={helperText}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        contained: classes.contained,
                      },
                    }}
                  />
                )}
              />
            )}
            name={name}
            control={control}
          />
        ) : (
          <Controller
            render={({ field: { onChange: change, value } }) => (
              <DesktopTimePicker
                value={value}
                minTime={minTime}
                maxTime={maxTime}
                onChange={value => {
                  let newValue = value;
                  let error = false;
                  if (minTime && value && minTime.getTime() > value.getTime()) {
                    newValue = minTime;
                    setError?.(name, {
                      message: t(
                        translations.addManualTestData.timeBeforeError,
                      ),
                    });
                    error = true;
                  }
                  if (maxTime && value && value.getTime() > maxTime.getTime()) {
                    newValue = maxTime;
                    setError?.(name, {
                      message: t(translations.addManualTestData.timeAfterError),
                    });
                    error = true;
                  }

                  if (newValue && isNaN(newValue.getTime())) {
                    setError?.(name, {
                      message: t(translations.addManualTestData.invalidDate),
                    });
                    error = true;
                  }
                  if (!error) {
                    setError?.(name, { message: '' });
                  }

                  change(newValue);
                  onChange?.(newValue);
                }}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    classes={{ root: classes.root }}
                    error={error}
                    helperText={helperText}
                    fullWidth
                    FormHelperTextProps={{
                      classes: {
                        contained: classes.contained,
                      },
                    }}
                  />
                )}
              />
            )}
            name={name}
            control={control}
          />
        )}
      </LocalizationProvider>
    </div>
  );
};
