import { PageHeader } from 'app/shared/components/generic-ui/PageHeader';
import {
  Pages,
  TabIds,
} from 'app/shared/components/layout/TabNavigation/TabNavigation.types';
import { NotificationEntityType, Routes } from 'types';
import { GeneralOptions } from './GeneralOptions';
import { GroupsSection } from './GroupSection';
import { EntityAssignments } from './EntityAssignments';
import { GroupAssignments } from './GroupAssignments/GroupAssignments';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, useNotificationSettings } from 'app/shared/providers';
import { UserNotificationSettings } from './UserNotificationSettings';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ConfirmationModal } from 'app/shared/components/generic-ui/ConfirmationModal/ConfirmationModal';
import { useUnassignUserApi } from 'app/shared/hooks';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useOwnProjectsApi } from 'app/shared/hooks/useOwnProjectsApi';
import { useStyles } from './NotificationSettingsPage.styles';
import { CustomReturnedConcreteModal } from 'app/shared/components/generic-ui/CustomReturnedConcreteModal/CustomReturnedConcreteModal';
import { useSearchParams } from './hooks';
import { useDosagesApi } from 'app/shared/hooks/useDosageApi';
import { Button } from 'app/shared/components/generic-ui/Button/Button';

export const NotificationSettingPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isAddDosageModalOpen, setIsAddDosageModalOpen] = useState(false);
  const { searchQuery } = useSearchParams();
  const history = useHistory();
  const { tab, organizationId }: { tab: string; organizationId: string } =
    useParams();

  const { isMember, isSuperAdmin } = useAuth();
  const { changeSelectedEntityHandler, isLoading, selectedEntity, entityType } =
    useNotificationSettings();

  const internalId =
    tab === TabIds.ORGANIZATION ? organizationId : selectedEntity?.internalId;

  const queryParamsDosage = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        sortBy: '',
        sortAsc: false,
        query: searchQuery || '',
        notificationEntityId: internalId,
      },
    };
  }, [entityType, organizationId, searchQuery, internalId]);

  const {
    dosages,
    fetchDosages,
    isFetchingDosages,
    isFetchingAssignedDosages,
    assignedDosages,
    fetchAssignedDosages,
  } = useDosagesApi(queryParamsDosage);

  useEffect(() => {
    if (internalId) {
      fetchAssignedDosages({ notificationEntityId: internalId });
    }
  }, [fetchAssignedDosages, internalId, queryParamsDosage]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { unassignUser, isUnAssigningUser } = useUnassignUserApi({
    organizationId: organizationId,
  });

  const { fetchOwnProjects } = useOwnProjectsApi();

  useEffect(() => {
    if (isAddDosageModalOpen) {
      fetchDosages();
    }
  }, [fetchDosages, isAddDosageModalOpen, queryParamsDosage]);

  const addDosageHandler = useCallback(() => {
    setIsAddDosageModalOpen(true);
  }, []);

  useEffect(() => {
    const entityType =
      tab === TabIds.PLANT
        ? NotificationEntityType.PLANT
        : tab === TabIds.PROJECT
        ? NotificationEntityType.PROJECT
        : tab === TabIds.CUSTOMER
        ? NotificationEntityType.CUSTOMER
        : tab === TabIds.MIX_DESIGN
        ? NotificationEntityType.MIX_DESIGN
        : tab === TabIds.VEHICLE
        ? NotificationEntityType.VEHICLE
        : tab === TabIds.DRIVER
        ? NotificationEntityType.DRIVER
        : tab === TabIds.POUR_EVENT
        ? NotificationEntityType.POUR_EVENT
        : NotificationEntityType.ORGANIZATION;

    changeSelectedEntityHandler(entityType);
  }, [changeSelectedEntityHandler, tab]);

  const isEntityAssignment = useMemo(() => {
    return (
      tab === TabIds.ORGANIZATION ||
      (!!selectedEntity?.internalId &&
        !selectedEntity?.notificationEntityGroupId)
    );
  }, [
    selectedEntity?.internalId,
    selectedEntity?.notificationEntityGroupId,
    tab,
  ]);

  const isUserAssignments = useMemo(() => {
    return (tab === TabIds.ORGANIZATION || !!selectedEntity) && isMember;
  }, [isMember, selectedEntity, tab]);

  const unassignUserHandler = useCallback(() => {
    unassignUser().then(() => {
      setIsModalOpen(false);
      if (!isSuperAdmin) {
        fetchOwnProjects();
      }
    });
  }, [fetchOwnProjects, isSuperAdmin, unassignUser]);

  const closeModalHandler = useCallback(() => {
    if (searchQuery) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    }

    setIsAddDosageModalOpen(false);
  }, [history, searchQuery]);

  const unassignButton = useMemo(() => {
    return !isSuperAdmin ? (
      <div
        className={classes.buttonWrapper}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className={classes.button}>
          <CloseIcon
            stroke={themeColors.systemRed}
            strokeWidth={3}
            height={14}
            className={classes.icon}
          />
        </div>
        <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
          {t(translations.notificationSettingsPage.unassign)}
        </CustomTypography>
      </div>
    ) : undefined;
  }, [classes.button, classes.buttonWrapper, classes.icon, isSuperAdmin, t]);

  return (
    <PageHeader
      header="Notification Settings"
      route={Routes.NotificationSettings}
      pageType={Pages.NOTIFICATION_SETTINGS}
      withActions={false}
      button={unassignButton}
    >
      {isModalOpen && (
        <ConfirmationModal
          closeHandler={() => setIsModalOpen(false)}
          message={`${t(translations.notificationSettingsPage.areYouSure)}?`}
          isOpen={isModalOpen}
          isLoading={isUnAssigningUser}
          confirmationHandler={unassignUserHandler}
        />
      )}
      {!isLoading ? (
        <div>
          {tab !== TabIds.ORGANIZATION ? (
            <div>
              <GeneralOptions />
              <GroupsSection
                addDosageHandler={addDosageHandler}
                isFetchingDosage={isFetchingAssignedDosages}
                assignedDosages={assignedDosages}
              />
            </div>
          ) : null}
          {isSuperAdmin &&
          tab === TabIds.ORGANIZATION &&
          !isFetchingAssignedDosages ? (
            <div className={classes.headerContainer}>
              <Button variant="primaryProfile" onClick={addDosageHandler}>
                <CustomTypography variant="subtitle2" bold color="mbsBlue">
                  {assignedDosages?.data.length
                    ? t(translations.notificationSettingsPage.editDosage)
                    : t(translations.notificationSettingsPage.addDosage)}
                </CustomTypography>
              </Button>
            </div>
          ) : null}

          {isEntityAssignment && !isMember ? (
            <EntityAssignments isUnAssigningUser={isUnAssigningUser} />
          ) : null}

          {selectedEntity?.notificationEntityGroupId &&
          !isLoading &&
          !isMember ? (
            <GroupAssignments isUnAssigningUser={isUnAssigningUser} />
          ) : null}

          {isUserAssignments ? (
            <UserNotificationSettings isUnAssigningUser={isUnAssigningUser} />
          ) : null}
        </div>
      ) : null}
      {isAddDosageModalOpen && (
        <CustomReturnedConcreteModal
          tableRowId="externalId"
          isOpen={isAddDosageModalOpen}
          closeHandler={closeModalHandler}
          isLoading={isFetchingDosages}
          header={t(
            translations.notificationSettingsPage.createReturnedConcreteModule,
          )}
          internalId={internalId}
          entityType={entityType}
          withSearch={true}
          dosages={dosages?.data}
          withTableHeader
          searchHeader={t(translations.notificationSettingsPage.searchDosage)}
          refetchDosage={fetchAssignedDosages}
          assignedDosages={assignedDosages}
        />
      )}
    </PageHeader>
  );
};
