import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
  },
  valueContainer: {
    wordWrap: 'break-word',
  },
  subheader: {
    backgroundColor: themeColors.greyscale4,

    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '8px 24px',
  },
  darkContainer: {
    boxSizing: 'border-box',
    padding: '15px 24px 10px 24px',
    backgroundColor: themeColors.greyscale5,
  },
  lightContainer: {
    boxSizing: 'border-box',
    padding: '15px 24px 10px 24px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '200px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
