import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IOrganization,
  IMetaData,
  IProfile,
  IOrganizationDetails,
  IApiParams,
} from 'types';
import { useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const organizationQueryKey = 'createOrganization';
const organizationsQueryKey = 'getOrganizations';
const organizationAdminsQueryKey = 'getOrganizationAdmins';
const createOrganizationUrl = '/api/v1/Organizations/create-organization';
const getOrganizationsUrl = '/api/v1/Organizations/search-organizations';

export function useOrganizationsApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClientLegacy = useAuthClient(true);
  const authClient = useAuthClient();
  const { openModal } = useError();
  const queryClient = useQueryClient();

  const getOrganizationAdminsUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Organizations/get-organization-admins`;
  }, [organizationId]);

  const getOrganizationUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Organizations/get-organization-details`;
  }, [organizationId]);

  const updateOrganizationUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Organizations/update-organization`;
  }, [organizationId]);

  const {
    data: organizationAdminsResponse,
    refetch: fetchOrganizationAdmins,
    isFetching: isFetchingOrganizationAdmins,
    error: fetchOrganizationAdminsError,
  } = useQuery<IResponseData<IProfile[]>, IError>(
    [organizationAdminsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getOrganizationAdminsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOrganizationAdmins, error, {
          getOrganizationAdminsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: organizationsResponse,
    refetch: fetchOrganizations,
    isFetching: isFetchingOrganizations,
    error: fetchOrganizationsError,
  } = useQuery<IResponseData<IOrganization[]>, IError>(
    [organizationsQueryKey, { queryParameters }],
    async () => {
      return await authClientLegacy(`${getOrganizationsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOrganizations, error, {
          getOrganizationsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: organizationResponse,
    refetch: fetchOrganization,
    isFetching: isFetchingOrganization,
  } = useQuery<IResponseData<IOrganizationDetails>, IError>(
    [organizationsQueryKey, { queryParameters }],
    async () => {
      return await authClientLegacy(`${getOrganizationUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOrganization, error, {
          getOrganizationUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const { mutateAsync: createOrganization, isLoading: isCreatingOrganization } =
    useMutation(
      (payload: FormData) =>
        authClientLegacy(createOrganizationUrl, {
          method: 'POST',
          data: payload,
          contentType: 'multipart/form-data',
        }),
      {
        retry: false,
        onMutate: async () => {
          await queryClient.cancelQueries(organizationQueryKey);
          return queryClient.getQueryData<IResponseData<boolean>>(
            organizationQueryKey,
          );
        },
        onError: (error: IError, variables, context) => {
          if (context) {
            queryClient.setQueryData<IResponseData<boolean>>(
              organizationQueryKey,
              context,
            );
          }

          firebaseAnalytics.logErrorEvent(errors.createOrganization, error, {
            createOrganizationUrl,
          });
          openModal(error);
        },
        onSettled: () => {
          queryClient.invalidateQueries(organizationQueryKey);
        },
      },
    );

  const { mutateAsync: updateOrganization, isLoading: isUpdatingOrganization } =
    useMutation(
      (payload: FormData) =>
        authClientLegacy(updateOrganizationUrl, {
          method: 'POST',
          data: payload,
          contentType: 'multipart/form-data',
        }),
      {
        retry: false,
        onMutate: async () => {
          await queryClient.cancelQueries(organizationQueryKey);
          return queryClient.getQueryData<IResponseData<boolean>>(
            organizationQueryKey,
          );
        },
        onError: (error: IError, variables, context) => {
          if (context) {
            queryClient.setQueryData<IResponseData<boolean>>(
              organizationQueryKey,
              context,
            );
          }

          firebaseAnalytics.logErrorEvent(errors.updateOrganization, error, {
            updateOrganizationUrl,
          });
          openModal(error);
        },
        onSettled: () => {
          queryClient.invalidateQueries(organizationQueryKey);
        },
      },
    );

  const organizations = useMemo<IOrganization[] | undefined>(
    () => organizationsResponse?.data,
    [organizationsResponse],
  );
  const organizationDetails = useMemo<IOrganizationDetails | undefined>(
    () => organizationResponse?.data,
    [organizationResponse],
  );

  const organizationAdmins = useMemo<IProfile[] | undefined>(
    () => organizationAdminsResponse?.data,
    [organizationAdminsResponse],
  );

  const organizationsMetadata = useMemo<IMetaData | undefined>(
    () => organizationsResponse?.metadata,
    [organizationsResponse],
  );

  return {
    organizations,
    fetchOrganizations,
    fetchOrganizationsError,
    isFetchingOrganizations,
    createOrganization,
    isCreatingOrganization,
    organizationsMetadata,
    organizationAdmins,
    fetchOrganizationAdmins,
    isFetchingOrganizationAdmins,
    fetchOrganizationAdminsError,
    organizationDetails,
    fetchOrganization,
    isFetchingOrganization,
    updateOrganization,
    isUpdatingOrganization,
  };
}
