import { Switch as MuiSwitch } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { useStyles } from './Switch.styles';

interface ISwitchProps {
  disabled?: boolean;
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const Switch = ({ disabled, checked, onChange }: ISwitchProps) => {
  const classes = useStyles();

  return (
    <MuiSwitch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      color="primary"
      className={classes.toggle}
      classes={{
        root: classes.rootSwitch,
        switchBase: classes.switchBase,
        track: classes.switchTrack,
        thumb: classes.thumb,
      }}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};
