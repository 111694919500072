import { translations } from 'app/locales/i18n';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IProbeRanges } from 'types';
import * as yup from 'yup';

export const useManualTestDataValidation = (probeRanges: IProbeRanges) => {
  const { t } = useTranslation();
  const { getTranslatedUnit } = useProbeDataFormatter();

  const formDefaultValues = useMemo(() => {
    return {
      slump: '',
      slumpFlow: '',
      density: '',
      airContent: '',
      temperature: '',
      hydraulicPressure: '',
      probeTakenOn: probeRanges.defaultTimeSelectionUTC
        ? new Date(probeRanges.defaultTimeSelectionUTC)
        : new Date(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = yup.object().shape({
    slump: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.slumpRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.slumpRange.maxValue
        }`,
      )
      .min(
        probeRanges.slumpRange.minValue,
        `${t(translations.errors.minValue)} ${probeRanges.slumpRange.minValue}`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),

    slumpFlow: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.slumpFlowRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.slumpFlowRange.maxValue
        }`,
      )
      .min(
        probeRanges.slumpFlowRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges.slumpFlowRange.minValue
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
    density: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.densityRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.densityRange.maxValue
        }`,
      )
      .min(
        probeRanges.densityRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges.densityRange.minValue
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
    airContent: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.airContentPercentageRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.airContentPercentageRange.maxValue
        }`,
      )
      .min(
        probeRanges.airContentPercentageRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges.airContentPercentageRange.minValue
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
    temperature: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.concreteTemperatureRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.concreteTemperatureRange.maxValue
        }`,
      )
      .min(
        probeRanges.concreteTemperatureRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges.concreteTemperatureRange.minValue
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
    hydraulicPressure: yup
      .number()
      .typeError(t(translations.errors.thisFieldMustBe))
      .max(
        probeRanges.hydraulicPressureRange.maxValue,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          probeRanges.hydraulicPressureRange.maxValue
        }`,
      )
      .min(
        probeRanges.hydraulicPressureRange.minValue,
        `${t(translations.errors.minValue)} ${
          probeRanges.hydraulicPressureRange.minValue
        }`,
      )
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue.trim() === '' ? null : value,
      ),
    probeTakenOn: yup
      .date()
      .typeError(t(translations.errors.thisFieldMustBeTime))
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
  });

  const textInputs: { name: keyof typeof formDefaultValues; label: string }[] =
    useMemo(() => {
      return [
        {
          name: 'slump',
          label: `${t(
            translations.addManualTestData.slump,
          )} (${getTranslatedUnit(probeRanges.slumpRange.measurement)})`,
        },
        {
          name: 'slumpFlow',
          label: `${t(
            translations.addManualTestData.slumpFlow,
          )} (${getTranslatedUnit(probeRanges.slumpFlowRange.measurement)})`,
        },
        {
          name: 'airContent',
          label: `${t(translations.addManualTestData.airContent)} (%)`,
        },
        {
          name: 'density',
          label: `${t(
            translations.addManualTestData.unitWtDensity,
          )} (${getTranslatedUnit(probeRanges.densityRange.measurement)})`,
        },
        {
          name: 'temperature',
          label: `${t(
            translations.addManualTestData.temperature,
          )} (${getTranslatedUnit(
            probeRanges.concreteTemperatureRange.measurement,
          )})`,
        },
        {
          name: 'hydraulicPressure',
          label: `${t(
            translations.addManualTestData.slumpMeter,
          )} (${getTranslatedUnit(
            probeRanges.hydraulicPressureRange.measurement,
          )})`,
        },
      ];
    }, [
      getTranslatedUnit,
      probeRanges.concreteTemperatureRange.measurement,
      probeRanges.densityRange.measurement,
      probeRanges.hydraulicPressureRange.measurement,
      probeRanges.slumpFlowRange.measurement,
      probeRanges.slumpRange.measurement,
      t,
    ]);

  return {
    validationSchema,
    formDefaultValues,
    textInputs,
  };
};
