import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'types';
import './MainRouter.scss';
import { firebaseAnalytics } from '../../analytics';

export interface IRouterMiddlewareProps {
  children?: ReactNode | undefined;
}

export const RouterMiddleware = ({ children }: IRouterMiddlewareProps) => {
  const history = useHistory();
  const routesTree = useRef<Record<any, any>>({
    value: 'Not_Found',
    children: {},
  });

  const updateTree = useCallback((routeName: string, routePath: string) => {
    if (!routePath) {
      return;
    }

    const staticPath = routePath.split('/:')[0];
    let target = routesTree.current;

    staticPath.split('/').map(pathPart => {
      if (pathPart) {
        if (!target.children.hasOwnProperty(pathPart)) {
          target.children[pathPart] = {
            value: routeName,
            children: {},
          };
        }

        target = target.children[pathPart];
      }
    });
  }, []);

  const findRouteNameByPath = useCallback(
    (path: string) => {
      let target = routesTree.current;
      const pathParts = path.split('/');

      for (let i = 0; i < pathParts.length; i++) {
        const pathPart = pathParts[i];

        if (!pathPart) {
          continue;
        }

        if (target.children.hasOwnProperty(pathPart)) {
          target = target.children[pathPart];
        } else {
          break;
        }
      }

      return target.value;
    },
    [routesTree],
  );

  const buildTree = useCallback(() => {
    Object.entries(Routes).map(([key, value]) => updateTree(key, value));
  }, [updateTree]);

  const fireAnalyticsEvent = useCallback(
    path => {
      firebaseAnalytics.logPageView(findRouteNameByPath(path), path);
    },
    [findRouteNameByPath],
  );

  useEffect(() => {
    buildTree();

    fireAnalyticsEvent(history.location.pathname);

    history.listen(location => {
      fireAnalyticsEvent(location.pathname);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children ?? null}</>;
};
