import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  formControl: {
    marginTop: 8,
    alignItems: 'center',
    display: 'flex',
  },
  selectContainer: {
    marginTop: 15,
  },
  labelContainer: {
    marginLeft: 10,
  },
  groupsContainer: {
    width: '100%',
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  button: {
    width: 70,
    cursor: 'pointer',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectSearch: {
    width: 300,
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
