import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  phoneComponentWrapper: {
    height: 38,
  },
  phoneInput: {
    width: '100% !important',
    fontSize: 14,
    lineHeight: 22,
    height: 33,
    padding: '8px 10px 8px 48px',
  },
  phoneContainer: {
    marginBottom: 10,
  },
  error: {
    color: '#f44336',
    marginLeft: '14px',
    fontSize: '0.75rem',
    marginTop: 3,
    fontWeight: 400,
    lineHeight: '1.66',
  },
});
