import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { IError } from 'types';
import { ErrorModal } from '../components/generic-ui/ErrorModal';

export const ErrorContext = createContext<IErrorContext | null>(null);

ErrorContext.displayName = 'ErrorContext';

export interface IErrorContext {
  isErrorModalOpen: boolean;
  openModal: (error: IError) => void;
  error: IError | undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function ErrorProvider({ children }: PropsWithChildren<{}>) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<IError>();

  const openModal = (newError: IError) => {
    if (!isErrorModalOpen) {
      setIsErrorModalOpen(true);
      setError(newError);
    }
  };

  const contextValue = useMemo(() => {
    return {
      isErrorModalOpen,
      openModal,
      error,
      setError,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isErrorModalOpen]);

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
      {error && (
        <ErrorModal
          isOpen={isErrorModalOpen}
          closeHandler={() => {
            setIsErrorModalOpen(false);
          }}
          error={error}
        />
      )}
    </ErrorContext.Provider>
  );
}

export function useError() {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('ErrorContext must be in scope when useError is used.');
  }

  return context;
}
