import React, { useCallback } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ShortFormBody.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { CssTextField } from '../CreateProjectModal.styles';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  Controller,
  UseFormTrigger,
} from 'react-hook-form';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IProjectInformation } from 'types';

interface IShortFormBody {
  errors: DeepMap<FieldValues, FieldError>;
  control?: Control<IProjectInformation>;
  trigger: UseFormTrigger<IProjectInformation>;
  setProjectIdValue: (value: string) => void;
  isEdit?: boolean;
}

export const ShortFormBody = ({
  errors,
  control,
  trigger,
  setProjectIdValue,
  isEdit,
}: IShortFormBody) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClickAway = useCallback(() => {
    return trigger('projectId');
  }, [trigger]);

  return (
    <div className={classes.container}>
      <CustomTypography variant="header6" bold color="accentDark">
        {t(translations.projectModal.projectDetailsRequired)}
      </CustomTypography>
      <div className={classes.label}>
        <CustomTypography variant="caption1" bold color="greyscale6">
          {t(translations.projectModal.projectFromConnex)}
        </CustomTypography>
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CssTextField
                disabled={isEdit}
                onChange={e => {
                  onChange(e);
                  trigger('projectId');
                  setProjectIdValue(e.target.value);
                }}
                value={value}
                id="outlined-full-width"
                classes={{ root: classes.root }}
                name="projectId"
                error={!!errors?.projectId}
                placeholder={t(translations.projectModal.projectIdProjectName)}
                fullWidth
                margin="normal"
                helperText={
                  errors?.projectId?.message
                    ? errors.projectId.message
                    : `${t(
                        translations.projectModal.copyTheProjectFieldFromConnex,
                      )}`
                }
                InputLabelProps={{
                  shrink: true,
                }}
                FormHelperTextProps={{
                  classes: {
                    contained: classes.contained,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                    focused: classes.focused,
                  },
                }}
                variant="outlined"
              />
            )}
            name="projectId"
            control={control}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};
