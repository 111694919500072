import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 30px',
  },
  wrapperMobile: {
    margin: '-50px 0px 0px -16px',
    padding: '27px 16px 0px 16px',
    backgroundColor: themeColors.white,
    boxSizing: 'border-box',
  },
  header: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clientAppSection: {
    boxSizing: 'border-box',
    paddingLeft: '1px',
    position: 'relative',
  },
  clientAppSectionMobile: {
    boxSizing: 'border-box',
    paddingLeft: '1px',
    position: 'relative',
    marginLeft: '-24px',
  },
  content: {
    position: 'absolute',
    maxWidth: 458,
    top: 61,
    left: 29,
  },
  contentMobile: {
    position: 'absolute',
    maxWidth: 458,
    top: 40,
    left: 16,
  },
  description: {
    margin: '16px 0',
  },
  descriptionMobile: {
    margin: '16px 0px',
    width: 210,
  },
  cover: {
    marginTop: 32,
    objectFit: 'cover',
    height: 500,
    width: '100%',
    marginBottom: '76px',
    objectPosition: '10% 50%',
  },
  coverMobile: {
    marginTop: 32,
    objectFit: 'cover',
    height: 334,
    float: 'right',
    marginRight: '-90px',
    marginBottom: '76px',
    objectPosition: '10% 50%',
  },
  loading: {
    height: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrContainer: {
    marginTop: 17,
  },
  text: {
    lineHeight: 1.5,
  },
  editMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 30,
    backgroundColor: themeColors.white,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: 157,
    height: 'auto',
    marginTop: 9,
  },
});
