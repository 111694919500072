import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  IPlantGroup,
  INotificationEntityGroup,
  INotificationEntity,
  IAddToNotificationEntity,
  IUpdateNotificationEntityGroup,
  ICreateNotificationEntityGroupRequest,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const notificationEntityGroupsKey = 'getNotificationEntityGroups';
const notificationEntityGroupKey = 'getNotificationEntityGroup';
const unassignedNotificationEntity = 'getUnassignedNotificationEntity';

export function useNotificationEntityGroupApi({
  queryParameters,
  organizationId,
  entityType,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getUnassignedNotificationEntityUrl = useMemo(() => {
    return `/api/v1/${organizationId}/NotificationEntityGroup/${entityType}/get-unnasigned-notification-entities`;
  }, [entityType, organizationId]);

  const getNotificationEntityGroupsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/get-notification-entities-groups`;
  }, [currentSupplierId, entityType]);

  const getNotificationEntityGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/get-notification-entities-group`;
  }, [currentSupplierId, entityType]);

  const createNotificationEntityGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/create-notification-entities-group`;
  }, [currentSupplierId, entityType]);

  const removeNotificationEntityFromGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/remove-notification-entities-from-group`;
  }, [currentSupplierId, entityType]);

  const addToNotificationEntityGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/add-notification-entities-to-group`;
  }, [entityType, currentSupplierId]);

  const updateNotificationEntityGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/update-notification-entities-group`;
  }, [currentSupplierId, entityType]);

  const deleteNotificationEntityGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationEntityGroup/${entityType}/delete-notification-entities-group`;
  }, [currentSupplierId, entityType]);

  const {
    data: notificationEntityGroupsResponse,
    refetch: fetchNotificationEntityGroups,
    isFetching: isFetchingNotificationEntityGroups,
  } = useQuery<IResponseData<INotificationEntityGroup[]>, IError>(
    [notificationEntityGroupsKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationEntityGroupsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getNotificationEntitiesGroups,
          error,
          {
            getNotificationEntityGroupsUrl,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: notificationEntityGroupResponse,
    refetch: fetchNotificationEntityGroup,
    isFetching: isFetchingNotificationEntityGroup,
  } = useQuery<IResponseData<INotificationEntityGroup>, IError>(
    [notificationEntityGroupKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationEntityGroupUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getNotificationEntitiesGroup,
          error,
          {
            getNotificationEntityGroupUrl,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: unassignedNotificationEntityResponse,
    refetch: fetchUnassignedNotificationEntity,
    isFetching: isFetchingUnassignedNotificationEntity,
  } = useQuery<IResponseData<INotificationEntity[]>, IError>(
    [unassignedNotificationEntity, { queryParameters }],
    async () => {
      return await authClient(`${getUnassignedNotificationEntityUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getUnassignedNotificationEntities,
          error,
          {
            getUnassignedNotificationEntityUrl,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: deleteNotificationEntityGroupResponse,
    refetch: deleteNotificationEntityGroup,
    isLoading: isNotificationEntityGroupDeleting,
    error: deleteNotificationEntityGroupError,
  } = useQuery<IResponseData<boolean>, IError>(
    [notificationEntityGroupKey, { queryParameters }],
    async () => {
      return await authClient(`${deleteNotificationEntityGroupUrl}`, {
        queryParameters,
        method: 'DELETE',
      });
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.deleteNotificationEntitiesGroup,
          error,
          {
            deleteNotificationEntityGroupUrl,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: addNotificationEntitiesToGroup,
    isLoading: isAddingNotificationEntitiesToGroup,
    isSuccess: isAddingNotificationEntitiesToGroupSuccess,
    error: addingNotificationEntitiesToGroupError,
  } = useMutation(
    (payload: IAddToNotificationEntity) =>
      authClient(addToNotificationEntityGroupUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationEntityGroupKey);
        return queryClient.getQueryData<IResponseData<IPlantGroup>>(
          notificationEntityGroupKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IPlantGroup>>(
            notificationEntityGroupKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.addNotificationEntitiesToGroup,
          error,
          {
            addToNotificationEntityGroupUrl,
            notificationEntityGroupId: variables.notificationEntityGroupId,
            internalIds: variables.internalIds,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationEntityGroupKey);
      },
    },
  );

  const {
    mutateAsync: updateNotificationEntityGroup,
    isLoading: isUpdatingNotificationEntityGroup,
    isSuccess: isUpdatingNotificationEntityGroupSuccess,
    error: updatingPlantNotificationEntityError,
  } = useMutation(
    (payload: IUpdateNotificationEntityGroup) =>
      authClient(updateNotificationEntityGroupUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationEntityGroupKey);
        return queryClient.getQueryData<IResponseData<IPlantGroup>>(
          notificationEntityGroupKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IPlantGroup>>(
            notificationEntityGroupKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.updateNotificationEntitiesGroup,
          error,
          {
            updateNotificationEntityGroupUrl,
            notificationEntityGroupId: variables.notificationEntityGroupId,
            notificationEntityGroupName: variables.notificationEntityGroupName,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationEntityGroupKey);
      },
    },
  );

  const {
    mutateAsync: removeNotificationEntityFromGroup,
    isLoading: isRemovingNotificationEntityFromGroup,
    isSuccess: isRemovingNotificationEntityFromGroupSuccess,
    error: removingPlantNotificationEntityGroupError,
  } = useMutation(
    (payload: IAddToNotificationEntity) =>
      authClient(removeNotificationEntityFromGroupUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationEntityGroupKey);
        return queryClient.getQueryData<IResponseData<IPlantGroup>>(
          notificationEntityGroupKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IPlantGroup>>(
            notificationEntityGroupKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.removeNotificationEntitiesFromGroup,
          error,
          {
            removeNotificationEntityFromGroupUrl,
            notificationEntityGroupId: variables.notificationEntityGroupId,
            internalIds: variables.internalIds,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationEntityGroupKey);
      },
    },
  );

  const {
    mutateAsync: createNotificationEntityGroup,
    isLoading: isNotificationEntityGroupCreating,
    isSuccess: isNotificationEntityGroupSuccess,
    error: createNotificationEntityGroupError,
  } = useMutation(
    (payload: ICreateNotificationEntityGroupRequest) =>
      authClient(createNotificationEntityGroupUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationEntityGroupKey);
        return queryClient.getQueryData<IResponseData<IPlantGroup>>(
          notificationEntityGroupKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IPlantGroup>>(
            notificationEntityGroupKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.createNotificationEntitiesGroup,
          error,
          {
            createNotificationEntityGroupUrl,
            notificationEntityGroupName: variables.notificationEntityGroupName,
            internalIds: variables.internalIds,
          },
        );
        openModal(error);
      },
    },
  );

  const unassignedNotificationEntities = useMemo<
    INotificationEntity[] | undefined
  >(
    () => unassignedNotificationEntityResponse?.data,
    [unassignedNotificationEntityResponse?.data],
  );

  const notificationEntityGroups = useMemo<
    INotificationEntityGroup[] | undefined
  >(
    () => notificationEntityGroupsResponse?.data,
    [notificationEntityGroupsResponse],
  );

  const notificationEntityGroup = useMemo<INotificationEntityGroup | undefined>(
    () => notificationEntityGroupResponse?.data,
    [notificationEntityGroupResponse?.data],
  );

  return {
    notificationEntityGroups,
    fetchNotificationEntityGroups,
    isFetchingNotificationEntityGroups,
    fetchNotificationEntityGroup,
    isFetchingNotificationEntityGroup,
    notificationEntityGroup,
    deleteNotificationEntityGroupResponse,
    deleteNotificationEntityGroup,
    isNotificationEntityGroupDeleting,
    deleteNotificationEntityGroupError,
    unassignedNotificationEntities,
    fetchUnassignedNotificationEntity,
    isFetchingUnassignedNotificationEntity,
    addNotificationEntitiesToGroup,
    isAddingNotificationEntitiesToGroup,
    isAddingNotificationEntitiesToGroupSuccess,
    addingNotificationEntitiesToGroupError,
    updateNotificationEntityGroup,
    isUpdatingNotificationEntityGroup,
    isUpdatingNotificationEntityGroupSuccess,
    updatingPlantNotificationEntityError,
    removeNotificationEntityFromGroup,
    isRemovingNotificationEntityFromGroup,
    isRemovingNotificationEntityFromGroupSuccess,
    removingPlantNotificationEntityGroupError,
    createNotificationEntityGroup,
    isNotificationEntityGroupCreating,
    isNotificationEntityGroupSuccess,
    createNotificationEntityGroupError,
  };
}
