import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellSize,
  CellAlignment,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { IProfile, Routes } from 'types';
import { useStyles } from './TeammatesList.styles';
import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import { getUserInitials } from 'app/shared/utils/getUserInitials';
import { formatPhoneNumber } from 'app/shared/utils';
import { useAuth } from 'app/shared/providers';
import { useOrganizationsApi, useProfileApi } from 'app/shared/hooks';
import { IAction } from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { firebaseAnalytics } from 'app/shared/analytics';
import { ConfirmationModal } from 'app/shared/components/generic-ui/ConfirmationModal/ConfirmationModal';
import { useRemoveUserApi } from 'app/shared/hooks/useRemoveUserApi';
import { useHistory, useParams } from 'react-router-dom';

interface ITeammatesListProps {
  teammates?: IProfile[];
  onClickSearchRow: () => void;
  rowsTotal: number;
  isLoading: boolean;
  refetchTeammates: () => void;
  sortHandler: (id: string) => void;
}

const defaultRowsCount = 0;

export const TeammatesList = ({
  teammates,
  isLoading,
  rowsTotal = defaultRowsCount,
  refetchTeammates,
  sortHandler,
}: ITeammatesListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { supplierId } = useAuth();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<IProfile | null>(null);
  const { organizationId }: { organizationId: string } = useParams();
  const { profile } = useProfileApi({ organizationId: organizationId });

  const currentOrganizationId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const { fetchOrganizationAdmins, organizationAdmins } = useOrganizationsApi({
    organizationId: currentOrganizationId,
  });

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        userId: userData ? userData.userId : '',
      },
    };
  }, [organizationId, userData]);

  const { removeUser, isRemovingUser } = useRemoveUserApi(queryParams);

  const columns = [
    {
      id: 'name',
      label: t(translations.teammatesPage.name),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
      sortable: true,
    },
    {
      id: 'roleNames',
      label: t(translations.teammatesPage.role),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'email',
      label: t(translations.teammatesPage.email),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'phoneNumber',
      label: t(translations.teammatesPage.phone),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const actions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.teammatesPage.removeTeammateFromSystem),
        onClick: (row: IProfile) => {
          setUserData(row);
          firebaseAnalytics.logPressEvent(
            Routes.Teammates,
            'Remove user action',
          );
          setIsModalOpen(true);
        },
      },
    ];
  }, [t]);

  const modalMessage = useMemo(() => {
    return `${t(translations.confirmationModal.areYouSure, {
      email: userData ? userData.email : '',
    })}?`;
  }, [t, userData]);

  useEffect(() => {
    fetchOrganizationAdmins();
  }, [fetchOrganizationAdmins]);

  const renderCell = (
    rowData: IProfile,
    cellId: string,
    isHovered?: boolean,
  ) => {
    if (cellId === 'name') {
      const cellContent = organizationAdmins?.find(
        (user: any) => user.userId === rowData.userId,
      )
        ? [rowData.firstName, rowData.lastName, '(Admin)']
        : [rowData.firstName, rowData.lastName];

      return (
        <div
          className={
            !rowData.active
              ? classes.nameContainer
              : classes.nameContainerActiveUser
          }
        >
          <Avatar
            content={
              cellContent[0] &&
              cellContent[1] &&
              getUserInitials(cellContent[0], cellContent[1])
            }
            color={
              isHovered && rowData.active
                ? 'white'
                : !isHovered && rowData.active
                ? 'highlightBlue'
                : 'greyscale4'
            }
            size={32}
          />
          <div style={{ paddingLeft: '8px' }}>{cellContent.join(' ')}</div>
        </div>
      );
    } else {
      return (
        <div
          className={
            !rowData.active
              ? classes.nameContainer
              : classes.nameContainerActiveUser
          }
        >
          {cellId === 'phoneNumber' && formatPhoneNumber(rowData[cellId] || '')}
          {cellId === 'roleNames' && rowData[cellId]?.join(', ')}
          {cellId !== 'phoneNumber' &&
            cellId !== 'roleNames' &&
            rowData[cellId as keyof IProfile]}
        </div>
      );
    }
  };

  const closeModalHandler = useCallback(() => {
    if (!isRemovingUser) {
      setUserData(null);
      setIsModalOpen(false);
    }
  }, [isRemovingUser]);

  const removeUserHandler = useCallback(async () => {
    await removeUser();
    closeModalHandler();
    refetchTeammates();
  }, [closeModalHandler, refetchTeammates, removeUser]);

  const renderActions = useCallback(
    (rowData: any) => {
      return profile?.userId !== rowData.userId;
    },
    [profile?.userId],
  );

  const onClickRowHandler = useCallback(
    (rowData: IProfile) => {
      const searchParams = new URLSearchParams({
        ['id']: `${rowData.userId}`,
      });
      history.push({
        pathname: Routes.Profile.replace(':organizationId', organizationId),
        search: `${searchParams}`,
      });
    },
    [history, organizationId],
  );

  return (
    <>
      {isModalOpen && (
        <ConfirmationModal
          closeHandler={closeModalHandler}
          message={modalMessage}
          isOpen={isModalOpen}
          isLoading={isRemovingUser}
          confirmationHandler={removeUserHandler}
        />
      )}
      <Table
        withHover={true}
        actions={actions}
        columns={columns}
        tableData={teammates}
        onClickRow={onClickRowHandler}
        withCheckbox={false}
        rowsTotal={rowsTotal}
        renderActions={renderActions}
        orderBy={'name'}
        renderCell={renderCell}
        withPagination={true}
        baseURL={Routes.Teammates.replace(
          ':organizationId',
          currentOrganizationId,
        )}
        isLoading={isLoading}
        sortHandler={sortHandler}
        withPerPageCount={true}
      />
    </>
  );
};
