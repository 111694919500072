import React from 'react';
import {
  NotificationEntityStatus,
  ProjectStatus,
  PourEventStatus,
} from 'types';
import { CustomTypography } from '../Typography/Typography';
import { useStyles } from './ProjectStatusLabel.styles';

interface IProjectStatusLabelProps {
  status: NotificationEntityStatus | ProjectStatus | PourEventStatus | string;
}

export const ProjectStatusLabel = ({ status }: IProjectStatusLabelProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span
        className={
          status === NotificationEntityStatus.ACTIVE
            ? classes.greenCircle
            : status === NotificationEntityStatus.INACTIVE ||
              status === NotificationEntityStatus.UPCOMING
            ? classes.greyCircle
            : classes.blueCircle
        }
      />
      <CustomTypography
        variant="caption2"
        bold
        color={
          status === NotificationEntityStatus.ACTIVE
            ? 'systemGreen'
            : status === NotificationEntityStatus.INACTIVE ||
              status === NotificationEntityStatus.UPCOMING
            ? 'greyscale2'
            : 'systemBlue'
        }
      >
        {status}
      </CustomTypography>
    </div>
  );
};
