import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  mixIdSearch: {
    marginBottom: 30,
  },
  mixId: {
    margin: '0px -16px',
    padding: '0px 12px',
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: themeColors.white,
  },
  mixIdHovered: {
    margin: '0px -16px',
    padding: '0px 12px',
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: themeColors.highlightBlue,
  },
});
