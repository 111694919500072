import React, { useCallback, useEffect, useMemo } from 'react';
import { useStyles } from './ViewButtons.styles';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { ReactComponent as ListIcon } from 'assets/list_button.svg';
import { ReactComponent as CardIcon } from 'assets/card_button.svg';
import { ViewMode } from '../../types';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';
import { useScreen } from 'app/shared/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/store/types';
import { actions } from 'app/store/slices/layoutSlice';

export const ViewButtons = () => {
  const classes = useStyles();
  const { screenWidth } = useScreen();
  const dispatch = useDispatch();
  const viewMode = useSelector((state: RootState) => state.layout.view);

  const handleListViewClick = useCallback(() => {
    dispatch(actions.setView(ViewMode.LIST_VIEW));
    dispatch(actions.setNotificationEntitySectionDefaultState());

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      'List view element',
    );
  }, [dispatch]);

  const handleCardViewClick = useCallback(() => {
    dispatch(actions.setView(ViewMode.CARD_VIEW));
    dispatch(actions.setNotificationEntitySectionDefaultState());

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      'Card view element',
    );
  }, [dispatch]);

  const isSideNavOpen = useSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );

  const sideNavWidth = useMemo(() => {
    return isSideNavOpen
      ? themeLayout.sideNavWidth
      : themeLayout.sideNavHiddenWidth;
  }, [isSideNavOpen]);

  const containerWidth = useMemo(() => {
    return screenWidth - sideNavWidth;
  }, [sideNavWidth, screenWidth]);

  useEffect(() => {
    if (containerWidth < themeLayout.minContainerWidthForListView) {
      dispatch(actions.setNotificationEntitySectionDefaultState());
      dispatch(actions.setView(ViewMode.CARD_VIEW));
    }
  }, [sideNavWidth, containerWidth, dispatch]);

  return (
    <div className={classes.buttons}>
      <Button
        variant={viewMode === ViewMode.LIST_VIEW ? 'activeView' : 'view'}
        disabled={containerWidth < themeLayout.minContainerWidthForListView}
        onClick={handleListViewClick}
      >
        <ListIcon
          fill={
            viewMode === ViewMode.LIST_VIEW
              ? themeColors.white
              : themeColors.greyscale1
          }
        />
      </Button>
      <Button
        variant={viewMode === ViewMode.CARD_VIEW ? 'activeView' : 'view'}
        onClick={handleCardViewClick}
      >
        <CardIcon
          fill={
            viewMode === ViewMode.CARD_VIEW
              ? themeColors.white
              : themeColors.greyscale1
          }
        />
      </Button>
    </div>
  );
};
