import { makeStyles, TextField, withStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    marginTop: `-${themeLayout.headerHeight}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  body: {
    paddingTop: 78,
  },
  small: {
    width: 780,
    height: 269,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: 375,
      height: 269,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },

  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px',
    alignItems: 'center',
    position: 'fixed',
    height: '86px',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    width: 780,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: 375,
    },
  },

  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 24px 0px 24px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    paddingBottom: 28,
  },
  label: {
    padding: '24px 10px 8px 10px',
  },

  input: {
    padding: '8px 10px',
  },
  root: {
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    margin: 0,
    height: 38,
  },

  focused: {
    borderColor: themeColors.white,
  },
  contained: {
    marginLeft: '10px',
    color: themeColors.greyscale2,
  },
}));

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: 7,
      '& fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&:hover fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.systemBlue,
      },
      '&.MuiFormHelperText-root ': {
        marginLeft: '-10px',
      },
      input: {
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue',
        },
      },
    },
  },
})(TextField);
