import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Tabs } from './Tabs';
import { Pages } from './TabNavigation.types';
import { useStyles } from './TabNavigation.styles';

interface ITabNavigationProps {
  pageType: Pages;
  buttonText?: string;
  onClick?: () => void;
}

const urlLengthForProjectDetails = 3;

export const TabNavigation = ({ pageType }: ITabNavigationProps) => {
  const classes = useStyles();
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const { tab }: { tab: string } = useParams();

  const tabName = useMemo(() => {
    return pathname.length > urlLengthForProjectDetails &&
      pageType === Pages.PROJECT_PAGE
      ? pathname[2]
      : pageType === Pages.ORGANIZATION_PAGE ||
        pageType === Pages.MY_ORGANIZATION ||
        pageType === Pages.REPORT ||
        pageType === Pages.NOTIFICATION_SETTINGS
      ? tab
      : '';
  }, [pageType, pathname, tab]);

  return (
    <div
      className={
        pageType === Pages.PROJECT_PAGE ||
        pageType === Pages.REPORT ||
        pageType === Pages.NOTIFICATION_SETTINGS
          ? classes.projectTabNavigation
          : classes.tabNavigation
      }
    >
      <Tabs pageType={pageType} tab={tabName} />
    </div>
  );
};
