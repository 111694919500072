import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { useVehicleApi } from 'app/shared/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './TruckSettings.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { Routes, IVehicle } from 'types';
import { Switch } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { themeColors } from 'app/shared/theme/theme';
import { FilterModal } from './FilterModal';
import { useAuth } from 'app/shared/providers';

const skipOnePage = 1;

export const TruckSettings = () => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuth();

  const classes = useStyles();
  const [truckStatus, setTruckStatus] = useState<boolean | ''>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleFilterModalOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    },
    [],
  );

  const closeFilterHandler = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const {
    page,
    perPage,
    tab,
    organizationId,
  }: {
    page: string;
    perPage: string;
    orderType: string;
    organizationId: string;
    tab: string;
  } = useParams();

  const contactUsHandler = useCallback(() => {
    return history.push(
      Routes.ContactUs.replace(':organizationId', organizationId),
    );
  }, [history, organizationId]);

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      isInfiniteScrolling: false,
      queryParameters: {
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        sortBy: 'externalId',
        sortAsc: true,
        hasProbeTracker: truckStatus.toString() || '',
      },
    };
  }, [organizationId, page, perPage, truckStatus]);

  const {
    vehicles,
    fetchVehicles,
    isFetchingVehicles,
    vehiclesResponse,
    changeVehicleNotificationState,
  } = useVehicleApi(queryParams);

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles, queryParams]);

  const updateTruckNotifications = useCallback(
    (notifications?: boolean, visibility?: boolean, items?: IVehicle[]) => {
      const payload = items?.map(truck => {
        return {
          vehicleId: truck.vehicleId,
          enableNotifications:
            visibility === false
              ? false
              : notifications === undefined
              ? truck.enableNotifications
              : notifications,
          enableVisibility:
            visibility === undefined ? truck.enableVisibility : visibility,
        };
      });
      payload && changeVehicleNotificationState(payload);
    },
    [changeVehicleNotificationState],
  );

  const filterButton = useMemo(() => {
    return (
      <div
        className={classes.filterContainer}
        onClick={e => {
          handleFilterModalOpen(e);
        }}
      >
        <div className={classes.filter}>
          <FilterIcon
            height={11}
            stroke={themeColors.mbsBlue}
            strokeWidth={0.3}
            fill={themeColors.mbsBlue}
          />
        </div>

        <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
          {t(translations.truckListing.filter)}
        </CustomTypography>
      </div>
    );
  }, [classes.filter, classes.filterContainer, handleFilterModalOpen, t]);

  const columns = useMemo(() => {
    return [
      {
        id: 'vehicleId',
        label: t(translations.truckListing.trucks),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'hasProbeTracker',
        label: t(translations.truckListing.status),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'enableVisibility',
        label: t(translations.plantListing.visibility),
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
      {
        id: 'enableNotifications',
        label: t(translations.plantListing.notifications),
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        sortable: false,
      },
    ];
  }, [t]);

  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'enableNotifications' || cellId === 'enableVisibility') {
        return (
          <div className={classes.switchContainer}>
            {!isSuperAdmin && (
              <CustomTypography
                variant="subtitle1"
                bold
                color={rowData[cellId] ? 'mbsBlue' : 'greyscale2'}
              >
                {rowData[cellId]
                  ? t(translations.truckListing.on)
                  : t(translations.truckListing.off)}
              </CustomTypography>
            )}

            <Switch
              disabled={!isSuperAdmin}
              checked={rowData[cellId]}
              onChange={() =>
                updateTruckNotifications(
                  cellId === 'enableNotifications'
                    ? !rowData.enableNotifications
                    : undefined,
                  cellId === 'enableVisibility'
                    ? !rowData.enableVisibility
                    : undefined,
                  [rowData],
                )
              }
              color="primary"
              className={classes.toggle}
              classes={{
                root: classes.rootSwitch,
                switchBase: classes.switchBase,
                track: classes.switchTrack,
                thumb: classes.thumb,
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        );
      } else if (cellId === 'vehicleId') {
        return (
          <div>{`${t(translations.truckListing.truck)} #${
            rowData[cellId] || ''
          }`}</div>
        );
      } else if (cellId === 'hasProbeTracker') {
        return rowData[cellId] === true ? (
          <div className={classes.valueContainer}>
            {t(translations.truckListing.withProbe)}
          </div>
        ) : null;
      }
    },
    [
      classes.rootSwitch,
      classes.switchBase,
      classes.switchContainer,
      classes.switchTrack,
      classes.thumb,
      classes.toggle,
      classes.valueContainer,
      isSuperAdmin,
      t,
      updateTruckNotifications,
    ],
  );

  const filterSubmitHandler = useCallback(
    value => {
      setTruckStatus(value);
      closeFilterHandler();
    },
    [closeFilterHandler],
  );

  return (
    <div>
      <FilterModal
        anchorEl={anchorEl}
        submitHandler={filterSubmitHandler}
        closeHandler={closeFilterHandler}
        open={open}
        initialValue={truckStatus}
      />
      <SectionSpoiler
        header={t(translations.truckListing.truckListing)}
        withIcons={true}
      >
        {!isSuperAdmin && (
          <div className={classes.alertText}>
            <CustomTypography variant="subtitle1" color="accentDark">{`${t(
              translations.truckListing.noPermissions,
            )}`}</CustomTypography>
            <div className={classes.link} onClick={contactUsHandler}>
              <CustomTypography bold variant="subtitle1" color="mbsBlue">
                {t(translations.truckListing.contactUs)}
              </CustomTypography>
            </div>
          </div>
        )}

        <div className={classes.actions}>
          {filterButton}
          {isSuperAdmin && (
            <>
              <div
                className={classes.linkContainer}
                onClick={() => updateTruckNotifications(true, true, vehicles)}
              >
                <CustomTypography
                  variant="buttonTextSmall"
                  bold
                  color="mbsBlue"
                >
                  {t(translations.truckListing.activateAll)}
                </CustomTypography>
              </div>
              <div
                className={classes.linkContainer}
                onClick={() => updateTruckNotifications(false, false, vehicles)}
              >
                <CustomTypography
                  variant="buttonTextSmall"
                  bold
                  color="mbsBlue"
                >
                  {t(translations.truckListing.deactivateAll)}
                </CustomTypography>
              </div>
            </>
          )}
        </div>

        <Table
          withHover={true}
          withPerPageCount={true}
          withPagination={true}
          columns={columns}
          tableData={vehicles}
          withCheckbox={false}
          isLoading={isFetchingVehicles}
          emptyTableText={`${t(translations.truckListing.noTrucks)}.`}
          baseURL={Routes.OrganizationDetails.replace(
            ':organizationId/:tab',
            `${organizationId}/${tab}`,
          )}
          rowsTotal={vehiclesResponse?.metadata?.totalCount}
          renderCell={renderCell}
          withPadding={false}
        />
      </SectionSpoiler>
    </div>
  );
};
