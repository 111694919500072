import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  values: {
    padding: '0px 6px',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  value: {
    padding: '10px',
  },
});
