import React, { useMemo } from 'react';
import { useStyles } from './ErrorModal.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { StatusCodes } from 'http-status-codes';
import { IError } from 'types';
import { ModalHeader, ModalVariant } from '../ModalHeader';

interface IErrorModalProps {
  isOpen: boolean;
  closeHandler: () => void;
  error: IError;
}

export const ErrorModal = ({
  isOpen,
  closeHandler,
  error,
}: IErrorModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  //TODO: think for better error messages

  const title = useMemo(() => {
    return error.statusCode === StatusCodes.BAD_REQUEST
      ? t(translations.errors.badRequest)
      : error.statusCode === StatusCodes.UNAUTHORIZED
      ? t(translations.errors.notAuthorized)
      : error.statusCode || t(translations.errors.notFound);
  }, [error.statusCode, t]);

  const errorMessage = useMemo(() => {
    //TODO: handle translations
    return error.message || t(translations.errors.notFound);
  }, [error.message, t]);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.small}>
        <div className={classes.container}>
          <ModalHeader
            variant={ModalVariant.SMALL}
            header={`${t(translations.errors.error)} - ${title}`}
            color="systemRed"
            closeHandler={closeHandler}
          />

          <div className={classes.body}>
            <CustomTypography variant="bodyLarge" color="accentLight">
              {errorMessage}
            </CustomTypography>
          </div>
          <div className={classes.saveButton} data-testid="button">
            <Button variant="primarySave" onClick={closeHandler}>
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.errors.okay)}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
