import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '16px 24px',
  },
  input: {
    boxSizing: 'border-box',
    padding: 8,
    marginBottom: 8,
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: '100%',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      width: '50%',
    },
  },
  inputs: {
    padding: '16px 16px',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      flexDirection: 'row',
    },
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  info: {
    boxSizing: 'border-box',
    backgroundColor: themeColors.greyscale4_50,
    padding: '14px 45px 14px 25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  icon: {
    marginRight: 10,
  },
  form: {
    height: '100%',
  },
  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
