import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    boxSizing: 'border-box',
    padding: '16px 24px',
  },

  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
