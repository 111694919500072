import { useStyles } from './MixIdSearch.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchHelper } from 'app/shared/utils';
import { useHistory, useParams } from 'react-router-dom';
import { useMixDesignApi } from 'app/shared/hooks';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { Routes } from 'types';

export const MixIdSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const history = useHistory();
  const { prepareSearchQueryParameter } = useSearchHelper();

  const {
    organizationId,
  }: {
    organizationId: string;
  } = useParams();

  const searchColumns = [
    {
      id: 'mixId',
      label: t(translations.mixIdTable.mixId),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const searchQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        query: prepareSearchQueryParameter(search),
        take: 10,
        skip: 0,
        sortBy: 'externalId',
        sortAsc: true,
      },
    };
  }, [organizationId, prepareSearchQueryParameter, search]);

  const {
    fetchSearchMixIdCategorizations,
    searchMixIdCategorization,
    isFetchingSearchMixIdCategorizations,
  } = useMixDesignApi(searchQueryParams);

  useEffect(() => {
    fetchSearchMixIdCategorizations();
  }, [fetchSearchMixIdCategorizations, searchQueryParams]);

  const renderCell = useCallback(
    (rowData: any, cellId: string, isHovered?: boolean) => {
      if (cellId === 'mixId') {
        return (
          <div className={isHovered ? classes.mixIdHovered : classes.mixId}>{`${
            rowData.externalProductId || ''
          } / ${rowData.externalProductName || ''}`}</div>
        );
      }
    },
    [classes.mixId, classes.mixIdHovered],
  );

  return (
    <div className={classes.mixIdSearch}>
      <Search
        columns={searchColumns}
        entries={searchMixIdCategorization}
        onChange={setSearch}
        isLoading={isFetchingSearchMixIdCategorizations}
        placeholder={t(translations.mixIdTable.searchBy)}
        onClickRow={value => {
          setSearch(`${value?.firstName} ${value?.lastName}`);
          const searchParams = new URLSearchParams({
            ['search']: `${value?.externalProductId} / ${value?.externalProductName}`,
          });
          history.push({
            pathname: location.pathname,
            search: `${searchParams}`,
          });
        }}
        renderData={renderCell}
        getRowId={'externalProductId'}
        analyticsReference={Routes.OrganizationDetails}
      />
    </div>
  );
};
