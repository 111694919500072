import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  avatarContainer: {
    borderRadius: '50%',
    width: '100%',
    color: themeColors.mbsBlue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
