import { makeStyles, TextField, withStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  body: {
    paddingTop: '3px',
    margin: '71px 0 0px 0',
    maxHeight: 620,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      maxHeight: 470,
    },
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  small: {
    width: 780,
    height: 375,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
      height: 390,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '375px',
      height: 390,
    },

    margin: 'auto',
    position: 'relative',
    outline: 'none !important',
    backgroundColor: themeColors.white,
  },
  big: {
    width: 780,
    height: 730,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
      height: 570,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
      height: 570,
    },
    margin: 'auto',
    outline: 'none !important',
    position: 'relative',
    backgroundColor: themeColors.white,
  },
  expandButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 24px 0px 24px',
    alignItems: 'center',
    cursor: 'pointer',
  },
  expandIcon: {
    color: themeColors.greyscale1,
  },
  saveButtonExpanded: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '24px',
    position: 'absolute',
    bottom: 0,
    height: '86px',
    width: '780px',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    borderTop: `2px solid ${themeColors.greyscale4}`,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '34px 24px',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    height: '86px',
    width: '780px',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
  },
}));

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: 7,
      '& fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&:hover fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.systemBlue,
      },
      '&.MuiFormHelperText-root ': {
        marginLeft: '-10px',
      },
      input: {
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue',
        },
      },
    },
  },
})(TextField);
