import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import React, { useCallback, useState } from 'react';
import {
  INotificationEntity,
  INotificationSent,
  NotificationEntityType,
  Routes,
} from 'types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

import {
  getNotificationEntityName,
  getNotificationSettingsTab,
} from 'app/shared/utils';
import { useStyles } from './Notifications.styles';
import { ReactComponent as ImageNotifications } from 'assets/bell.svg';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as NotificationSettingsIcon } from 'assets/settings.svg';
import { useHistory, useParams } from 'react-router-dom';

interface INotificationsProps {
  isExpanded: boolean;
  origin: NotificationEntityType;
  notifications: INotificationSent[];
  notificationEntity: INotificationEntity;
  eventStatusCode: string;
  viewNotificationsSentHandler: (notifications: INotificationSent[]) => void;
}

const isPlural = 1;

export const Notifications = ({
  isExpanded,
  origin,
  notifications,
  viewNotificationsSentHandler,
  notificationEntity,
  eventStatusCode,
}: INotificationsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(isExpanded);
  const { organizationId }: { organizationId: string } = useParams();

  const expandHandler = useCallback(() => {
    setIsExpand(prevState => !prevState);
  }, []);

  const notificationSettingsClickHandler = useCallback(() => {
    localStorage.setItem(origin.toString(), JSON.stringify(notificationEntity));
    const searchParams = new URLSearchParams({
      ['code']: eventStatusCode,
    });

    history.push({
      pathname: Routes.NotificationSettings.replace(
        ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
        `${organizationId}/${getNotificationSettingsTab(origin)}/1/20/1/20`,
      ),
      search: `${searchParams}`,
    });
  }, [eventStatusCode, history, notificationEntity, organizationId, origin]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerClickable} onClick={expandHandler}>
        <div className={classes.text}>
          <div className={classes.title}>
            <ImageNotifications
              stroke={themeColors.mbsBlue}
              height={18}
              width={18}
            />

            <div className={classes.paddingContainer}>
              <CustomTypography variant="bodySmall" bold color="mbsBlue">
                {t(translations.batchEventCard.notifications)}
              </CustomTypography>
            </div>
          </div>
        </div>

        <div className={classes.arrowContainer}>
          {isExpand ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </div>
      </div>

      {isExpand && (
        <div className={classes.container}>
          <div className={classes.item}>
            <CustomTypography variant="bodySmall" color="accentDark">
              {`${t(translations.batchEventCard.notificationsSentTo)}: `}
            </CustomTypography>

            <div
              className={classes.sentNotificationsLink}
              onClick={() =>
                notifications.length
                  ? viewNotificationsSentHandler(notifications)
                  : null
              }
            >
              <CustomTypography
                variant="bodySmall"
                color={notifications?.length ? 'mbsBlue' : 'accentDark'}
              >
                {`${notifications?.length} Member${
                  notifications.length > isPlural || !notifications.length
                    ? 's'
                    : ''
                }`}
              </CustomTypography>
            </div>
          </div>

          <div className={classes.item}>
            <CustomTypography variant="bodySmall" color="accentDark">
              {`${t(
                translations.batchEventCard.origin,
              )}: ${getNotificationEntityName(origin)}`}
            </CustomTypography>

            <NotificationSettingsIcon
              stroke={themeColors.mbsBlue}
              className={classes.sentNotificationsLink}
              onClick={notificationSettingsClickHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};
