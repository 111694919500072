import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField,
  withStyles,
} from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { IIcon } from 'types';

export const useStyles = makeStyles({
  root: {
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 0,
    },
    margin: 0,
    height: 38,
  },
  rootDefault: { height: 'unset' },
  rootBig: {
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 0,
    },
    margin: 0,
    height: 44,
  },
  focused: {
    borderColor: themeColors.white,
  },
  multiline: {
    padding: 0,
  },
  contained: {
    marginLeft: '0px',
    marginTop: '-5px',
    color: themeColors.greyscale2,
    height: 0,
  },
  input: {
    padding: '8px 10px',
    fontSize: '14px',
    lineHeight: '22px',
    height: 37,
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  inputBig: {
    padding: '8px 10px',
    fontSize: '14px',
    lineHeight: '22px',
    height: 44,
    boxSizing: 'border-box',
  },
  icons: {
    // marginRight: 5,
  },
});

export const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
      borderColor: themeColors.mbsBlue,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: '0px',
    },
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: '4px',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: 7,
      '& fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&:hover fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.mbsBlue,
      },
      '&.MuiFormHelperText-root ': {
        marginLeft: '-10px',
        marginTop: '-10px',
        height: 0,
      },

      input: {
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue',
        },
      },
    },
  },
})(TextField);

export enum InputSize {
  SMALL,
  BIG,
  NORMAL,
}

interface ITextInputProps {
  disabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
  name: string;
  error?: boolean;
  control?: Control<any, any>;
  helperText?: React.ReactNode;
  maxLength?: number;
  backgroundColor?: keyof typeof themeColors;
  multilineRowsCount?: number;
  customSize?: InputSize;
  onChangeHandler?: (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClickHandler?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  autoFocus?: boolean;
  currentValue?: string;
  icons?: IIcon[];
  autoComplete?: string;
  borderRadius?: number;
  withHelperText?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  withoutMargin?: boolean;
  color?: string;
}

const rowsCount = 5;
const defaultBorderRadius = 4;

export const TextInput = ({
  disabled,
  placeholder,
  multiline,
  name,
  error,
  customSize = InputSize.SMALL,
  onChangeHandler,
  control,
  helperText,
  maxLength,
  backgroundColor,
  multilineRowsCount = rowsCount,
  onClickHandler,
  autoFocus,
  currentValue,
  icons,
  autoComplete = 'on',
  borderRadius = defaultBorderRadius,
  withHelperText = true,
  withoutMargin,
  onKeyPress,
  color,
}: ITextInputProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState(currentValue || '');

  useEffect(() => {
    if (currentValue !== undefined) {
      setTextValue(currentValue);
    }
  }, [currentValue]);

  return (
    <div>
      {control ? (
        <Controller
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              onFocus={onClickHandler}
              disabled={disabled ?? false}
              classes={{
                root:
                  customSize === InputSize.SMALL
                    ? classes.root
                    : customSize === InputSize.NORMAL
                    ? classes.rootDefault
                    : classes.rootBig,
              }}
              name={name}
              error={error}
              value={currentValue !== undefined ? currentValue : value}
              autoComplete={autoComplete}
              onChange={e => {
                setTextValue(e.target.value);
                onChangeHandler && onChangeHandler(e);
                onChange(e);
              }}
              placeholder={placeholder}
              helperText={
                helperText
                  ? helperText
                  : value?.length === maxLength && maxLength
                  ? `${t(
                      translations.errors.thisFieldCanNotExceed,
                    )} ${maxLength} ${t(translations.errors.characters)}.`
                  : ''
              }
              fullWidth
              type="string"
              multiline={multiline}
              minRows={multiline ? multilineRowsCount : undefined}
              maxRows={5}
              margin={withoutMargin ? undefined : 'normal'}
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                classes: {
                  contained: classes.contained,
                },
              }}
              inputProps={{
                maxLength: maxLength,
              }}
              InputProps={{
                endAdornment: icons ? (
                  <InputAdornment position="end">
                    {icons?.map((icon, index) => (
                      <div key={index} onClick={icon.action}>
                        {icon.icon}
                      </div>
                    ))}
                  </InputAdornment>
                ) : null,
                classes: {
                  input:
                    customSize === InputSize.BIG
                      ? classes.inputBig
                      : classes.input,
                  focused: classes.focused,
                  multiline: classes.multiline,
                },

                style: {
                  backgroundColor:
                    backgroundColor && !disabled
                      ? themeColors[backgroundColor]
                      : disabled
                      ? themeColors.greyscale4_50
                      : '',
                  borderRadius: borderRadius,
                },
              }}
              variant="outlined"
            />
          )}
          name={name}
          control={control}
        />
      ) : (
        <StyledTextField
          onFocus={onClickHandler}
          autoFocus={autoFocus}
          disabled={disabled ?? false}
          classes={{
            root:
              customSize === InputSize.SMALL
                ? classes.root
                : customSize === InputSize.NORMAL
                ? classes.rootDefault
                : classes.rootBig,
          }}
          autoComplete={autoComplete}
          name={name}
          error={error}
          value={textValue}
          onChange={e => {
            setTextValue(e.target.value);
            onChangeHandler && onChangeHandler(e);
          }}
          placeholder={placeholder}
          helperText={
            helperText
              ? helperText
              : textValue?.length === maxLength && maxLength && withHelperText
              ? `${t(
                  translations.errors.thisFieldCanNotExceed,
                )} ${maxLength} ${t(translations.errors.characters)}.`
              : ''
          }
          fullWidth
          type="string"
          multiline={multiline}
          minRows={multiline ? multilineRowsCount : undefined}
          maxRows={5}
          onKeyPress={onKeyPress}
          margin={withoutMargin ? undefined : 'normal'}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: {
              contained: classes.contained,
            },
          }}
          inputProps={{
            maxLength: maxLength,
          }}
          InputProps={{
            endAdornment: icons ? (
              <InputAdornment position="end">
                {icons?.map((icon, index) => (
                  <div key={index} onClick={icon.action}>
                    {icon.icon}
                  </div>
                ))}
              </InputAdornment>
            ) : null,
            classes: {
              input:
                customSize === InputSize.BIG ? classes.inputBig : classes.input,
              focused: classes.focused,
              multiline: classes.multiline,
            },
            style: {
              color: color,
              caretColor: themeColors.accentDark,
              backgroundColor:
                backgroundColor && !disabled
                  ? themeColors[backgroundColor]
                  : disabled
                  ? themeColors.greyscale4_50
                  : '',
              borderRadius: borderRadius,
            },
          }}
          variant="outlined"
        />
      )}
    </div>
  );
};
