import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  container: {
    backgroundColor: themeColors.mobileBackground,
    boxSizing: 'border-box',
    padding: '24px 24px 17px 24px',
    height: '100%',
  },
  subheaderContainer: {
    marginBottom: 8,
    borderBottom: `2px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    marginBottom: 5,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    cursor: 'pointer',
    borderLeft: `1px solid ${themeColors.accentDark14}`,
  },
  icon: {
    margin: '0px 7px ',
  },
  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  refreshButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
    paddingRight: 10,
    cursor: 'pointer',
  },
  refreshIcon: {
    marginRight: 6,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    zIndex: 9999,
    backgroundColor: themeColors.greyscale4_50,
    position: 'absolute',
    top: 0,
    bottom: 0,
    paddingTop: 220,
  },
});
