import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { useStyles } from './ContactUsPage.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Grid, GridVariant } from 'app/shared/components/generic-ui';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { contactUsInfo } from 'app/shared/config';
import { useCallback } from 'react';

export const ContactUsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const emailClickHandler = useCallback(() => {
    return (window.location.href = `mailto:${contactUsInfo.email}`);
  }, []);

  return (
    <div className={classes.pageWrapper}>
      <SectionSpoiler
        header={t(translations.contactUsPage.contactUs)}
        withIcons={false}
      >
        <div className={classes.text}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {`${t(translations.contactUsPage.toAskQuestion)}:`}
          </CustomTypography>
        </div>

        <div className={classes.contacts}>
          <Grid container spacing={1}>
            <Grid container item spacing={1} variant={GridVariant.LARGE}>
              <Grid item md={12} lg={12}>
                <CustomTypography variant="subtitle1" color="greyscale2" bold>
                  {t(translations.contactUsPage.callUs)}
                </CustomTypography>
              </Grid>

              <Grid item md={12} lg={12}>
                <CustomTypography variant="subtitle1" color="accentDark">
                  {contactUsInfo.phone}
                </CustomTypography>
              </Grid>
            </Grid>

            <Grid container item spacing={1} variant={GridVariant.LARGE}>
              <Grid item md={12} lg={12}>
                <CustomTypography variant="subtitle1" color="greyscale2" bold>
                  {t(translations.contactUsPage.emailAddress)}
                </CustomTypography>
              </Grid>

              <Grid
                item
                md={12}
                lg={12}
                onClick={emailClickHandler}
                className={classes.link}
              >
                <CustomTypography variant="subtitle1" color="mbsBlue">
                  {contactUsInfo.email}
                </CustomTypography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </SectionSpoiler>
    </div>
  );
};
