import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';
import { themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '5px 30px 160px 30px',
  },
  header: {
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12,
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
  },
  label: {
    padding: '0px 10px 8px 10px',
  },
  formControl: {
    width: '100%',
    minHeight: '35px',
    backgroundColor: themeColors.white,
  },
  select: {
    padding: '8px 10px',
    fontSize: '14px',
    lineHeight: '20px',
    height: 17,
  },
  paper: {
    marginTop: 45,
  },
  labelRoot: {
    transform: 'translate(10px,10px) scale(1)',
  },
  inputContainer: {
    marginBottom: 15,
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: 292,
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      [theme.breakpoints.down(themeLayout.mobileWidth)]: {
        width: 292,
      },
      [theme.breakpoints.up(themeLayout.mobileWidth)]: {
        width: 356,
      },
    },
  },
  multilineContainer: {
    marginBottom: 60,
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: 292,
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      width: 356,
    },
  },
  measurementContainer: {
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: 292,
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      width: 356,
    },
    marginBottom: 15,
  },
  helperText: {
    backgroundColor: themeColors.greyscale5,
    padding: '3px 10px 0px 0px',
    color: 'red',
  },
  logo: {
    margin: '10px 0px 16px 0px',
    maxWidth: 444,
  },
  dropzone: {
    maxHeight: 200,
  },
  backArrow: {
    marginRight: 13,
    cursor: 'pointer',
  },
  grid: {
    maxWidth: 760,
  },
}));
