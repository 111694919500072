import React from 'react';
import { MainRouter } from 'app/shared/components';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'app/shared/theme/theme';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'app/shared/config';
import {
  AuthProvider,
  ErrorProvider,
  ModalProvider,
  NotificationSettingsProvider,
} from 'app/shared/providers';

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      useErrorBoundary: false,
      retry: 1,
      cacheTime: 1,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

const msalInstance = new PublicClientApplication({
  ...msalConfig,
  auth: {
    ...msalConfig.auth,
    navigateToLoginRequestUrl: !!localStorage.getItem('redirectUrl'),
  },
});

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ErrorProvider>
            <ModalProvider>
              <NotificationSettingsProvider>
                <QueryClientProvider client={queryClient}>
                  <MainRouter />
                </QueryClientProvider>
              </NotificationSettingsProvider>
            </ModalProvider>
          </ErrorProvider>
        </ThemeProvider>
      </AuthProvider>
    </MsalProvider>
  );
}
