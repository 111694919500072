import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  appHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: themeLayout.headerHeight,
    zIndex: 5,
    backgroundColor: themeColors.white,
    boxShadow: themeShadows.shadowMedium,
    position: 'fixed',
    top: 0,
    boxSizing: 'border-box',
    padding: '0px 15px 0px 23px',
  },
  clientLogo: {
    maxHeight: '44px',
    maxWidth: '144px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
  divider: {
    width: '2px',
    height: '36px',
    backgroundColor: themeColors.greyscale4,
    marginRight: '16px',
  },

  menuList: {
    marginTop: 3,
    width: '209px',
    padding: '8px 0 8px 0',
    backgroundColor: themeColors.white,
    boxShadow: themeShadows.shadowMedium,
    borderRadius: '2px',
  },
  avatarContainer: {
    cursor: 'pointer',
  },
});
