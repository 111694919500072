import { useStyles } from './AddOrganizationPage.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ICreateOrganizationPayload, IError, Routes } from 'types';
import { useOrganizationsApi } from 'app/shared/hooks';
import { useHistory } from 'react-router';
import { StyledDropzone } from 'app/shared/components/generic-ui/StyledDropzone/StyledDropzone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useError } from 'app/shared/providers';
import { useParams } from 'react-router-dom';
import arrow_left from 'assets/arrow_left.svg';
import { Grid, LoadingScreen } from 'app/shared/components/generic-ui';
import { firebaseAnalytics } from 'app/shared/analytics';
import { errors as configErrors } from 'app/shared/config';
import { TabIds } from 'app/shared/components/layout/TabNavigation/TabNavigation.types';
import { useAuth } from 'app/shared/providers';

const maxInputLength = 100;
const maxDescriptionLength = 500;
const imperial = 0;
const metric = 1;

export const AddOrganizationPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { createOrganization, isCreatingOrganization } = useOrganizationsApi(
    {},
  );
  const history = useHistory();
  const { openModal } = useError();
  const { organizationId }: { organizationId: string } = useParams();
  const [error, setError] = useState<IError>();
  const [file, setFile] = useState<File>();
  const [isDeleted, setIsDeleted] = useState(false);
  const { isSuperAdmin } = useAuth();

  const {
    fetchOrganization,
    organizationDetails,
    updateOrganization,
    isUpdatingOrganization,
    isFetchingOrganization,
  } = useOrganizationsApi({
    organizationId: organizationId,
  });

  useEffect(() => {
    if (organizationId) {
      fetchOrganization();
    }
  }, [fetchOrganization, organizationId]);

  const guidRegExp = new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i',
  );

  const selectMetricSystemValues = useMemo(() => {
    return [
      {
        label: t(translations.addOrganizationPage.imperial),
        value: 0,
      },
      {
        label: t(translations.addOrganizationPage.metric),
        value: 1,
      },
    ];
  }, [t]);

  const selectActiveDataFlowValues = useMemo(() => {
    return [
      {
        label: t(translations.addOrganizationPage.active),
        value: true,
      },
      {
        label: t(translations.addOrganizationPage.inactive),
        value: false,
      },
    ];
  }, [t]);

  const formDefaultValues = useMemo(() => {
    return {
      supplierId: organizationId || '',
      supplierName:
        organizationId && organizationDetails?.supplierName
          ? organizationDetails.supplierName
          : '',
      description:
        organizationId && organizationDetails?.description
          ? organizationDetails?.description
          : '',
      measurementSystem: !organizationId
        ? ''
        : organizationDetails?.measurementSystem === imperial
        ? imperial
        : metric,
      active: !organizationId ? true : organizationDetails?.active,
    };
  }, [organizationDetails, organizationId]);

  const validationSchema = yup.object().shape({
    supplierId: yup
      .string()
      .matches(guidRegExp, {
        message: `${t(translations.errors.invalidFormat)}`,
        excludeEmptyString: true,
      })
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
    supplierName: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
    measurementSystem: yup
      .string()
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: ICreateOrganizationPayload) => {
    firebaseAnalytics.logPressEvent(
      Routes.AddOrganization,
      'Save/Create button',
    );

    const currentData = {
      ...data,
      logoPath:
        isDeleted && !file ? '' : organizationDetails?.supplierImagePath || '',
      logo: (isDeleted && !file) || (!isDeleted && !file) ? undefined : file,
    };

    const formData = new FormData();
    Object.keys(currentData).forEach(key =>
      formData.append(
        key,
        currentData.hasOwnProperty(key)
          ? //@ts-ignore
            currentData[key]
          : '',
      ),
    );

    const createOrganizationQuery = organizationId
      ? await updateOrganization(formData)
      : await createOrganization(formData);

    if (!createOrganizationQuery.isError) {
      if (organizationId) {
        history.push(
          Routes.OrganizationDetails.replace(
            ':organizationId/:tab/:page/:perPage',
            `${organizationId}/${TabIds.ORGANIZATION_INFO}/1/20`,
          ),
        );
      } else {
        history.push(Routes.Organizations);
      }
    }
    reset();
  };

  useEffect(() => {
    if (error) {
      firebaseAnalytics.logErrorEvent(configErrors.imageUpload, error);
      openModal(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      {(isFetchingOrganization ||
        isCreatingOrganization ||
        isUpdatingOrganization) && (
        <LoadingScreen
          text={
            isCreatingOrganization
              ? t(translations.loadingScreen.addOrganizationLoadingText)
              : undefined
          }
        />
      )}

      <div className={classes.wrapper}>
        <div className={classes.header}>
          <img
            src={arrow_left}
            className={classes.backArrow}
            onClick={handleGoBack}
          />
          <CustomTypography variant="header3" bold color="accentDark">
            {organizationId
              ? t(translations.addOrganizationPage.editOrganizationInfo)
              : t(translations.addOrganizationPage.addOrganization)}
          </CustomTypography>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={3}
              item
              md={12}
              lg={12}
              className={classes.grid}
            >
              <Grid item>
                <div className={classes.inputContainer}>
                  <div className={classes.label}>
                    <CustomTypography variant="caption1" color="greyscale1">
                      {`${t(
                        translations.addOrganizationPage.externalSupplierId,
                      )}*`}
                    </CustomTypography>
                  </div>

                  <div>
                    <TextInput
                      disabled={!!organizationId}
                      placeholder={t(
                        translations.addOrganizationPage.id,
                      ).toUpperCase()}
                      name="supplierId"
                      control={control}
                      error={!!errors?.supplierId}
                      maxLength={maxInputLength}
                      helperText={errors?.supplierId?.message}
                      backgroundColor="white"
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.label}>
                    <CustomTypography variant="caption1" color="greyscale1">
                      {`${t(translations.addOrganizationPage.supplierName)}*`}
                    </CustomTypography>
                  </div>

                  <div>
                    <TextInput
                      placeholder={t(
                        translations.addOrganizationPage.name,
                      ).toUpperCase()}
                      control={control}
                      name="supplierName"
                      error={!!errors?.supplierName}
                      maxLength={maxInputLength}
                      helperText={errors?.supplierName?.message}
                      backgroundColor="white"
                    />
                  </div>
                </div>

                {isSuperAdmin && (
                  <div className={classes.measurementContainer}>
                    <div className={classes.label}>
                      <CustomTypography variant="caption1" color="greyscale1">
                        {`${t(
                          translations.addOrganizationPage.dataFlowStatus,
                        )}*`}
                      </CustomTypography>
                    </div>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            displayEmpty
                            id="demo-simple-select-outlined"
                            value={value}
                            variant="outlined"
                            onChange={onChange}
                            name="activeDataFlow"
                            classes={{
                              outlined: classes.select,
                            }}
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                              },
                            }}
                          >
                            {selectActiveDataFlowValues.map((item, index) => (
                              <MenuItem value={item.value as any} key={index}>
                                <CustomTypography
                                  variant="subtitle2"
                                  color="accentDark"
                                >
                                  {item.label}
                                </CustomTypography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      name="active"
                      control={control}
                    />
                  </div>
                )}

                <div className={classes.measurementContainer}>
                  <div className={classes.label}>
                    <CustomTypography variant="caption1" color="greyscale1">
                      {`${t(
                        translations.addOrganizationPage.measurementSystem,
                      )}*`}
                    </CustomTypography>
                  </div>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        {value === '' && (
                          <InputLabel
                            shrink={false}
                            disableAnimation={true}
                            classes={{ outlined: classes.labelRoot }}
                          >
                            {t(translations.addOrganizationPage.chooseOne)}
                          </InputLabel>
                        )}
                        <Select
                          displayEmpty
                          id="demo-simple-select-outlined"
                          value={value}
                          variant="outlined"
                          onChange={onChange}
                          name="measurementSystem"
                          error={!!errors.measurementSystem}
                          classes={{
                            outlined: classes.select,
                          }}
                          MenuProps={{
                            classes: {
                              paper: classes.paper,
                            },
                          }}
                        >
                          {selectMetricSystemValues.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              <CustomTypography
                                variant="subtitle2"
                                color="accentDark"
                              >
                                {item.label}
                              </CustomTypography>
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.measurementSystem?.message && (
                          <FormHelperText className={classes.helperText}>
                            {errors?.measurementSystem?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                    name="measurementSystem"
                    control={control}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.logo}>
                    <div className={classes.label}>
                      <CustomTypography variant="caption1" color="greyscale1">
                        {t(
                          translations.addOrganizationPage
                            .organizationLogoOptional,
                        )}
                      </CustomTypography>
                    </div>
                    <div className={classes.dropzone}>
                      <StyledDropzone
                        logo={
                          organizationId &&
                          organizationDetails?.supplierImagePath
                            ? organizationDetails?.supplierImagePath
                            : undefined
                        }
                        errorHandler={setError}
                        submitHandler={setFile}
                        setIsDeleted={setIsDeleted}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.multilineContainer}>
                  <div className={classes.label}>
                    <CustomTypography variant="caption1" color="greyscale1">
                      {t(translations.addOrganizationPage.tenantDescription)}
                    </CustomTypography>
                  </div>

                  <div>
                    <TextInput
                      multiline
                      placeholder={t(
                        translations.addOrganizationPage.typeDescription,
                      )}
                      name="description"
                      error={!!errors?.description}
                      maxLength={maxDescriptionLength}
                      control={control}
                      multilineRowsCount={5}
                      backgroundColor="white"
                    />
                  </div>
                </div>
              </Grid>

              <Grid item lg={5}>
                <div className={classes.inputContainer}>
                  <Button type="submit" variant="primaryBig" disabled={false}>
                    <CustomTypography variant="buttonText" bold color="white">
                      {organizationId
                        ? t(translations.addOrganizationPage.save)
                        : t(translations.addOrganizationPage.create)}
                    </CustomTypography>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};
