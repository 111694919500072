import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { IProfile, IProjectMembers, Routes } from 'types';
import { useStyles } from './TeamAssignmentsCard.styles';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { firebaseAnalytics } from 'app/shared/analytics';

interface ITeamAssignmentsCard {
  team?: IProjectMembers[] | IProfile[];
  title: string;
  isLoading: boolean;
  onAddTeammate: (value: boolean) => void;
  onRemoveProjectMember: (userId: string, role?: string) => void;
  showTeamModal: (value: boolean) => void;
  changeCurrentRoleHandler: () => void;
}
const firstElementIndex = 0;
const thirdElementIndex = 2;
const emptyArrayLength = 0;

export const TeamAssignmentsCard = ({
  team,
  title,
  onAddTeammate,
  changeCurrentRoleHandler,
  showTeamModal,
  isLoading,
  onRemoveProjectMember,
}: ITeamAssignmentsCard) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const teammatesToShow = useMemo(() => {
    return team?.slice(firstElementIndex, thirdElementIndex);
  }, [team]);

  const handleAddTeammateClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.OrganizationDetails,
      'Team assignments card add teammate button',
    );

    changeCurrentRoleHandler();
    onAddTeammate(true);
  }, [changeCurrentRoleHandler, onAddTeammate]);

  const removeTeammateHandler = useCallback(
    (userId: string, title: string) => {
      changeCurrentRoleHandler();
      onRemoveProjectMember(userId, title);
    },
    [changeCurrentRoleHandler, onRemoveProjectMember],
  );

  const showMoreHandler = useCallback(() => {
    changeCurrentRoleHandler();
    showTeamModal(true);
  }, [changeCurrentRoleHandler, showTeamModal]);

  return (
    <div className={classes.teamCard}>
      <div>
        <div className={classes.title}>
          <CustomTypography variant="buttonTextSmall" bold color="greyscale1">
            {title}
          </CustomTypography>
        </div>

        {!team?.length && !isLoading && (
          <div className={classes.empty}>
            <CustomTypography variant="bodySmall" color="greyscale3">
              {t(translations.teamAssignmentCard.noTeammates)}
            </CustomTypography>
          </div>
        )}

        {teammatesToShow &&
          !isLoading &&
          teammatesToShow?.map((teammate, index) => (
            <div key={index} className={classes.label}>
              <CustomTypography variant="caption1" color="greyscale1">
                {`${teammate.firstName} ${teammate.lastName}`}
              </CustomTypography>

              <div
                className={classes.closeButton}
                onClick={() => removeTeammateHandler(teammate.userId, title)}
              >
                <CloseIcon height={15} />
              </div>
            </div>
          ))}

        {isLoading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </div>

      <div className={classes.footer}>
        <Button
          type="submit"
          variant="secondaryOutlined"
          onClick={handleAddTeammateClick}
        >
          <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
            {t(translations.projectTeamPage.addTeammate)}
          </CustomTypography>
        </Button>

        {team &&
          teammatesToShow &&
          team.length - teammatesToShow.length > emptyArrayLength && (
            <div className={classes.more} onClick={showMoreHandler}>
              <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
                {`${t(translations.teamAssignmentCard.view)} ${
                  team.length - teammatesToShow.length
                } ${t(translations.teamAssignmentCard.more)}`}
              </CustomTypography>
            </div>
          )}
      </div>
    </div>
  );
};
