import React from 'react';
import { useScreen } from 'app/shared/hooks';
import { Grid, GridVariant } from 'app/shared/components/generic-ui';
import QRCode from 'react-qr-code';
import { useStyles } from './DownloadAtlasAppSection.styles';
import { useLocation } from 'react-router';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import apple from 'assets/apple_app_store_button.svg';
import android from 'assets/google_play_button.svg';
import profile_png from 'assets/profile.png';
import phone_png from 'assets/phone_mock.png';

interface IDownloadAtlasAppSection {
  isLoading: boolean;
}

export const DownloadAtlasAppSection = ({
  isLoading,
}: IDownloadAtlasAppSection) => {
  const { isMobile } = useScreen();
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className={!isMobile ? classes.desktopContainer : ''}>
      {isMobile && (
        <div>
          <div className={classes.info}>
            <CustomTypography variant="caption1" color="greyscale1">
              {t(translations.profilePage.forBetterUserExperience)}
            </CustomTypography>
          </div>

          <CustomTypography variant={'header6'} bold color={'mbsBlue'}>
            {t(translations.profilePage.downloadTheAtlasApp)}
          </CustomTypography>
          <div className={classes.descriptionMobile}>
            <CustomTypography
              variant={isMobile ? 'caption1' : 'subtitle1'}
              color="accentLight"
              style={{ lineHeight: 1.5 }}
            >
              {t(translations.profilePage.clientAppDescription)}
            </CustomTypography>

            <div className={classes.paragraph}>
              <CustomTypography
                variant={isMobile ? 'caption1' : 'subtitle1'}
                color="accentLight"
                style={{ lineHeight: 1.5 }}
              >
                {t(translations.profilePage.download)}
              </CustomTypography>
            </div>
          </div>
        </div>
      )}

      {!isLoading && location.pathname.includes('profile') && (
        <div
          className={
            isMobile ? classes.clientAppSectionMobile : classes.clientAppSection
          }
        >
          {isMobile && (
            <div className={classes.contentMobile}>
              <div className={classes.buttonsContainer}>
                <a href="https://apps.apple.com/ca/app/atlas-masterbuilderssolutions/id1603402915">
                  <img src={apple} className={classes.button} />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.mbcc.mbs.atlas">
                  <img src={android} className={classes.button} />
                </a>
              </div>
            </div>
          )}

          {!isMobile && (
            <div className={isMobile ? classes.contentMobile : classes.content}>
              <CustomTypography
                variant={isMobile ? 'header6' : 'header3'}
                bold
                color={isMobile ? 'accentDark' : 'mbsBlue'}
              >
                {t(translations.profilePage.downloadTheAtlasApp)}
              </CustomTypography>
              <div
                className={
                  isMobile ? classes.descriptionMobile : classes.description
                }
              >
                <CustomTypography
                  variant={isMobile ? 'caption1' : 'subtitle1'}
                  color="accentLight"
                  style={{ lineHeight: 1.5 }}
                >
                  {t(translations.profilePage.clientAppDescription)}
                </CustomTypography>

                <div className={classes.paragraph}>
                  <CustomTypography
                    variant={isMobile ? 'caption1' : 'subtitle1'}
                    color="accentLight"
                    style={{ lineHeight: 1.5 }}
                  >
                    {t(translations.profilePage.download)}
                  </CustomTypography>
                </div>
              </div>

              <CustomTypography variant="header6" bold color="mbsBlue">
                {t(translations.profilePage.scanQrCode)}
              </CustomTypography>

              <div className={classes.qrContainer}>
                <Grid container spacing={1}>
                  <Grid item variant={GridVariant.MEDIUM}>
                    <CustomTypography
                      variant="subtitle2"
                      bold
                      color="greyscale1"
                    >
                      {t(translations.profilePage.iosApp)}
                    </CustomTypography>
                  </Grid>
                  <Grid item variant={GridVariant.MEDIUM}>
                    <CustomTypography
                      variant="subtitle2"
                      bold
                      color="greyscale1"
                    >
                      {t(translations.profilePage.androidApp)}
                    </CustomTypography>
                  </Grid>
                  <Grid item variant={GridVariant.MEDIUM}>
                    <QRCode
                      value="https://apps.apple.com/ca/app/atlas-masterbuilderssolutions/id1603402915"
                      size={145}
                    />
                  </Grid>

                  <Grid item variant={GridVariant.MEDIUM}>
                    <QRCode
                      value="https://play.google.com/store/apps/details?id=com.mbcc.mbs.atlas"
                      size={145}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          <img
            src={isMobile ? phone_png : profile_png}
            className={isMobile ? classes.coverMobile : classes.cover}
          />
        </div>
      )}
    </div>
  );
};
