import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: themeColors.white,

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
  },

  body: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    height: 518,
    width: 561,
    backgroundColor: themeColors.greyscale4_50,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  sentNotificationModal: {
    backgroundColor: themeColors.white,
    boxSizing: 'border-box',
    height: 566,
    width: 561,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
  },
  internalContent: {
    backgroundColor: themeColors.white,
    boxSizing: 'border-box',
    height: 566,
    width: 561,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  wrapper: {
    width: 561,
    height: 636,
    boxSizing: 'border-box',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    margin: 'auto',
    outline: 'none !important',
  },

  closeButton: {
    cursor: 'pointer',
    zIndex: 201,
  },
  loading: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
