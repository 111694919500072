import React, { useCallback, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Modal } from '@material-ui/core';
import { useStyles } from './CustomTeamModal.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import {
  INotificationEntity,
  IPlant,
  IProfile,
  IProjectMembers,
  Routes,
} from 'types';
import {
  CellAlignment,
  CellSize,
  IHeadCell,
  TableHeaderType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { getUserInitials } from 'app/shared/utils/getUserInitials';
import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { useScreen } from 'app/shared/hooks';
import {
  ModalHeader,
  ModalVariant,
} from 'app/shared/components/generic-ui/ModalHeader/ModalHeader';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useLocation, useHistory } from 'react-router-dom';
import { TextInput } from '../TextInput/TextInput';

interface ICustomModal {
  tableRowId?: string;
  isOpen: boolean;
  closeHandler: () => void;
  header?: string;
  team?: IProjectMembers[] | IProfile[];
  roleName?: string;
  emptyTableText?: string;
  onRemoveProjectMember?: (userId: string) => void;
  onAddItem?: (ids: string[]) => void;
  isLoading?: boolean;
  withSearch?: boolean;
  withRemoveButton?: boolean;
  columns?: Array<IHeadCell>;
  plants?: INotificationEntity[] | IPlant[];
  createItem?: (ids: string[], name?: string) => void;
  withTableHeader?: boolean;
  withTextField?: boolean;
  buttonText?: string;
  textFieldHeader?: string;
  searchHeader?: string;
}

const defaultColumns = [
  {
    id: 'name',
    label: 'Name',
    alignment: CellAlignment.LEFT,
    size: CellSize.MEDIUM,
  },
  {
    id: 'roleNames',
    label: 'Role',
    alignment: CellAlignment.LEFT,
    size: CellSize.MEDIUM,
  },
];

const rowHeight = 44;
const footerAndHeaderHeight = 159;
const headerHeight = 73;
const paddingOfTableContainer = 25;
const maxModalHeight = 708;
const bodyHeight = 635;
const footerHeight = 104;
const searchContainerHeight = 90;
const textFieldContainerHeight = 168;
const loadingHeight = 170;
const emptyLines = 4;
const zero = 0;
const margin = 24;

enum Variant {
  LOADING_WITH_SEARCH = 'loadingWithSearch',
  LOADING = 'loading',
  WITH_SEARCH = 'withSearch',
  WITHOUT_SEARCH = 'withoutSearch',
  WITH_SEARCH_AND_TEXT_FIELD = 'withSearchAndTextField',
}

export const CustomTeamModal = ({
  tableRowId,
  isOpen,
  closeHandler,
  header,
  team,
  emptyTableText,
  roleName,
  onAddItem,
  onRemoveProjectMember,
  isLoading,
  withSearch,
  withRemoveButton,
  columns = defaultColumns,
  plants,
  createItem,
  withTableHeader,
  withTextField,
  buttonText,
  textFieldHeader,
  searchHeader,
}: ICustomModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { screenHeight } = useScreen();
  const [error, setError] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const location = useLocation();
  const history = useHistory();

  const tableData = useMemo(() => {
    return plants || team;
  }, [plants, team]);

  const modalVariant = useMemo(() => {
    return withTextField
      ? Variant.WITH_SEARCH_AND_TEXT_FIELD
      : (isLoading && withSearch) || (withSearch && !tableData)
      ? Variant.LOADING_WITH_SEARCH
      : (isLoading && !withSearch) || (!withSearch && !tableData)
      ? Variant.LOADING
      : !isLoading && withSearch
      ? Variant.WITH_SEARCH
      : Variant.WITHOUT_SEARCH;
  }, [isLoading, tableData, withSearch, withTextField]);

  const genericElementsHeight = useMemo(() => {
    switch (modalVariant) {
      case Variant.WITH_SEARCH_AND_TEXT_FIELD: {
        return (
          footerAndHeaderHeight +
          searchContainerHeight +
          textFieldContainerHeight +
          paddingOfTableContainer +
          margin
        );
      }
      case Variant.WITH_SEARCH:
      case Variant.LOADING_WITH_SEARCH: {
        return (
          footerAndHeaderHeight +
          searchContainerHeight +
          paddingOfTableContainer +
          margin
        );
      }

      case Variant.LOADING:
      case Variant.WITHOUT_SEARCH: {
        return headerHeight + paddingOfTableContainer + margin;
      }
      default: {
        return zero;
      }
    }
  }, [modalVariant]);

  const rowsHeight = useMemo(() => {
    return isLoading
      ? loadingHeight
      : withTableHeader
      ? (tableData?.length ? tableData.length : emptyLines) * rowHeight +
        rowHeight
      : (tableData?.length ? tableData.length : emptyLines) * rowHeight;
  }, [isLoading, tableData?.length, withTableHeader]);

  const containerHeight = useMemo(() => {
    return genericElementsHeight + rowsHeight > maxModalHeight
      ? maxModalHeight
      : genericElementsHeight + rowsHeight;
  }, [genericElementsHeight, rowsHeight]);

  const containerStyles = useMemo<React.CSSProperties>(() => {
    return {
      height: containerHeight < screenHeight ? containerHeight : screenHeight,
    };
  }, [containerHeight, screenHeight]);

  const bodyStyles = useMemo<React.CSSProperties>(() => {
    return {
      top:
        withSearch && !withTextField
          ? headerHeight + searchContainerHeight
          : withTextField
          ? headerHeight + searchContainerHeight + textFieldContainerHeight
          : headerHeight,
      maxHeight: !withSearch
        ? bodyHeight
        : !withTextField
        ? bodyHeight - footerHeight - searchContainerHeight
        : bodyHeight -
          footerHeight -
          searchContainerHeight -
          textFieldContainerHeight,
      height:
        containerHeight < screenHeight
          ? !withSearch && !isLoading
            ? `${rowsHeight + paddingOfTableContainer}px`
            : isLoading && !withSearch
            ? loadingHeight
            : tableData
            ? `${rowsHeight + paddingOfTableContainer}px`
            : ''
          : screenHeight - genericElementsHeight + rowHeight,
    };
  }, [
    containerHeight,
    genericElementsHeight,
    isLoading,
    rowsHeight,
    screenHeight,
    tableData,
    withSearch,
    withTextField,
  ]);

  const renderCell = useCallback(
    (rowData: any, cellId: string, isHovered?: boolean) => {
      if (cellId === 'name' && !plants) {
        const cellContent = [rowData.firstName, rowData.lastName];
        return (
          <div className={classes.nameContainer}>
            <Avatar
              content={
                cellContent[0] &&
                cellContent[1] &&
                getUserInitials(cellContent[0], cellContent[1])
              }
              color={withSearch && isHovered ? 'white' : 'highlightBlue'}
              size={32}
            />
            <div style={{ paddingLeft: '8px' }}>
              {cellContent[0] &&
                cellContent[1] &&
                `${cellContent[0]} ${cellContent[1]}`}
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.cell}>
            {cellId === 'roleNames' && (
              <>
                {!roleName || !!onAddItem ? (
                  rowData[cellId]?.map((el: string, index: number) => (
                    <div key={index} className={el && classes.label}>
                      {el}
                    </div>
                  ))
                ) : (
                  <div className={classes.label}>
                    {rowData[cellId]?.find(
                      (role: string) => role === roleName,
                    ) || roleName}
                  </div>
                )}
                {withRemoveButton && (
                  <div
                    className={classes.deleteButton}
                    onClick={() => {
                      onRemoveProjectMember &&
                        onRemoveProjectMember(rowData.userId);
                    }}
                  >
                    <CloseIcon height={15} />
                  </div>
                )}
              </>
            )}
            {cellId === 'referenceRoleName' && rowData[cellId] && (
              <div className={classes.label}>{rowData[cellId]}</div>
            )}
            {(cellId === 'email' ||
              cellId === 'plantName' ||
              cellId === 'originId' ||
              (cellId === 'name' && plants) ||
              cellId === 'externalId') && (
              <div
                className={
                  cellId === 'name' && plants
                    ? classes.negativeMargin
                    : classes.withoutPadding
                }
              >
                {rowData[cellId] || '-'}
              </div>
            )}
          </div>
        );
      }
    },
    [
      classes.cell,
      classes.deleteButton,
      classes.label,
      classes.nameContainer,
      classes.negativeMargin,
      classes.withoutPadding,
      onAddItem,
      onRemoveProjectMember,
      plants,
      roleName,
      withRemoveButton,
      withSearch,
    ],
  );

  const handleAddTeammateButton = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.OrganizationDetails,
      'Team modal add teammate button',
    );
    history.push({
      pathname: location.pathname,
      search: '',
    });
    if (withTextField && createItem) {
      createItem(selectedItems, textFieldValue);
    } else {
      onAddItem && onAddItem(selectedItems);
    }

    closeHandler();
  }, [
    closeHandler,
    createItem,
    history,
    location.pathname,
    onAddItem,
    selectedItems,
    textFieldValue,
    withTextField,
  ]);

  const isButtonDisabled = useMemo(() => {
    return (
      (!selectedItems.length && !plants) ||
      (!selectedItems.length && !withTextField) ||
      isLoading ||
      error ||
      (!textFieldValue?.length && withTextField)
    );
  }, [
    error,
    isLoading,
    plants,
    selectedItems.length,
    textFieldValue?.length,
    withTextField,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.container} style={containerStyles}>
        <ModalHeader
          variant={ModalVariant.SMALL}
          header={header || t(translations.teamModal.projectMembers)}
          closeHandler={closeHandler}
        />
        {withTextField && textFieldHeader && (
          <div className={classes.wrapper}>
            <div className={classes.subheaderWithoutBorder}>
              <CustomTypography variant="header6" bold color="accentDark">
                {textFieldHeader}
              </CustomTypography>
            </div>

            <div className={classes.textFieldContainer}>
              <div className={classes.textFieldLabel}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {textFieldHeader}
                </CustomTypography>
              </div>

              <TextInput
                onChangeHandler={e => {
                  setTextFieldValue(e?.target?.value || '');
                  setError(e?.target.value === '');
                }}
                placeholder={textFieldHeader}
                name="groupName"
                error={error}
                maxLength={200}
                helperText={error ? 'This field should be populated' : ''}
                backgroundColor="white"
              />
            </div>
          </div>
        )}

        {withSearch && (
          <div
            className={withTextField ? classes.searchWrapper : classes.wrapper}
          >
            {searchHeader && (
              <div className={classes.subheader}>
                <CustomTypography variant="header6" bold color="accentDark">
                  {searchHeader}
                </CustomTypography>
              </div>
            )}

            <div className={classes.searchContainer}>
              <Search
                isLoading={false}
                placeholder={
                  !plants
                    ? t(translations.teamModal.searchByNameOrRole)
                    : t(translations.teamModal.searchByOriginIdOrName)
                }
                getRowId={'name'}
              />
            </div>
          </div>
        )}
        <div className={classes.body} style={bodyStyles}>
          <Table
            withHover={withSearch}
            columns={columns}
            emptyTableText={emptyTableText || undefined}
            tableData={tableData}
            withCheckbox={!!withSearch}
            rowsTotal={tableData?.length || zero}
            renderCell={renderCell}
            headerType={
              withTableHeader ? TableHeaderType.DARK : TableHeaderType.NO_HEADER
            }
            withBorders={false}
            rowId={
              tableRowId ? tableRowId : plants ? 'plantOriginId' : 'userId'
            }
            sendSelectedItems={setSelectedItems}
            isLoading={isLoading}
            isScrollable={false}
            overflow="visible"
          />
        </div>

        {withSearch && (
          <div className={classes.saveButton}>
            <Button
              type="submit"
              disabled={isButtonDisabled}
              variant="primarySave"
              onClick={handleAddTeammateButton}
            >
              <CustomTypography variant="buttonText" bold color="white">
                {buttonText || t(translations.teamModal.addTeammate)}
              </CustomTypography>
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
