import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    boxSizing: 'border-box',
    marginTop: 24,
  },
  filterWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    marginTop: 24,
    backgroundColor: themeColors.white,
    padding: 20,
  },
});
