import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: themeColors.white,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '85px 25px 25px 25px',
  },
  text: {
    marginBottom: 11,
    marginTop: 16,
  },
  icon: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: themeColors.systemGreen20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '16px 24px',
    borderTop: `1px solid ${themeColors.greyscale4}`,
  },
  textWrapper: {
    maxWidth: 455,
    textAlign: 'center',
  },
});
