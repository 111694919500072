import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { ViewMode } from 'app/pages/projects/types';
import { INotificationEntitySections, LayoutState } from '../types';

export const initialState: LayoutState = {
  isSideNavOpen: true,
  isFooterVisible: false,
  view: ViewMode.CARD_VIEW,
  notificationEntitySection: {
    page: 1,
    perPage: 8,
  },
};

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    setIsSideNavOpen(state, action: PayloadAction<boolean>) {
      state.isSideNavOpen = action.payload;
    },
    setIsFooterVisible(state, action: PayloadAction<boolean>) {
      state.isFooterVisible = action.payload;
    },
    setNotificationEntityPagination(
      state,
      action: PayloadAction<INotificationEntitySections>,
    ) {
      state.notificationEntitySection = action.payload;
    },
    setView(state, action: PayloadAction<ViewMode>) {
      state.view = action.payload;
    },
    setNotificationEntitySectionDefaultState(state) {
      state.notificationEntitySection = {
        ...state.notificationEntitySection,
        page: 1,
      };
    },
  },
});

export const { actions, reducer, name } = layoutSlice;
