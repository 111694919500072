import React, { useEffect, useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BatchMixtureProportions.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Grid, GridVariant } from 'app/shared/components/generic-ui';
import { useBatchApi, useProbeDataFormatter } from 'app/shared/hooks';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useParams } from 'react-router-dom';

interface IBatchMixtureProps {
  crn: string;
}

const half = 2;
const nothing = 0;

export const BatchMixtureProportions = ({ crn }: IBatchMixtureProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderProbeData } = useProbeDataFormatter();
  const { organizationId }: { organizationId: string } = useParams();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        crn: crn,
      },
    };
  }, [crn, organizationId]);

  const { fetchBatchMixtureProps, batchMixProps, isFetchingBatchMixtureProps } =
    useBatchApi(queryParams);

  useEffect(() => {
    fetchBatchMixtureProps();
  }, [fetchBatchMixtureProps, queryParams]);

  return (
    <div className={classes.container}>
      <div className={classes.subheader}>
        <CustomTypography variant="subtitle1" color="accentDark">
          {t(
            translations.mixtureProportions.batchedMixtureProportions,
          ).toUpperCase()}
        </CustomTypography>
      </div>
      {!isFetchingBatchMixtureProps &&
        batchMixProps?.map((el, index) => (
          <Grid
            container
            spacing={1}
            key={index}
            className={
              index % half === nothing
                ? classes.lightContainer
                : classes.darkContainer
            }
          >
            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {t(translations.mixtureProportions.materialCode)}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {el.materialCode}
              </CustomTypography>
            </Grid>

            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {t(translations.mixtureProportions.description)}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {el.description}
              </CustomTypography>
            </Grid>

            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {t(translations.mixtureProportions.batchQuantity)}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {renderProbeData({
                  value: el.batchedQuantity,
                  measurement: el.batchedQuantityUom,
                })}
              </CustomTypography>
            </Grid>

            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {t(translations.mixtureProportions.targetQuantity)}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {renderProbeData({
                  value: el.targetQuantity,
                  measurement: el.targetQuantityUom,
                })}
              </CustomTypography>
            </Grid>

            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {`% ${t(translations.mixtureProportions.variance)}`}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {el.variancePercent !== null ? `${el.variancePercent}` : '-'}
              </CustomTypography>
            </Grid>

            <Grid item variant={GridVariant.LARGE}>
              <CustomTypography variant="caption2" bold color="greyscale2">
                {`${t(translations.mixtureProportions.moisture)} %`}
              </CustomTypography>
            </Grid>
            <Grid
              item
              variant={GridVariant.LARGE}
              className={classes.valueContainer}
            >
              <CustomTypography variant="bodySmall" color="accentDark">
                {el.moisturePercent !== null ? `${el.moisturePercent}` : '-'}
              </CustomTypography>
            </Grid>
          </Grid>
        ))}
      {isFetchingBatchMixtureProps && (
        <div className={classes.loading}>
          <CircularProgress withLabel />
        </div>
      )}
    </div>
  );
};
