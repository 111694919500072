import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ITeamAssignments } from 'types';
import { CustomTypography } from '../../Typography/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { useInView } from 'react-intersection-observer';
import { useStyles } from './TeammatesSearch.styles';
import { CustomTeamModal } from '../../CustomTeamModal';

export enum NotificationLevel {
  ORGANIZATION,
  PLANT,
  PROJECT,
}

interface ITeammatesSearchProps {
  teammates?: ITeamAssignments[];
  isLoading: boolean;
  searchTermHandler?: (value: string) => void;
  selectedTeammatesHandler: (teammatesIds: ITeamAssignments[]) => void;
  searchParams?: URLSearchParams;
}

export const TeammatesSearch = ({
  teammates,
  isLoading,
  searchTermHandler,
  selectedTeammatesHandler,
  searchParams,
}: ITeammatesSearchProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeammates, setSelectedTeammates] = useState<
    ITeamAssignments[]
  >(
    localStorage.getItem('savedTeammates')
      ? (JSON.parse(
          localStorage.getItem('savedTeammates') || '',
        ) as ITeamAssignments[])
      : [],
  );
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        label: 'Name',
        alignment: CellAlignment.LEFT,
        size: CellSize.SMALL,
      },
      {
        id: 'roleNames',
        label: 'Roles',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
      },
    ];
  }, []);

  const onChangeHandler = useCallback(
    (value: string) => {
      searchTermHandler && searchTermHandler(value);
    },
    [searchTermHandler],
  );

  const selectedItems = useMemo(() => {
    return selectedTeammates.map(user => {
      return user.userId;
    });
  }, [selectedTeammates]);

  const prepareSelectedTeammates = useCallback(
    (ids: string[]) => {
      setSelectedTeammates(prevState => {
        const selectedTeam = teammates?.filter(el => ids.includes(el.userId));
        const prevSelected = prevState?.filter(el => !teammates?.includes(el));

        prevSelected.map(el => {
          if (!selectedTeam?.find(item => item.userId === el.userId)) {
            selectedTeam?.push(el);
          }
        });

        return selectedTeam || [];
      });
    },
    [teammates],
  );

  const viewAllHandler = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const clearAllHandler = useCallback(() => {
    setSelectedTeammates([]);
  }, []);

  const removeSelectedUserHandler = useCallback((userId: string) => {
    setSelectedTeammates(prevState =>
      prevState.filter(user => user.userId !== userId),
    );
  }, []);

  useEffect(() => {
    selectedTeammatesHandler(selectedTeammates);
    localStorage.setItem('savedTeammates', JSON.stringify(selectedTeammates));
  }, [selectedTeammates, selectedTeammatesHandler]);

  return (
    <div className={classes.wrapper}>
      {isModalOpen && (
        <CustomTeamModal
          isOpen={isModalOpen}
          closeHandler={() => {
            setIsModalOpen(false);
          }}
          team={selectedTeammates}
          isLoading={false}
          onRemoveProjectMember={removeSelectedUserHandler}
          withRemoveButton={true}
        />
      )}

      <Search
        height={38}
        columns={columns}
        entries={teammates}
        onChange={onChangeHandler}
        isLoading={isLoading}
        placeholder={t(translations.teammatesPage.searchByNameOrRole)}
        selectedItemsHandler={prepareSelectedTeammates}
        getRowId={'userId'}
        analyticsReference={location.pathname}
        withBorder={true}
        withCheckbox={true}
        selectedItems={selectedItems}
        changeSelectedState={prepareSelectedTeammates}
        searchParams={searchParams}
      />

      <div className={classes.overflowContainer}>
        <div className={classes.selectedContainer}>
          {selectedTeammates.map(teammate => (
            <div key={teammate.userId} className={classes.item}>
              <CustomTypography variant="caption1" color="accentLight">
                {`${teammate.firstName} ${teammate.lastName}`}
              </CustomTypography>
              <div
                className={classes.closeIconWrapper}
                onClick={() => removeSelectedUserHandler(teammate.userId)}
              >
                <CloseIcon height={13} className={classes.closeIcon} />
              </div>
            </div>
          ))}
          <div ref={ref} className={classes.viewMoreIndicator} />
        </div>

        <div className={classes.buttons}>
          {!inView && selectedTeammates.length ? (
            <div className={classes.viewAll} onClick={viewAllHandler}>
              <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
                {t(translations.notificationSettings.viewAll)}
              </CustomTypography>
            </div>
          ) : null}

          {selectedTeammates.length ? (
            <div className={classes.clearAll} onClick={clearAllHandler}>
              <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
                {t(translations.notificationSettings.clearAll)}
              </CustomTypography>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
