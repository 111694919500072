import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IApiParams,
  IError,
  IMixId,
  IMixIdCategorization,
  IResponseData,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';
import { IQueryParams } from '../services';

const mixIdsQueryKey = 'getMixIds';
const mixIdCategorizationsQueryKey = 'getMixIdCategorizations';
const mixIdSearchCategorizationsQueryKey = 'searchMixIdCategorizations';
const updateMixIdCategorizationKey = 'updateMixIdCategorization';
const defaultCount = 0;
const defaultSkip = 0;

export function useMixDesignApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();
  const [mixIds, setMixIds] = useState<IMixId[]>([]);

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getMixIdsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/MixDesign/get-mix-ids`;
  }, [currentSupplierId]);

  const getMixDesignCategorizationsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/MixDesign/get-mix-design-categorizations`;
  }, [currentSupplierId]);

  const updateMixIdCategorizationUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/MixDesign/update-mix-design-categorizations`;
  }, [currentSupplierId]);

  const {
    data: mixIdsResponse,
    refetch: fetchMixIds,
    isLoading: isFetchingMixIds,
    error: fetchMixIdsError,
  } = useQuery<IResponseData<IMixId[]>, IError>(
    [mixIdsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getMixIdsUrl}`, {
        method: 'GET',
        queryParameters,
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getMixIds, error, {
          getMixIds: getMixIdsUrl,
        });
        openModal(error);
      },
    },
  );

  const useMixIdCategorizationQuery = (
    queryKey: string,
    url: string,
    queryParams?: IQueryParams,
  ) => {
    return useQuery<IResponseData<IMixIdCategorization[]>, IError>(
      [queryKey, { queryParams }],
      async () => {
        return await authClient(`${url}`, {
          queryParameters: queryParams,
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          firebaseAnalytics.logErrorEvent(
            errors.getMixDesignCategorizations,
            error,
            {
              getMixId: getMixIdsUrl,
            },
          );
          openModal(error);
        },
      },
    );
  };

  const {
    data: mixIdCategorizationsResponse,
    refetch: fetchMixIdCategorizations,
    isLoading: isFetchingMixIdCategorizations,
    error: fetchMixIdCategorizationsError,
  } = useMixIdCategorizationQuery(
    mixIdCategorizationsQueryKey,
    getMixDesignCategorizationsUrl,
    queryParameters,
  );

  const {
    data: searchMixIdCategorizationsResponse,
    refetch: fetchSearchMixIdCategorizations,
    isLoading: isFetchingSearchMixIdCategorizations,
    error: fetchMixIdSearchCategorizationsError,
  } = useMixIdCategorizationQuery(
    mixIdSearchCategorizationsQueryKey,
    getMixDesignCategorizationsUrl,
    queryParameters,
  );

  const {
    mutateAsync: updateMixDesignCategorization,
    isLoading: isUpdatingMixDesignCategorization,
  } = useMutation(
    (payload: IMixIdCategorization[]) =>
      authClient(updateMixIdCategorizationUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(updateMixIdCategorizationKey);
        return queryClient.getQueryData<IResponseData<IMixIdCategorization[]>>(
          updateMixIdCategorizationKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IMixIdCategorization[]>>(
            updateMixIdCategorizationKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeOverallNotificationsStatus,
          error,
          {
            updateMixIdCategorizationUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(updateMixIdCategorizationKey);
        await queryClient.refetchQueries(mixIdCategorizationsQueryKey);
      },
    },
  );

  useEffect(() => {
    if (!isFetchingMixIds) {
      setMixIds((prevState: IMixId[]) => {
        return queryParameters?.skip !== defaultSkip
          ? mixIdsResponse?.data
            ? [...prevState, ...mixIdsResponse.data]
            : prevState
          : mixIdsResponse?.data || [];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixIdsResponse?.data]);

  const mixIdCategorization = useMemo<IMixIdCategorization[] | undefined>(
    () => mixIdCategorizationsResponse?.data,
    [mixIdCategorizationsResponse],
  );

  const mixIdCategorizationTotalCount = useMemo<number>(
    () => mixIdCategorizationsResponse?.metadata?.totalCount || defaultCount,
    [mixIdCategorizationsResponse],
  );

  const searchMixIdCategorization = useMemo<IMixIdCategorization[] | undefined>(
    () => searchMixIdCategorizationsResponse?.data,
    [searchMixIdCategorizationsResponse],
  );

  return {
    mixIdsResponse,
    mixIds,
    fetchMixIds,
    isFetchingMixIds,
    fetchMixIdsError,
    mixIdCategorization,
    fetchMixIdCategorizations,
    isFetchingMixIdCategorizations,
    fetchMixIdCategorizationsError,
    mixIdCategorizationTotalCount,
    updateMixDesignCategorization,
    isUpdatingMixDesignCategorization,
    searchMixIdCategorization,
    fetchSearchMixIdCategorizations,
    isFetchingSearchMixIdCategorizations,
    fetchMixIdSearchCategorizationsError,
  };
}
