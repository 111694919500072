import { Switch } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { INotification } from 'types';
import { useStyles } from './UserNotificationCard.styles';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { themeColors } from 'app/shared/theme';
import { NotificationPopover } from './NotificationPopover';
import { useCallback, useEffect, useState } from 'react';

interface IUserNotificationCardProps {
  notification: INotification;
  changeNotificationStatusHandler: (
    notificationTypeId: string,
    isAssigned: boolean,
  ) => void;
}

export const UserNotificationCard = ({
  notification,
  changeNotificationStatusHandler,
}: IUserNotificationCardProps) => {
  const classes = useStyles();
  const [currentNotification, setCurrentNotification] = useState(notification);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setCurrentNotification(notification);
  }, [notification]);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div key={notification.supplierNotificationId} className={classes.card}>
      {currentNotification ? (
        <NotificationPopover
          notification={currentNotification}
          open={open}
          anchor={anchorEl}
        />
      ) : null}

      <div className={classes.info}>
        {!notification.active ? (
          <div
            className={classes.infoIcon}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <InfoIcon height={16} width={16} stroke={themeColors.mbsBlue} />
          </div>
        ) : null}

        <div className={classes.text}>
          <CustomTypography
            variant="bodySmall"
            bold
            color={notification.active ? 'accentLight' : 'greyscale2'}
          >
            {notification.notificationCode?.replace(/[A-Z]/g, ' $&').trim()}
          </CustomTypography>

          <CustomTypography
            variant="caption1"
            color={notification.active ? 'greyscale1' : 'greyscale2'}
          >
            {notification.notificationText}
          </CustomTypography>
        </div>
      </div>

      <div className={classes.switchContainer}>
        <Switch
          disabled={!notification?.active}
          checked={notification?.isAssigned}
          onChange={() =>
            changeNotificationStatusHandler(
              notification.notificationTypeId,
              !notification.isAssigned,
            )
          }
          color="primary"
          className={classes.toggle}
          classes={{
            root: classes.rootSwitch,
            switchBase: classes.switchBase,
            track: classes.switchTrack,
            thumb: classes.thumb,
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
    </div>
  );
};
