import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  chart: {
    height: 83,
    padding: '8px 13px 15px 13px',
  },
  chartTitle: {
    paddingBottom: 12,
  },
  triangleContainer: {
    width: '100%',
    margin: '-5px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 12,
    borderBottom: `1px solid ${themeColors.primaryNavy}`,
  },
  chartPart: {
    width: '33.3%',
  },
  chartLabels: {
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginLeft: '17%',
  },
  chartMinMax: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
  },
  centerChartPart: {
    height: '100%',
    width: '33.3%',
    borderLeft: `1px solid ${themeColors.primaryNavy}`,
    borderRight: `1px solid ${themeColors.primaryNavy}`,
  },
  marker: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});
