import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  empty: {
    paddingBottom: '14px',
  },
  progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor: themeColors.greyscale4_50,
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  progressDefault: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
