import React, { useCallback, useState } from 'react';
import { useStylesDark, useStylesLight } from './ActionButton.styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { CustomTypography } from '../Typography/Typography';

export enum ActionButtonStyle {
  LIGHT,
  DARK,
}
export interface IAction {
  label: string;
  onClick: (row?: any, index?: number) => void;
}
export interface IActionButton {
  style?: ActionButtonStyle;
  options: IAction[];
  actionsGroupClickHandler?: () => void;
  rowData?: any;
  disabled?: boolean;
  rowIndex?: number;
}

export const ActionButton = ({
  options,
  style,
  actionsGroupClickHandler,
  rowData,
  disabled,
  rowIndex,
}: IActionButton) => {
  const classesDark = useStylesDark();
  const classesLight = useStylesLight();
  const iconButtonRef = React.useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsOpen(prevOpen => !prevOpen);

    if (actionsGroupClickHandler) {
      actionsGroupClickHandler();
    }
  }, [actionsGroupClickHandler]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpen(false);
    }
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton
        data-testid="button"
        disabled={disabled}
        classes={{
          root: style ? classesDark.button : classesLight.button,
        }}
        ref={iconButtonRef}
        aria-controls={isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ zIndex: 100 }}
      >
        <MoreVertIcon
          classes={{
            root: disabled
              ? classesLight.iconDisabled
              : isOpen && style
              ? classesDark.iconClicked
              : isOpen && !style
              ? classesLight.iconClicked
              : classesDark.icon,
          }}
        />
      </IconButton>
      {!disabled && (
        <Popper
          open={isOpen}
          anchorEl={iconButtonRef.current}
          transition
          disablePortal
          placement="bottom-end"
          className={classesLight.zIndex}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={isOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classesDark.menuList}
                  >
                    {options.map((option: IAction, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          option.onClick(rowData, rowIndex);
                          handleClose();
                        }}
                      >
                        <CustomTypography
                          variant="subtitle1"
                          color="accentDark"
                        >
                          {option.label}
                        </CustomTypography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};
