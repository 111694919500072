import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    //marginTop: `-${themeLayout.headerHeight}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  text: {
    padding: '20px 24px',
  },
  body: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '7px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
    width: 561,
    height: '90%',

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: 375,
      height: 269,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
  small: {
    width: 561,
    height: '85%',

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: 375,
      height: 269,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
  fitContent: {
    width: 561,

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: 375,
      height: 269,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
}));
