const secondsInMinute = 60;
const millisecondsInSecond = 100;
const defaultValue = 0;
const decimalPlaces = 3;

export const convertMinutesFraction = (minFraction: number) => {
  let secondsFraction = minFraction * secondsInMinute;
  let minutes = defaultValue;
  while (secondsFraction >= secondsInMinute) {
    minutes++;
    secondsFraction -= secondsInMinute;
  }

  const seconds = Math.floor(secondsFraction);
  const milliseconds = Math.round(
    +(secondsFraction - seconds).toFixed(decimalPlaces) * millisecondsInSecond,
  );

  return {
    minutes,
    seconds,
    milliseconds,
  };
};
