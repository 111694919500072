import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IError, IResponseData, IRole } from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const roleQueryKey = 'getRoles';

export function useRoleApi(organizationId?: string) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getRoleUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/role/get-roles`;
  }, [currentSupplierId]);

  const {
    data: roleResponse,
    refetch: fetchRoles,
    isLoading: isFetchingRoles,
    error: fetchRolesError,
  } = useQuery<IResponseData<IRole[]>, IError>(
    roleQueryKey,
    async () => {
      return await authClient(`${getRoleUrl}`, {
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getRoles, error, {
          getRolesUrl: getRoleUrl,
        });
        openModal(error);
      },
    },
  );

  const roles = useMemo<IRole[] | undefined>(
    () => roleResponse?.data,
    [roleResponse],
  );

  return {
    roles,
    roleResponse,
    fetchRoles,
    isFetchingRoles,
    fetchRolesError,
  };
}
