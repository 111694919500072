import React from 'react';
import { useStyles } from './ConfirmationModal.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ModalHeader, ModalVariant } from '../ModalHeader';
import { CircularProgress } from '../CircularProgress';

interface IErrorModalProps {
  isOpen: boolean;
  message: string;
  isLoading?: boolean;
  confirmationHandler: () => void;
  closeHandler: () => void;
}

export const ConfirmationModal = ({
  isOpen,
  message,
  isLoading,
  closeHandler,
  confirmationHandler,
}: IErrorModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.small}>
        <div className={classes.container}>
          <ModalHeader
            variant={ModalVariant.SMALL}
            header={t(translations.confirmationModal.confirmation)}
            closeHandler={closeHandler}
          />

          <div className={classes.body}>
            {isLoading ? (
              <div>
                <CircularProgress withLabel />
              </div>
            ) : (
              <CustomTypography variant="bodyLarge" color="accentLight">
                {message}
              </CustomTypography>
            )}
          </div>

          <div className={classes.buttons}>
            <Button variant="primaryCancel" onClick={closeHandler}>
              <CustomTypography variant="buttonText" bold color="accentDark">
                {t(translations.confirmationModal.cancel)}
              </CustomTypography>
            </Button>

            <Button variant="primarySave" onClick={confirmationHandler}>
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.confirmationModal.confirm)}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
