import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  applyButton: {
    marginLeft: 16,
    marginTop: 18,
  },
  input: {
    width: 260,
  },
});
