import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useReportConfig } from '../hooks';
import { Select } from 'app/shared/components/generic-ui';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './HealthCheck.styles';
import { useReportsApi } from 'app/shared/hooks';
import { HealthCheckStatus, ISupplier } from 'types';
import { subDays } from 'date-fns';

const daysDif = 1;

export const HealthCheck = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [supplier, setSupplier] = useState<ISupplier | undefined>();
  const [batchesData, setBatchesData] = useState<
    { crn: string; id: string; status: HealthCheckStatus }[]
  >([]);

  const {
    ticketColumns,
    eventColumns,
    batchColumns,
    renderBatchCell,
    renderEventCell,
    renderTicketCell,
    refetchBatches,
    refetchEvents,
    refetchTickets,
  } = useReportConfig();

  const {
    suppliers,
    batchesHealthCheck,
    ticketHealthCheck,
    eventHealthCheck,
    fetchBatchesHealthCheck,
    fetchSuppliers,
    fetchTicketsHealthCheck,
    fetchEventsHealthCheck,
    isFetchingBatchesHealthCheck,
    isFetchingEventsHealthCheck,
    isFetchingTicketsHealthCheck,
    isFetchingSuppliers,
  } = useReportsApi();

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  useEffect(() => {
    if (batchesHealthCheck) {
      const data: { crn: string; id: string; status: HealthCheckStatus }[] = [];

      batchesHealthCheck.forEach(el => {
        el.items.forEach(item => {
          return data.push({ crn: el.crn, id: item.id, status: item.status });
        });
      });

      setBatchesData(data);
    }
  }, [batchesHealthCheck]);

  const fetchHealthCheck = useCallback(
    (supplierId?: string) => {
      const currentSupplierId = supplierId
        ? supplierId
        : supplier?.id
        ? supplier?.id
        : '';

      const payload = {
        supplierId: currentSupplierId,
        startDate: subDays(new Date(), daysDif),
        endDate: new Date(),
      };

      if ((refetchBatches.get && supplier) || !!supplierId) {
        fetchBatchesHealthCheck(payload);
        !supplierId && refetchBatches.set(false);
      }

      if ((refetchEvents.get && supplier) || !!supplierId) {
        fetchEventsHealthCheck(payload);
        !supplierId && refetchEvents.set(false);
      }

      if ((refetchTickets.get && supplier) || !!supplierId) {
        fetchTicketsHealthCheck(payload);
        !supplierId && refetchTickets.set(false);
      }
    },
    [
      fetchBatchesHealthCheck,
      fetchEventsHealthCheck,
      fetchTicketsHealthCheck,
      refetchBatches,
      refetchEvents,
      refetchTickets,
      supplier,
    ],
  );

  useEffect(() => {
    if (
      (refetchBatches.get || refetchEvents.get || refetchTickets.get) &&
      supplier
    ) {
      fetchHealthCheck();
    }
  }, [
    fetchHealthCheck,
    refetchBatches.get,
    refetchEvents.get,
    refetchTickets.get,
    supplier,
  ]);

  const tables = useMemo(() => {
    return [
      {
        header: t(translations.reportPage.ticketHealthCheck),
        data: ticketHealthCheck,
        columns: ticketColumns,
        renderCell: renderTicketCell,
        isLoading: isFetchingTicketsHealthCheck,
      },
      {
        header: t(translations.reportPage.batchHealthCheck),
        data: batchesData,
        columns: batchColumns,
        renderCell: renderBatchCell,
        isLoading: isFetchingBatchesHealthCheck,
      },
      {
        header: t(translations.reportPage.eventHealthCheck),
        data: eventHealthCheck,
        columns: eventColumns,
        renderCell: renderEventCell,
        isLoading: isFetchingEventsHealthCheck,
      },
    ];
  }, [
    t,
    ticketHealthCheck,
    ticketColumns,
    renderTicketCell,
    isFetchingTicketsHealthCheck,
    batchesData,
    batchColumns,
    renderBatchCell,
    isFetchingBatchesHealthCheck,
    eventHealthCheck,
    eventColumns,
    renderEventCell,
    isFetchingEventsHealthCheck,
  ]);

  return (
    <div>
      <div className={classes.selectContainer}>
        <div className={classes.labelContainer}>
          <CustomTypography variant="caption1" color="greyscale1">
            {t(translations.reportPage.supplierName)}
          </CustomTypography>
        </div>

        <div className={classes.formControl}>
          <Select.Base
            value={supplier?.id || ''}
            onChange={e => {
              const currentSupplier = suppliers?.find(
                el => el.id === e.target.value,
              );

              if (currentSupplier?.id) {
                fetchHealthCheck(currentSupplier.id);
                setSupplier(currentSupplier);
              }
            }}
            values={suppliers}
            valueId="id"
            labelId="name"
            label={t(translations.reportPage.selectSupplier)}
            isLoading={isFetchingSuppliers}
          />
        </div>
      </div>

      {tables.map((table, index) => (
        <SectionSpoiler header={table.header} key={index} isOpenState={false}>
          <Table
            columns={table.columns}
            tableData={table.data}
            withCheckbox={false}
            renderCellWithIndexOfRow={table.renderCell}
            withColoredRows={true}
            withPagination={false}
            isLoading={table.isLoading}
            emptyTableText={t(translations.reportPage.noDataAvailable)}
            headRowsCount={2}
            withHover={true}
          />
        </SectionSpoiler>
      ))}
    </div>
  );
};
