import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IApiParams,
  IChangeVehicleNotificationStateRequest,
  IError,
  IResponseData,
  IVehicle,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const vehicleQueryKey = 'getRoles';
const defaultSkip = 0;

export function useVehicleApi({
  queryParameters,
  organizationId,
  isInfiniteScrolling = true,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const queryClient = useQueryClient();
  const { supplierId } = useAuth();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getVehiclesUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Vehicle/get-vehicles`;
  }, [currentSupplierId]);

  const changeVehicleNotificationsStateUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Vehicle/change-enable-vehicles`;
  }, [currentSupplierId]);

  const {
    data: vehiclesResponse,
    refetch: fetchVehicles,
    isLoading: isFetchingVehicles,
    error: fetchVehiclesError,
  } = useQuery<IResponseData<IVehicle[]>, IError>(
    [vehicleQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getVehiclesUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getVehicles, error, {
          getVehiclesUrl: getVehiclesUrl,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: changeVehicleNotificationState,
    isLoading: isChangingVehicleNotificationState,
    isSuccess: isChangingVehicleNotificationStateSuccess,
    error: changeVehicleNotificationStateError,
  } = useMutation(
    (payload: IChangeVehicleNotificationStateRequest[]) =>
      authClient(changeVehicleNotificationsStateUrl, {
        method: 'PATCH',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(vehicleQueryKey);
        return queryClient.getQueryData<boolean>(vehicleQueryKey);
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<boolean>(vehicleQueryKey, context);
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeVehicleNotificationState,
          error,
          {
            changeVehicleNotificationsStateUrl,
          },
        );
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(vehicleQueryKey);
        await queryClient.refetchQueries(vehicleQueryKey);
      },
    },
  );

  useEffect(() => {
    if (!isFetchingVehicles) {
      setVehicles((prevState: IVehicle[]) => {
        return queryParameters?.skip !== defaultSkip && isInfiniteScrolling
          ? vehiclesResponse?.data
            ? [...prevState, ...vehiclesResponse.data]
            : prevState
          : vehiclesResponse?.data || [];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesResponse?.data]);

  return {
    vehicles,
    vehiclesResponse,
    fetchVehicles,
    isFetchingVehicles,
    fetchVehiclesError,
    changeVehicleNotificationState,
    isChangingVehicleNotificationState,
    isChangingVehicleNotificationStateSuccess,
    changeVehicleNotificationStateError,
  };
}
