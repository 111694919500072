import { IErrorData } from 'types';

type ErrorKeys =
  | 'signIn'
  | 'refreshToken'
  | 'getBatchHistory'
  | 'getBatchMixtureProportions'
  | 'removeUserFromNotification'
  | 'getNotifications'
  | 'addUserToNotification'
  | 'changeNotificationStatus'
  | 'changeOverallNotificationsStatus'
  | 'changeNotificationProperty'
  | 'getOrganizationAdmins'
  | 'getOrganizations'
  | 'getOrganization'
  | 'createOrganization'
  | 'updateOrganization'
  | 'getOwnProjects'
  | 'renamePourEvent'
  | 'getPourEventMixDesigns'
  | 'getPourEventsList'
  | 'getPourEventHistory'
  | 'getProfile'
  | 'updateProfile'
  | 'getProjectMembersList'
  | 'addProjectMembers'
  | 'removeProjectMembers'
  | 'getProjectsList'
  | 'removeProject'
  | 'getProjectDetailed'
  | 'createProject'
  | 'updateProject'
  | 'getRoles'
  | 'inviteTeammate'
  | 'getTeammates'
  | 'imageUpload'
  | 'removeUser'
  | 'changeProjectNotificationsStatus'
  | 'getOrgNotifications'
  | 'removeUserFromOrgNotification'
  | 'addUserToOrgNotification'
  | 'changeOrgNotificationStatus'
  | 'changeOverallOrgNotificationsStatus'
  | 'changeOrgNotificationProperty'
  | 'getOrganizationRolesList'
  | 'addUserToOrganizationRole'
  | 'removeUserFromOrganizationRole'
  | 'getPlantsList'
  | 'getPlantGroup'
  | 'getPlantGroupList'
  | 'createPlantGroup'
  | 'renamePlantGroup'
  | 'removePlantFromGroup'
  | 'addPlantsToGroup'
  | 'getGroupNotifications'
  | 'removeUserFromGroupNotification'
  | 'addUserToGroupNotification'
  | 'changeGroupNotificationStatus'
  | 'changeOverallGroupNotificationsStatus'
  | 'changeGroupNotificationProperty'
  | 'getGroupRolesList'
  | 'addUserToGroupRole'
  | 'removeUserFromGroupRole'
  | 'removePlantGroup'
  | 'getBatchStatuses'
  | 'updateNotificationType'
  | 'getVehicles'
  | 'getBatchHistoryList'
  | 'changePlantNotificationState'
  | 'changeVehicleNotificationState'
  | 'getMixIds'
  | 'getTeamAssignments'
  | 'getMixDesignCategorizations'
  | 'getProbeRanges'
  | 'createManualProbe'
  | 'invalidateManualProbe'
  | 'changeProjectNotificationSettings'
  | 'changePlantNotificationSettings'
  | 'changeOrgNotificationSettings'
  | 'getSuppliersReport'
  | 'getSupportedSuppliers'
  | 'getTicketsHealthCheck'
  | 'getEventsHealthCheck'
  | 'getBatchesHealthCheck'
  | 'getEntityMembers'
  | 'getEntityNotificationSettings'
  | 'getEntityUserNotificationAssignments'
  | 'changeEntityNotificationPropertyValue'
  | 'changeEntityNotificationStatus'
  | 'changeEntityNotificationStatusAll'
  | 'changeEntityNotificationStatusAllGroup'
  | 'enableEntityNotification'
  | 'enableEntityNotificationAll'
  | 'enableEntityNotificationAllGroup'
  | 'changeEntityUserNotificationAssignments'
  | 'getEntityGroupNotificationSettings'
  | 'getEntityGroupUserNotificationAssignments'
  | 'changeEntityGroupNotificationPropertyValue'
  | 'changeEntityGroupNotificationStatus'
  | 'changeEntityGroupNotificationStatusAll'
  | 'changeEntityGroupNotificationStatusAllGroup'
  | 'changeEntityGroupUserNotificationAssignments'
  | 'getNotificationEntitiesGroup'
  | 'getNotificationEntitiesGroups'
  | 'getUnassignedNotificationEntities'
  | 'createNotificationEntitiesGroup'
  | 'updateNotificationEntitiesGroup'
  | 'deleteNotificationEntitiesGroup'
  | 'addNotificationEntitiesToGroup'
  | 'removeNotificationEntitiesFromGroup'
  | 'getUserNotificationSettings'
  | 'getUserGroupNotificationSettings'
  | 'unassignUser'
  | 'getRelatedNotificationEntities';

export const errors: Record<ErrorKeys, IErrorData> = {
  signIn: {
    code: '501',
    message: 'Cannot sign in.',
  },
  refreshToken: {
    code: '502',
    message: 'Cannot refresh token to continue work with the app.',
  },
  getBatchHistory: {
    code: '601',
    message: 'Cannot retrieve batch history.',
  },
  getBatchMixtureProportions: {
    code: '602',
    message: 'Cannot retrieve batch mixture proportions.',
  },
  removeUserFromNotification: {
    code: '603',
    message: 'Cannot remove user from the notification.',
  },
  getNotifications: {
    code: '604',
    message: 'Cannot retrieve notifications list.',
  },
  addUserToNotification: {
    code: '605',
    message: 'Cannot add a user to the notification.',
  },
  changeNotificationStatus: {
    code: '606',
    message: 'Cannot change notification status.',
  },
  changeOverallNotificationsStatus: {
    code: '607',
    message: 'Cannot change overall notifications status.',
  },
  changeNotificationProperty: {
    code: '608',
    message: 'Cannot update the notification.',
  },
  getOrganizationAdmins: {
    code: '609',
    message: "Cannot retrieve the organization's admins list.",
  },
  getOrganizations: {
    code: '610',
    message: 'Cannot retrieve an organizations list.',
  },
  getOrganization: {
    code: '611',
    message: 'Cannot retrieve an organization data.',
  },
  createOrganization: {
    code: '612',
    message: 'Cannot create the organization.',
  },
  updateOrganization: {
    code: '613',
    message: 'Cannot update the organization.',
  },
  getOwnProjects: {
    code: '614',
    message: 'Cannot retrieve own projects list.',
  },
  renamePourEvent: {
    code: '615',
    message: 'Cannot rename the pour event.',
  },
  getPourEventMixDesigns: {
    code: '616',
    message: 'Cannot retrieve pour event mix designs.',
  },
  getPourEventsList: {
    code: '617',
    message: 'Cannot retrieve pour events list.',
  },
  getPourEventHistory: {
    code: '618',
    message: 'Cannot retrieve pour event history.',
  },
  getProfile: {
    code: '619',
    message: 'Cannot retrieve a profile data.',
  },
  updateProfile: {
    code: '620',
    message: 'Cannot update the profile data.',
  },
  getProjectMembersList: {
    code: '621',
    message: "Cannot retrieve a project's members list.",
  },
  addProjectMembers: {
    code: '622',
    message: 'Cannot add project members.',
  },
  removeProjectMembers: {
    code: '623',
    message: 'Cannot remove project members.',
  },
  getProjectsList: {
    code: '624',
    message: 'Cannot retrieve projects list.',
  },
  removeProject: {
    code: '625',
    message: 'Cannot remove the project.',
  },
  getProjectDetailed: {
    code: '626',
    message: 'Cannot retrieve the project data.',
  },
  createProject: {
    code: '627',
    message: 'Cannot create a project.',
  },
  updateProject: {
    code: '628',
    message: 'Cannot update a project.',
  },
  getRoles: {
    code: '629',
    message: 'Cannot retrieve a roles list.',
  },
  inviteTeammate: {
    code: '630',
    message: 'Cannot invite the teammate.',
  },
  getTeammates: {
    code: '631',
    message: 'Cannot retrieve a teammates list.',
  },
  imageUpload: {
    code: '632',
    message: 'Cannot upload an image.',
  },
  removeUser: {
    code: '633',
    message: 'Cannot remove this user.',
  },
  changeProjectNotificationsStatus: {
    code: '634',
    message: 'Cannot update project notifications status',
  },
  removeUserFromOrgNotification: {
    code: '635',
    message: 'Cannot remove user from the notification.',
  },
  getOrgNotifications: {
    code: '636',
    message: 'Cannot retrieve organization notifications list.',
  },
  addUserToOrgNotification: {
    code: '637',
    message: 'Cannot add a user to the notification.',
  },
  changeOrgNotificationStatus: {
    code: '638',
    message: 'Cannot change notification status.',
  },
  changeOverallOrgNotificationsStatus: {
    code: '639',
    message: 'Cannot change overall notifications status.',
  },
  changeOrgNotificationProperty: {
    code: '640',
    message: 'Cannot update the notification.',
  },
  getOrganizationRolesList: {
    code: '641',
    message: 'Cannot retrieve a organization roles list.',
  },
  addUserToOrganizationRole: {
    code: '642',
    message: 'Cannot add user to role.',
  },
  removeUserFromOrganizationRole: {
    code: '643',
    message: 'Cannot remove user from role.',
  },
  getPlantsList: {
    code: '644',
    message: 'Cannot get plants list.',
  },
  getPlantGroupList: {
    code: '645',
    message: 'Cannot get plant group list.',
  },
  getPlantGroup: {
    code: '646',
    message: 'Cannot get plant group details.',
  },
  createPlantGroup: {
    code: '647',
    message: 'Cannot create plant group.',
  },
  renamePlantGroup: {
    code: '648',
    message: 'Cannot rename plant group.',
  },
  removePlantFromGroup: {
    code: '649',
    message: 'Cannot remove plant from group.',
  },
  addPlantsToGroup: {
    code: '650',
    message: 'Cannot add plants to group.',
  },
  removeUserFromGroupNotification: {
    code: '651',
    message: 'Cannot remove user from the notification.',
  },
  getGroupNotifications: {
    code: '652',
    message: 'Cannot retrieve organization notifications list.',
  },
  addUserToGroupNotification: {
    code: '653',
    message: 'Cannot add a user to the notification.',
  },
  changeGroupNotificationStatus: {
    code: '654',
    message: 'Cannot change notification status.',
  },
  changeOverallGroupNotificationsStatus: {
    code: '655',
    message: 'Cannot change overall notifications status.',
  },
  changeGroupNotificationProperty: {
    code: '656',
    message: 'Cannot update the notification.',
  },
  getGroupRolesList: {
    code: '657',
    message: 'Cannot retrieve a organization roles list.',
  },
  addUserToGroupRole: {
    code: '658',
    message: 'Cannot add user to role.',
  },
  removeUserFromGroupRole: {
    code: '659',
    message: 'Cannot remove user from role.',
  },
  removePlantGroup: {
    code: '660',
    message: 'Cannot remove plant group.',
  },
  getBatchStatuses: {
    code: '661',
    message: 'Cannot get batch statuses.',
  },
  updateNotificationType: {
    code: '662',
    message: 'Cannot update notification type.',
  },
  getVehicles: {
    code: '663',
    message: 'Cannot get vehicles',
  },
  getBatchHistoryList: {
    code: '664',
    message: 'Cannot get batch history list',
  },
  changePlantNotificationState: {
    code: '665',
    message: 'Cannot change plant notification state',
  },
  changeVehicleNotificationState: {
    code: '666',
    message: 'Cannot change truck notification state',
  },
  getMixIds: {
    code: '667',
    message: 'Cannot get Mix IDs',
  },
  getTeamAssignments: {
    code: '668',
    message: 'Cannot get Team Assignments',
  },
  getMixDesignCategorizations: {
    code: '669',
    message: 'Cannot get Mix Design Categorization',
  },
  getProbeRanges: {
    code: '670',
    message: 'Cannot get Manual probe ranges',
  },
  createManualProbe: {
    code: '671',
    message: 'Cannot create Manual probe data',
  },
  invalidateManualProbe: {
    code: '672',
    message: 'Cannot invalidate Manual probe data',
  },
  changeProjectNotificationSettings: {
    code: '673',
    message: 'Cannot change project notifications settings',
  },
  changePlantNotificationSettings: {
    code: '674',
    message: 'Cannot change plant notifications settings',
  },
  changeOrgNotificationSettings: {
    code: '675',
    message: 'Cannot change organization notifications settings',
  },
  getSuppliersReport: {
    code: '676',
    message: 'Cannot get suppliers report',
  },
  getSupportedSuppliers: {
    code: '677',
    message: 'Cannot get supported suppliers',
  },
  getTicketsHealthCheck: {
    code: '678',
    message: 'Cannot get Tickets health check',
  },
  getEventsHealthCheck: {
    code: '679',
    message: 'Cannot get Events health check',
  },
  getBatchesHealthCheck: {
    code: '680',
    message: 'Cannot get Batches health check',
  },
  getEntityMembers: {
    code: '681',
    message: 'Cannot get entity members',
  },
  getEntityNotificationSettings: {
    code: '682',
    message: 'Cannot get entity notification settings',
  },
  getEntityUserNotificationAssignments: {
    code: '683',
    message: 'Cannot get entity user assignments',
  },
  changeEntityNotificationPropertyValue: {
    code: '684',
    message: 'Cannot change entity notification property value',
  },
  changeEntityNotificationStatus: {
    code: '685',
    message: 'Cannot change entity notification status',
  },
  changeEntityNotificationStatusAll: {
    code: '686',
    message: 'Cannot change entity notification statuses for all notifications',
  },
  changeEntityNotificationStatusAllGroup: {
    code: '687',
    message:
      'Cannot change entity notification statuses for all notifications in group',
  },
  changeEntityUserNotificationAssignments: {
    code: '688',
    message: 'Cannot change entity user assignments',
  },
  getEntityGroupNotificationSettings: {
    code: '682',
    message: 'Cannot get entity group notification settings',
  },
  getEntityGroupUserNotificationAssignments: {
    code: '683',
    message: 'Cannot get entity group user assignments',
  },
  changeEntityGroupNotificationPropertyValue: {
    code: '684',
    message: 'Cannot change entity group notification property value',
  },
  changeEntityGroupNotificationStatus: {
    code: '685',
    message: 'Cannot change entity group notification status',
  },
  changeEntityGroupNotificationStatusAll: {
    code: '686',
    message:
      'Cannot change entity group notification statuses for all notifications',
  },
  changeEntityGroupNotificationStatusAllGroup: {
    code: '687',
    message:
      'Cannot change entity group notification statuses for all notifications in group',
  },
  changeEntityGroupUserNotificationAssignments: {
    code: '688',
    message: 'Cannot change entity group user assignments',
  },
  getNotificationEntitiesGroup: {
    code: '689',
    message: 'Cannot get notification entities group',
  },
  getNotificationEntitiesGroups: {
    code: '690',
    message: 'Cannot get notification entities group',
  },
  getUnassignedNotificationEntities: {
    code: '691',
    message: 'Cannot get unassigned entities group',
  },
  createNotificationEntitiesGroup: {
    code: '692',
    message: 'Cannot create notification entities group',
  },
  updateNotificationEntitiesGroup: {
    code: '693',
    message: 'Cannot update notification entities group',
  },
  deleteNotificationEntitiesGroup: {
    code: '694',
    message: 'Cannot delete notification entities group',
  },
  addNotificationEntitiesToGroup: {
    code: '695',
    message: 'Cannot add notification entities to group',
  },
  removeNotificationEntitiesFromGroup: {
    code: '696',
    message: 'Cannot remove notification entities from group',
  },
  getUserNotificationSettings: {
    code: '697',
    message: 'Cannot get user notification settings',
  },
  getUserGroupNotificationSettings: {
    code: '698',
    message: 'Cannot get user group notification settings',
  },
  unassignUser: {
    code: '699',
    message: 'Cannot unassign user from all notifications',
  },
  getRelatedNotificationEntities: {
    code: '670',
    message: 'Cannot unassign user from all notifications',
  },
  enableEntityNotification: {
    code: '701',
    message: 'Cannot enable notification',
  },
  enableEntityNotificationAll: {
    code: '702',
    message: 'Cannot enable all notifications',
  },
  enableEntityNotificationAllGroup: {
    code: '703',
    message: 'Cannot enable notifications in group',
  },
};
