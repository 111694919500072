import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
const breakpoint = 1250;

export const useStyles = makeStyles(theme => ({
  tabNavigation: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down(breakpoint)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    flexDirection: 'row',

    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    boxSizing: 'border-box',
  },
  projectTabNavigation: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    flexDirection: 'row',

    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    boxSizing: 'border-box',
  },
  tabElement: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '24px 23px 14px 23px',
  },
  tabElementActive: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 23px 0px 23px',
    margin: '0px 1px',
    flexDirection: 'column',
  },
  borderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0px 1px',
  },
  border: {
    marginTop: '10px',
    width: '100%',
    height: '4px',
    backgroundColor: themeColors.mbsBlue,
    borderRadius: '2px',
  },
}));
