import React, { useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import File from 'react-dropzone';
import upload from 'assets/upload.svg';
import { useStyles } from './StyledDropzone.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as BorderComponent } from 'assets/rectangle.svg';
import { ReactComponent as BorderComponentSmall } from 'assets/border_small.svg';
import { IError } from 'types';
import { useScreen } from 'app/shared/hooks';

interface IStyledDropzone {
  errorHandler: (error: IError) => void;
  submitHandler: (file: any) => void;
  setIsDeleted: (value: boolean) => void;
  logo?: string;
}

const screenSizeForSmallerView = 450;

export const StyledDropzone = ({
  errorHandler,
  submitHandler,
  setIsDeleted,
  logo,
}: IStyledDropzone) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [files, setFiles] = useState<any[]>([]);
  const [error, setError] = useState<IError>();

  const [dropzoneOpen, setDropzoneOpen] = useState(logo === undefined);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/svg'],
    maxSize: 1000000,
    maxFiles: 1,
    onDrop: <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
    ) => {
      setDropzoneOpen(false);
      !fileRejections[0]?.errors[0]?.code && setIsDeleted(false);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      if (fileRejections[0]?.errors[0]?.code) {
        setFiles([]);
        setError({
          statusCode: fileRejections[0]?.errors[0]?.code,
          message: fileRejections[0]?.errors[0]?.message,
        });
      }
    },
  });

  const { screenWidth } = useScreen();

  const containerStyle = useMemo(() => {
    return {
      width: screenWidth >= screenSizeForSmallerView ? '305px' : '242px',
    };
  }, [screenWidth]);

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file?.preview));
    },
    [files],
  );

  useEffect(() => {
    if (error) {
      errorHandler(error);
      setDropzoneOpen(true);
      setFiles([]);
    } else if (files.length && !error) {
      submitHandler(files[0]);
    }
  }, [error, errorHandler, files, submitHandler]);

  return (
    <div className={classes.container}>
      {!dropzoneOpen && (
        <div className={classes.imageContainer}>
          {files.length ? (
            files.map(file => (
              <img
                src={file.preview}
                key={file.preview}
                className={classes.uploadedImage}
              />
            ))
          ) : (
            <img src={logo} key={logo} className={classes.uploadedImage} />
          )}
          <div
            onClick={() => {
              setDropzoneOpen(true);
              setIsDeleted(true);
              setFiles([]);
            }}
            className={classes.delete}
          >
            <CustomTypography variant="bodyRegular" color="mbsBlue">
              {t(translations.organizationsPage.delete)}
            </CustomTypography>
          </div>
        </div>
      )}
      {dropzoneOpen && (
        <div className={classes.dropzoneContainer}>
          {screenWidth >= screenSizeForSmallerView ? (
            <BorderComponent
              stroke={
                isDragActive ? themeColors.systemGreen : themeColors.greyscale2
              }
              className={classes.image}
            />
          ) : (
            <BorderComponentSmall
              stroke={
                isDragActive ? themeColors.systemGreen : themeColors.greyscale2
              }
              className={classes.image}
            />
          )}
          <div
            {...getRootProps({
              className: classes.dropzone,
              style: containerStyle,
            })}
          >
            <input {...getInputProps()} />

            <img src={upload} />
            <div className={classes.textContainer}>
              <CustomTypography
                variant="header5"
                style={{ whiteSpace: 'initial', fontWeight: 700 }}
                color="accentDark"
              >
                {t(translations.organizationsPage.dragAndDrop)}
              </CustomTypography>
            </div>
            <div className={classes.textContainer}>
              <CustomTypography variant="bodyRegular" color="accentDark">
                {t(translations.organizationsPage.orBrowse)}
              </CustomTypography>
            </div>
            <div className={classes.textContainer}>
              <CustomTypography
                variant="bodySmall"
                color="greyscale2"
                style={{ whiteSpace: 'initial' }}
              >
                {t(translations.organizationsPage.onlyJpeg)}
              </CustomTypography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
