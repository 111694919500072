import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  IHistoryRecord,
  IRelatedEntitiesPayload,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const batchHistoryListQueryKey = 'getPourEvents';

export function useBatchHistoryList({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getBatchHistoryListUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-filtered-history`;
  }, [currentSupplierId]);

  const getTicketHistoryUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/BatchHistoryList/get-ticket-history`;
  }, [currentSupplierId]);

  const url = useMemo(() => {
    return queryParameters &&
      !Object.keys(queryParameters).includes('ticketNumber')
      ? getBatchHistoryListUrl
      : getTicketHistoryUrl;
  }, [getBatchHistoryListUrl, getTicketHistoryUrl, queryParameters]);

  const {
    data: batchesHistoryResponse,
    mutateAsync: fetchBatchesHistory,
    isLoading: isFetchingBatchesHistory,
  } = useMutation(
    (payload: IRelatedEntitiesPayload) =>
      authClient(getBatchHistoryListUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(batchHistoryListQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          batchHistoryListQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            batchHistoryListQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getBatchHistoryList, error, {
          batchHistoryListQueryKey,
          variables: JSON.stringify(variables),
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(batchHistoryListQueryKey);
      },
    },
  );

  const {
    data: ticketHistoryResponse,
    refetch: fetchTicketHistory,
    isFetching: isFetchingTicketHistory,
    error: fetchTicketHistoryError,
  } = useQuery<IResponseData<IHistoryRecord[]>, IError>(
    [batchHistoryListQueryKey, { queryParameters }],
    async () => {
      return await authClient(getTicketHistoryUrl, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getPourEventHistory, error, {
          url,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const batchesHistory = useMemo(() => {
    return queryParameters?.ticketNumber
      ? ticketHistoryResponse?.data
      : batchesHistoryResponse?.data;
  }, [
    batchesHistoryResponse?.data,
    queryParameters?.ticketNumber,
    ticketHistoryResponse?.data,
  ]);

  const batchesHistoryMetadata = useMemo(() => {
    return queryParameters?.ticketNumber
      ? ticketHistoryResponse?.metadata
      : batchesHistoryResponse?.metadata;
  }, [
    batchesHistoryResponse?.metadata,
    queryParameters?.ticketNumber,
    ticketHistoryResponse?.metadata,
  ]);

  return {
    fetchTicketHistory,
    isFetchingTicketHistory,
    fetchTicketHistoryError,
    batchesHistory,
    fetchBatchesHistory,
    isFetchingBatchesHistory,
    batchesHistoryMetadata,
  };
}
