import { makeStyles } from '@material-ui/core';
import { themeLayout } from 'app/shared/theme';
import { themeColors } from 'app/shared/theme/theme';

const mid = 460;
const big = 560;
const sideNavOpen = 1335;

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 20,
    backgroundColor: themeColors.white,
    marginTop: 16,
  },
  radioContainer: { display: 'flex', gap: '16px', marginRight: '32px' },
  popover: {
    [theme.breakpoints.down(mid)]: {
      width: 250,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointLarge,
    )]: {
      width: 424,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 324,
    },
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      width: 324,
    },
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
    boxSizing: 'border-box',
    maxHeight: 300,
  },
  formControl: {
    [theme.breakpoints.down(mid)]: {
      width: 250,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointLarge,
    )]: {
      width: 424,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 324,
    },
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      width: 324,
    },
    height: 44,
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 430,
    marginTop: 15,
    marginRight: 15,
  },
  labelContainer: {},
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(themeLayout.breakpointLarge)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'left',
    },
  },
  containerSideNavOpen: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(sideNavOpen)]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    [theme.breakpoints.up(sideNavOpen)]: {
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'left',
    },
  },
  selectedItem: {
    height: 30,
    padding: '6px 10px ',
    backgroundColor: themeColors.highlightBlue,
    width: 'fit-content',
    borderRadius: 30,
    boxSizing: 'border-box',
    marginRight: 8,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down(themeLayout.breakpointLarge)]: {
      marginLeft: '-8px',
      marginTop: 16,
    },
    [theme.breakpoints.down(mid)]: {
      width: 265,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointBig,
    )]: {
      width: 440,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 340,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      width: 440,
    },
  },

  datePicker: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down(sideNavOpen)]: {
      marginLeft: '-8px',
      marginTop: 16,
    },
    [theme.breakpoints.down(mid)]: {
      width: 265,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointBig,
    )]: {
      width: 440,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 340,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      width: 440,
    },
  },
  selectedItems: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    flexFlow: 'wrap',
  },
  clearAll: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  close: {
    marginLeft: 6,
    width: 12,
    height: 12,
    border: `1px solid ${themeColors.mbsBlue}`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(mid)]: {
      width: 250,
    },
    [theme.breakpoints.between(
      themeLayout.breakpointSmall,
      themeLayout.breakpointLarge,
    )]: {
      width: 424,
    },
    [theme.breakpoints.between(mid, big)]: {
      width: 324,
    },
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {},
  },
}));
