import React, { useCallback } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useStyles } from './DatePicker.styles';
import { useScreen } from 'app/shared/hooks';
import { CustomDateInput } from './CustomDateInput';

interface ICustomDatePickerProps {
  startDate?: Date | null;
  endDate?: Date | null;
  withEndDate?: boolean;
  isStartDateRequired?: boolean;
  minDate?: Date | null;
  changeDateHandler: (startDate?: Date | null, endDate?: Date | null) => void;
}

const mobileView = 600;

const CustomDatePicker = ({
  startDate,
  endDate,
  withEndDate = true,
  isStartDateRequired,
  minDate,
  changeDateHandler,
}: ICustomDatePickerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isMobile } = useScreen(mobileView);

  const onChange = useCallback(
    (dates: any) => {
      if (withEndDate) {
        const [start, end] = dates;
        changeDateHandler(start, end);
      } else {
        changeDateHandler(dates);
      }
    },
    [changeDateHandler, withEndDate],
  );

  const resetDateRange = useCallback(() => {
    changeDateHandler(undefined, undefined);
  }, [changeDateHandler]);

  const resetEndDate = useCallback(() => {
    changeDateHandler(startDate, undefined);
  }, [changeDateHandler, startDate]);

  return (
    <div className={classes.mainContainer}>
      <DatePicker
        selected={startDate}
        showPopperArrow={false}
        onChange={onChange}
        placeholderText={t(translations.datePicker.mask)}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        minDate={minDate}
        popperClassName={classes.wrapper}
        selectsRange={withEndDate}
        customInput={
          <CustomDateInput
            isMobile={isMobile}
            resetDateRange={resetDateRange}
            resetEndDate={resetEndDate}
            onChange={onChange}
            placeholder={''}
            startDate={startDate}
            endDate={endDate}
            withEndDate={withEndDate}
            isStartDateRequired={isStartDateRequired}
          />
        }
      />
    </div>
  );
};

export { CustomDatePicker as DatePicker };
