import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: themeColors.greyscale4,
    marginBottom: 16,
    padding: '19px 25px 19px 19px',
    cursor: 'pointer',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: themeColors.greyscale4,
    marginBottom: 16,
    padding: '19px 25px 19px 19px',
  },
  logo: {
    height: 25,
  },
  poweredBy: {
    marginRight: 7,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 332,
    marginLeft: 16,
  },
  mobileTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 332,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  buttonContainer: {
    width: 30,
  },
});
