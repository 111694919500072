import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './InviteTeammateModal.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Modal } from '@material-ui/core';
import { Button } from 'app/shared/components/generic-ui/Button/Button';

import { useTeammateInvitationApi } from 'app/shared/hooks';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { InviteTeammateForm } from './InviteTeammateForm/InviteTeammateForm';
import {
  ModalHeader,
  ModalVariant,
} from 'app/shared/components/generic-ui/ModalHeader';
import { useRoleApi } from 'app/shared/hooks/useRoleApi';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from 'app/shared/providers';

interface IInviteTeammateModalProps {
  isOpen: boolean;
  isAdmin?: boolean;
  header?: string;
  closeHandler: () => void;
}

export const InviteTeammateModal = ({
  isOpen,
  closeHandler,
  header,
  isAdmin = false,
}: IInviteTeammateModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const { supplierId } = useAuth();
  const { organizationId }: { organizationId: string } = useParams();
  const [invitedTeammate, setInvitedTeammate] = useState('');
  const [lastInvitationUrl, setLastInvitationUrl] = useState('');

  const currentOrganizationId = useMemo(() => {
    return pathname.includes('my-organization') ? supplierId : organizationId;
  }, [organizationId, pathname, supplierId]);

  const { isInvitingTeammate } = useTeammateInvitationApi({
    organizationId: currentOrganizationId,
  });

  const { fetchRoles } = useRoleApi(currentOrganizationId);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const closeModalHandler = useCallback(
    (close?: boolean) => {
      setInvitedTeammate('');
      close && closeHandler();
    },
    [closeHandler],
  );

  const inviteHandler = useCallback((url: string, email: string) => {
    setInvitedTeammate(email);
    setLastInvitationUrl(url);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeModalHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <>
        {!!invitedTeammate && (
          <div className={classes.alternative}>
            <ModalHeader
              header={
                header || t(translations.inviteTeammateModal.inviteNewTeammate)
              }
              closeHandler={closeModalHandler}
              variant={ModalVariant.BIG}
            />

            <div className={classes.bodyInvitedModal}>
              <div className={classes.labelInvited}>
                <CustomTypography variant="subtitle1" color="greyscale2">
                  {`${t(
                    translations.inviteTeammateModal.youInvited,
                  )} ${invitedTeammate} ${t(
                    translations.inviteTeammateModal.toJoinTheTeam,
                  )}.`}
                </CustomTypography>
                <CustomTypography
                  variant="subtitle1"
                  color="greyscale2"
                  style={{ display: 'inline' }}
                >
                  {t(translations.inviteTeammateModal.ifThereIssue)}
                </CustomTypography>
                &nbsp;
                <a href={lastInvitationUrl} target="_blank" rel="noreferrer">
                  <CustomTypography
                    variant="subtitle1"
                    color="systemBlue"
                    style={{ display: 'inline' }}
                  >
                    {t(translations.inviteTeammateModal.copyThisLink)}
                  </CustomTypography>
                </a>
                &nbsp;
                <CustomTypography
                  variant="subtitle1"
                  color="greyscale2"
                  style={{ display: 'inline' }}
                >
                  {`${t(translations.inviteTeammateModal.sendToYourTeammate)}.`}
                </CustomTypography>
              </div>

              <div className={classes.buttonInviteNew}>
                <Button
                  onClick={() => closeModalHandler(false)}
                  variant="primaryOutlined"
                >
                  <CustomTypography
                    variant="buttonText"
                    bold
                    color="accentDark"
                  >
                    {t(translations.inviteTeammateModal.inviteAnotherTeammate)}
                  </CustomTypography>
                </Button>
              </div>
            </div>
          </div>
        )}

        {!invitedTeammate && (
          <div className={classes.formModal}>
            <ModalHeader
              header={t(translations.inviteTeammateModal.inviteNewTeammate)}
              closeHandler={closeModalHandler}
              variant={ModalVariant.BIG}
            />

            <div className={classes.body}>
              <div className={classes.fieldContainer}>
                {!isInvitingTeammate ? (
                  <InviteTeammateForm
                    isAdmin={isAdmin}
                    inviteHandler={inviteHandler}
                  />
                ) : (
                  <div className={classes.loaderWrapper}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};
