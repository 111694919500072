import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CustomModal } from '../components/generic-ui';

export const ModalContext = createContext<IModalContext | null>(null);

ModalContext.displayName = 'ModalContext';

export interface IModalContext {
  isModalOpen: boolean;
  openModal: (isSmall?: boolean) => void;
  setTitle: (value: string) => void;
  setContent: (value: any) => void;
  closeModalHandler: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<any>('');
  const [fitContent, setFitContent] = useState(false);

  const openModal = useCallback(
    (isSmall?: boolean) => {
      if (isSmall) {
        setFitContent(isSmall || false);
      }
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    },
    [isModalOpen],
  );

  const closeModalHandler = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const contextValue = useMemo(() => {
    return {
      isModalOpen,
      openModal,
      setTitle,
      setContent,
      closeModalHandler,
    };
  }, [closeModalHandler, isModalOpen, openModal]);

  return (
    <ModalContext.Provider value={contextValue}>
      <CustomModal
        isModalOpen={isModalOpen}
        closeModalHandler={closeModalHandler}
        title={title}
        content={content}
        fitContent={fitContent}
      />
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('ModalContext must be in scope when useModal is used.');
  }

  return context;
}
