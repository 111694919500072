import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InputSize, TextInput } from './TextInput';
import { IProfile } from 'types';
import { useTeammatesApi } from 'app/shared/hooks/useTeammatesApi';
import { useAuth } from 'app/shared/providers';
import { CustomTypography } from '../Typography/Typography';
import { themeColors } from 'app/shared/theme';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

const emptyStringLength = 0;
const oneElement = 1;
const firstIndex = 0;
const threeElements = 3;
const singleIndex = 1;
const firstElement = 0;
const notInArray = -1;
const emptyArrayLength = 0;
const magicNumber = 2;

export const splitStringByNames = (value: string, names: string[]) => {
  if (names) {
    const splitString = value === '@' ? ['@'] : value.split('@');

    const finalSplit = splitString.map((element, index) => {
      return index !== firstIndex ? '@' + element : element;
    });

    for (let i = 0; i < finalSplit.length; i++) {
      const element = finalSplit[i];
      const splitByWhiteSpaces = element?.split(/(\s+)/);

      if (
        splitByWhiteSpaces.length >= threeElements &&
        splitByWhiteSpaces[0].includes('@')
      ) {
        const name = splitByWhiteSpaces
          .slice(firstIndex, threeElements)
          .join('');

        const rest = splitByWhiteSpaces
          .slice(threeElements, splitByWhiteSpaces.length)
          .join('');

        if (rest.length !== emptyStringLength) {
          finalSplit.splice(i, oneElement, `${name}`, rest);
        }
      }
    }

    return finalSplit;
  } else {
    return [value];
  }
};

interface Props {
  setReceviersIds: (value: string[]) => void;
  noteChange: (value: string) => void;
  index: number;
}

export function TeammateTextInput({
  noteChange,
  setReceviersIds,
  index,
}: Props) {
  const classes = useStyles();
  const [currentNote, setCurrentNote] = useState('');
  const [teammatesQuery, setTeammatesQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeammates, setSelectedTeammates] = useState<IProfile[]>([]);
  const [slicedNote, setSlicedNote] = useState<string[]>([]);
  const { userId } = useAuth();
  const divRef = useRef<HTMLDivElement>(null);
  const divPRef = useRef<HTMLDivElement>(null);
  const { organizationId }: { organizationId: string } = useParams();

  const { teammates, fetchTeammates } = useTeammatesApi({
    queryParameters: { query: teammatesQuery },
    organizationId,
  });

  const selectedNames = useMemo(() => {
    return selectedTeammates.map(el => {
      return el.firstName + ' ' + el.lastName;
    });
  }, [selectedTeammates]);

  const selectedTeammateIds = useMemo(() => {
    const noteNames = slicedNote.filter(el => el.includes('@'));

    const selectedTeammatesNames = selectedTeammates.map(el => {
      return el.firstName + ' ' + el.lastName;
    });

    const finalNames = selectedTeammatesNames.filter(
      el => noteNames.includes(`@${el} `) || noteNames.includes(`@${el}`),
    );

    return selectedTeammates
      .filter(teammate =>
        finalNames.includes(`${teammate.firstName} ${teammate.lastName}`),
      )
      .map(el => {
        return el.userId;
      });
  }, [selectedTeammates, slicedNote]);

  useEffect(() => {
    noteChange(currentNote);
    setReceviersIds(selectedTeammateIds);
  }, [
    selectedTeammates,
    currentNote,
    noteChange,
    setReceviersIds,
    selectedTeammateIds,
  ]);

  const teammatesList = useMemo(() => {
    return teammates?.filter(teammate => teammate.userId !== userId);
  }, [userId, teammates]);

  const teammatesModalHandler = useCallback(
    (note: string) => {
      const lastIndex = note.lastIndexOf('@');
      const splitNote = note.slice(lastIndex + singleIndex).split(' ');

      if (splitNote?.length === singleIndex && lastIndex !== notInArray) {
        setIsModalOpen(true);
        setTeammatesQuery(splitNote[0]);
      } else {
        setIsModalOpen(false);
      }

      setSlicedNote(splitStringByNames(note, selectedNames));
      setCurrentNote(note);
    },
    [selectedNames],
  );

  const selectTeammateHandler = useCallback(
    (teammate: IProfile) => {
      const lastIndex = currentNote.lastIndexOf('@');

      setIsModalOpen(false);
      setSelectedTeammates(prevState =>
        prevState.includes(teammate) ? prevState : [...prevState, teammate],
      );

      const newNote =
        teammatesQuery.length !== emptyArrayLength
          ? currentNote.substring(firstElement, lastIndex) +
            currentNote.substring(
              lastIndex,
              currentNote.length - teammatesQuery.length,
            ) +
            `${teammate.firstName} ${teammate.lastName}`
          : currentNote + `${teammate.firstName} ${teammate.lastName}`;

      setCurrentNote(newNote);
      setSlicedNote(splitStringByNames(newNote, selectedNames));
    },
    [currentNote, selectedNames, teammatesQuery.length],
  );

  useEffect(() => {
    fetchTeammates();
  }, [fetchTeammates, teammatesQuery]);

  useEffect(() => {
    const textarea = divRef.current?.querySelector(
      'textarea',
    ) as HTMLTextAreaElement | null;
    if (divPRef.current && textarea) {
      divPRef.current.scrollTop = textarea.scrollTop;
    }
  }, [currentNote]);

  useEffect(() => {
    const textarea = divRef.current?.querySelector(
      'textarea',
    ) as HTMLTextAreaElement | null;
    textarea?.addEventListener('scroll', e => {
      if (divPRef.current && textarea) {
        divPRef.current.scrollTop = textarea.scrollTop;
      }
    });
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: currentNote.length >= 400 ? '24px' : '0px',
      }}
    >
      <div ref={divRef} style={{ position: 'relative' }}>
        <TextInput
          multiline
          currentValue={currentNote}
          onChangeHandler={e => {
            teammatesModalHandler(e?.target.value || '');
          }}
          name={'note'}
          customSize={InputSize.NORMAL}
          autoComplete="off"
          placeholder="Add note"
          withoutMargin
          borderRadius={2}
          color="white"
          maxLength={400}
        />
        <div className={classes.divWrapper} ref={divPRef}>
          <p
            style={{
              padding: '8px 10px',
              fontSize: '14px',
              lineHeight: '22px',
              margin: 0,
              letterSpacing: '0.15008px',
              whiteSpace: 'pre-line',
            }}
          >
            {slicedNote.map((note, index) =>
              note.includes('@') &&
              selectedNames?.includes(
                note.substring(singleIndex, note.length).trim(),
              ) ? (
                <span key={index} style={{ color: themeColors.mbsBlue }}>
                  {note}
                </span>
              ) : (
                <span key={index}>{note}</span>
              ),
            )}
          </p>
        </div>
      </div>
      {isModalOpen && teammatesList?.length && (
        <div
          className={classes.modalContainer}
          style={{
            [index > magicNumber ? 'top' : 'bottom']: 0,
            transform: `translateY(${index > magicNumber ? '-105%' : '100%'})`,
          }}
        >
          {teammatesList?.map(teammate => (
            <div
              key={teammate.userId}
              className={classes.itemWrapper}
              onClick={() => selectTeammateHandler(teammate)}
            >
              <div className={classes.item}>
                <CustomTypography color="mbsBlue" variant="caption2" bold>
                  {`${teammate.firstName
                    .charAt(emptyArrayLength)
                    .toUpperCase()}${teammate.lastName
                    .charAt(emptyArrayLength)
                    .toUpperCase()}`}
                </CustomTypography>
              </div>
              <CustomTypography
                color="accentDark"
                variant="bodySmall"
              >{`${teammate.firstName} ${teammate.lastName}`}</CustomTypography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export const useStyles = makeStyles({
  modalContainer: {
    maxHeight: '150px',
    overflow: 'scroll',
    cursor: 'pointer',
    position: 'absolute',
    backgroundColor: 'white',
    left: 0,
    right: 0,
    zIndex: 100,
    border: `1px solid ${themeColors.greyscale4}`,
    boxShadow: '0px 1px 5px 3px rgba(0,0,0,0.18)',
  },
  itemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
    alignItems: 'center',
  },
  item: {
    borderRadius: 40,
    backgroundColor: themeColors.highlightBlue,
    padding: 8,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  divWrapper: {
    position: 'absolute',
    inset: 0,
    pointerEvents: 'none',
    overflow: 'scroll',
    bottom: 7,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
