import { NotificationEntityType } from 'types';
import { TabIds } from '../components/layout/TabNavigation/TabNavigation.types';

export const getNotificationEntityName = (
  entityType: NotificationEntityType,
) => {
  switch (entityType) {
    case NotificationEntityType.PLANT:
      return 'Plant';

    case NotificationEntityType.CUSTOMER:
      return 'Customer';

    case NotificationEntityType.DRIVER:
      return 'Driver';

    case NotificationEntityType.MIX_DESIGN:
      return 'Mix Design';

    case NotificationEntityType.POUR_EVENT:
      return 'Pour Event';

    case NotificationEntityType.PROJECT:
      return 'Project';

    case NotificationEntityType.VEHICLE:
      return 'Vehicle';

    default:
      return 'Default';
  }
};

export const getNotificationSettingsTab = (
  notificationEntityType: NotificationEntityType,
) => {
  switch (notificationEntityType) {
    case NotificationEntityType.PLANT:
      return TabIds.PLANT;

    case NotificationEntityType.CUSTOMER:
      return TabIds.CUSTOMER;

    case NotificationEntityType.DRIVER:
      return TabIds.DRIVER;

    case NotificationEntityType.MIX_DESIGN:
      return TabIds.MIX_DESIGN;

    case NotificationEntityType.POUR_EVENT:
      return TabIds.POUR_EVENT;

    case NotificationEntityType.PROJECT:
      return TabIds.PROJECT;

    case NotificationEntityType.VEHICLE:
      return TabIds.VEHICLE;

    default:
      return TabIds.ORGANIZATION;
  }
};
