import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  projectCardBody: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 84,
    width: '100%',
    boxShadow: themeShadows.shadowMedium,
    borderRadius: 2,
    margin: '4px 10px',
    padding: '12px 20px 20px 20px',
    boxSizing: 'border-box',
    backgroundColor: themeColors.white,
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px',
  },
  icon: {
    marginRight: '10px',
  },
  name: {
    paddingTop: 8,
  },
});
