import { useMutation, useQueryClient } from 'react-query';
import { IApiParams, IError, IResponseData, ITeammateInvitation } from 'types';
import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { useMemo } from 'react';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const inviteTeammateQueryKey = 'inviteTeammate';

export function useTeammateInvitationApi({ organizationId }: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const inviteTeammateURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Users/send-email-invite`;
  }, [currentSupplierId]);

  const {
    mutateAsync: inviteTeammate,
    isLoading: isInvitingTeammate,
    error: inviteTeammateError,
  } = useMutation(
    (payload: ITeammateInvitation) =>
      authClient(inviteTeammateURL, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(inviteTeammateQueryKey);
        return queryClient.getQueryData<IResponseData<string>>(
          inviteTeammateQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<string>>(
            inviteTeammateQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.inviteTeammate, error, {
          inviteTeammateURL,
          ...variables,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(inviteTeammateQueryKey);
      },
    },
  );

  return {
    inviteTeammate,
    isInvitingTeammate,
    inviteTeammateError,
  };
}
