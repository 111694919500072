import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { IPourEventCard } from 'types';
import { useStyles } from './PourEventCardSection.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { PourEventCard } from '../PourEventCard/PourEventCard';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';

interface IPourEventCardSection {
  pourEventCards?: IPourEventCard[];
  isLoading: boolean;
}

export const PourEventCardSection = ({
  pourEventCards,
  isLoading,
}: IPourEventCardSection) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <div className={classes.progress}>
          <CircularProgress withLabel />
        </div>
      ) : (
        <SectionSpoiler withIcons={false}>
          {pourEventCards?.length ? (
            <div className={classes.pourEvents}>
              {pourEventCards.map(pourEventCard => (
                <PourEventCard
                  key={pourEventCard.internalId}
                  pourEventCard={pourEventCard}
                />
              ))}
            </div>
          ) : (
            <div className={classes.empty}>
              <CustomTypography variant="header6" color="greyscale3">
                {t(
                  translations.projectEventsPage.thereAreCurrentlyNoPourEvents,
                )}
              </CustomTypography>
            </div>
          )}
        </SectionSpoiler>
      )}
    </>
  );
};
