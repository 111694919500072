import { useQuery } from 'react-query';
import { IError, IMetaData, IProject, IResponseData } from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { useMemo, useState } from 'react';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';
import { IQueryParams } from 'app/shared/services';

const ownProjectsQueryKey = 'getOwnProjects';
const memberProjectsQueryKey = 'getMemberProjects';
const searchMemberProjectsQueryKey = 'searchMemberProjects';

const emptyArrayLength = 0;
const oneElement = 1;

export function useOwnProjectsApi(queryParameters?: IQueryParams) {
  const authClient = useAuthClient();
  const { userId } = useAuth();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const [lastTicketDispatchDateTimeUtc, setLastTicketDispatchDateTimeUtc] =
    useState<string | undefined>('');

  const ownProjectsUrl = useMemo(() => {
    return `/api/v1/${supplierId}/Project/get-user-projects`;
  }, [supplierId]);

  const {
    data: ownProjectsResponse,
    refetch: fetchOwnProjects,
    isLoading: isFetchingOwnProjects,
    error: fetchOwnProjectsError,
  } = useQuery<IResponseData<IProject[]>, IError>(
    ownProjectsQueryKey,
    async () => {
      return await authClient(`${ownProjectsUrl}`, {
        queryParameters: {
          userId,
          sortBy: 'lastTicketDispatchDateTimeUtc',
          sortAsc: false,
        },
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOwnProjects, error, {
          ownProjectsUrl,
          userId,
        });
        openModal(error);
      },
    },
  );

  const {
    data: searchMemberProjectsResponse,
    refetch: fetchSearchMemberProjects,
    isFetching: isFetchingSearchMemberProjects,
    isLoading: isLoadingSearchMemberProjects,
    error: fetchSearchMemberProjectsError,
  } = useQuery<IResponseData<IProject[]>, IError>(
    searchMemberProjectsQueryKey,
    async () => {
      return await authClient(`${ownProjectsUrl}`, {
        queryParameters: {
          ...queryParameters,
          sortBy: 'lastTicketDispatchDateTimeUtc',
          lastElementDateTimeUtc:
            queryParameters?.skip !== emptyArrayLength.toString()
              ? lastTicketDispatchDateTimeUtc
              : '',
        },
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOwnProjects, error, {
          ownProjectsUrl,
          userId,
        });
        openModal(error);
      },
    },
  );

  const {
    data: memberProjectsResponse,
    refetch: fetchMemberProjects,
    isFetching: isFetchingMemberProjects,
    isLoading: isLoadingMemberProjects,
    error: fetchMemberProjectsError,
  } = useQuery<IResponseData<IProject[]>, IError>(
    memberProjectsQueryKey,
    async () => {
      return await authClient(`${ownProjectsUrl}`, {
        queryParameters: {
          ...queryParameters,
          sortBy: 'lastTicketDispatchDateTimeUtc',
          lastElementDateTimeUtc:
            queryParameters?.skip !== emptyArrayLength.toString()
              ? lastTicketDispatchDateTimeUtc
              : '',
        },
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getOwnProjects, error, {
          ownProjectsUrl,
          userId,
        });
        openModal(error);
      },
    },
  );

  const ownProjects = useMemo<IProject[] | undefined>(
    () => ownProjectsResponse?.data,
    [ownProjectsResponse],
  );

  const myProjects = useMemo<IProject[] | undefined>(() => {
    setLastTicketDispatchDateTimeUtc(
      memberProjectsResponse?.data[
        memberProjectsResponse?.data.length - oneElement
      ]?.lastTicketDispatchDateTimeUtc || undefined,
    );

    return memberProjectsResponse?.data;
  }, [memberProjectsResponse]);

  const searchMyProjects = useMemo<IProject[] | undefined>(() => {
    return searchMemberProjectsResponse?.data;
  }, [searchMemberProjectsResponse]);

  const memberProjectsMetadata = useMemo<IMetaData | undefined>(
    () => memberProjectsResponse?.metadata,
    [memberProjectsResponse],
  );

  return {
    ownProjects,
    fetchOwnProjects,
    isFetchingOwnProjects,
    fetchOwnProjectsError,
    myProjects,
    fetchMemberProjects,
    memberProjectsResponse,
    isFetchingMemberProjects,
    fetchMemberProjectsError,
    memberProjectsMetadata,
    isLoadingMemberProjects,
    searchMemberProjectsResponse,
    fetchSearchMemberProjects,
    isFetchingSearchMemberProjects,
    isLoadingSearchMemberProjects,
    fetchSearchMemberProjectsError,
    searchMyProjects,
  };
}
