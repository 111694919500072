import React, { useCallback, useEffect, useMemo } from 'react';
import { Drawer, List, ListItem } from '@material-ui/core';
import { Link, generatePath, useLocation, useParams } from 'react-router-dom';
import { NotificationEntityType, Routes } from 'types';
import { useStyles } from './SideNavigation.styles';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CustomTypography } from '../../generic-ui/Typography/Typography';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/store/slices/layoutSlice';
import { RootState } from 'app/store/types';
import { useScreen } from 'app/shared/hooks';
import { firebaseAnalytics } from '../../../analytics';
import { getNotificationEntityName } from 'app/shared/utils';
import { ReactComponent as ImageProjects } from 'assets/grid_icon.svg';
import { ReactComponent as Plants } from 'assets/plants_icon.svg';
import { ReactComponent as Customers } from 'assets/customers_icon.svg';
import { ReactComponent as MixDesigns } from 'assets/mix_design_icon.svg';
import { ReactComponent as Vehicles } from 'assets/vehicles_icon.svg';
import { ReactComponent as Drivers } from 'assets/drivers_icon.svg';
import { ReactComponent as PourEvents } from 'assets/pour_events_icon.svg';

interface ISideNavigationProps {
  setSideNavWidth: (value: string) => void;
}

const marginWhenFooterIsVisible = 160;

const TABS = [
  {
    entityType: NotificationEntityType.PLANT,
    Icon: Plants,
  },
  {
    entityType: NotificationEntityType.CUSTOMER,
    Icon: Customers,
  },
  {
    entityType: NotificationEntityType.MIX_DESIGN,
    Icon: MixDesigns,
  },
  {
    entityType: NotificationEntityType.VEHICLE,
    Icon: Vehicles,
  },
  {
    entityType: NotificationEntityType.DRIVER,
    Icon: Drivers,
  },
  {
    entityType: NotificationEntityType.PROJECT,
    Icon: ImageProjects,
  },
  {
    entityType: NotificationEntityType.POUR_EVENT,
    Icon: PourEvents,
  },
];

export function SideNavigation({ setSideNavWidth }: ISideNavigationProps) {
  const {
    entityType: currentEntityType,
    organizationId,
  }: { entityType: string; organizationId: string } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const { isMobile } = useScreen(themeLayout.breakpointBig);

  const isSideNavOpen = useSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );

  const handleDrawerOpen = useCallback(() => {
    setSideNavWidth(`${themeLayout.sideNavWidth}px`);
    dispatch(actions.setIsSideNavOpen(true));
  }, [setSideNavWidth, dispatch]);

  const handleDrawerClose = useCallback(() => {
    setSideNavWidth(`${themeLayout.sideNavHiddenWidth}px`);
    dispatch(actions.setIsSideNavOpen(false));
  }, [setSideNavWidth, dispatch]);

  useEffect(() => {
    if (isMobile) {
      dispatch(actions.setIsSideNavOpen(false));
    }
  }, [dispatch, isMobile]);

  useEffect(() => {
    if (
      pathname.includes('organizations') ||
      pathname.includes('my-organization') ||
      pathname.includes('profile') ||
      pathname === Routes.Not_Found
    ) {
      handleDrawerClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, pathname, isSideNavOpen, handleDrawerClose]);

  const isFooterVisible = useSelector(
    (state: RootState) => state.layout.isFooterVisible,
  );

  const sideNavStyle = useMemo(() => {
    return isSideNavOpen
      ? classes.drawerOpen
      : !isSideNavOpen && pathname.includes('profile') && isMobile
      ? classes.noDrawer
      : classes.drawerClose;
  }, [
    classes.drawerClose,
    classes.drawerOpen,
    classes.noDrawer,
    isMobile,
    isSideNavOpen,
    pathname,
  ]);

  const handleExpanderClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'Side navigation expander button');

    if (isSideNavOpen) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [isSideNavOpen, handleDrawerClose, handleDrawerOpen]);

  const handleProjectLinkClick = useCallback(() => {
    firebaseAnalytics.logPressEvent('', 'Side navigation project link');
  }, []);

  return (
    <>
      {!pathname.includes('organizations') &&
        !pathname.includes('my-organization') &&
        !pathname.includes('profile') && (
          <IconButton
            onClick={handleExpanderClick}
            className={classes.showHideButton}
            style={{ left: isSideNavOpen ? '286px' : '42px' }}
            classes={{ root: classes.button }}
          >
            {isSideNavOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      <Drawer
        anchor="left"
        open={true}
        variant={'permanent'}
        classes={{
          root: isSideNavOpen ? classes.rootOpen : classes.root,
          paper: sideNavStyle,
        }}
      >
        {!pathname.includes('organizations') &&
          !pathname.includes('my-organization') &&
          !pathname.includes('profile') && (
            <List
              disablePadding={true}
              style={{
                marginBottom: isFooterVisible ? marginWhenFooterIsVisible : '',
              }}
            >
              {isSideNavOpen && (
                <div className={classes.navHeader}>
                  <CustomTypography variant="subtitle2" color="white50" bold>
                    {t(translations.sideNav.menu).toUpperCase()}
                  </CustomTypography>
                </div>
              )}
              <div
                className={`${!isSideNavOpen && classes.linkWrapperClose} ${
                  classes.linkWrapper
                }`}
              >
                {TABS?.map(({ entityType, Icon }) => (
                  <Link
                    to={generatePath(Routes.NotificationEntityPage, {
                      organizationId,
                      entityType,
                    })}
                    className={`${!isSideNavOpen && classes.linkClose} ${
                      classes.link
                    }`}
                    key={`link-${entityType}`}
                    onClick={handleProjectLinkClick}
                  >
                    <ListItem
                      button
                      divider={false}
                      disableGutters={true}
                      disableRipple={true}
                      style={{
                        backgroundColor:
                          Number(currentEntityType) === entityType
                            ? '#EAEBEB80'
                            : 'unset',
                      }}
                      classes={{
                        root: classes.sideNavItemWrapper,
                        container: classes.listItem,
                      }}
                    >
                      <Icon
                        height={20}
                        width={20}
                        stroke={
                          Number(currentEntityType) === entityType
                            ? 'white'
                            : themeColors.greyscale3
                        }
                        color={
                          Number(currentEntityType) === entityType
                            ? 'white'
                            : themeColors.greyscale3
                        }
                      />
                      {isSideNavOpen && (
                        <CustomTypography
                          variant="subtitle2"
                          bold={Number(currentEntityType) === entityType}
                          color={
                            Number(currentEntityType) === entityType
                              ? 'white'
                              : 'greyscale3'
                          }
                        >
                          {`${getNotificationEntityName(entityType)}s`}
                        </CustomTypography>
                      )}
                    </ListItem>
                  </Link>
                ))}
              </div>
            </List>
          )}
      </Drawer>
    </>
  );
}
