import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    width: 70,
    justifyContent: 'space-between',
  },
});
