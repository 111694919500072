import { makeStyles } from '@material-ui/core';
import {
  theme,
  themeColors,
  themeLayout,
  themeShadows,
} from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    position: 'fixed',
    left: 0,
  },
  rootOpen: {
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      zIndex: 8000,
    },
    height: '100%',
    position: 'fixed',
    left: 0,
  },
  drawerOpen: {
    width: themeLayout.sideNavWidth,
    position: 'fixed',
    left: 0,
    height: '100%',
    backgroundColor: `${themeColors.accentLight} !important`,
    top: themeLayout.headerHeight,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      zIndex: 9999,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      zIndex: 2,
      height: '100%',
    },

    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4_50,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.accentDark,
    },
  },
  drawerClose: {
    overflowX: 'hidden',
    width: '52px',
    backgroundColor: `${themeColors.accentLight} !important`,
    zIndex: 2,
    top: themeLayout.headerHeight,
  },
  noDrawer: {
    width: 0,
  },
  showHideButton: {
    position: 'fixed',
    top: '81px',
    width: 22,
    height: 22,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      zIndex: 9999,
    },
    [theme.breakpoints.up(themeLayout.breakpointBig)]: {
      zIndex: 1300,
    },
  },
  link: {
    color: themeColors.white,
    textDecoration: 'none',
    margin: '0px 16px',
  },
  linkClose: {
    margin: '0px ',
  },
  button: {
    backgroundColor: themeColors.white,
    boxShadow: themeShadows.shadowLarge,
    zIndex: 9900,

    '&:hover': {
      backgroundColor: themeColors.white,
    },
  },
  listItem: {
    transition: 'none',
  },
  navHeader: {
    padding: '18px 16px 16px 16px',
    fontFamily: '"Arial", sans-serif',
  },
  sideNavItemWrapper: {
    width: '100%',
    padding: '8px 16px',
    gap: '8px',
    '&:hover': {
      backgroundColor: '#EAEBEB80 !important',
      '& > h5': {
        color: 'white',
        fontWeight: '700 !important',
      },
      '& > svg': {
        color: 'white',
        stroke: 'white',
      },
    },
  },
  linkWrapper: { display: 'flex', flexDirection: 'column', gap: '8px' },
  linkWrapperClose: { marginTop: '50px' },
  greyCircle: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    marginRight: '10px',
    marginLeft: '1px',
    marginBottom: '1px',
    backgroundColor: themeColors.greyscale3,
    border: `1px solid ${themeColors.greyscale3}`,
  },
  greenCircle: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    marginRight: '10px',
    marginLeft: '1px',
    marginBottom: '1px',
    backgroundColor: themeColors.systemGreen,
    border: `1px solid ${themeColors.systemGreen}`,
  },
});
