import React, { useCallback, useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from '../../Button/Button';
import { TablePages } from './TablePages';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './TablePagination.styles';
import { CustomTypography } from '../../Typography/Typography';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import {
  PaginationType,
  IPaginationUrlParams,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { createPaginationUrl } from '../utils';
import { useScreen } from 'app/shared/hooks';
import { themeLayout } from 'app/shared/theme';

interface ITablePagination {
  count: number;
  baseURL?: string;
  withPerPageCount?: boolean;
  searchParams?: URLSearchParams;
  paginationType?: PaginationType;
  paginationChangeHandler?: () => Promise<boolean>;
  tableScrollHandler?: () => void;
}

const onePage = 1;
// eslint-disable-next-line no-magic-numbers
const defaultValuesPerPage = [5, 10, 20, 50, 100];

export const TablePagination = ({
  count,
  baseURL,
  withPerPageCount = true,
  searchParams,
  paginationType,
  paginationChangeHandler,
  tableScrollHandler,
}: ITablePagination) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useScreen(themeLayout.breakpointSmall);

  const {
    page,
    perPage,
    alternativePage,
    alternativePerPage,
    orderType,
  }: IPaginationUrlParams = useParams();

  const searchQuery = new URLSearchParams(useLocation().search).get('search');

  const currentSearchParams = useMemo(() => {
    return (
      searchParams ||
      new URLSearchParams({
        ['search']: searchQuery || '',
      })
    );
  }, [searchParams, searchQuery]);

  const currentPage = useMemo(() => {
    return paginationType === PaginationType.SECOND_LEVEL && alternativePage
      ? alternativePage
      : page;
  }, [alternativePage, page, paginationType]);

  const currentPerPage = useMemo(() => {
    return paginationType === PaginationType.SECOND_LEVEL && alternativePerPage
      ? alternativePerPage
      : perPage;
  }, [alternativePerPage, paginationType, perPage]);

  const secondLevelPaginationChange = useMemo(() => {
    return (
      (baseURL?.includes('alternativePage') &&
        paginationType === PaginationType.SECOND_LEVEL) ||
      false
    );
  }, [baseURL, paginationType]);

  const basePaginationWithAlternativePagination = useMemo(() => {
    return (
      (paginationType === PaginationType.MAIN &&
        baseURL?.includes('alternativePage')) ||
      false
    );
  }, [baseURL, paginationType]);

  const pages = useMemo(() => {
    return Math.ceil(count / +currentPerPage);
  }, [count, currentPerPage]);

  const paginationHandler = useCallback(
    (pathName: string) => {
      tableScrollHandler && tableScrollHandler();
      paginationChangeHandler
        ? paginationChangeHandler().then(res => {
            if (res) {
              history.push({
                pathname: pathName,
                search: `${currentSearchParams}`,
              });
            }
          })
        : history.push({
            pathname: pathName,
            search: `${currentSearchParams}`,
          });
    },
    [currentSearchParams, history, paginationChangeHandler, tableScrollHandler],
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const pathName = createPaginationUrl(
      baseURL || '',
      secondLevelPaginationChange ? page : '1',
      secondLevelPaginationChange ? perPage : (event.target.value as string),
      secondLevelPaginationChange ? '1' : alternativePage,
      secondLevelPaginationChange
        ? (event.target.value as string)
        : alternativePerPage,
      orderType,
    );

    paginationHandler(pathName);
  };

  const onClickPrevNextHandler = useCallback(
    (pageToChange: number) => {
      const pathName = createPaginationUrl(
        baseURL || '',
        secondLevelPaginationChange ? page : pageToChange,
        perPage,
        secondLevelPaginationChange ? pageToChange : alternativePage,
        alternativePerPage,
        orderType,
      );

      paginationHandler(pathName);
    },
    [
      alternativePage,
      alternativePerPage,
      baseURL,
      orderType,
      page,
      paginationHandler,
      perPage,
      secondLevelPaginationChange,
    ],
  );

  return (
    <div className={isMobile ? classes.containerVariant : classes.container}>
      <div className={classes.pagination}>
        {withPerPageCount && (
          <>
            <CustomTypography variant="bodySmall" color="accentDark">
              Rows per page:
            </CustomTypography>

            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={currentPerPage}
                onChange={handleChange}
                classes={{ outlined: classes.select }}
              >
                {defaultValuesPerPage.map(value => (
                  <MenuItem value={value} key={value}>
                    <CustomTypography variant="bodySmall" color="accentDark">
                      {value}
                    </CustomTypography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>
      <div className={classes.pagination}>
        <Button
          variant="pagination"
          onClick={() => onClickPrevNextHandler(+currentPage - onePage)}
          disabled={currentPage === '1'}
        >
          <CustomTypography
            variant="bodySmall"
            bold
            color={currentPage === '1' ? 'greyscale1' : 'mbsBlue'}
          >
            {t(translations.pagination.prev)}
          </CustomTypography>
        </Button>

        <div className={isMobile ? classes.pagesVariant : classes.pages}>
          <TablePages
            numPages={pages}
            baseURL={baseURL}
            searchParams={currentSearchParams}
            currentPage={currentPage}
            currentPerPage={currentPerPage}
            isSecondLevelPagination={secondLevelPaginationChange}
            isBasePaginationWithAlternative={
              basePaginationWithAlternativePagination
            }
            paginationChangeHandler={paginationChangeHandler}
            tableScrollHandler={tableScrollHandler}
          />
        </div>

        <Button
          variant="pagination"
          onClick={() => onClickPrevNextHandler(+currentPage + onePage)}
          disabled={+currentPage >= Math.ceil(count / +currentPerPage)}
        >
          <CustomTypography
            variant="bodySmall"
            bold
            color={
              +currentPage >= Math.ceil(count / +currentPerPage)
                ? 'greyscale1'
                : 'mbsBlue'
            }
          >
            {t(translations.pagination.next)}
          </CustomTypography>
        </Button>
      </div>
    </div>
  );
};
