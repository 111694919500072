import { useState, useEffect, useCallback } from 'react';
import { IMixIdCategorization } from 'types';

const textboxValues = [
  'hesAge',
  'hesStrength',
  'd28Strength',
  'workabilityMin',
  'workabilityMax',
];

export const useMixIdList = ({
  mixIdList,
}: {
  mixIdList?: IMixIdCategorization[];
}) => {
  const [mixIds, setMixIds] = useState<IMixIdCategorization[]>([]);
  const [updatedMixIdList, setUpdatedMixIdList] = useState<
    IMixIdCategorization[]
  >([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    setMixIds(mixIdList || []);
  }, [mixIdList]);

  const validateField = useCallback(
    (value: string | boolean | null, id: string) => {
      return (
        value !== null &&
        value !== '' &&
        ((id === 'hesAge' &&
          value &&
          !value
            .toString()
            ?.match(new RegExp(/^(?=.*[1-9])\d+(\.[05]0?)?$/))) ||
          ((id === 'hesStrength' ||
            id === 'd28Strength' ||
            id === 'workabilityMin' ||
            id === 'workabilityMax') &&
            !!value &&
            !value.toString()?.match(new RegExp(/^[1-9]\d*$/))))
      );
    },
    [],
  );

  const changeValueHandler = useCallback(
    (
      value: string | boolean | null,
      id: string,
      item: IMixIdCategorization,
    ) => {
      setErrors(prevState =>
        validateField(value, id)
          ? !prevState.find(el => el === id)
            ? [...prevState, id]
            : prevState
          : prevState.filter(el => el === id),
      );

      setUpdatedMixIdList(prevState => {
        return prevState.find(
          mixId =>
            mixId.externalProductId === item.externalProductId &&
            mixId.externalProductName === item.externalProductName,
        )
          ? prevState.map(updatedMixId => {
              return updatedMixId.externalProductId ===
                item.externalProductId &&
                updatedMixId.externalProductName === item.externalProductName
                ? { ...item, [id]: value === '' ? null : value }
                : updatedMixId;
            })
          : [
              ...prevState,
              {
                ...item,
                [id]: value === '' ? null : value,
              },
            ];
      });

      setMixIds(prevState => {
        const itemToChange = mixIds.find(
          el =>
            el.externalProductId === item.externalProductId &&
            el.externalProductName === item.externalProductName,
        );

        const newItems = itemToChange
          ? prevState.map(el => {
              return el === itemToChange
                ? {
                    ...el,
                    [id]: value === '' ? null : value,
                  }
                : el;
            })
          : prevState;

        return itemToChange ? newItems : prevState;
      });
    },
    [mixIds, validateField],
  );

  const saveMixIdsChanges = useCallback(() => {
    setUpdatedMixIdList([]);
  }, []);

  const cancelMixIdsChanges = useCallback(() => {
    setMixIds(mixIdList || []);
    setUpdatedMixIdList([]);
  }, [mixIdList]);

  return {
    mixIdList: mixIds,
    updatedMixIdList,
    textboxValues,
    error: !!errors.length,
    validateField,
    changeValueHandler,
    saveMixIdsChanges,
    cancelMixIdsChanges,
  };
};
