import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './ProfileSection.styles';
import { Grid } from 'app/shared/components/generic-ui';
import { formatPhoneNumber } from 'app/shared/utils/formatPhoneNumber';
import { EditProfileForm } from '../EditProfileForm/EditProfileForm';
import { useRoleApi } from 'app/shared/hooks/useRoleApi';
import { useEffect, useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { IProfile } from 'types';

type SectionPropsId =
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
  | 'roleNames'
  | 'email'
  | 'notificationEmails';

export interface ISectionValue {
  id: SectionPropsId;
  key: string;
  value?: string;
}
export interface ISectionProps {
  header: string;
  values: ISectionValue[];
  userId?: string;
  isMobile?: boolean;
}
interface IProfileSectionProps {
  profile: IProfile;
  userId?: string;
  isMobile?: boolean;
  isCurrentUser: boolean;
  organizationId: string;
}

export const ProfileSection = ({
  profile,
  userId,
  isMobile,
  isCurrentUser,
  organizationId,
}: IProfileSectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetchRoles, roles } = useRoleApi(organizationId);

  const sectionProps: ISectionProps = useMemo(() => {
    return {
      header: t(translations.profilePage.userDetails),
      values: [
        {
          id: 'firstName',
          key: isCurrentUser
            ? t(translations.profilePage.myFirstName)
            : t(translations.profilePage.firstName),
          value: profile?.firstName,
        },
        {
          id: 'lastName',
          key: isCurrentUser
            ? t(translations.profilePage.myLastName)
            : t(translations.profilePage.lastName),
          value: profile?.lastName,
        },
        {
          id: 'roleNames',
          key: isCurrentUser
            ? t(translations.profilePage.myRoles)
            : t(translations.profilePage.roles),
          value: profile?.roleNames?.join(', '),
        },
        {
          id: 'email',
          key: isCurrentUser
            ? t(translations.profilePage.myEmailAddress)
            : t(translations.profilePage.emailAddress),
          value: profile?.email,
        },
        {
          id: 'phoneNumber',
          key: isCurrentUser
            ? t(translations.profilePage.myPhone)
            : t(translations.profilePage.phone),
          value: profile?.phoneNumber,
        },
        {
          id: 'notificationEmails',
          key: t(translations.profilePage.teamsEmail),
          value: profile?.notificationEmails
            ? profile?.notificationEmails[0]
            : '',
        },
      ],
    };
  }, [isCurrentUser, profile, t]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <div>
      <div
        className={isMobile ? classes.subheaderMobile : classes.subheader}
        data-testid="subheader"
      >
        <CustomTypography
          variant={isMobile ? 'mobileHeader6' : 'header4'}
          bold
          color="accentDark"
        >
          {sectionProps.header}
        </CustomTypography>
      </div>

      {!userId && (
        <div className={classes.projectInfoSection}>
          <Grid container spacing={3}>
            {sectionProps.values.map((el, index) => (
              <Grid item lg={4} key={index}>
                <CustomTypography
                  variant={isMobile ? 'caption2' : 'buttonText'}
                  bold
                  color="greyscale2"
                >
                  {el.key}
                </CustomTypography>

                <div
                  className={
                    isMobile
                      ? classes.valueContainerMobile
                      : classes.valueContainer
                  }
                >
                  <CustomTypography
                    variant={isMobile ? 'mobileBodyRegular' : 'bodyExtraLarge'}
                    color="accentDark"
                  >
                    {el?.value
                      ? el.id === 'phoneNumber'
                        ? formatPhoneNumber(el.value || '')
                        : el.value
                      : '-'}
                  </CustomTypography>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {userId && roles && (
        <EditProfileForm
          values={sectionProps.values}
          organizationRoles={roles}
          userId={userId}
          organizationId={organizationId}
        />
      )}
    </div>
  );
};
