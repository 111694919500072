import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './OrganizationsPage.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes, IOrganization } from 'types';
import { useOrganizationsApi } from 'app/shared/hooks';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { OrganizationCard } from './OrganizationCard/OrganizationCard';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store/slices/layoutSlice';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useSearchHelper } from 'app/shared/utils';

export const OrganizationsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isOrganizationsLoading, setIsOrganizationsLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();

  useEffect(() => {
    dispatch(actions.setIsSideNavOpen(false));
  }, [dispatch]);

  const queryParams = useMemo(() => {
    return {
      take: search && !searchQuery ? '10' : '100',
      skip: '0',
      sortBy: 'supplierName',
      sortAsc: true,
      query: prepareSearchQueryParameter(search ? search : searchQuery),
    };
  }, [prepareSearchQueryParameter, search, searchQuery]);

  const columns = useMemo(() => {
    return [
      {
        id: 'supplierName',
        label: t(translations.organizationsPage.organizationName),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
      },
    ];
  }, [t]);

  const { fetchOrganizations, organizations, isFetchingOrganizations } =
    useOrganizationsApi({ queryParameters: queryParams });

  const [searchedOrganizations, setSearchedOrganizations] = useState<
    IOrganization[] | undefined
  >([]);
  const [currentOrganizations, setCurrentOrganizations] = useState<
    IOrganization[] | undefined
  >([]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations, queryParams]);

  useEffect(() => {
    if (search) {
      firebaseAnalytics.logPressEvent(
        Routes.Organizations,
        'Search in all organizations page',
      );

      setIsSearchLoading(isFetchingOrganizations);
      setSearchedOrganizations(organizations);
    }
    if (
      (!!searchQuery && searchQuery == search) ||
      (!searchQuery && !search) ||
      !search
    ) {
      setIsOrganizationsLoading(isFetchingOrganizations);
      setCurrentOrganizations(organizations);
    }
  }, [history, isFetchingOrganizations, organizations, search, searchQuery]);

  const clickSearchRowHandler = useCallback(
    value => {
      setSearch(`${value?.supplierName}`);

      firebaseAnalytics.logPressEvent(
        Routes.Organizations,
        'Click Search row in all organizations page',
      );

      const searchParams = new URLSearchParams({
        ['search']: `${value?.supplierName}`,
      });

      history.push({
        pathname: location.pathname,
        search: `${searchParams}`,
      });
    },
    [history, location.pathname],
  );

  const handleAddOrganizationButton = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.Organizations,
      'Add organization button',
    );

    history.push(Routes.AddOrganization);
  }, [history]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <CustomTypography variant="header3" bold color="accentDark">
            {t(translations.organizationsPage.organizations)}
          </CustomTypography>
        </div>

        <Button variant="primary" onClick={handleAddOrganizationButton}>
          <CustomTypography variant="header6" bold color="white">
            {t(translations.organizationsPage.addOrganization)}
          </CustomTypography>
        </Button>
      </div>

      <div className={classes.searchContainer}>
        <Search
          columns={columns}
          entries={searchedOrganizations}
          onChange={setSearch}
          isLoading={isSearchLoading}
          placeholder={t(translations.organizationsPage.searchBy)}
          onClickRow={clickSearchRowHandler}
          getRowId={'supplierId'}
          analyticsReference={Routes.Organizations}
        />
      </div>

      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {t(translations.organizationsPage.activeOrganizations)}
        </CustomTypography>
      </div>

      {isOrganizationsLoading ? (
        <div className={classes.progress}>
          <CircularProgress withLabel />
        </div>
      ) : (
        <>
          <div className={classes.organizations} data-testid="project-card">
            {currentOrganizations
              ?.filter(x => x.active === true)
              .map(organization => (
                <OrganizationCard
                  key={organization.supplierId}
                  organization={organization}
                />
              ))}
          </div>
        </>
      )}

      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {t(translations.organizationsPage.inactiveOrganizations)}
        </CustomTypography>
      </div>

      {isOrganizationsLoading ? (
        <div className={classes.progress}>
          <CircularProgress withLabel />
        </div>
      ) : (
        <>
          <div className={classes.organizations} data-testid="project-card">
            {currentOrganizations
              ?.filter(x => x.active === false)
              .map(organization => (
                <OrganizationCard
                  key={organization.supplierId}
                  organization={organization}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
