import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '26px 27px 22px 24px',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: themeColors.white,
    zIndex: 200,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    width: '780px',
  },
  containerSmall: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '26px 27px 22px 24px',
    borderBottom: `2px solid ${themeColors.greyscale4}`,

    boxSizing: 'border-box',
    backgroundColor: themeColors.white,
    zIndex: 200,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    width: '561px',
  },
  closeButton: {
    cursor: 'pointer',
    zIndex: 201,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  back: {
    marginRight: 12,
    cursor: 'pointer',
  },
}));
