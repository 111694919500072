import React, { useCallback, useMemo, useState } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import calendar from 'assets/calendar.svg';
import users from 'assets/users.svg';
import { ReactComponent as DispatchIdIcon } from 'assets/file_text.svg';
import { useStyles } from './PourEventCardBody.styles';
import { format } from 'date-fns';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { IPourEventCard, Routes } from 'types';
import { themeColors } from 'app/shared/theme/theme';
import { CustomTeamModal } from 'app/shared/components/generic-ui';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useParams } from 'react-router-dom';
import { useNotificationSettingsEntityApi } from 'app/shared/hooks';
import { getNotificationEntityName } from 'app/shared/utils';
import { NotificationEntityCardTeamSection } from 'app/pages/projects/components/NotificationEntityCardCard/NotificationEntityCardTeamSection';

interface IBodyProps {
  pourEventCard: IPourEventCard;
  onClickHandler: () => void;
}

export const PourEventCardBody = ({
  pourEventCard,
  onClickHandler,
}: IBodyProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);

  const {
    organizationId,
    entityType,
    notificationEntityId,
  }: {
    organizationId: string;
    entityType: string;
    notificationEntityId: string;
  } = useParams();

  const formatMask = 'MMM d, y - hh:mm aaa';

  const formattedDate = useMemo(
    () =>
      format(new Date(pourEventCard.lastTicketDispatchDateTimeUtc), formatMask),
    [pourEventCard.lastTicketDispatchDateTimeUtc],
  );
  const entityName = getNotificationEntityName(Number(entityType));

  const notificationEntityMembersQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: Number(entityType),
      queryParameters: {
        sortBy: 'firstname,lastname',
        sortAsc: true,
        notificationEntityId,
      },
    };
  }, [organizationId, entityType, notificationEntityId]);

  const {
    fetchActiveEntityAssignments: fetchNotificationEntityMembers,
    isFetchingActiveEntityAssignments: isFetchingProjectMembers,
    activeEntityAssignments: projectMembers,
  } = useNotificationSettingsEntityApi(notificationEntityMembersQueryParams);

  const onClickMembers = useCallback(async () => {
    setIsTeamModalOpen(true);
    await fetchNotificationEntityMembers();

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPourEventsPage,
      `${entityName} card members`,
    );
  }, [fetchNotificationEntityMembers, entityName]);

  return (
    <div className={classes.projectCardBody}>
      {isTeamModalOpen && (
        <CustomTeamModal
          isOpen={isTeamModalOpen}
          closeHandler={() => {
            setIsTeamModalOpen(false);
          }}
          team={projectMembers}
          isLoading={isFetchingProjectMembers}
        />
      )}
      <div className={classes.nameContainer} onClick={onClickHandler}>
        <CustomTypography variant="header5" bold color="accentDark">
          {pourEventCard?.name
            ? pourEventCard.name
            : `${pourEventCard.externalId || '-'}/${pourEventCard.name || '-'}`}
        </CustomTypography>
      </div>

      <div className={classes.textContainer} onClick={onClickHandler}>
        <DispatchIdIcon
          stroke={themeColors.greyscale1}
          className={classes.dispatchIcon}
          height={20}
        />
        <CustomTypography variant="bodySmall" color="greyscale1">
          {`${t(translations.pourEventCard.dispatchId)}: ${
            pourEventCard.externalId || '-'
          }`}
        </CustomTypography>
      </div>
      <NotificationEntityCardTeamSection
        teammatesCount={
          pourEventCard.notificationEntitySummaryModel?.assignedUsersCount
        }
        showTeamModal={onClickMembers}
      />
      {pourEventCard.notificationEntityGroupName && (
        <div className={classes.textContainer} onClick={onClickHandler}>
          <img src={users} className={classes.icon} />
          <CustomTypography variant="bodySmall" color="greyscale1">
            {`${t(translations.pourEventCard.group)}: ${
              pourEventCard.notificationEntityGroupName || '-'
            }`}
          </CustomTypography>
        </div>
      )}
      <div className={classes.textContainer} onClick={onClickHandler}>
        <img src={calendar} className={classes.icon} />
        <CustomTypography variant="bodySmall" color="greyscale1">
          {`${t(translations.pourEventCard.lastDispatch)}: ${
            formattedDate || '-'
          }`}
        </CustomTypography>
      </div>
    </div>
  );
};
