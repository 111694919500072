export enum TabIds {
  PROJECT_INFO = 'info',
  PROJECT_TEAM = 'team',
  PROJECT_EVENTS = 'events',
  ROLES = 'roles',
  PLANT_TEAM = 'plant-team',
  ORGANIZATION_TEAM = 'organization-team',
  ORGANIZATION_INFO = 'organization-info',
  ORGANIZATION_ROLES = 'organization-roles',
  TRUCK_SETTINGS = 'truck-settings',
  MIX_ID_CATEGORIZATION = 'mix-id',
  SUPPLIER_REPORT = 'supplier-report',
  HEALTH_CHECK = 'health-check',
  ORGANIZATION = 'organization',
  TRUCK = 'truck',
  CUSTOMER = 'customer',
  PLANT = 'plant',
  VEHICLE = 'vehicle',
  MIX_DESIGN = 'mix-design',
  POUR_EVENT = 'pour-event',
  DRIVER = 'driver',
  PROJECT = 'project',
}

export enum Pages {
  PROJECT_PAGE = 'projectDetailsPage',
  PROFILE_PAGE = 'profilePage',
  ORGANIZATION_PAGE = 'organizationPage',
  MY_ORGANIZATION = 'myOrganization',
  REPORT = 'report',
  NOTIFICATION_SETTINGS = 'notificationSettings',
}
