import { useMutation, useQueryClient } from 'react-query';
import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { useMemo } from 'react';
import {
  IDeleteUserNotificationAssignmentsPayload,
  IError,
  IGetUserNotificationAssignmentsPayload,
  IGetUserNotificationAssignmentsResponse,
  IResponseData,
} from 'types';

interface IProps {
  organizationId?: string;
  notificationEntityId: string;
}

const deleteAssignmentByNotificationTypeIdKey =
  'deleteAssignmentByNotificationTypeIdKey';
const getUserNotificationAssignmentByNotificationTypeIdKey =
  'getUserNotificationAssignmentByNotificationTypeIdKey';

export function useUserNotificationAssignmentApi({
  organizationId,
  notificationEntityId,
}: IProps) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const deleteAssignmentByNotificationTypeIdUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/UserNotificationAssignment/delete-by-notificationTypeId`;
  }, [currentSupplierId]);

  const getUserNotificationAssignmentByNotificationTypeIdUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/UserNotificationAssignment/get-by-notificationTypeId`;
  }, [currentSupplierId]);

  const {
    data: getUserNotificationAssignmentsResponse,
    mutateAsync: getUserNotificationAssignments,
    isLoading: isLoadingUserNotificationAssignments,
  } = useMutation(
    (
      payload: IGetUserNotificationAssignmentsPayload,
    ): Promise<IResponseData<IGetUserNotificationAssignmentsResponse[]>> =>
      authClient(getUserNotificationAssignmentByNotificationTypeIdUrl, {
        method: 'GET',
        queryParameters: { ...payload, notificationEntityId },
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(
          getUserNotificationAssignmentByNotificationTypeIdKey,
        );
        return queryClient.getQueryData<IResponseData<any>>(
          getUserNotificationAssignmentByNotificationTypeIdKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    data: deleteUserNotificationAssignmentsResponse,
    mutateAsync: deleteUserNotificationAssignments,
    isLoading: isDeletingUserNotificationAssignments,
  } = useMutation(
    (
      payload: IDeleteUserNotificationAssignmentsPayload,
    ): Promise<IResponseData<boolean>> =>
      authClient(deleteAssignmentByNotificationTypeIdUrl, {
        method: 'DELETE',
        queryParameters: { ...payload, notificationEntityId },
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(
          deleteAssignmentByNotificationTypeIdKey,
        );
        return queryClient.getQueryData<IResponseData<any>>(
          deleteAssignmentByNotificationTypeIdKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  return {
    getUserNotificationAssignmentsResponse,
    isLoadingUserNotificationAssignments,
    getUserNotificationAssignments,
    deleteUserNotificationAssignmentsResponse,
    deleteUserNotificationAssignments,
    isDeletingUserNotificationAssignments,
  };
}
