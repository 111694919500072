import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { useStyles } from './NotificationItem.styles';
import { INotification, IUpdateNotificationProperty } from 'types';
import { NotificationEntityType } from 'types';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { FormControl, MenuItem, Select, Switch } from '@material-ui/core';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { NotificationPopover } from './NotificationPopover';
import { themeColors } from 'app/shared/theme';
import { useNotificationSettings } from 'app/shared/providers';
import { getNotificationEntityName } from 'app/shared/utils';

interface INotificationItemProps {
  notification?: INotification;
  changeNotificationProperty?: (
    notification: IUpdateNotificationProperty,
  ) => void;
  changeNotificationStatus?: (notification: {
    notificationTypeId?: string;
    active: boolean;
    projectId?: string;
    plantNotificationId?: string;
  }) => void;
  enableNotification?: (notification: {
    notificationTypeId?: string;
    enable: boolean;
  }) => void;
}

enum TooltipType {
  INFO,
  ENABLED,
  NOTIFICATION,
  THRESHOLD,
}

const zeroStep = 0;
const decimalPlaces = 1;

export const NotificationItem = ({
  notification,
  changeNotificationProperty,
  changeNotificationStatus,
  enableNotification,
}: INotificationItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { formatProbeValue } = useProbeDataFormatter();
  const [currentNotification, setCurrentNotification] = useState(notification);
  const [tooltipType, setTooltipType] = useState<TooltipType | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { entityType, selectedEntity } = useNotificationSettings();
  const open = Boolean(anchorEl);
  const enabledOnLevel =
    entityType === currentNotification?.notificationEntityType;

  useEffect(() => {
    setCurrentNotification(notification);
  }, [notification]);

  const handlePopoverOpen = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      tooltipTypeName: TooltipType,
    ) => {
      setTooltipType(tooltipTypeName);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setTooltipType(null);
  }, []);

  const onChangeDropdownValue = useCallback(
    async currentValue => {
      changeNotificationProperty &&
        changeNotificationProperty({
          notificationTypeId: currentNotification?.notificationTypeId,
          propertyValue: currentValue.target.value,
        });
    },
    [changeNotificationProperty, currentNotification?.notificationTypeId],
  );

  const dropdownValues = useMemo(() => {
    const values: number[] = [];

    if (currentNotification && currentNotification.step > zeroStep) {
      for (
        let i = currentNotification.minValue;
        i <= currentNotification.maxValue;
        i += currentNotification.step
      ) {
        values.push(+formatProbeValue(i, decimalPlaces));
      }
    } else {
      currentNotification && values.push(currentNotification.minValue);
    }

    return values;
  }, [currentNotification, formatProbeValue]);

  const handleActiveSwitchChange = useCallback(async () => {
    changeNotificationStatus &&
      changeNotificationStatus({
        notificationTypeId: currentNotification?.notificationTypeId,
        active: !currentNotification?.active,
      });
  }, [
    changeNotificationStatus,
    currentNotification?.active,
    currentNotification?.notificationTypeId,
  ]);

  const handleEnableSwitchChange = useCallback(async () => {
    enableNotification?.({
      notificationTypeId: currentNotification?.notificationTypeId,
      enable:
        currentNotification?.notificationEntityType ===
        NotificationEntityType.ORGANIZATION,
    });
  }, [
    enableNotification,
    currentNotification?.notificationTypeId,
    currentNotification?.notificationEntityType,
  ]);

  const popoverContent = useMemo(() => {
    switch (tooltipType) {
      case TooltipType.INFO:
        return {
          title: currentNotification?.notificationCode
            ?.replace(/[A-Z]/g, ' $&')
            .trim(),
          text: currentNotification?.notificationText || '',
        };
      case TooltipType.ENABLED: {
        const groupText = selectedEntity?.notificationEntityGroupName
          ? `${getNotificationEntityName(entityType).toLowerCase()}s part of ${
              selectedEntity?.notificationEntityGroupName
            } group`
          : '';

        const nameText = selectedEntity?.name
          ? `${getNotificationEntityName(entityType).toLowerCase()} ${
              selectedEntity?.name
            }`
          : '';

        return {
          title: '',
          text: t(translations.notificationCard.enableDescription, {
            parameter: `${groupText || nameText || ''}`,
          }),
        };
      }
      case TooltipType.NOTIFICATION:
        return {
          title: '',
          text: t(translations.notificationCard.notificationDescription),
        };
      case TooltipType.THRESHOLD:
        return {
          title: '',
          text: t(translations.notificationCard.thresholdDescription),
        };
      default:
        return { title: '', text: '' };
    }
  }, [
    currentNotification?.notificationCode,
    currentNotification?.notificationText,
    t,
    tooltipType,
    entityType,
    selectedEntity?.notificationEntityGroupName,
    selectedEntity?.name,
  ]);

  return (
    <div className={classes.card}>
      {currentNotification ? (
        <NotificationPopover
          open={open}
          anchor={anchorEl}
          title={popoverContent.title}
          text={popoverContent.text}
        />
      ) : null}

      {currentNotification ? (
        <div className={classes.wrapper}>
          <div className={classes.title}>
            <div
              className={classes.info}
              onMouseEnter={e => handlePopoverOpen(e, TooltipType.INFO)}
              onMouseLeave={handlePopoverClose}
            >
              <InfoIcon height={16} width={16} stroke={themeColors.mbsBlue} />
            </div>

            <CustomTypography
              variant="caption1"
              style={{ fontSize: 11, fontWeight: 700 }}
              bold
              color="accentDark"
            >
              {currentNotification?.notificationCode
                ?.replace(/[A-Z]/g, ' $&')
                .trim()}
            </CustomTypography>
          </div>
          <div className={classes.actions}>
            {entityType !== NotificationEntityType.ORGANIZATION && (
              <>
                <div className={classes.action}>
                  <div
                    className={classes.info}
                    onMouseEnter={e =>
                      handlePopoverOpen(e, TooltipType.ENABLED)
                    }
                    onMouseLeave={handlePopoverClose}
                  >
                    <InfoIcon
                      height={16}
                      width={16}
                      stroke={themeColors.mbsBlue}
                    />
                  </div>
                  <div className={classes.actionLabels}>
                    <CustomTypography variant="caption1" color="greyscale1">
                      {`${t(translations.notificationCard.enable)}:`}
                    </CustomTypography>
                  </div>
                  <Switch
                    checked={enabledOnLevel}
                    onChange={handleEnableSwitchChange}
                    color="primary"
                    className={classes.toggle}
                    classes={{
                      root: classes.rootSwitch,
                      switchBase: classes.switchBase,
                      track: classes.switchTrack,
                      thumb: classes.thumb,
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </>
            )}

            <div className={classes.action}>
              <div
                className={classes.info}
                onMouseEnter={e =>
                  handlePopoverOpen(e, TooltipType.NOTIFICATION)
                }
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon height={16} width={16} stroke={themeColors.mbsBlue} />
              </div>
              <div className={classes.actionLabels}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {`${t(translations.notificationCard.sendPush)}:`}
                </CustomTypography>
              </div>

              <Switch
                disabled={!enabledOnLevel}
                checked={currentNotification?.active}
                onChange={handleActiveSwitchChange}
                color="primary"
                className={classes.toggle}
                classes={{
                  root: classes.rootSwitch,
                  switchBase: classes.switchBase,
                  track: classes.switchTrack,
                  thumb: classes.thumb,
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>

            <div className={classes.action}>
              <div
                className={classes.info}
                onMouseEnter={e => handlePopoverOpen(e, TooltipType.THRESHOLD)}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon height={16} width={16} stroke={themeColors.mbsBlue} />
              </div>
              <div className={classes.actionLabels}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {`${t(translations.notificationCard.setValue)}:`}
                </CustomTypography>
              </div>

              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currentNotification?.propertyValue}
                  variant="outlined"
                  disabled={!enabledOnLevel}
                  className={classes.hideIconPadding}
                  onChange={onChangeDropdownValue}
                  classes={{
                    icon: classes.icon,
                    iconOutlined: classes.outlined,
                  }}
                  MenuProps={{ classes: { paper: classes.menuList } }}
                >
                  {dropdownValues.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <CustomTypography
                        variant="subtitle1"
                        bold={currentNotification?.propertyValue === item}
                        color={
                          currentNotification?.propertyValue === item &&
                          enabledOnLevel
                            ? 'mbsBlue'
                            : !enabledOnLevel &&
                              currentNotification?.propertyValue === item
                            ? 'greyscale2'
                            : 'accentDark'
                        }
                      >
                        {`${item}`}
                      </CustomTypography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.emptyCard}>
          <CustomTypography variant="caption1" color="greyscale2">
            N/A
          </CustomTypography>
        </div>
      )}
    </div>
  );
};
