import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { ICompressiveStrengthMeasurements } from 'types';
import { useStyles } from './CompressiveStrengthList.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useManualProbeApi } from 'app/shared/hooks';
import { useParams } from 'react-router-dom';
import { useAuth } from 'app/shared/providers';
import { format } from 'date-fns';
import { Switch } from 'app/shared/components/generic-ui';

export interface ICompressiveStrengthListProps {
  compressiveStrengthMeasurements: ICompressiveStrengthMeasurements;
}

const hoursInDay = 24;
const formatMask = 'MM/dd/yy';

export const CompressiveStrengthList = ({
  compressiveStrengthMeasurements,
}: ICompressiveStrengthListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userId, isAdmin, isSuperAdmin } = useAuth();
  const { organizationId }: { organizationId: string } = useParams();
  const { invalidateManualProbe, invalidateManualProbeError } =
    useManualProbeApi({ organizationId: organizationId });

  const [compressiveStrength, setCompressiveStrength] = useState(
    compressiveStrengthMeasurements.compressiveStrength,
  );

  const handleSwitchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, id: number) => {
      const newValues = compressiveStrength.map(el => {
        return el.manualProbeDataId === id
          ? { ...el, isInvalid: !e.target.checked }
          : el;
      });

      const itemToInvalidate = compressiveStrength.find(
        el => el.manualProbeDataId === id,
      );

      if (itemToInvalidate) {
        await invalidateManualProbe({
          manualProbeDataId: itemToInvalidate.manualProbeDataId,
          isInvalid: !e.target.checked,
        });

        if (!invalidateManualProbeError) {
          setCompressiveStrength(newValues);
        }
      }
    },
    [compressiveStrength, invalidateManualProbe, invalidateManualProbeError],
  );

  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'createdOnUtc') {
        return (
          <div>
            {rowData?.createdOnUtc
              ? format(new Date(rowData.createdOnUtc), formatMask)
              : '-'}
          </div>
        );
      } else if (cellId === 'hoursAfterPourEvent') {
        return (
          <div>
            {rowData[cellId] >= hoursInDay
              ? `${rowData[cellId] / hoursInDay} Days`
              : `${rowData[cellId]} Hours`}
          </div>
        );
      } else if (cellId === 'value') {
        return (
          <div>
            {`${rowData[cellId]} ${compressiveStrengthMeasurements.measurement}`}
          </div>
        );
      } else if (cellId === 'isInvalid') {
        return (
          <Switch
            disabled={
              !(userId === rowData?.createdBy || isSuperAdmin || isAdmin)
            }
            checked={!rowData[cellId]}
            onChange={e => handleSwitchChange(e, rowData.manualProbeDataId)}
          />
        );
      }
    },
    [
      compressiveStrengthMeasurements.measurement,
      handleSwitchChange,
      isAdmin,
      isSuperAdmin,
      userId,
    ],
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'createdOnUtc',
        label: t(translations.batchDetailsModal.date),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        colspan: 1,
      },
      {
        id: 'hoursAfterPourEvent',
        label: t(translations.batchDetailsModal.age),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        colspan: 1,
      },
      {
        id: 'value',
        label: t(translations.batchDetailsModal.value),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        colspan: 1,
      },
      {
        id: 'isInvalid',
        label: t(translations.batchDetailsModal.validity),
        alignment: CellAlignment.RIGHT,
        size: CellSize.SMALL,
        colspan: 1,
      },
    ];
  }, [t]);

  return (
    <div className={classes.wrapper}>
      <Table
        columns={columns}
        tableData={compressiveStrength}
        withCheckbox={false}
        rowsTotal={compressiveStrengthMeasurements.compressiveStrength.length}
        renderCellWithIndexOfRow={renderCell}
        withColoredRows={true}
        emptyTableText={t(translations.batchDetailsModal.thereNoComprStrValues)}
        headRowsCount={2}
        withHover={false}
        withPadding={false}
      />
    </div>
  );
};
