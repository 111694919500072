import {
  Checkbox,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useStyles } from './BulkAssignButton.styles';
import { CustomTypography } from '../../../Typography/Typography';
import { INotification } from 'types';
import { Button } from '../../../Button/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';

interface IBulkAssignButton {
  notifications?: INotification[];
  submitSelectedNotifications: (notifications: INotification[]) => void;
  disabled?: boolean;
}

export const BulkAssignButton = ({
  notifications,
  submitSelectedNotifications,
  disabled,
}: IBulkAssignButton) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const iconButtonRef = React.useRef<HTMLDivElement>(null);
  const [selectedNotifications, setSelectedNotifications] = useState<
    INotification[]
  >([]);

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    if (!disabled) {
      setIsOpen(prevOpen => !prevOpen);
    }
  }, [disabled]);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedNotifications([]);
  };

  const changeCheckboxHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, notification: INotification) => {
      setSelectedNotifications(prevState =>
        e.target.checked
          ? [...prevState, notification]
          : prevState.filter(el => el !== notification),
      );
    },
    [],
  );

  const onSubmitHandler = useCallback(() => {
    submitSelectedNotifications(selectedNotifications);
    handleClose();
  }, [selectedNotifications, submitSelectedNotifications]);

  const cancelHandler = useCallback(() => {
    handleClose();
  }, []);

  return (
    <div>
      <div
        ref={iconButtonRef}
        onClick={handleToggle}
        className={disabled ? classes.buttonDisabled : classes.button}
      >
        <div className={classes.buttonWrapper}>
          <CustomTypography
            variant="caption1"
            style={{ fontSize: 11, lineHeight: 1, fontWeight: 700 }}
            bold
            color={disabled ? 'greyscale2' : 'mbsBlue'}
          >
            Bulk Assign
          </CustomTypography>
        </div>

        <MoreVertIcon
          classes={{
            root: disabled ? classes.iconDisabled : classes.icon,
          }}
        />
      </div>
      <Popper
        open={isOpen}
        anchorEl={iconButtonRef.current}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.zIndex}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.wrapper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <div className={classes.label}>
                    <CustomTypography variant="caption1" color="accentDark">
                      {t(translations.notificationSettings.notify)}
                    </CustomTypography>
                  </div>

                  {notifications?.map((notification, index) => {
                    return notification?.notificationCode ? (
                      <div key={index} className={classes.item}>
                        <Checkbox
                          onChange={e => changeCheckboxHandler(e, notification)}
                          disabled={!notification?.active}
                          checked={selectedNotifications.includes(notification)}
                          disableRipple
                          size="small"
                        />
                        <div className={classes.notificationCode}>
                          <CustomTypography
                            variant="caption2"
                            bold
                            color="accentDark"
                          >
                            {notification?.notificationCode
                              ?.replace(/[A-Z]/g, ' $&')
                              ?.trim()}
                          </CustomTypography>
                        </div>
                      </div>
                    ) : null;
                  })}

                  <div className={classes.buttons}>
                    <Button onClick={onSubmitHandler} variant={'secondaryDark'}>
                      <CustomTypography
                        variant="buttonTextSmall"
                        bold
                        color={'white'}
                      >
                        {t(translations.notificationSettings.save)}
                      </CustomTypography>
                    </Button>

                    <Button onClick={cancelHandler} variant={'secondaryLight'}>
                      <CustomTypography
                        variant="buttonTextSmall"
                        bold
                        color={'mbsBlue'}
                      >
                        {t(translations.notificationSettings.cancel)}
                      </CustomTypography>
                    </Button>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
