import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { useStyles } from './NotFoundPage.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import trucks from 'assets/trucks.svg';
import image404 from 'assets/404.svg';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'types';
import { useAuth } from 'app/shared/providers';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store/slices';

export function NotFoundPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.setIsSideNavOpen(false));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <CustomTypography variant="bodyExtraLarge" color="primaryNavy">
          {t(translations.pageNotFound.sorry)}
        </CustomTypography>

        <CustomTypography variant="bodyExtraLarge" color="primaryNavy">
          {t(translations.pageNotFound.unavailable)}
        </CustomTypography>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          variant="primaryNotFound"
          onClick={() => {
            if (!isAuthenticated) {
              localStorage.setItem(
                'redirectUrl',
                location.pathname + location.search,
              );
            }

            history.push(isAuthenticated ? Routes.Home : Routes.Login);
          }}
        >
          <CustomTypography variant="buttonText" bold color="white">
            {isAuthenticated
              ? t(translations.pageNotFound.goToYourDashboard)
              : t(translations.pageNotFound.signIn)}
          </CustomTypography>
        </Button>
      </div>

      <div className={classes.images}>
        <img
          src={image404}
          data-testid="image_404"
          className={classes.image404}
          alt=""
        />

        <img
          src={trucks}
          data-testid="trucks"
          className={classes.trucks}
          alt=""
        />
      </div>
    </div>
  );
}
