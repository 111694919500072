import React from 'react';
import { useStyles } from './InfoCard.styles';

export const InfoCard = ({
  testId,
  children,
  onClick,
}: {
  testId?: string;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.infoCard} onClick={onClick} data-testid={testId}>
      {children}
    </div>
  );
};
