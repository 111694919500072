import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { OrganizationAdminsTable } from '../OrganizationAdminsTable';
import { useStyles } from './OrganizationDetails.styles';
import { Grid } from 'app/shared/components/generic-ui';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';
import { InviteTeammateModal } from 'app/pages/teammates/components/InviteTeammateModal/InviteteammateModal';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth } from 'app/shared/providers';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useOrganizationsApi } from 'app/shared/hooks/useOrganizationsApi';

const imperial = 0;

export const OrganizationDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { organizationId }: { organizationId: string } = useParams();
  const history = useHistory();
  const pathname = useLocation().pathname;
  const { supplierId } = useAuth();

  const currentOrganizationId = useMemo(() => {
    return pathname.includes('my-organization') ? supplierId : organizationId;
  }, [organizationId, pathname, supplierId]);

  const queryParam = useMemo(() => {
    return {
      organizationId: currentOrganizationId,
    };
  }, [currentOrganizationId]);

  const { fetchOrganization, organizationDetails, isFetchingOrganization } =
    useOrganizationsApi(queryParam);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization, queryParam]);

  const handleAddAdministratorClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.OrganizationDetails,
      'Add administrator button',
    );

    setIsModalOpen(true);
  }, []);

  const handleEditOrganizationClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.OrganizationDetails,
      'Edit organization button',
    );

    history.push(
      Routes.EditOrganization.replace(':organizationId', organizationId),
    );
  }, [history, organizationId]);

  return (
    <>
      <InviteTeammateModal
        isAdmin
        isOpen={isModalOpen}
        header={t(translations.organizationDetailsPage.inviteAdministrator)}
        closeHandler={() => setIsModalOpen(false)}
      />

      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {t(translations.organizationDetailsPage.branding)}
        </CustomTypography>
        <Button variant="primaryProfile" onClick={handleEditOrganizationClick}>
          <CustomTypography variant="subtitle2" bold color="mbsBlue">
            {t(translations.organizationDetailsPage.editOrganization)}
          </CustomTypography>
        </Button>
      </div>

      {!isFetchingOrganization ? (
        <Grid container spacing={3} className={classes.grid}>
          <Grid item>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.externalSupplierId)}
                </CustomTypography>
              </div>
              <CustomTypography variant="bodySmall" color="accentDark">
                {currentOrganizationId}
              </CustomTypography>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.supplierName)}
                </CustomTypography>
              </div>
              <CustomTypography variant="bodySmall" color="accentDark">
                {organizationDetails?.supplierName || '-'}
              </CustomTypography>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.dataFlowStatus)}
                </CustomTypography>
              </div>
              <CustomTypography variant="bodySmall" color="accentDark">
                {organizationDetails?.active
                  ? t(translations.organizationDetailsPage.active)
                  : t(translations.organizationDetailsPage.inactive)}
              </CustomTypography>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.measurementSystem)}
                </CustomTypography>
              </div>
              <CustomTypography variant="bodySmall" color="accentDark">
                {organizationDetails?.measurementSystem === imperial
                  ? t(translations.organizationDetailsPage.imperial)
                  : t(translations.organizationDetailsPage.metric)}
              </CustomTypography>
            </div>
            <div className={classes.logo}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.organizationLogo)}
                </CustomTypography>
              </div>
              <div className={classes.dropzone}>
                {organizationDetails?.supplierImagePath ? (
                  <img
                    src={organizationDetails?.supplierImagePath}
                    className={classes.logo}
                  />
                ) : (
                  <CustomTypography variant="bodySmall" color="greyscale2">
                    {t(translations.organizationDetailsPage.noLogo)}
                  </CustomTypography>
                )}
              </div>
            </div>
          </Grid>

          <Grid item>
            <div className={classes.inputContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" color="greyscale1">
                  {t(translations.organizationDetailsPage.description)}
                </CustomTypography>
              </div>

              <CustomTypography variant="bodySmall" color="accentDark">
                {organizationDetails?.description || '-'}
              </CustomTypography>
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.progress}>
          <CircularProgress withLabel />
        </div>
      )}

      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {t(translations.organizationDetailsPage.administrators)}
        </CustomTypography>
        <Button variant="primaryProfile" onClick={handleAddAdministratorClick}>
          <CustomTypography variant="subtitle2" bold color="mbsBlue">
            {t(translations.organizationDetailsPage.addAdministrators)}
          </CustomTypography>
        </Button>
      </div>

      <OrganizationAdminsTable />
    </>
  );
};
