import React, { useCallback } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { IMixDesign, Routes } from 'types';
import { useStyles } from './MixPropsSection.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { firebaseAnalytics } from 'app/shared/analytics';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { ReactComponent as ArrowLeft } from 'assets/left.svg';
import { ReactComponent as ArrowRight } from 'assets/right.svg';

interface IMixPropsSection {
  mixProps?: IMixDesign;
  isLoading: boolean;
  totalCount?: number;
  selectedPosition?: number;
  getPrevMixDesignHandler: () => void;
  getNextMixDesignHandler: () => void;
}

const defaultMixIdLength = 1;

export const MixPropsSection = ({
  mixProps,
  isLoading,
  totalCount,
  selectedPosition,
  getNextMixDesignHandler,
  getPrevMixDesignHandler,
}: IMixPropsSection) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderProbeData } = useProbeDataFormatter();

  const columns = [
    {
      id: 'itemId',
      label: t(translations.mixtureProportions.materialCode),
      alignment: CellAlignment.JUSTIFY,
      size: CellSize.MEDIUM,
    },
    {
      id: 'itemDescription',
      label: t(translations.mixtureProportions.description),
      alignment: CellAlignment.JUSTIFY,
      size: CellSize.MEDIUM,
    },
    {
      id: 'itemTargetQuantity',
      label: t(translations.mixtureProportions.designQuantity),
      alignment: CellAlignment.RIGHT,
      size: CellSize.MEDIUM,
    },
  ];

  const renderCell = (rowData: any, cellId: string) => {
    if (cellId === 'itemTargetQuantity') {
      return (
        <div>
          {renderProbeData({
            value: rowData[cellId],
            measurement: rowData.itemTargetQuantityUom,
          })}
        </div>
      );
    } else {
      return <div>{rowData[cellId] || ''}</div>;
    }
  };

  const handleSpoilerClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Mixture proportions section spoiler',
    );
  }, []);

  return (
    <div>
      {mixProps && (
        <SectionSpoiler
          header={t(translations.pourEventPage.mixProperties)}
          handleSpoilerClick={handleSpoilerClick}
        >
          <div className={classes.info}>
            <div className={classes.mixtureProps}>
              <div className={classes.subtitle}>
                <CustomTypography variant="subtitle2" bold color="greyscale1">
                  {`${t(translations.pourEventPage.products)}:`}
                </CustomTypography>
              </div>

              <CustomTypography variant="subtitle1" color="greyscale1">
                {`${mixProps?.mixDesign.externalProductId || '-'} / ${
                  mixProps?.mixDesign.externalProductName || '-'
                }`}
              </CustomTypography>
            </div>

            {totalCount && totalCount > defaultMixIdLength ? (
              <div className={classes.arrows}>
                <div className={classes.icon} onClick={getPrevMixDesignHandler}>
                  <ArrowLeft />
                </div>

                <div className={classes.text}>
                  <CustomTypography variant="header6" bold color="accentDark">
                    {selectedPosition}
                  </CustomTypography>
                  <CustomTypography variant="header6" color="accentDark">
                    {`/${totalCount}`}
                  </CustomTypography>
                </div>

                <div className={classes.icon} onClick={getNextMixDesignHandler}>
                  <ArrowRight />
                </div>
              </div>
            ) : null}
          </div>

          <Table
            columns={columns}
            tableData={mixProps.details}
            withCheckbox={false}
            withHover={false}
            rowsTotal={mixProps.details.length}
            withColoredRows={true}
            isLoading={isLoading}
            renderCell={renderCell}
            emptyTableText={t(translations.pourEventPage.noMixProps)}
          />
        </SectionSpoiler>
      )}
    </div>
  );
};
