import React, { useEffect } from 'react';
import { useStyles } from './LoginPage.styles';
import { useAuth } from 'app/shared/providers';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useHistory } from 'react-router-dom';

export function LoginPage() {
  const { signIn, isLoading } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const pathname = localStorage.getItem('redirectUrl');

  const handleSignIn = async () => {
    if (!isLoading) {
      await signIn();
    }
  };

  useEffect(() => {
    handleSignIn();

    if (pathname) {
      history.replace(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.pageWrapper}>
      <CircularProgress size={50} withLabel />
    </div>
  );
}
