/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import calendar from 'assets/date_picker.svg';
import InputBase from '@material-ui/core/InputBase';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './DatePicker.styles';
import { format } from 'date-fns';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

interface ICustomDateInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  placeholder: string;
  startDate?: Date | null;
  endDate?: Date | null;
  id?: string;
  isMobile: boolean;
  withEndDate: boolean;
  isStartDateRequired?: boolean;
  resetDateRange: () => void;
  resetEndDate: () => void;
  onClick?: () => void;
}

export const CustomDateInput = React.forwardRef<
  HTMLDivElement,
  ICustomDateInputProps
>((props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const formatDate = useCallback((date: Date | undefined | null) => {
    return date ? format(new Date(date), 'MMM dd, yyyy') : '';
  }, []);

  return (
    <div className={classes.container} ref={ref}>
      <div className={classes.inputContainer}>
        <div className={classes.dateLabel}>
          <CustomTypography variant="caption2" color="greyscale1">
            {`${t(translations.datePicker.startDate)}${
              props.isStartDateRequired ? '*' : ''
            }: `}
          </CustomTypography>
        </div>

        <div className={classes.dateContainer}>
          {!props.isMobile && (
            <div className={classes.calendarIcon}>
              <img src={calendar} alt="calendar" className={classes.root} />
            </div>
          )}

          <InputBase
            data-testid="input"
            placeholder={props.placeholder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={formatDate(props.startDate)}
            inputProps={{ 'aria-label': 'search' }}
            onChange={props.onChange}
            onClick={props.onClick}
            id={props.id}
          />

          {props.startDate && (
            <div className={classes.closeButton} onClick={props.resetDateRange}>
              <CloseIcon height={19} />
            </div>
          )}
        </div>
      </div>

      {props.withEndDate ? (
        <>
          <div className={classes.dash}>
            <CustomTypography variant="caption2" color="greyscale1">
              –
            </CustomTypography>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.dateLabel}>
              <CustomTypography variant="caption2" color="greyscale1">
                {`${t(translations.datePicker.endDate)}: `}
              </CustomTypography>
            </div>

            <div className={classes.dateContainer}>
              {!props.isMobile && (
                <div className={classes.calendarIcon}>
                  <img src={calendar} alt="calendar" className={classes.root} />
                </div>
              )}

              <InputBase
                data-testid="input"
                placeholder={props.placeholder}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={formatDate(props.endDate)}
                inputProps={{ 'aria-label': 'search' }}
                onChange={props.onChange}
                onClick={props.onClick}
                id={props.id}
              />

              {props.endDate && (
                <div
                  className={classes.closeButton}
                  onClick={props.resetEndDate}
                >
                  <CloseIcon height={19} />
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
});
