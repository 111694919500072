import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './ProjectInfoSection.styles';
import { Grid } from 'app/shared/components/generic-ui';

interface ISectionValue {
  key: string;
  value?: string;
}

export interface IProjectInfoSectionProps {
  header: string;
  values: ISectionValue[];
}

export const ProjectInfoSection = ({
  header,
  values,
}: IProjectInfoSectionProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.subheader} data-testid="subheader">
        <CustomTypography variant="header4" bold color="accentDark">
          {header}
        </CustomTypography>
      </div>

      <div className={classes.projectInfoSection}>
        <Grid container spacing={3}>
          {values.map((el, index) => (
            <Grid item md={4} lg={4} sm={6} key={index}>
              <CustomTypography variant="buttonText" bold color="greyscale2">
                {el.key}
              </CustomTypography>

              <div className={classes.valueContainer}>
                <CustomTypography variant="subtitle2" color="accentDark">
                  {el?.value || '-'}
                </CustomTypography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
