import React, { useCallback, useMemo, useState } from 'react';
import { InfoCard } from 'app/shared/components/generic-ui/InfoCard/InfoCard';
import { INotificationEntityCard, Routes } from 'types';
import { NotificationEntityCardTeamSection } from './NotificationEntityCardTeamSection';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useNotificationSettingsEntityApi } from 'app/shared/hooks';
import { firebaseAnalytics } from 'app/shared/analytics';
import { StatusSection } from './StatusSection/StatusSection';
import { NotificationEntityCardInfoSection } from './NotificationEntityCardInfoSection/NotificationEntityCardInfoSection';
import { CustomTeamModal } from 'app/shared/components/generic-ui';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { getNotificationEntityName } from 'app/shared/utils';
import { translations } from 'app/locales/i18n';
import project_id_icon from 'assets/project_id_icon.svg';
import users from 'assets/users.svg';

import calendarIcon from 'assets/calendar.svg';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface INotificationEntityCardCardProps {
  notificationEntityCard: INotificationEntityCard;
}

export const NotificationEntityCardCard = ({
  notificationEntityCard,
}: INotificationEntityCardCardProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const {
    organizationId,
    entityType,
  }: { organizationId: string; entityType: string } = useParams();
  const entityName = getNotificationEntityName(Number(entityType));
  const eventDate = useMemo(
    () =>
      format(
        new Date(notificationEntityCard.lastTicketDispatchDateTimeUtc),
        'MMM d, y - hh:mm bb',
      ),
    [notificationEntityCard],
  );
  const notificationEntityMembersQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: Number(entityType),
      queryParameters: {
        sortBy: 'firstname,lastname',
        sortAsc: true,
        notificationEntityId: notificationEntityCard.internalId,
      },
    };
  }, [organizationId, entityType, notificationEntityCard.internalId]);

  const {
    fetchActiveEntityAssignments: fetchNotificationEntityMembers,
    isFetchingActiveEntityAssignments: isFetchingProjectMembers,
    activeEntityAssignments: projectMembers,
  } = useNotificationSettingsEntityApi(notificationEntityMembersQueryParams);

  const onClickMembers = useCallback(async () => {
    setIsTeamModalOpen(true);
    await fetchNotificationEntityMembers();

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      `${entityName} card members`,
    );
  }, [fetchNotificationEntityMembers, entityName]);

  const handleCardClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      `${entityName} card`,
    );

    history.push(
      generatePath(Routes.NotificationEntityPourEventsPage, {
        entityType,
        notificationEntityId: notificationEntityCard.internalId,
        organizationId,
        page: 1,
        perPage: 20,
        name: notificationEntityCard.name,
        externalId: notificationEntityCard.externalId,
      }),
    );
  }, [
    entityName,
    history,
    entityType,
    notificationEntityCard.internalId,
    notificationEntityCard.name,
    notificationEntityCard.externalId,
    organizationId,
  ]);

  return (
    <>
      {isTeamModalOpen && (
        <CustomTeamModal
          isOpen={isTeamModalOpen}
          closeHandler={() => {
            setIsTeamModalOpen(false);
          }}
          team={projectMembers}
          isLoading={isFetchingProjectMembers}
        />
      )}

      <InfoCard onClick={handleCardClick}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomTypography variant="header5" bold color="accentDark">
            {notificationEntityCard?.name || ''}
          </CustomTypography>
          <StatusSection notificationEntityBaseModel={notificationEntityCard} />
        </div>
        <div>
          <NotificationEntityCardInfoSection
            value={notificationEntityCard.externalId}
            label={t(translations.notificationEntityCardCard.entityID, {
              entity: entityName,
            })}
            icon={project_id_icon}
          />
        </div>
        <div>
          <NotificationEntityCardTeamSection
            teammatesCount={
              notificationEntityCard?.notificationEntitySummaryModel
                .assignedUsersCount
            }
            showTeamModal={onClickMembers}
          />
        </div>
        {notificationEntityCard.notificationEntityGroupName ? (
          <div>
            <NotificationEntityCardInfoSection
              value={notificationEntityCard.notificationEntityGroupName}
              label={t(translations.notificationEntityCardCard.group)}
              icon={users}
            />
          </div>
        ) : (
          <div />
        )}
        <div>
          <NotificationEntityCardInfoSection
            value={eventDate}
            label={t(translations.notificationEntityCardCard.lastDispatch)}
            icon={calendarIcon}
          />
        </div>
      </InfoCard>
    </>
  );
};
