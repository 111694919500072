import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  teamCard: {
    width: 256,
    height: 190,
    boxSizing: 'border-box',
    padding: '16px',
    border: `1px solid ${themeColors.white}`,
    borderRadius: '2px',
    boxShadow: themeShadows.shadowMedium,
    margin: '10px',
    backgroundColor: themeColors.white,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  title: {
    marginBottom: 20,
  },
  empty: {
    marginTop: 4,
  },
  label: {
    padding: '6px 10px',
    width: 'fit-content',
    backgroundColor: themeColors.greyscale4_50,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 14,
  },
  closeButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  more: {
    cursor: 'pointer',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
