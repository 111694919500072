import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  subheader: {
    margin: '24px 0px 20px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  subheaderMobile: {
    margin: '24px 0px 8px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '8px',
    alignItems: 'center',
  },
  projectInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  valueContainer: {
    paddingTop: 9,
    paddingBottom: 36,
  },
  valueContainerMobile: {
    paddingTop: 4,
    paddingBottom: 0,
  },
  inputContainer: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginRight: 20,
  },
  phoneComponentWrapper: {
    height: 38,
  },
  phoneContainer: {
    marginBottom: 10,
  },
});
