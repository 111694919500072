import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  empty: {
    paddingBottom: '14px',
  },
  mixtureProps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 16,
  },
  subtitle: {
    paddingRight: 5,
  },
  info: {
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    height: 26,
    width: 26,
    borderRadius: 30,
    backgroundColor: themeColors.mbsBlue,
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrows: {
    display: 'flex',
    flexDirection: 'row',
    borderLeft: `1px solid ${themeColors.accentDark14}`,
    paddingLeft: 16,
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 8px',
    alignItems: 'center',
  },
});
