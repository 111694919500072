import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  projectCardBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '12px',
  },
  icon: {
    marginRight: '10px',
  },
  dispatchIcon: { marginRight: '6px' },
});
