import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './ChartLimitedRange.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ReactComponent as SlumpArrow } from 'assets/slump_arrow.svg';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
import { useProbeDataFormatter } from 'app/shared/hooks';
import { useScreen } from 'app/shared/hooks';

interface IBatchEventCard {
  rangeMin: number;
  rangeMax: number;
  value: number;
  units: string | null;
}

const sectionOfChart = 0.5;
const sectionsCount = 3;
const hundredPercents = 100;
const positionOfTriangleIfLessThanMin = 0;
const positionOfTriangleIfMoreThanMax = 97;
const maxOffset = 85;
const minOffset = 15;
const zero = 0;
const half = 2;
const smallModal = 327;
const bigModal = 513;
const decimalPlaces = 1;

export const ChartLimitedRange = ({
  rangeMin,
  rangeMax,
  value,
  units,
}: IBatchEventCard) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { renderProbeData, formatProbeValue } = useProbeDataFormatter();
  const [markerWidth, setMarkerWidth] = useState(zero);
  const valueContainer = useRef<HTMLDivElement>(null);

  const { screenWidth } = useScreen();
  const modalWidth =
    screenWidth > themeLayout.mobileWidth ? bigModal : smallModal;

  useEffect(() => {
    setMarkerWidth(valueContainer.current?.offsetWidth || zero);
  }, [valueContainer]);

  const differenceInRange = useMemo(() => {
    return rangeMax - rangeMin;
  }, [rangeMax, rangeMin]);

  const sectionsPercentage = useMemo(() => {
    return (
      hundredPercents / ((differenceInRange / sectionOfChart) * sectionsCount)
    );
  }, [differenceInRange]);

  const minValueInChart = useMemo(() => {
    return rangeMin - differenceInRange;
  }, [rangeMin, differenceInRange]);

  const maxValueInChart = useMemo(() => {
    return rangeMax + differenceInRange;
  }, [rangeMax, differenceInRange]);

  const markerOffset = useMemo(() => {
    return (markerWidth / half / modalWidth) * hundredPercents;
  }, [markerWidth, modalWidth]);

  const arrowPadding = useMemo(() => {
    return value <= minValueInChart
      ? positionOfTriangleIfLessThanMin - markerOffset
      : value >= maxValueInChart
      ? positionOfTriangleIfMoreThanMax - markerOffset
      : (sectionsPercentage * (value - minValueInChart)) / sectionOfChart;
  }, [
    value,
    markerOffset,
    maxValueInChart,
    minValueInChart,
    sectionsPercentage,
  ]);

  const difference = useMemo(() => {
    return value < rangeMin
      ? +formatProbeValue(rangeMin, decimalPlaces, 'floor', true) -
          +formatProbeValue(value, decimalPlaces, 'floor', true)
      : +formatProbeValue(value, decimalPlaces, 'floor', true) -
          +formatProbeValue(rangeMax, decimalPlaces, 'floor', true);
  }, [value, rangeMin, formatProbeValue, rangeMax]);

  const isValueOutOfRange = useMemo(() => {
    return value < rangeMin || value > rangeMax;
  }, [value, rangeMin, rangeMax]);

  return (
    <div className={classes.chart}>
      <div className={classes.chartTitle}>
        <CustomTypography variant="bodySmall" color="greyscale2">
          {t(translations.pourEventPage.slumpRange)}
        </CustomTypography>
      </div>
      <div
        className={classes.triangleContainer}
        style={{
          paddingLeft: `${
            arrowPadding > maxOffset
              ? maxOffset
              : arrowPadding < minOffset
              ? minOffset
              : arrowPadding - markerOffset
          }%`,
          marginLeft: isValueOutOfRange ? '0px' : '-6px',
        }}
      >
        <div
          className={classes.marker}
          ref={valueContainer}
          style={{
            marginLeft: isValueOutOfRange ? '0px' : '-6px',
          }}
        >
          {isValueOutOfRange && (
            <div>
              <CustomTypography
                variant="caption1"
                bold
                color="systemRed"
                style={{ whiteSpace: 'nowrap', fontWeight: 700 }}
              >
                {`${value < rangeMin ? '-' : '+'} ${renderProbeData(
                  {
                    value: difference,
                    measurement: units,
                  },
                  decimalPlaces,
                )}`}
              </CustomTypography>
            </div>
          )}
          <SlumpArrow
            fill={
              isValueOutOfRange
                ? themeColors.systemRed
                : themeColors.systemGreen
            }
          />
        </div>
      </div>
      <div className={classes.chartContainer}>
        <div className={classes.chartPart} />
        <div className={classes.centerChartPart} />
        <div className={classes.chartPart} />
      </div>
      <div className={classes.chartLabels}>
        <div className={classes.label}>
          <div className={classes.chartMinMax}>
            <CustomTypography variant="bodySmall" color="greyscale2">
              {renderProbeData({
                value: rangeMin,
                measurement: units,
              })}
            </CustomTypography>
          </div>
          <div className={classes.chartMinMax}>
            <CustomTypography variant="bodySmall" color="greyscale2">
              {renderProbeData({
                value: rangeMax,
                measurement: units,
              })}
            </CustomTypography>
          </div>
        </div>
      </div>
    </div>
  );
};
