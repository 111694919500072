import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 30px 100px 30px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  status: {
    padding: '4px 19px 0px 19px',
  },
  backArrow: {
    marginRight: 13,
    cursor: 'pointer',
  },
});
