import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  chart: {
    height: 83,
    padding: '8px 13px 15px 13px',
  },
  chartTitle: {
    paddingBottom: 12,
  },
  markerContainer: {
    width: '100%',
    margin: '-5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  marker: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 12,
    borderBottom: `1px solid ${themeColors.primaryNavy}`,
  },
  chartPart: {
    width: '50%',
    borderRight: `1px solid ${themeColors.primaryNavy}`,
  },
  chartLabels: {
    paddingTop: 8,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  circleMarker: {
    height: 11,
    width: 11,
    borderRadius: '50%',
    backgroundColor: themeColors.systemRed,
  },
  value: {
    marginLeft: 8,
  },
});
