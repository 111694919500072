import { useLocation } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

function LoginWrapper({ children }: Props) {
  const location = useLocation();
  localStorage.setItem('redirectUrl', location.pathname + location.search);
  return children;
}

export default LoginWrapper;
