import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './BatchDetailsTabs.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';

export enum BatchHistoryTab {
  INFO,
  HISTORY,
}

interface IBatchHistoryTabsProps {
  tab: BatchHistoryTab;
  changeTabHandler: (tab: BatchHistoryTab) => void;
}

export const BatchDetailsTabs = ({
  tab,
  changeTabHandler,
}: IBatchHistoryTabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div
        onClick={() => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Press batch history info tab',
          );

          changeTabHandler(BatchHistoryTab.INFO);
        }}
        className={
          tab === BatchHistoryTab.INFO ? classes.tabActive : classes.tab
        }
      >
        <CustomTypography
          variant="bodySmall"
          bold={tab === BatchHistoryTab.INFO}
          color={tab === BatchHistoryTab.INFO ? 'mbsBlue' : 'greyscale1'}
        >
          {t(translations.batchDetailsModal.batchInfo)}
        </CustomTypography>
      </div>

      <div
        onClick={() => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Press batch history tab',
          );
          changeTabHandler(BatchHistoryTab.HISTORY);
        }}
        className={
          tab === BatchHistoryTab.HISTORY ? classes.tabActive : classes.tab
        }
      >
        <CustomTypography
          variant="bodySmall"
          bold={tab === BatchHistoryTab.HISTORY}
          color={tab === BatchHistoryTab.HISTORY ? 'mbsBlue' : 'greyscale1'}
        >
          {t(translations.batchDetailsModal.batchHistory)}
        </CustomTypography>
      </div>
    </div>
  );
};
