import { useStyles } from './NotificationEntityCardTeamSection.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

import project_team from 'assets/project_team.svg';

interface IProjectTeamSectionProps {
  teammatesCount?: number;
  showTeamModal: (value: boolean) => void;
}

const noTeammates = 0;

export const NotificationEntityCardTeamSection = ({
  teammatesCount = noTeammates,
  showTeamModal,
}: IProjectTeamSectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.projectCardFooter}>
        <img data-testid="avatar" src={project_team} className={classes.icon} />
        <CustomTypography variant="bodySmall" color="greyscale1">
          {`${t(translations.notificationEntityCardCard.assignedUsers)}:`}
        </CustomTypography>
        <div
          className={classes.members}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            teammatesCount !== noTeammates && showTeamModal(true);
          }}
        >
          <CustomTypography
            variant="bodySmall"
            bold
            color={teammatesCount === noTeammates ? 'greyscale3' : 'mbsBlue'}
          >
            {`${teammatesCount} ${t(
              translations.notificationEntityCardCard.members,
            )}`}
          </CustomTypography>
        </div>
      </div>
    </div>
  );
};
