import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  pourEvents: {
    marginTop: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  empty: {
    paddingTop: 10,
  },
  progress: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
