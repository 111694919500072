import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { Link, useParams } from 'react-router-dom';
import { CustomTypography } from './../../generic-ui/Typography/Typography';
import { Routes } from 'types';
import { useStyles } from './Footer.styles';
import logo from 'assets/msb_logo.png';
import { useOnScreen } from 'app/shared/hooks/layout/useOnScreen';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store/slices/layoutSlice';
import { externalLinksConfig } from 'app/shared/config';
import { useAuth, useModal } from 'app/shared/providers';
import { useCallback } from 'react';

export const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const footerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(footerRef);
  const dispatch = useDispatch();
  const { organizationId }: { organizationId: string } = useParams();
  const { supplierId, isAuthenticated } = useAuth();
  const { setTitle, setContent, openModal } = useModal();

  useEffect(() => {
    dispatch(actions.setIsFooterVisible(isVisible));
  }, [dispatch, isVisible]);

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const modalContent = useMemo(() => {
    return (
      <div>
        <div className={classes.textWrapper}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {t(translations.termsOfUsePage.byAccessing)}
          </CustomTypography>
        </div>
        <div className={classes.textWrapper}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {t(translations.termsOfUsePage.theApp)}
          </CustomTypography>
        </div>
        <div className={classes.textWrapper}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {t(translations.termsOfUsePage.theAppIsDesigned)}
          </CustomTypography>
        </div>

        <CustomTypography variant="bodyRegular" color="accentDark">
          {t(translations.termsOfUsePage.theAppAndAll)}
        </CustomTypography>
      </div>
    );
  }, [classes.textWrapper, t]);

  const termsOfUseClickHandler = useCallback(() => {
    setTitle(t(translations.footer.termsOfUse));
    setContent(modalContent);
    openModal();
  }, [modalContent, openModal, setContent, setTitle, t]);

  const privacyPolicyClickHandler = useCallback(() => {
    return window.open(
      externalLinksConfig.privacyPolicy,
      '_blank',
      'noopener,noreferrer',
    );
  }, []);

  return (
    <div className={classes.footerContainer} ref={footerRef}>
      <div className={classes.logoContainer}>
        <img
          src={logo}
          data-testid="logo"
          alt="logo"
          className={classes.logo}
        />
      </div>

      {isAuthenticated ? (
        <div className={classes.links}>
          <Link
            to={Routes.ContactUs.replace(':organizationId', currentSupplierId)}
            data-testid="policy-link"
            className={classes.link}
          >
            <CustomTypography variant="caption1" bold color="greyscale1">
              {t(translations.footer.contacts)}
            </CustomTypography>
          </Link>

          <div
            onClick={privacyPolicyClickHandler}
            data-testid="policy-link"
            className={classes.link}
          >
            <CustomTypography variant="caption1" bold color="greyscale1">
              {t(translations.footer.privacyPolicy)}
            </CustomTypography>
          </div>

          <div onClick={termsOfUseClickHandler} className={classes.link}>
            <CustomTypography variant="caption1" bold color="greyscale1">
              {t(translations.footer.termsOfUse)}
            </CustomTypography>
          </div>
        </div>
      ) : null}
    </div>
  );
};
