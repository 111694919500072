const valuesEqualFlag = 0;
const valueIsBiggerFlag = 1;
const valueIsLessFlag = -1;

export const sortInAlphabeticalOrder = (
  orderType: string,
  values: any[],
  label?: string,
  secondaryLabel?: string,
) => {
  const sort = values.sort((valueA: any, valueB: any): number => {
    const first: string =
      (label ? valueA[label].toLowerCase() : valueA.toLowerCase()) +
      (secondaryLabel?.length ? valueA[secondaryLabel].toLowerCase() : '');
    const second: string =
      (label ? valueB[label].toLowerCase() : valueB.toLowerCase()) +
      (secondaryLabel?.length ? valueB[secondaryLabel].toLowerCase() : '');

    return orderType === 'asc'
      ? first < second
        ? valueIsLessFlag
        : first > second
        ? valueIsBiggerFlag
        : valuesEqualFlag
      : second < first
      ? valueIsLessFlag
      : second > first
      ? valueIsBiggerFlag
      : valuesEqualFlag;
  });

  return sort;
};
