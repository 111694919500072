import { Modal } from '@material-ui/core';
import { ModalHeader, ModalVariant } from '../ModalHeader';
import { useStyles } from './CustomModal.styles';

interface ICustomModalProps {
  isModalOpen: boolean;
  closeModalHandler: () => void;
  title: string;
  content: any;
  fitContent?: boolean;
}

export const CustomModal = ({
  isModalOpen,
  closeModalHandler,
  title,
  content,
  fitContent,
}: ICustomModalProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Modal
        open={isModalOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          closeModalHandler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <div className={fitContent ? classes.fitContent : classes.small}>
          <ModalHeader
            header={title}
            closeHandler={closeModalHandler}
            variant={ModalVariant.SMALL}
          />

          <div className={classes.body}>
            <div className={classes.text}>{content}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
