import { LogLevel } from '@azure/msal-browser';

export const config = {
  legacyUrl: process.env.REACT_APP_API_LEGACY_URL,
  baseUrl: process.env.REACT_APP_API_BASE_URL,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_B2C_AUTHORITY ?? '',
    knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITY ?? ''],
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (
          containsPii ||
          process.env.NODE_ENV === 'production' ||
          process.env.NODE_ENV === 'test'
        ) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['offline_access', process.env.REACT_APP_B2C_SCOPE_READ_ALL ?? ''],
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_ANALYTICS_API_KEY ?? '',
  authDomain: process.env.REACT_APP_FB_ANALYTICS_AUTH_DOMAIN ?? '',
  projectId: process.env.REACT_APP_FB_ANALYTICS_PROJECT_ID ?? '',
  storageBucket: process.env.REACT_APP_FB_ANALYTICS_STORAGE_BUCKET ?? '',
  messagingSenderId:
    process.env.REACT_APP_FB_ANALYTICS_MESSAGING_SENDER_ID ?? '',
  appId: process.env.REACT_APP_FB_ANALYTICS_APP_ID ?? '',
  measurementId: process.env.REACT_APP_FB_ANALYTICS_MEASUREMENT_ID ?? '',
};

export const reactNativeAppConfig = {
  ios: process.env.REACT_NATIVE_APP_IOS ?? '',
  android: process.env.REACT_NATIVE_APP_ANDROID ?? '',
};

export const externalLinksConfig = {
  exact: 'https://portal.exacttechnology.com/',
  privacyPolicy:
    'https://www.master-builders-solutions.com/en-us/footer/data-protection',
};

export const contactUsInfo = {
  email: 'joseph.daczko@mbcc-group.com',
  phone: '1-216-839-7044',
};
