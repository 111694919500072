import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  button: {
    height: 10,
    width: 10,
    border: `1px solid ${themeColors.mbsBlue}`,
    marginRight: 5,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: -7,
    right: 2,
    left: 1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '6px',
  },
});
