import { IProbeRanges, Routes } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './AddManualTestData.styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { themeColors } from 'app/shared/theme';
import {
  Grid,
  GridVariant,
  Select,
  TimePicker,
} from 'app/shared/components/generic-ui';
import { useBatchApi, useManualProbeApi } from 'app/shared/hooks';
import { useParams } from 'react-router';
import {
  InputSize,
  TextInput,
} from 'app/shared/components/generic-ui/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useManualTestDataValidation } from '../hooks';
import { format } from 'date-fns';
import {
  CircularProgress,
  ProgressType,
} from 'app/shared/components/generic-ui/CircularProgress';
import { SuccessPage } from '../SuccessPage';
import { firebaseAnalytics } from 'app/shared/analytics';
import { TeammateTextInput } from 'app/shared/components/generic-ui/TextInput/TeammateTextInput';

interface IAddCompressiveStrengthModalProps {
  probeRanges: IProbeRanges;
  crn: string;
  closeHandler: () => void;
  eventStatusCode?: string;
}

const last = 1;
const zero = 0;

export const AddManualTestData = ({
  probeRanges,
  crn,
  closeHandler,
}: IAddCompressiveStrengthModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [currentNote, setCurrentNote] = useState('');
  const [receiverIds, setReceiverIds] = useState<string[]>([]);
  const { organizationId }: { organizationId: string } = useParams();
  const { batchStatuses, fetchBatchStatuses } = useBatchApi({
    organizationId: organizationId,
  });

  const { validationSchema, formDefaultValues, textInputs } =
    useManualTestDataValidation(probeRanges);

  const { createManualProbe, isCreateManualProbe } = useManualProbeApi({
    organizationId: organizationId,
  });
  const [isSuccessful, setIsSuccessful] = useState(false);

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!batchStatuses) {
      fetchBatchStatuses();
    }
  }, [batchStatuses]);

  const isDisabled = useMemo(() => {
    return !(
      dirtyFields?.slump ||
      dirtyFields.airContent ||
      dirtyFields.density ||
      dirtyFields.hydraulicPressure ||
      dirtyFields.slumpFlow ||
      dirtyFields.temperature
    );
  }, [
    dirtyFields.airContent,
    dirtyFields.density,
    dirtyFields.hydraulicPressure,
    dirtyFields?.slump,
    dirtyFields.slumpFlow,
    dirtyFields.temperature,
  ]);

  const statuses = useMemo(() => {
    return batchStatuses
      ?.filter(
        el =>
          !probeRanges.availableRanges ||
          (probeRanges.availableRanges &&
            probeRanges.availableRanges.some(
              range => range.eventStatusCode === el.replace(' ', '_'),
            )),
      )
      .map(el => {
        return {
          value: el.replace(' ', '_'),
          label: el,
        };
      });
  }, [batchStatuses, probeRanges.availableRanges]);

  const sortedAndTransformedProbeRanges = useMemo(() => {
    return (
      probeRanges.availableRanges
        ?.map(item => ({
          eventStatusCode: item.eventStatusCode,
          eventDateTimeUtc: new Date(item.eventDateTimeUtc),
        }))
        .sort(
          (a, b) => a.eventDateTimeUtc.getTime() - b.eventDateTimeUtc.getTime(),
        ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(probeRanges)]);

  const findNearestEvent = useCallback(
    (value: Date | null) => {
      if (value) {
        const nearestEventIndex = sortedAndTransformedProbeRanges.findIndex(
          val => value.getTime() < val.eventDateTimeUtc.getTime(),
        );

        if (nearestEventIndex === zero) {
          return sortedAndTransformedProbeRanges[nearestEventIndex]
            .eventStatusCode;
        } else if (nearestEventIndex !== -last) {
          return sortedAndTransformedProbeRanges[nearestEventIndex - last]
            .eventStatusCode;
        } else {
          return sortedAndTransformedProbeRanges[
            sortedAndTransformedProbeRanges.length - last
          ].eventStatusCode;
        }
      }
    },
    [sortedAndTransformedProbeRanges],
  );

  const [selectedEvent, setSelectedEvent] = useState(
    sortedAndTransformedProbeRanges.length &&
      probeRanges.defaultTimeSelectionUTC
      ? findNearestEvent(new Date(probeRanges.defaultTimeSelectionUTC))
      : undefined,
  );

  const dateFilterFormat = useCallback((date: string | undefined | null) => {
    return date ? format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssxxx") : '';
  }, []);

  const onSubmit = useCallback(
    (data: any) => {
      const payload = {
        crn: crn,
        eventStatusCode: selectedEvent,
        probeTakenOn: dateFilterFormat(data.probeTakenOn),
        slump: {
          measurement: probeRanges.slumpRange.measurement,
          value: data.slump,
        },
        slumpFlow: {
          measurement: probeRanges.slumpFlowRange.measurement,
          value: data.slumpFlow,
        },
        density: {
          measurement: probeRanges.densityRange.measurement,
          value: data.density,
        },
        concreteTemperature: {
          measurement: probeRanges.concreteTemperatureRange.measurement,
          value: data.temperature,
        },
        hydraulicPressure: {
          measurement: probeRanges.hydraulicPressureRange.measurement,
          value: data.hydraulicPressure,
        },
        airContentPercentage: data.airContent,
        noteText: currentNote,
        receiverIds: receiverIds,
      };

      createManualProbe(payload).then(res => {
        setIsSuccessful(!!res?.data);
        firebaseAnalytics.logPressEvent(
          Routes.SinglePourEvent,
          'Create Manual Test Data',
        );
      });

      return null;
    },
    [
      createManualProbe,
      crn,
      currentNote,
      dateFilterFormat,
      probeRanges.concreteTemperatureRange.measurement,
      probeRanges.densityRange.measurement,
      probeRanges.hydraulicPressureRange.measurement,
      probeRanges.slumpFlowRange.measurement,
      probeRanges.slumpRange.measurement,
      receiverIds,
      selectedEvent,
    ],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      {!isCreateManualProbe && !isSuccessful ? (
        <div className={classes.wrapper}>
          <div className={classes.inputs}>
            <div className={classes.sectionHeader}>
              <CustomTypography variant="header6" bold color="accentDark">
                {t(translations.addManualTestData.status)}
              </CustomTypography>
            </div>
            <div className={classes.row}>
              <div className={classes.input}>
                <div>
                  <CustomTypography variant="caption1" color="greyscale1">
                    {t(translations.addManualTestData.selectEvent)}
                  </CustomTypography>
                </div>

                <Select.Base
                  onChange={e => {
                    setSelectedEvent(e.target.value);
                    const val = sortedAndTransformedProbeRanges.find(
                      event => event.eventStatusCode === e.target.value,
                    );
                    if (val) {
                      setValue('probeTakenOn', val.eventDateTimeUtc);
                    }
                  }}
                  value={selectedEvent}
                  values={statuses}
                  valueId="value"
                  labelId="label"
                  label={'Select'}
                  height={44}
                  multiSelect={false}
                />
              </div>

              <div className={classes.input}>
                <div>
                  <CustomTypography variant="caption1" color="greyscale1">
                    {t(translations.addManualTestData.selectTime)}
                  </CustomTypography>
                </div>

                <TimePicker
                  minTime={new Date(probeRanges.minTimeUTC)}
                  maxTime={new Date(probeRanges.maxTimeUTC)}
                  onChange={date => {
                    if (date) setSelectedEvent(findNearestEvent(date));
                  }}
                  name="probeTakenOn"
                  control={control}
                  error={!!errors?.probeTakenOn}
                  helperText={errors?.probeTakenOn?.message || ''}
                  setError={setError}
                />
              </div>
            </div>

            <div className={classes.sectionWithBorder}>
              <CustomTypography variant="header6" bold color="accentDark">
                {t(translations.addManualTestData.batchValues)}
              </CustomTypography>
            </div>

            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              className={classes.inputsContainer}
            >
              {textInputs.map(
                (el: {
                  name: keyof typeof formDefaultValues;
                  label: string;
                }) => (
                  <Grid
                    variant={GridVariant.MEDIUM}
                    item
                    container
                    key={el.name}
                  >
                    <Grid item md={12} lg={12}>
                      <CustomTypography variant="caption1" color="greyscale1">
                        {el.label}
                      </CustomTypography>
                    </Grid>
                    <Grid item md={12} lg={12}>
                      <TextInput
                        name={el.name}
                        control={control}
                        error={!!errors[el.name]}
                        helperText={errors[el.name]?.message || ''}
                        customSize={InputSize.BIG}
                        autoComplete="off"
                        placeholder="Type here"
                        borderRadius={2}
                      />
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              className={classes.inputsContainer}
            >
              <Grid item md={12} lg={12}>
                <Grid item md={12} lg={12}>
                  <CustomTypography variant="caption1" color="greyscale1">
                    {'Note'}
                  </CustomTypography>
                </Grid>
                <TeammateTextInput
                  noteChange={setCurrentNote}
                  setReceviersIds={setReceiverIds}
                  index={3}
                />
              </Grid>
            </Grid>
          </div>

          <div className={classes.infoWrapper}>
            <div className={classes.info}>
              <div>
                <InfoIcon
                  stroke={themeColors.mbsBlue}
                  className={classes.icon}
                />
              </div>

              <CustomTypography variant="bodyRegular" color="accentLight">
                {t(translations.addCompressiveStrength.keepInMind)}
              </CustomTypography>
            </div>

            <div className={classes.button}>
              <Button variant="primarySave" type="submit" disabled={isDisabled}>
                <CustomTypography variant="buttonText" bold color="white">
                  {t(translations.addCompressiveStrength.saveData)}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </div>
      ) : !isSuccessful ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <SuccessPage
          buttonText={t(translations.batchDetailsModal.backToBatchHistory)}
          backHandler={closeHandler}
        />
      )}
    </form>
  );
};

export const AddManualTestDataWrapper = (
  props: Omit<IAddCompressiveStrengthModalProps, 'probeRanges'>,
) => {
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const { fetchProbeRanges, probeRanges } = useManualProbeApi({
    organizationId: organizationId,
    queryParameters: {
      crn: props.crn,
    },
  });

  useEffect(() => {
    fetchProbeRanges();
  }, [fetchProbeRanges]);

  if (probeRanges)
    return <AddManualTestData {...props} probeRanges={probeRanges} />;

  return (
    <div className={classes.loading}>
      <CircularProgress withLabel size={40} type={ProgressType.LIGHT} />
    </div>
  );
};
