import { makeStyles, withStyles } from '@material-ui/core';
import { TextField } from '@mui/material';
import { themeColors } from 'app/shared/theme';

export const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
      borderColor: themeColors.mbsBlue,
    },

    '& .MuiOutlinedInput-root': {
      padding: '10px 9px !important',
      marginBottom: 7,
      height: 44,
      borderRadius: '2px !important',
      '&:notchedOutline': {
        borderColor: 'red',
      },

      '&.Mui-focused fieldset': {
        borderColor: themeColors.mbsBlue,
      },
    },
    '&.MuiFormHelperText-root ': {
      marginLeft: '-10px',
      marginTop: '-10px',
      height: 0,
      '.Mui-error': {
        color: 'red',
      },
    },
  },
})(TextField);

export const useStyles = makeStyles({
  input: {
    padding: '12px 10px !important',
    fontSize: '14px',
    lineHeight: '22px',
    height: 44,
    boxSizing: 'border-box',
  },
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
      borderColor: themeColors.mbsBlue,
    },
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 0,
    },
    margin: 0,
    height: 44,
  },
  contained: {
    marginLeft: '0px !important',
    marginTop: '-5px !important',
    color: themeColors.greyscale2,
    height: 0,
  },
});
