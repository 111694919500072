import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 30px 160px 30px',
  },
  pourEventHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pourEventStatus: {
    padding: '4px 19px 0px 19px',
  },
  backArrow: {
    marginRight: 13,
    cursor: 'pointer',
  },
  subheader: {
    margin: '30px 0px 17px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  pourEvents: {
    marginTop: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
