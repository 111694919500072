import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useStyles } from './PageHeader.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { firebaseAnalytics } from 'app/shared/analytics';
import {
  ActionButton,
  ActionButtonStyle,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { ProjectStatusLabel } from 'app/shared/components/generic-ui/ProjectStatusLabel/ProjectStatusLabel';
import { NotificationEntityStatus } from 'types';
import { Pages } from '../../layout/TabNavigation/TabNavigation.types';
import { TabNavigation } from '../../layout/TabNavigation';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import arrow_left from 'assets/arrow_left.svg';

interface IPageHeaderProps {
  header: string;
  status?: NotificationEntityStatus;
  pageType?: Pages;
  editHandler?: () => void;
  deleteHandler?: () => void;
  route?: string;
  withActions?: boolean;
  withBackIcon?: boolean;
  withTabNavigation?: boolean;
  button?: JSX.Element;
}

export const PageHeader = ({
  header,
  status,
  route,
  pageType = Pages.PROJECT_PAGE,
  withActions = true,
  children,
  deleteHandler,
  editHandler,
  withBackIcon,
  withTabNavigation = true,
  button,
}: PropsWithChildren<IPageHeaderProps>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const actions: IAction[] = useMemo(() => {
    return withActions && deleteHandler && editHandler
      ? [
          {
            label: t(translations.pourEventPage.editProjectDetails),
            onClick: editHandler,
          },
          {
            label: t(translations.pourEventPage.deleteProject),
            onClick: deleteHandler,
          },
        ]
      : [];
  }, [deleteHandler, editHandler, t, withActions]);

  const handleActionGroupClick = useCallback(() => {
    route && firebaseAnalytics.logPressEvent(route, 'Actions group element');
  }, [route]);

  const handleBackClick = useCallback(() => {
    return history.goBack();
  }, [history]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          {withBackIcon && (
            <img
              src={arrow_left}
              className={classes.backArrow}
              onClick={handleBackClick}
            />
          )}
          <CustomTypography variant="header3" bold color="accentDark">
            {header}
          </CustomTypography>
          {status && (
            <div className={classes.status}>
              <ProjectStatusLabel status={status} />
            </div>
          )}
        </div>

        {withActions && (
          <ActionButton
            style={ActionButtonStyle.DARK}
            options={actions}
            actionsGroupClickHandler={handleActionGroupClick}
          />
        )}

        {button ? <div>{button}</div> : null}
      </div>
      {withTabNavigation && <TabNavigation pageType={pageType} />}

      {children}
    </div>
  );
};
