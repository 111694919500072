import React, { useEffect, useMemo } from 'react';
import { NotificationEntityStatus } from 'types';
import { useParams } from 'react-router';
import { useOrganizationsApi } from 'app/shared/hooks';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'app/shared/providers';
import { PageHeader } from 'app/shared/components/generic-ui/PageHeader/PageHeader';
import {
  Pages,
  TabIds,
} from 'app/shared/components/layout/TabNavigation/TabNavigation.types';
import { OrganizationDetails } from './OrganizationDetails';
import { PlantSettings } from './PlantSettings/PlantSettings';
import { TruckSettings } from './TruckSettings/TruckSettings';
import { OrganizationRoleAssignments } from './OrganizationRoleAssignments';
import { OrganizationMixIdTable } from './OrganizationMixIdTable/OrganizationMixIdTable';

export const OrganizationDetailsWrapper = () => {
  const { supplierId } = useAuth();
  const { organizationId }: { organizationId: string } = useParams();
  const pathname = useLocation().pathname;
  const { isAdmin, isSuperAdmin } = useAuth();

  const currentOrganizationId = useMemo(() => {
    return pathname.includes('my-organization') ? supplierId : organizationId;
  }, [organizationId, pathname, supplierId]);

  const queryParam = useMemo(() => {
    return {
      organizationId: currentOrganizationId,
    };
  }, [currentOrganizationId]);

  const { fetchOrganization, organizationDetails } =
    useOrganizationsApi(queryParam);

  const pageType = useMemo(() => {
    return pathname.includes('my-organization')
      ? Pages.MY_ORGANIZATION
      : Pages.ORGANIZATION_PAGE;
  }, [pathname]);

  const status = useMemo(() => {
    return organizationDetails?.active
      ? NotificationEntityStatus.ACTIVE
      : NotificationEntityStatus.INACTIVE;
  }, [organizationDetails?.active]);

  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization, queryParam]);

  return (
    <div>
      <PageHeader
        header={organizationDetails?.supplierName || '-'}
        status={status}
        withBackIcon={true}
        withActions={false}
        pageType={pageType}
      >
        {pathname.includes(TabIds.PLANT_TEAM) ? (
          <PlantSettings />
        ) : pathname.includes(TabIds.TRUCK_SETTINGS) ? (
          <TruckSettings />
        ) : pathname.includes(TabIds.ORGANIZATION_ROLES) ? (
          <OrganizationRoleAssignments />
        ) : pathname.includes(TabIds.MIX_ID_CATEGORIZATION) &&
          (isAdmin || isSuperAdmin) ? (
          <OrganizationMixIdTable />
        ) : (
          <OrganizationDetails />
        )}
      </PageHeader>
    </div>
  );
};
