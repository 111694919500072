import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  valueContainer: {
    wordWrap: 'break-word',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ageContainerLight: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.greyscale4_50,
    boxSizing: 'border-box',
    width: 'fit-content',
    marginLeft: 5,
  },
  subheader: {
    paddingBottom: 8,
    paddingTop: 16,
    borderBottom: `2px solid ${themeColors.greyscale4}`,
    marginBottom: 8,
  },
  info: {
    width: 561,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    boxSizing: 'border-box',
    padding: '0px 24px 8px 24px',
    backgroundColor: themeColors.white,
    height: 518,
    overflow: 'auto',
  },
  expandButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 10,
  },
  expandIcon: {
    width: 13,
    marginLeft: 5,
    color: themeColors.mbsBlue,
  },
  icon: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 5,
  },
  sectionItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 4,
  },
}));
