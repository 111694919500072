import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    height: 171,
    marginTop: 36,
    backgroundColor: themeColors.white,
    padding: '16px 32px 16px 10px',
    boxSizing: 'border-box',
    flexDirection: 'row',
  },
  overflowContainer: {
    overflowY: 'hidden',
    height: '110px',
    position: 'relative',
  },
  selectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    marginRight: 8,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: themeColors.greyscale4_50,
    width: 'fit-content',
    borderRadius: 20,
    marginTop: 10,
    height: 'fit-content',
  },
  closeIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttons: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: themeColors.white,
    padding: 8,
    boxSizing: 'border-box',
  },
  viewAll: {
    paddingRight: 10,
    borderRight: `1px solid ${themeColors.greyscale3}`,
    cursor: 'pointer',
  },
  clearAll: {
    paddingLeft: 10,
    marginRight: '-5px',
    cursor: 'pointer',
  },
  viewMoreIndicator: {
    width: 120,
    height: 35,
  },
  closeIcon: {
    marginLeft: 5,
  },
});
