import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
