import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    overflowY: 'auto',
  },
  valueContainer: {
    paddingTop: 9,
    paddingBottom: 36,
  },
  inputContainer: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginRight: 20,
  },
  formControl: {
    width: '100%',
    minHeight: '35px',
    backgroundColor: themeColors.white,
  },
  select: {
    padding: '8px 10px',
  },
  paper: {
    marginTop: 45,
  },
  labelRoot: {
    transform: 'translate(10px,10px) scale(1)',
  },
  label: {
    marginTop: 15,
    marginLeft: 10,
    marginBottom: 8,
  },
  labelContainer: {
    marginTop: 16,
    marginBottom: 0,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 86,
    margin: '0px -24px 0px -24px',
    backgroundColor: themeColors.white,
    paddingRight: 24,
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 200,
  },
  helperText: {
    color: 'red',
    position: 'absolute',
    top: 31,
    left: '-7px',
  },
});
