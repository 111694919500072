import { configureStore as createStore } from '@reduxjs/toolkit';
import { rootReducer } from './rootReducer';

const configureStore = () => {
  const store = createStore({ reducer: rootReducer });

  return store;
};

export { configureStore };
