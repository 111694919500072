import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';

export const themeLayout = {
  footerHeight: '80px',
  headerHeight: '64px',
  sideNavWidth: 296,
  sideNavHiddenWidth: 24,
  minContainerWidthForListView: 921,
  infoCardWidth: '340px',
  mobileWidth: 500,
  hamburgerNavigationWidth: 247,
  breakpointSmall: 600,
  breakpointBig: 780,
  breakpointLarge: 1155,
};

export const themeColors = {
  mbsBlue: '#004a96',
  mbsOrange: '#f39500',
  accentDark: '#2e3237',
  accentLight: '#4f5d6a',
  systemRed: '#d74739',
  systemGreen: '#46b361',
  systemBlue: '#3e94ec',
  greyscale1: '#585b5f',
  greyscale2: '#828487',
  greyscale3: '#c0c2c3',
  greyscale4: '#eaebeb',
  greyscale5: '#f9fafb',
  white: '#ffffff',
  white16: 'rgba(255, 255, 255, 0.16)',
  white50: 'rgba(255,255,255,0.5)',
  highlightBlue: '#f0f6fd',
  greyscale4_50: 'rgba(234, 235, 235, 0.5)',
  mobileBackground: '#f2f4f6',
  systemWarning: '#FBF1E9',

  //this color is not included in the theme yet
  greyscale: '#DADADA',
  primaryNavy: '#27465B',
  greyscale6: '#585B5F',
  accentDark60: 'rgba(46, 50, 55, 0.6)',
  accentDark14: 'rgba(46, 50, 55, 0.14)',
  systemGreen20: 'rgba(70, 179, 97, 0.2)',
};

export const themeShadows = {
  //shadows
  shadowMedium: '0px 5px 15px rgba(0, 0, 0, 0.1)',
  shadowLarge: '0px 5px 20px rgba(0, 0, 0, 0.2)',
  shadowSmall: '0px 5px 10px rgba(0, 0, 0, 0.05)',
};

export const theme = createTheme({
  palette: {
    secondary: {
      main: themeColors.mbsBlue,
    },
  },
  overrides: {
    MuiCircularProgress: {
      colorPrimary: { color: themeColors.white },
      colorSecondary: { color: themeColors.mbsBlue },
    },
    MuiOutlinedInput: {
      root: {
        width: '100%',
        borderRadius: 2,
        '&:notchedOutline': {
          borderColor: 'red',
        },
        '&$focused $notchedOutline': {
          borderColor: themeColors.mbsBlue,
        },
      },
      input: {
        padding: '8px 9px',
      },
      adornedEnd: {
        paddingRight: 4,
      },
    },
    MuiSelect: {
      root: {
        '&:before': {
          borderColor: 'red',
        },
        '&:after': {
          borderColor: themeColors.greyscale3,
        },
        '&:focus': {
          backgroundColor: themeColors.white,
          borderColor: 'red',
        },
      },
      select: {
        '&:focus': {
          backgroundColor: themeColors.white,
          borderColor: 'red',
        },
      },
    },

    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: themeColors.mbsBlue,
        },
        '&$hover': {
          // Controls checked color for the thumb
          color: themeColors.mbsBlue,
        },
      },
    },

    MuiSwitch: {
      root: {
        width: 70,
      },
      thumb: {
        height: 16,
        width: 16,
      },
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: themeColors.white,
        top: 5,
        left: 4,
      },
      colorPrimary: {
        '&$checked': {
          // Controls checked color for the thumb
          transform: 'translateX(27px)',
          color: themeColors.white,
        },
        '&$disabled': {
          // Controls checked color for the track
          opacity: 1,
          color: themeColors.white,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 1,
        width: 50,
        height: 20,
        borderRadius: 10,
        backgroundColor: themeColors.greyscale2,
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: themeColors.mbsBlue,
        },
        '$checked$disabled + &': {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: themeColors.greyscale1,
        },
        '$disabled$disabled + &': {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: themeColors.greyscale3,
        },
      },
    },

    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'blue',
          fontSize: '14px',
          lineHeight: '22px',
        },
        fontSize: '14px',
        lineHeight: '22px',
        color: 'white',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '14px',
        lineHeight: '18px',
        fontFamily: '"Arial", sans-serif',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      body2: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      subtitle1: {
        fontSize: '18px',
        lineHeight: '22px',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      h1: {
        fontFamily: '"Arial", sans-serif',
        fontSize: '24px',
        lineHeight: '28px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '20px',
        lineHeight: '24px',
      },
      h3: {
        fontSize: '12px',
        lineHeight: '18px',
      },
      h4: {
        fontSize: '14px',
        lineHeight: '22px',
      },
      h5: {
        fontSize: '12px',
        lineHeight: '14px',
      },
      h6: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      caption: {
        fontSize: '59px',
        lineHeight: '75px',
      },
      button: {
        fontSize: '18px',
        lineHeight: '26px',
        textTransform: 'none',
      },
      displayBlock: {
        fontSize: '15px',
        lineHeight: '22px',
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: '#27465B !important',
      },
    },
  },
});
