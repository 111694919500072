import React, { useMemo } from 'react';
import { Pages } from './Pages';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './SectionPagination.styles';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store/types';
import { actions } from 'app/store/slices/layoutSlice';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';

interface ISectionPagination {
  count: number;
  onClick: () => void;
}
const prevPage = -1;
const nextPage = 1;
const firstPage = 1;

export const SectionPagination = ({ count, onClick }: ISectionPagination) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { page, perPage } = useSelector(
    (state: RootState) => state.layout.notificationEntitySection,
  );

  const pages = Math.ceil(count / perPage);

  const isNextButtonDisabled = useMemo(() => {
    return page >= Math.ceil(count / perPage);
  }, [count, page, perPage]);

  const isPrevButtonDisabled = useMemo(() => {
    return page === firstPage;
  }, [page]);

  const handleChange = (value: number) => {
    dispatch(
      actions.setNotificationEntityPagination({
        page: page + value,
        perPage: perPage,
      }),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.pagination}>
        <Button
          variant="pagination"
          onClick={() => {
            onClick();
            handleChange(prevPage);
          }}
          disabled={isPrevButtonDisabled}
        >
          <CustomTypography
            variant="buttonTabText"
            bold
            color={isPrevButtonDisabled ? 'greyscale2' : 'mbsBlue'}
          >
            {t(translations.pagination.prev)}
          </CustomTypography>
        </Button>

        <div className={classes.pages}>
          <Pages
            numPages={pages}
            page={page}
            perPage={perPage}
            onClick={onClick}
          />
        </div>

        <Button
          variant="pagination"
          onClick={() => {
            handleChange(nextPage);
            onClick();
          }}
          disabled={isNextButtonDisabled}
        >
          <CustomTypography
            variant="buttonTabText"
            bold
            color={isNextButtonDisabled ? 'greyscale2' : 'mbsBlue'}
          >
            {t(translations.pagination.next)}
          </CustomTypography>
        </Button>
      </div>
    </div>
  );
};
