import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IMetaData,
  IProject,
  IProjectDetails,
  ICreateProject,
  IError,
  IApiParams,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const projectsQueryKey = 'getProjects';
const projectQueryKey = 'getProject';

const oneElement = 1;
const emptyArrayLength = 0;

export function useProjectsApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const queryClient = useQueryClient();
  const { supplierId } = useAuth();
  const [lastTicketDispatchDateTimeUtc, setLastTicketDispatchDateTimeUtc] =
    useState<string | undefined>('');

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getProjectsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/project/search-projects`;
  }, [currentSupplierId]);

  const getProjectUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Project/get-project`;
  }, [currentSupplierId]);

  const createProjectUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Project/create-project`;
  }, [currentSupplierId]);

  const deleteProjectUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Project/delete-project`;
  }, [currentSupplierId]);

  const updateProjectUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Project/update-project`;
  }, [currentSupplierId]);

  const {
    data: projectsResponse,
    refetch: fetchProjects,
    isLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
    error: fetchProjectsError,
  } = useQuery<IResponseData<IProject[]>, IError>(
    [projectsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getProjectsUrl}`, {
        queryParameters: {
          ...queryParameters,
          lastElementDateTimeUtc:
            queryParameters?.skip !== emptyArrayLength.toString()
              ? lastTicketDispatchDateTimeUtc
              : '',
        },
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getProjectsList, error, {
          getProjectsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: deleteProjectResponse,
    refetch: deleteProject,
    isLoading: isProjectDeleting,
    error: deleteProjectError,
  } = useQuery<IResponseData<boolean>, IError>(
    [projectQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${deleteProjectUrl}`, {
        queryParameters,
        method: 'DELETE',
      });
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.removeProject, error, {
          deleteProjectUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: projectResponse,
    refetch: fetchProject,
    isLoading: isFetchingProject,
    error: fetchProjectError,
  } = useQuery<IResponseData<IProjectDetails>, IError>(
    [projectQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getProjectUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getProjectDetailed, error, {
          getProjectUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: createProject,
    isLoading: isProjectCreating,
    isSuccess: isProjectSuccess,
    error: createProjectError,
  } = useMutation(
    (payload: ICreateProject) =>
      authClient(createProjectUrl, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(projectQueryKey);
        return queryClient.getQueryData<IResponseData<IProjectDetails>>(
          projectQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IProjectDetails>>(
            projectQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.createProject, error, {
          createProjectUrl,
          projectRef: variables.projectRef,
          clientRef: variables.clientRef,
          projectId: variables.projectId,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(projectQueryKey);
      },
    },
  );

  const {
    mutateAsync: updateProject,
    isLoading: isProjectUpdating,
    error: mutateProjectError,
  } = useMutation(
    (payload: ICreateProject) =>
      authClient(updateProjectUrl, { method: 'PATCH', data: payload }),
    {
      retry: false,
      onMutate: async (payload: ICreateProject) => {
        await queryClient.cancelQueries(projectQueryKey);
        const previousProjectData =
          queryClient.getQueryData<IResponseData<IProjectDetails>>(
            projectQueryKey,
          );

        if (previousProjectData) {
          const newData: IResponseData<IProjectDetails> = {
            ...previousProjectData,
          };

          newData.data = {
            ...newData.data,
            ...payload,
          };

          queryClient.setQueryData<IResponseData<IProjectDetails>>(
            projectQueryKey,
            newData,
          );
        }

        return { previousProject: previousProjectData };
      },
      onError: (error: IError, variables, context) => {
        if (context?.previousProject) {
          queryClient.setQueryData<IResponseData<IProjectDetails>>(
            projectQueryKey,
            context.previousProject,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.updateProject, error, {
          updateProjectUrl,
          projectRef: variables.projectRef,
          clientRef: variables.clientRef,
          projectId: variables.projectId,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(projectQueryKey);
      },
    },
  );

  const projects = useMemo<IProject[] | undefined>(() => {
    setLastTicketDispatchDateTimeUtc(
      projectsResponse?.data[projectsResponse?.data.length - oneElement]
        ?.lastTicketDispatchDateTimeUtc || undefined,
    );

    return projectsResponse?.data;
  }, [projectsResponse]);

  const project = useMemo<IProjectDetails | undefined>(
    () => projectResponse?.data,
    [projectResponse],
  );

  const projectsMetadata = useMemo<IMetaData | undefined>(
    () => projectsResponse?.metadata,
    [projectsResponse],
  );

  return {
    projectsMetadata,
    projects,
    project,
    projectResponse,
    projectsResponse,
    fetchProjects,
    fetchProject,
    isFetchingProject,
    isLoadingProjects,
    isFetchingProjects,
    createProject,
    isProjectCreating,
    updateProject,
    isProjectUpdating,
    deleteProject,
    isProjectDeleting,
    deleteProjectResponse,
    fetchProjectsError,
    fetchProjectError,
    createProjectError,
    mutateProjectError,
    deleteProjectError,
    isProjectSuccess,
  };
}
