import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '0px 30px 160px 30px',
  },
  header: {
    marginBottom: '24px',
    display: 'flex',
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerText: {
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      marginBottom: 20,
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  subheader: {
    margin: '32px 0px 10px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  organizations: {
    marginTop: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  progress: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
