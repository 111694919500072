import { PopoverSize, Select } from 'app/shared/components/generic-ui';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useNotificationEntityApi } from 'app/shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationEntityType } from 'types';
import { useStyles } from './EntitiesDropdown.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useAuth } from 'app/shared/providers';

interface selectedEntitiesIds {
  plantExternalIds: string[];
  mixDesignExternalIds: string[];
  vehicleExternalIds: string[];
  driverExternalIds: string[];
  customerExternalIds: string[];
  pourEventGIDs: string[];
  projectExternalIds: string[];
}

interface IEntitiesDropdownProps {
  label: string;
  entityType: NotificationEntityType;
  selectedValues?: string[];
  isCrossmatching?: boolean;
  selectedItems?: {
    id: string;
    label: string;
  }[];
  selectedEntitiesIds: selectedEntitiesIds;
  onChangeSelectedEntities: (
    values: string[],
    items?: {
      id: string;
      label: string;
      additionalId?: string;
    }[],
  ) => void;
}

const dropdownPerPage = 50;
const arrayOffset = 1;

export const EntitiesDropdown = ({
  label,
  entityType,
  selectedItems,
  selectedValues,
  selectedEntitiesIds,
  onChangeSelectedEntities,
  isCrossmatching,
}: IEntitiesDropdownProps) => {
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      id: 'externalId',
      label: 'ID',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'name',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
    };
  }, [entityType, organizationId]);

  const {
    fetchRelatedNotificationEntities,
    relatedNotificationEntities,
    isFetchingRelatedNotificationEntities,
    resetRelatedNotificationEntitiesQuery,
    hasMoreRelatedEntities,
  } = useNotificationEntityApi(queryParams);

  const preparedParams = useMemo(() => {
    switch (entityType) {
      case NotificationEntityType.PLANT: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.plantExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.CUSTOMER: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.customerExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.MIX_DESIGN: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.mixDesignExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.VEHICLE: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.vehicleExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.DRIVER: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.driverExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.PROJECT: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.projectExternalIds;
        return selectedEntitiesIdsCopy;
      }
      case NotificationEntityType.POUR_EVENT: {
        const selectedEntitiesIdsCopy = {
          ...selectedEntitiesIds,
        } as Partial<selectedEntitiesIds>;
        delete selectedEntitiesIdsCopy.pourEventGIDs;
        return selectedEntitiesIdsCopy;
      }
      default:
        return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, JSON.stringify(selectedEntitiesIds)]);
  const { userId } = useAuth();

  const payload = useMemo(() => {
    const params = {
      query: searchQuery,
      take: dropdownPerPage,
      lastElementDateTimeUtc: '',
      userId,
    } as {
      query: string;
      take: number;
      lastElementDateTimeUtc: string;
      userId?: string;
    };
    if (isCrossmatching) delete params.userId;

    return {
      ...params,
      ...preparedParams,
    };
  }, [isCrossmatching, preparedParams, searchQuery, userId]);

  const renderCell = useCallback((rowData: any, cellId: string) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{rowData[cellId]}</div>;
  }, []);

  const loadMoreHandler = useCallback(() => {
    fetchRelatedNotificationEntities({
      ...payload,
      lastElementDateTimeUtc:
        relatedNotificationEntities[
          relatedNotificationEntities.length - arrayOffset
        ].lastTicketDispatchDateTimeUtc,
    });
  }, [fetchRelatedNotificationEntities, payload, relatedNotificationEntities]);

  useEffect(() => {
    if (relatedNotificationEntities.length || searchQuery) {
      resetRelatedNotificationEntitiesQuery().then(() => {
        fetchRelatedNotificationEntities(payload);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRelatedNotificationEntities, searchQuery]);

  const changeSelectedValuesHandler = useCallback(
    values => {
      const currentItems = values.map((id: string) => {
        const element = relatedNotificationEntities.find(el =>
          entityType === NotificationEntityType.POUR_EVENT
            ? el.internalId === id
            : el.externalId === id,
        );

        return element
          ? {
              id: element?.externalId || '',
              label: element?.name || '',
              additionalId: element?.internalId,
            }
          : selectedItems?.find(el => el.id === id) || {
              id: '',
              label: '',
            };
      });

      onChangeSelectedEntities(values, currentItems);
    },
    [
      entityType,
      onChangeSelectedEntities,
      relatedNotificationEntities,
      selectedItems,
    ],
  );

  return (
    <div className={classes.selectContainer}>
      <CustomTypography variant="caption1" color="greyscale1">
        {label}
      </CustomTypography>

      <Select.Search
        searchQuery={searchQuery}
        isLoading={isFetchingRelatedNotificationEntities}
        valueId={
          entityType === NotificationEntityType.POUR_EVENT
            ? 'internalId'
            : 'externalId'
        }
        labelId={'name'}
        options={relatedNotificationEntities}
        selectedValues={selectedValues}
        selectedItems={selectedItems}
        onChangeSelectedValues={changeSelectedValuesHandler}
        showLoader={hasMoreRelatedEntities}
        loadMoreHandler={loadMoreHandler}
        withPagination={true}
        onSearchHandler={value => setSearchQuery(value)}
        popoverSize={PopoverSize.BIG}
        renderCell={renderCell}
        columns={columns}
        clickHandler={() => {
          resetRelatedNotificationEntitiesQuery().then(() => {
            fetchRelatedNotificationEntities(payload);
          });
        }}
      />
    </div>
  );
};
