import {
  useNotificationSettingsEntityApi,
  useNotificationSettingsGroupApi,
} from 'app/shared/hooks';
import { useAuth, useNotificationSettings } from 'app/shared/providers';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationEntityType } from 'types';
import {
  getNotificationsDefaultTab,
  sortInAlphabeticalOrder,
} from 'app/shared/utils';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { UserNotificationCard } from './UserNotificationCard';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

const lower = -1;
const bigger = 1;
const equal = 0;
const firstSymbolPosition = 0;
const secondSymbolPosition = 1;

interface IUserNotificationSettingsProps {
  isUnAssigningUser: boolean;
}

export const UserNotificationSettings = ({
  isUnAssigningUser,
}: IUserNotificationSettingsProps) => {
  const { t } = useTranslation();
  const { selectedEntity, entityType } = useNotificationSettings();
  const { userId } = useAuth();
  const { organizationId, tab }: { organizationId: string; tab: string } =
    useParams();
  const eventStatusCode = new URLSearchParams(location.search).get('code');

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        userId: userId,
        notificationEntityId:
          entityType === NotificationEntityType.ORGANIZATION
            ? organizationId
            : selectedEntity?.internalId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.internalId, userId]);

  const groupQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId,
        userId: userId,
      },
    };
  }, [
    organizationId,
    entityType,
    selectedEntity?.notificationEntityGroupId,
    userId,
  ]);

  const {
    fetchUserNotificationSettings,
    userNotificationSettings,
    changeUsersStatusNotificationEntity,
  } = useNotificationSettingsEntityApi(queryParams);

  const {
    changeUsersStatusNotificationGroup,
    fetchUserGroupNotificationSettings,
    userGroupNotificationSettings,
  } = useNotificationSettingsGroupApi(groupQueryParams);

  useEffect(() => {
    if (!isUnAssigningUser) {
      if (selectedEntity?.notificationEntityGroupId) {
        fetchUserGroupNotificationSettings();
      } else {
        fetchUserNotificationSettings();
      }
    }
  }, [
    fetchUserNotificationSettings,
    queryParams,
    groupQueryParams,
    selectedEntity?.notificationEntityGroupId,
    fetchUserGroupNotificationSettings,
    isUnAssigningUser,
  ]);

  const currentNotifications = useMemo(() => {
    return selectedEntity?.notificationEntityGroupId
      ? userGroupNotificationSettings?.notifications
      : userNotificationSettings?.notifications;
  }, [
    selectedEntity?.notificationEntityGroupId,
    userGroupNotificationSettings?.notifications,
    userNotificationSettings?.notifications,
  ]);

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  const notificationsCategories = useMemo(() => {
    let categories: string[] = [];
    currentNotifications?.forEach(notification => {
      if (
        !categories.includes(notification.description) &&
        notification.description
      ) {
        categories = [...categories, notification.description];
      }
    });

    const finalCategories = categories.map(category => {
      return category
        .split(' ')
        .map(
          el =>
            el.charAt(firstSymbolPosition).toUpperCase() +
            el.slice(secondSymbolPosition, el.length),
        )
        .join(' ');
    });

    return finalCategories.sort((firstCategory, secondCategory) =>
      firstCategory !== secondCategory
        ? firstCategory < secondCategory
          ? lower
          : bigger
        : equal,
    );
  }, [currentNotifications]);

  const sortedNotifications = useMemo(() => {
    return currentNotifications
      ? sortInAlphabeticalOrder(
          'asc',
          currentNotifications,
          'notificationTypeId',
        )
      : [];
  }, [currentNotifications]);

  const renderNotifications = useCallback(
    (category: string) => {
      return sortedNotifications?.filter(
        notification => notification.description === category.toLowerCase(),
      );
    },
    [sortedNotifications],
  );

  const changeAllNotificationStatusHandler = useCallback(
    (category: string, isAssigned: boolean) => {
      const payload = currentNotifications
        ?.filter(
          notification =>
            notification.description === category.toLowerCase() &&
            notification.active,
        )
        .map(notification => ({
          notificationTypeId: notification.notificationTypeId,
          isAssigned: isAssigned,
          userId: userId,
        }));

      if (selectedEntity?.notificationEntityGroupId) {
        payload &&
          changeUsersStatusNotificationGroup({ users: payload }).then(() =>
            fetchUserGroupNotificationSettings(),
          );
      } else {
        payload &&
          changeUsersStatusNotificationEntity({ users: payload }).then(() =>
            fetchUserNotificationSettings(),
          );
      }
    },
    [
      currentNotifications,
      selectedEntity?.notificationEntityGroupId,
      userId,
      changeUsersStatusNotificationGroup,
      fetchUserGroupNotificationSettings,
      changeUsersStatusNotificationEntity,
      fetchUserNotificationSettings,
    ],
  );

  const buttonsHandler = useCallback(
    (category: string) => {
      return [
        {
          value: t(translations.notificationSettingsPage.activateAll),
          onClickHandler: () => {
            changeAllNotificationStatusHandler(category, true);
          },
        },
        {
          value: t(translations.notificationSettingsPage.deactivateAll),
          onClickHandler: () => {
            changeAllNotificationStatusHandler(category, false);
          },
        },
      ];
    },
    [changeAllNotificationStatusHandler, t],
  );

  const changeNotificationStatusHandler = useCallback(
    (notificationTypeId: string, isAssigned: boolean) => {
      if (!selectedEntity?.notificationEntityGroupId) {
        changeUsersStatusNotificationEntity({
          users: [
            {
              notificationTypeId: notificationTypeId,
              isAssigned: isAssigned,
              userId: userId,
            },
          ],
        }).then(() => fetchUserNotificationSettings());
      } else {
        changeUsersStatusNotificationGroup({
          users: [
            {
              notificationTypeId: notificationTypeId,
              isAssigned: isAssigned,
              userId: userId,
            },
          ],
        }).then(() => fetchUserGroupNotificationSettings());
      }
    },
    [
      changeUsersStatusNotificationEntity,
      changeUsersStatusNotificationGroup,
      fetchUserGroupNotificationSettings,
      fetchUserNotificationSettings,
      selectedEntity?.notificationEntityGroupId,
      userId,
    ],
  );

  const currentTab = useMemo(() => {
    const defaultTab = getNotificationsDefaultTab(tab);
    const targetNotificationDescription = sortedNotifications.find(
      notification => notification.notificationCode === eventStatusCode,
    )?.description;

    return eventStatusCode
      ? notificationsCategories.find(
          category => category.toLowerCase() === targetNotificationDescription,
        )
      : defaultTab;
  }, [eventStatusCode, notificationsCategories, sortedNotifications, tab]);

  return (
    <SectionSpoiler
      header={t(translations.notificationSettingsPage.notificationsAndTeam, {
        entity: entityName,
      })}
      withIcons={false}
    >
      {notificationsCategories.map(category => (
        <div key={category} style={{ marginBottom: 0 }}>
          <SectionSpoiler
            isOpenState={currentTab === category}
            header={category}
            withBackground={true}
            isFlex
            margins={16}
            subHeaders={buttonsHandler(category)}
          >
            {renderNotifications(category)?.map(notification => (
              <UserNotificationCard
                key={notification.supplierNotificationId}
                notification={notification}
                changeNotificationStatusHandler={
                  changeNotificationStatusHandler
                }
              />
            ))}
          </SectionSpoiler>
        </div>
      ))}
    </SectionSpoiler>
  );
};
