import { INotification } from 'types';
import { Popover } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './UserNotificationCard.styles';

export enum PopoverType {
  INFO,
  MIX_ID,
}

interface INotificationPopoverProps {
  notification: INotification;
  open: boolean;
  anchor: HTMLElement | null;
  type?: PopoverType;
}

export const NotificationPopover = ({
  open,
  anchor,
}: INotificationPopoverProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      className={classes.popover}
      disableRestoreFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.body}>
        <CustomTypography variant="caption1" color="greyscale1">
          {t(translations.notificationSettingsPage.notificationIsDisabled)}
        </CustomTypography>
      </div>
    </Popover>
  );
};
