import { useCallback, useEffect, useMemo, useState } from 'react';
import { themeLayout } from '../../theme';

export const useScreen = (alternativeScreenWidth?: number) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const mobileScreenWidth = useMemo(() => {
    return alternativeScreenWidth || themeLayout.mobileWidth;
  }, [alternativeScreenWidth]);

  const isMobile = useMemo(() => {
    return innerWidth < mobileScreenWidth;
  }, [innerWidth, mobileScreenWidth]);

  const onSizeChange = useCallback(() => {
    const myWidth = window.innerWidth;
    const myHeight = window.innerHeight;

    setInnerWidth(myWidth);
    setInnerHeight(myHeight);
  }, []);

  useEffect(() => {
    window.onresize = onSizeChange;
    window.onload = onSizeChange;

    onSizeChange();
  }, [onSizeChange]);

  return {
    isMobile: isMobile,
    screenWidth: innerWidth,
    screenHeight: innerHeight,
  };
};
