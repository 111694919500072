import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  searchContainer: {
    height: 44,
    maxWidth: 666,
    boxShadow: themeShadows.shadowSmall,
    backgroundColor: themeColors.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',

    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 666,
    },
  },
  withBorder: {
    height: 44,
    maxWidth: 666,
    border: `1px solid ${themeColors.greyscale3}`,
    backgroundColor: themeColors.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',

    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 666,
    },
  },
  withoutButton: {
    height: 44,
    maxWidth: 441,
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.greyscale3}`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',

    width: '100%',
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      width: 425,
    },
  },
  small: {
    height: 38,
    maxWidth: 441,
    backgroundColor: themeColors.white,
    border: `1px solid ${themeColors.greyscale3}`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',

    width: '100%',
    [theme.breakpoints.up(themeLayout.breakpointLarge)]: {
      width: 425,
    },
  },
  secondarySearchContainer: {
    height: 38,
    maxWidth: '100%',
    backgroundColor: themeColors.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    alignItems: 'center',
    boxSizing: 'border-box',
    border: `1px solid ${themeColors.greyscale3}`,
  },
  searchIcon: {
    height: 28,
    width: 28,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    boxSizing: 'border-box',
    backgroundColor: themeColors.mbsBlue,
    cursor: 'pointer',
    borderRadius: 2,
    border: 'none',

    '&:disabled': {
      color: themeColors.white,
      boxSizing: 'border-box',
      height: 28,
      width: 28,
      border: `1px solid ${themeColors.greyscale3}`,
      borderRadius: '2px',
      backgroundColor: themeColors.greyscale3,
      cursor: 'not-allowed',
    },
  },
  tableContainer: {
    width: '100%',
    marginTop: 6,
    zIndex: 9000,
    position: 'absolute',
    top: 50,
    left: 0,
    boxShadow: themeShadows.shadowLarge,
    backgroundColor: themeColors.white,
    borderRadius: 2,
    boxSizing: 'border-box',
    padding: '2px 0px',
    overflowY: 'auto',
    maxHeight: 510,

    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  emptyContainer: {
    width: '100%',
    zIndex: 2000,
    position: 'absolute',
    top: 50,
    left: 0,
    boxShadow: themeShadows.shadowLarge,
    backgroundColor: themeColors.white,
    borderRadius: 2,
    boxSizing: 'border-box',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    width: '100%',
  },
  root: {
    width: 17,
  },
  circular: {
    color: themeColors.mbsBlue,
  },
  cellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 250,
  },
  nameWithCheckbox: {
    marginLeft: '-20px',
  },
}));
