import { useStyles } from './ConfirmationPage.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

export interface IConfirmationPage {
  backHandler: () => void;
  confirmHandler: () => void;
  eventName: string;
}

export const ConfirmationPage = ({
  eventName,
  backHandler,
  confirmHandler,
}: IConfirmationPage) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <div className={classes.text}>
            <CustomTypography variant="header6" bold color="accentDark">
              {t(translations.batchDetailsModal.markingBatch)}
            </CustomTypography>
          </div>

          <CustomTypography variant="subtitle1" color="accentDark">
            {t(translations.batchDetailsModal.areYouSure, { event: eventName })}
          </CustomTypography>
        </div>
      </div>

      <div className={classes.button}>
        <Button variant="primaryCancel" onClick={backHandler}>
          <CustomTypography variant="buttonText" bold color="accentLight">
            {t(translations.batchDetailsModal.cancel)}
          </CustomTypography>
        </Button>

        <Button variant="primarySave" onClick={confirmHandler}>
          <CustomTypography variant="buttonText" bold color="white">
            {t(translations.batchDetailsModal.confirm)}
          </CustomTypography>
        </Button>
      </div>
    </div>
  );
};
