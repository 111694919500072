import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { useAuth } from 'app/shared/providers';
import { PageHeader } from 'app/shared/components/generic-ui/PageHeader';
import { RoleAssignments } from 'app/shared/components/generic-ui/RoleAssignments';
import { firebaseAnalytics } from 'app/shared/analytics';
import { IProjectMemberRequest, Routes } from 'types';
import { IUsersAssigned } from '../organizationDetails/OrganizationTeamAssignments';
import { useOwnProjectsApi } from 'app/shared/hooks/useOwnProjectsApi';
import { useProjectMembersApi } from 'app/shared/hooks';
import { useRoleApi } from 'app/shared/hooks/useRoleApi';
import { useSearchHelper } from 'app/shared/utils';
import { useParams } from 'react-router-dom';

export const ProjectRoleAssignments = () => {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const { id, organizationId }: { id: string; organizationId: string } =
    useParams();
  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
    };
  }, [organizationId]);

  const projectMembersQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        projectId: id,
        query: prepareSearchQueryParameter(searchQuery),
      },
    };
  }, [id, organizationId, prepareSearchQueryParameter, searchQuery]);

  const { fetchRoles, roles } = useRoleApi(organizationId);

  const { deleteProjectMember, addProjectMember } =
    useProjectMembersApi(queryParams);

  const { fetchProjectMembers, projectMembers, isFetchingProjectMembers } =
    useProjectMembersApi(projectMembersQueryParams);

  const { fetchOwnProjects } = useOwnProjectsApi();

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    fetchProjectMembers();
    fetchOwnProjects();
  }, [fetchOwnProjects, fetchProjectMembers, searchQuery]);

  const preparedUsersAssigned = useMemo(() => {
    let usersAssigned: IUsersAssigned = {};

    roles?.map(el => {
      usersAssigned = { ...usersAssigned, [el.roleName]: [] };
      projectMembers?.forEach(user => {
        if (user.roleNames.includes(el.roleName)) {
          usersAssigned[el.roleName].push(user);
        }
      });
    });

    return usersAssigned;
  }, [projectMembers, roles]);

  const addMembersHandler = useCallback(
    async (members: IProjectMemberRequest[]) => {
      setIsLoading(true);

      firebaseAnalytics.logPressEvent(
        Routes.ProjectRoles,
        'Team assignments card add teammate button',
      );

      await addProjectMember(members).catch(() => setIsLoading(false));
      members.find(member => member.userId === userId) && fetchOwnProjects();
      fetchProjectMembers().then(() => setIsLoading(false));
    },
    [addProjectMember, fetchOwnProjects, fetchProjectMembers, userId],
  );

  const removeMemberHandler = useCallback(
    async user => {
      firebaseAnalytics.logPressEvent(
        Routes.ProjectRoles,
        'Team assignments card remove teammate',
      );

      setIsLoading(true);

      await deleteProjectMember(user).catch(() => setIsLoading(false));
      userId === user?.userId && (await fetchOwnProjects());
      fetchProjectMembers().then(() => setIsLoading(false));
    },
    [deleteProjectMember, fetchOwnProjects, fetchProjectMembers, userId],
  );

  return (
    <PageHeader
      header={t(translations.projectTeamPage.projectTeam)}
      withActions={false}
    >
      <RoleAssignments
        roles={roles}
        members={projectMembers}
        usersAssigned={preparedUsersAssigned}
        header={t(translations.organizationDetailsPage.teamAssignments)}
        modalHeader={t(translations.organizationDetailsPage.addTeammates)}
        addMembersHandler={addMembersHandler}
        deleteMemberHandler={removeMemberHandler}
        isLoading={isLoading}
        isFetchingProjectMembers={isFetchingProjectMembers}
      />
    </PageHeader>
  );
};
