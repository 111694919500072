import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '22px 24px 0px 24px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    paddingBottom: 38,
  },
  label: {
    padding: '24px 10px 8px 10px',
  },
  input: {
    padding: '8px 10px',
  },
  root: {
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    margin: 0,
    height: 38,
  },

  focused: {
    borderColor: themeColors.white,
  },
  contained: {
    marginLeft: '10px',
    color: themeColors.greyscale2,
  },
});
