import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IProjectDetails,
  IProjectMemberRequest,
  IError,
  IOrganizationRoles,
  IApiParams,
} from 'types';

import { useAuthClient } from './useAuthClient';
import { useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const organizationTeamQueryKey = 'organizationTeam';

export function useOrganizationTeamApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();

  const addUserToRoleURL = useMemo(() => {
    return `/api/v1/${organizationId}/OrganizationRole/add-users-to-role`;
  }, [organizationId]);

  const getOrganizationRolesURL = useMemo(() => {
    return `/api/v1/${organizationId}/OrganizationRole/get-organization-roles`;
  }, [organizationId]);

  const removeUserFromRoleURL = useMemo(() => {
    return `/api/v1/${organizationId}/OrganizationRole/remove-user-from-role`;
  }, [organizationId]);

  const {
    data: organizationRolesResponse,
    refetch: fetchOrganizationRoles,
    isFetching: isFetchingOrganizationRoles,
    error: fetchOrganizationRolesError,
  } = useQuery<IResponseData<IOrganizationRoles[]>, IError>(
    [organizationTeamQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getOrganizationRolesURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getOrganizationRolesList,
          error,
          {
            getOrganizationRolesURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: addUsersToRole,
    isLoading: isAddingUsersToRole,
    error: addUsersToRoleError,
  } = useMutation(
    (payload: IProjectMemberRequest[]) =>
      authClient(addUserToRoleURL, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(organizationTeamQueryKey);
        return queryClient.getQueryData<IResponseData<IProjectDetails>>(
          organizationTeamQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IProjectDetails>>(
            organizationTeamQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.addUserToOrganizationRole,
          error,
          {
            addUserToRoleURL,
            variables: JSON.stringify(variables),
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationTeamQueryKey);
      },
    },
  );

  const {
    mutateAsync: removeUserFromRole,
    isLoading: isRemovingUserFromRole,
    error: removeUserFromRoleError,
  } = useMutation(
    (payload: IProjectMemberRequest) =>
      authClient(removeUserFromRoleURL, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(organizationTeamQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          organizationTeamQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            organizationTeamQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.removeUserFromOrganizationRole,
          error,
          {
            deleteProjectMembersUrl: removeUserFromRoleURL,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(organizationTeamQueryKey);
      },
    },
  );

  const organizationRoles = useMemo<IOrganizationRoles[] | undefined>(
    () => organizationRolesResponse?.data,
    [organizationRolesResponse],
  );
  return {
    organizationRoles,
    fetchOrganizationRoles,
    fetchOrganizationRolesError,
    isFetchingOrganizationRoles,
    addUsersToRole,
    isAddingUsersToRole,
    addUsersToRoleError,
    isRemovingUserFromRole,
    removeUserFromRole,
    removeUserFromRoleError,
  };
}
