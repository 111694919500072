import { makeStyles, TextField, withStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    marginTop: `-${themeLayout.headerHeight}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  body: {
    paddingTop: 74,
  },
  bodyInvitedModal: {
    padding: '84px 26px 0px 26px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  formModal: {
    width: 780,
    height: 430,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
      height: 450,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
      height: 460,
    },
    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
  small: {
    width: 780,
    height: 500,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
      height: 500,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
      height: 500,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
  alternative: {
    width: 780,
    height: 255,
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
      height: 261,
    },
    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
      height: 261,
    },

    position: 'relative',
    backgroundColor: themeColors.white,
    outline: 'none !important',
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '20px',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    height: 86,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '26px 27px 22px 24px',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
    position: 'fixed',
    boxSizing: 'border-box',
    backgroundColor: themeColors.white,
    zIndex: 200,
    [theme.breakpoints.down('sm')]: {
      width: 375,
    },

    width: '780px',
  },
  closeButton: {
    cursor: 'pointer',
    zIndex: 201,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 24px 0px 24px',
    paddingBottom: 28,
    boxSizing: 'border-box',
  },
  label: {
    padding: '18px 0px 20px 0px',
  },
  labelInvited: {
    textAlign: 'center',
    padding: '18px 0px 0px',
  },
  buttonInviteNew: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
  },

  input: {
    padding: '11px 10px',
    fontSize: '15px',
    lineHeight: '22px',
    [theme.breakpoints.up('sm')]: {
      width: 525,
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  root: {
    '&.Mui-focused ': {
      borderColor: themeColors.white,
    },
    margin: 0,
    height: 38,
  },

  focused: {
    borderColor: themeColors.white,
  },
  contained: {
    marginLeft: '10px',
    color: themeColors.greyscale2,
  },
  inputContainer: {
    display: 'flex',

    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    flexDirection: 'row',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '125px',
    [theme.breakpoints.down('sm')]: {
      height: '218px',
    },
  },
}));

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: themeColors.greyscale3,
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: 3,
      '& fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&:hover fieldset': {
        borderColor: themeColors.greyscale3,
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColors.systemBlue,
      },
      '&.MuiFormHelperText-root ': {
        marginLeft: '-10px',
      },
      input: {
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: 'blue',
        },
      },
    },
  },
})(TextField);
