import { useStyles } from './SuccessPage.styles';
import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

export interface ISuccessPage {
  backHandler: () => void;
  buttonText: string;
}

export const SuccessPage = ({ buttonText, backHandler }: ISuccessPage) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.icon}>
          <SuccessIcon />
        </div>

        <div className={classes.textWrapper}>
          <div className={classes.text}>
            <CustomTypography variant="header6" bold color="accentDark">
              {t(translations.batchDetailsModal.success)}
            </CustomTypography>
          </div>

          <CustomTypography variant="subtitle1" color="accentDark">
            {t(translations.batchDetailsModal.yourManualTestData)}
          </CustomTypography>
        </div>
      </div>
      <div className={classes.button}>
        <Button variant="primarySave" onClick={backHandler} type="button">
          <CustomTypography variant="buttonText" bold color="white">
            {buttonText}
          </CustomTypography>
        </Button>
      </div>
    </div>
  );
};
