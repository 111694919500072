import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IResponseData, IError, IApiParams } from 'types';

import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const unassignUserQueryKey = 'unassignUsers';

export function useUnassignUserApi({ organizationId }: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId, userId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const unassignUserUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Users/disable-all-user-notifications`;
  }, [currentSupplierId]);

  const {
    data: unassignUserResponse,
    refetch: unassignUser,
    isFetching: isUnAssigningUser,
    error: unassignUserError,
  } = useQuery<IResponseData<boolean>, IError>(
    unassignUserQueryKey,
    async () => {
      return await authClient(`${unassignUserUrl}`, {
        queryParameters: {
          userId,
        },
        method: 'DELETE',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: IError) => {
        firebaseAnalytics.logErrorEvent(errors.unassignUser, error, {
          unassignUserUrl,
          userId,
        });
        openModal(error);
      },
    },
  );

  return {
    unassignUserResponse,
    unassignUser,
    isUnAssigningUser,
    unassignUserError,
  };
}
