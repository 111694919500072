/* eslint-disable no-magic-numbers */
import {
  Checkbox,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@material-ui/core';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { InputSize, TextInput } from '../TextInput/TextInput';
import { CustomTypography } from '../Typography/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as ArrowUp } from 'assets/arrow_drop_up.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow_drop_down.svg';
import { CircularProgress } from '../CircularProgress';
import { useStyles } from './SelectWithSearch.styles';
import { IMenuProps, PopoverSize } from './types';
import { TableHeaderType } from '../Table/Table.types';
import { Table } from '../Table/Table';
import { useScreen } from 'app/shared/hooks';

const timeout = 1000;
const marginHeight = -1;

export const SelectWithSearch = ({
  labelId,
  valueId,
  options,
  popoverSize = PopoverSize.SMALL,
  withPagination = false,
  onChangeSelectedValues,
  selectedValues,
  selectedItems,
  isLoading = false,
  showLoader,
  onSearchHandler,
  loadMoreHandler,
  customValueHandler,
  isMultiSelect = true,
  name,
  helperText,
  control,
  error,
  onChangeSelectedValue,
  className,
  popoverClassName,
  searchQuery,
  columns,
  renderCell,
  clickHandler,
}: IMenuProps) => {
  const classes = useStyles();
  const { isMobile } = useScreen();
  const [search, setSearch] = useState(searchQuery || '');
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [isBottom, setIsBottom] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);

  const open = Boolean(anchorEl);

  const closeHandler = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAnchorEl(event.currentTarget);
      clickHandler && clickHandler();
    },
    [clickHandler],
  );

  const filteredOptions = useMemo(() => {
    return onSearchHandler || !search || !isMultiSelect
      ? options
      : options?.filter((option: any) =>
          labelId ? option[labelId]?.includes(search) : option.includes(search),
        );
  }, [isMultiSelect, labelId, onSearchHandler, options, search]);

  const deleteHandler = useCallback(() => {
    setSearch('');
    onChangeSelectedValue && onChangeSelectedValue('');
  }, [onChangeSelectedValue]);

  const icons = useMemo(() => {
    return [
      {
        icon: search ? (
          <CloseIcon
            height={12}
            stroke={themeColors.greyscale3}
            strokeWidth={3}
            className={classes.deleteIcon}
          />
        ) : null,
        action: deleteHandler,
      },
      {
        icon: open ? (
          <ArrowUp height={50} fill={themeColors.greyscale1} />
        ) : (
          <ArrowDown height={50} fill={themeColors.greyscale1} />
        ),
      },
    ];
  }, [classes.deleteIcon, deleteHandler, open, search]);

  const clickMenuItemHandler = useCallback(
    (option: any) => {
      const values =
        selectedValues &&
        selectedValues?.find(
          (el: string) => el === (valueId ? option[valueId] : option),
        )
          ? selectedValues.filter(
              (el: string) => el !== (valueId ? option[valueId] : option),
            )
          : [...(selectedValues || []), valueId ? option[valueId] : option];

      const labels =
        labelId && valueId
          ? selectedItems &&
            selectedItems?.find(el => el.id === option[valueId])
            ? selectedItems.filter(el => el.id !== option[valueId])
            : [
                ...(selectedItems || []),
                { id: option[valueId], label: option[labelId] },
              ]
          : undefined;

      onChangeSelectedValues && onChangeSelectedValues(values, labels);

      if (!isMultiSelect) {
        setSearch(labelId ? option[labelId] : option);
        onChangeSelectedValue &&
          onChangeSelectedValue(valueId ? option[valueId] : option);
        closeHandler();
      }
    },
    [
      closeHandler,
      isMultiSelect,
      labelId,
      onChangeSelectedValue,
      onChangeSelectedValues,
      selectedItems,
      selectedValues,
      valueId,
    ],
  );
  const onScroll = useCallback((e: any) => {
    const bottom =
      e?.target?.scrollHeight - Math.ceil(e?.target?.scrollTop) ===
      e?.target?.clientHeight;

    if (bottom) {
      setTimeout(() => {
        setIsBottom(true);
      }, timeout);
    }
  }, []);

  useEffect(() => {
    if (isBottom) {
      listInnerRef.current?.scrollBy({ top: marginHeight });
      setIsBottom(false);
      showLoader && loadMoreHandler && loadMoreHandler();
    }
  }, [isBottom, loadMoreHandler, showLoader]);

  const onChangeHandler = useCallback(
    e => {
      customValueHandler && customValueHandler(e?.target?.value);
      onSearchHandler && onSearchHandler(e?.target?.value || '');
      !onSearchHandler && setSearch(e?.target?.value || '');
    },
    [customValueHandler, onSearchHandler],
  );

  useEffect(() => {
    if (!isMultiSelect) {
      setSearch(searchQuery || '');
    }
  }, [isMultiSelect, labelId, searchQuery, selectedValues]);

  return (
    <div className={className || classes.container}>
      <TextInput
        name={name || ''}
        helperText={helperText}
        error={error}
        currentValue={search}
        onClickHandler={onClickHandler}
        customSize={InputSize.BIG}
        onChangeHandler={onChangeHandler}
        icons={icons}
        control={control}
        autoComplete="off"
        placeholder="Type or Select"
        borderRadius={2}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        className={
          popoverSize === PopoverSize.SMALL || isMobile
            ? classes.marginsSmall
            : classes.marginsBig
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper
          onScroll={onScroll}
          ref={listInnerRef}
          className={
            popoverClassName
              ? popoverClassName
              : popoverSize === PopoverSize.SMALL
              ? classes.popover
              : classes.bigPopover
          }
        >
          {columns ? (
            <div>
              {(!isLoading && !options) || options.length ? (
                <Table
                  columns={columns}
                  tableData={options}
                  withCheckbox={isMultiSelect}
                  onClickRow={rowData => {
                    clickMenuItemHandler(rowData);
                  }}
                  renderCell={renderCell}
                  headerType={TableHeaderType.LIGHT}
                  withBorders={false}
                  rowId={valueId}
                  withPadding={!isMultiSelect}
                  selectedData={selectedValues}
                  sendSelectedItems={ids =>
                    onChangeSelectedValues && onChangeSelectedValues(ids)
                  }
                  overflow="inherit"
                />
              ) : null}
              {(isLoading || showLoader) && (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              )}
              {!filteredOptions?.length && !isLoading && !showLoader ? (
                <div className={classes.empty}>
                  <CustomTypography variant="bodyRegular" color="greyscale3">
                    No data
                  </CustomTypography>
                </div>
              ) : null}
            </div>
          ) : (
            <MenuList
              id="menu-list-grow"
              className={
                popoverSize === PopoverSize.SMALL
                  ? classes.menuListSmall
                  : classes.menuList
              }
            >
              {(!isLoading || withPagination) &&
                filteredOptions?.map((option: any, index: number) => (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    onClick={() => {
                      clickMenuItemHandler(option);
                    }}
                  >
                    {isMultiSelect && (
                      <Checkbox
                        disableRipple
                        size="small"
                        classes={{
                          root: classes.checkbox,
                          colorSecondary: classes.icon,
                          checked: classes.checkedIcon,
                        }}
                        checked={
                          !!selectedValues?.find(
                            (el: any) =>
                              el === (valueId ? option[valueId] : option),
                          )
                        }
                      />
                    )}

                    {labelId ? (
                      Array.isArray(option[labelId]) ? (
                        option[labelId].map(
                          (item: string, index: number, arr: any[]) => (
                            <CustomTypography
                              key={item}
                              style={{
                                whiteSpace:
                                  index === arr.length - 1
                                    ? 'pre-line'
                                    : index % 2 === 1
                                    ? 'pre'
                                    : undefined,
                                fontWeight: index % 2 === 1 ? 700 : 400,
                              }}
                              variant="subtitle1"
                              color="accentDark"
                            >
                              {index % 2 === 1 ? ` - ${item} - ` : item}
                            </CustomTypography>
                          ),
                        )
                      ) : (
                        <CustomTypography
                          variant="subtitle1"
                          color="accentDark"
                        >
                          {option[labelId]}
                        </CustomTypography>
                      )
                    ) : (
                      <CustomTypography variant="subtitle1" color="accentDark">
                        {option}
                      </CustomTypography>
                    )}
                  </MenuItem>
                ))}

              {(isLoading || showLoader) && (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              )}
            </MenuList>
          )}
        </Paper>
      </Popover>
    </div>
  );
};
