/* eslint-disable no-magic-numbers */
import React, { useMemo } from 'react';
import {
  Grid as MaterialUiGrid,
  GridSize,
  GridSpacing,
} from '@material-ui/core';

export enum GridVariant {
  SMALL,
  MEDIUM,
  LARGE,
  NONE,
}

interface IGrid {
  children: any;
  className?: string;
  container?: boolean;
  item?: boolean;
  variant?: GridVariant;
  spacing?: GridSpacing;
  onClick?: () => void;
  style?: React.CSSProperties;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

interface IVariant {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

export const Grid = ({
  children,
  className,
  container,
  variant,
  spacing,
  item,
  style,
  xs = 12,
  sm = 12,
  md = 6,
  lg = 6,
  onClick,
}: IGrid) => {
  const currentVariant: IVariant = useMemo(() => {
    switch (variant) {
      case GridVariant.SMALL:
        return {
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
        };
      case GridVariant.MEDIUM:
        return {
          xs: 6,
          sm: 6,
          md: 6,
          lg: 6,
        };
      case GridVariant.LARGE:
        return {
          xs: 12,
          sm: 6,
          md: 6,
          lg: 6,
        };
      case GridVariant.NONE:
        return {
          xs: undefined,
          sm: undefined,
          md: undefined,
          lg: undefined,
        };
      default:
        return {
          xs: xs,
          sm: sm,
          md: md,
          lg: lg,
        };
    }
  }, [variant, xs, sm, md, lg]);

  return (
    <MaterialUiGrid
      container={container}
      className={className}
      spacing={spacing}
      item={item}
      xs={item && currentVariant.xs}
      sm={item && currentVariant.sm}
      md={item && currentVariant.md}
      lg={item && currentVariant.lg}
      onClick={onClick}
      style={style}
    >
      {children}
    </MaterialUiGrid>
  );
};
