import { useAuth } from '../providers';
import { apiClient, IApiClientOptions } from '../services/api';
import { useCallback } from 'react';

export function useAuthClient(legacy?: boolean) {
  const { getAccessToken } = useAuth();

  const getAccessTokenHandler = useCallback(async () => {
    return await getAccessToken();
  }, [getAccessToken]);

  return (endpoint: string, options: IApiClientOptions = {}) =>
    apiClient(
      endpoint,
      { ...options, getAccessToken: getAccessTokenHandler },
      legacy,
    );
}
