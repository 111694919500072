import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { useParams } from 'react-router-dom';
import { useOrganizationsApi, useProfileApi } from 'app/shared/hooks';
import { IProfile, Routes } from 'types';
import { firebaseAnalytics } from 'app/shared/analytics';
import { IAction } from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { useRemoveUserApi } from 'app/shared/hooks/useRemoveUserApi';
import { ConfirmationModal } from 'app/shared/components/generic-ui/ConfirmationModal/ConfirmationModal';

export const OrganizationAdminsTable = () => {
  const { t } = useTranslation();
  const { organizationId }: { organizationId: string } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<IProfile | null>(null);

  const removeUserQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        userId: userData ? userData.userId : '',
      },
    };
  }, [organizationId, userData]);

  const profileQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
    };
  }, [organizationId]);

  const { profile } = useProfileApi(profileQueryParams);

  const { removeUser, isRemovingUser } = useRemoveUserApi(
    removeUserQueryParams,
  );

  const fetchOrganizationAdminsQueryParam = useMemo(() => {
    return {
      organizationId: organizationId,
    };
  }, [organizationId]);

  const {
    fetchOrganizationAdmins,
    organizationAdmins,
    isFetchingOrganizationAdmins,
  } = useOrganizationsApi(fetchOrganizationAdminsQueryParam);

  useEffect(() => {
    fetchOrganizationAdmins();
  }, [fetchOrganizationAdmins, fetchOrganizationAdminsQueryParam]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        label: t(translations.teammatesPage.name),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: true,
        colspan: 1,
      },
      {
        id: 'email',
        label: t(translations.teammatesPage.email),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        colspan: 1,
      },
    ];
  }, [t]);

  const actions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.teammatesPage.removeTeammateFromSystem),
        onClick: (row: IProfile) => {
          setUserData(row);
          firebaseAnalytics.logPressEvent(
            Routes.OrganizationDetails,
            'Remove user action',
          );
          setIsModalOpen(true);
        },
      },
    ];
  }, [t]);

  const modalMessage = useMemo(() => {
    return `${t(translations.confirmationModal.areYouSure, {
      email: userData ? userData.email : '',
    })}?`;
  }, [t, userData]);

  const renderCell = useCallback((rowData: IProfile, cellId: string) => {
    if (cellId === 'name') {
      return <div>{`${rowData.firstName} ${rowData.lastName} `}</div>;
    } else {
      return <div>{rowData[cellId as keyof IProfile]}</div>;
    }
  }, []);

  const closeModalHandler = useCallback(() => {
    if (!isRemovingUser) {
      setUserData(null);
      setIsModalOpen(false);
    }
  }, [isRemovingUser]);

  const removeUserHandler = useCallback(async () => {
    await removeUser();
    closeModalHandler();
  }, [closeModalHandler, removeUser]);

  const renderActions = useCallback(
    (rowData: any) => {
      return profile?.userId !== rowData.userId;
    },
    [profile?.userId],
  );

  return (
    <>
      {isModalOpen && (
        <ConfirmationModal
          closeHandler={closeModalHandler}
          message={modalMessage}
          isOpen={isModalOpen}
          isLoading={isRemovingUser}
          confirmationHandler={removeUserHandler}
        />
      )}

      <Table
        withHover={true}
        columns={columns}
        tableData={organizationAdmins}
        withCheckbox={false}
        renderCell={renderCell}
        isLoading={isFetchingOrganizationAdmins}
        actions={actions}
        renderActions={renderActions}
      />
    </>
  );
};
