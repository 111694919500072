import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  IDosageEntity,
  IDosagePropertyEntity,
  IDosagePayload,
  IDosageEntityFull,
  IAssignDosagePayload,
  IAddDosagePropertyValuePayload,
  IAddDosageBatchItemPayload,
  IAssignedDosages,
} from 'types';
import { useError } from '../providers';
import { useAuthClient } from './useAuthClient';

const dosagesQueryKey = 'getDosages';
const dosageQueryKey = 'getDosage';
const assignedDosagesQueryKey = 'getAssignedDosages';

const dosagesPropertiesQueryKey = 'getDosagesProperties';
const createDosageQueryKey = 'createDosage';
const removeDosageFromNotificationQueryKey = 'removeDosageFromNotification';
const assignDosageToNotificationQueryKey = 'assignDosageToNotification';
const addDosagePropertyValueQueryKey = 'addDosagePropertyValue';
const removeDosagePropertyValueQueryKey = 'removeDosagePropertyValue';
const updateDosagePropertyValueQueryKey = 'updateDosagePropertyValue';
const addDosageBatchItemQueryKey = 'addDosageBatchItem';
const updateDosageBatchItemQueryKey = 'updateDosageBatchItem';
const removeDosageBatchItemQueryKey = 'removeDosageBatchItem';

export function useDosagesApi({ queryParameters, organizationId }: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const queryClient = useQueryClient();

  const getDosagesUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/search-dosages`;
  }, [organizationId]);
  const getDosageUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/get-dosage`;
  }, [organizationId]);
  const getAssignedDosageUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/get-dosages`;
  }, [organizationId]);
  const getDosagesPropertiesUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/get-dosage-properties`;
  }, [organizationId]);
  const createDosageUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/create-dosage`;
  }, [organizationId]);
  const removeDosageFromNotificationEntityUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/delete-dosage-from-notification-entity`;
  }, [organizationId]);
  const assignDosageToNotificationEntityUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/add-dosage-to-notification-entity`;
  }, [organizationId]);
  const assignDosagePropertyToDosage = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/add-dosage-property-value`;
  }, [organizationId]);
  const removeDosagePropertyToDosageUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/delete-dosage-property-value`;
  }, [organizationId]);
  const updateDosagePropertyToDosageUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/update-dosage-property-value`;
  }, [organizationId]);
  const addDosageBatchItemUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/add-dosage-batch-item`;
  }, [organizationId]);
  const updateDosageBatchItemUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/update-dosage-batch-item-ranges`;
  }, [organizationId]);
  const removeDosageBatchItemUrl = useMemo(() => {
    return `/api/v1/${organizationId}/Dosage/delete-dosage-batch-item`;
  }, [organizationId]);

  const {
    mutateAsync: updateDosagePropertyValue,
    isLoading: isUpdateDosagePropertyValue,
  } = useMutation(
    (
      payload: IAddDosagePropertyValuePayload,
    ): Promise<IResponseData<boolean>> =>
      authClient(updateDosagePropertyToDosageUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(updateDosagePropertyValueQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          updateDosagePropertyValueQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: updateDosageBatchItem,
    isLoading: isUpdateDosageBatchItem,
  } = useMutation(
    (payload: IAddDosageBatchItemPayload): Promise<IResponseData<boolean>> =>
      authClient(updateDosageBatchItemUrl, {
        method: 'PUT',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(updateDosageBatchItemQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          updateDosageBatchItemQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: removeDosagePropertyValue,
    isLoading: isRemovingDosagePropertyValue,
  } = useMutation(
    (
      payload: IAddDosagePropertyValuePayload,
    ): Promise<IResponseData<boolean>> =>
      authClient(removeDosagePropertyToDosageUrl, {
        method: 'DELETE',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(removeDosagePropertyValueQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          removeDosagePropertyValueQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: removeDosageBatchItem,
    isLoading: isRemovingDosageBatchItem,
  } = useMutation(
    (payload: IAddDosageBatchItemPayload): Promise<IResponseData<boolean>> =>
      authClient(removeDosageBatchItemUrl, {
        method: 'DELETE',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(removeDosageBatchItemQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          removeDosageBatchItemQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: assignDosageToNotificationEntity,
    isLoading: isAssigningDosageToNotificationEntity,
  } = useMutation(
    (payload: IAssignDosagePayload): Promise<IResponseData<boolean>> =>
      authClient(assignDosageToNotificationEntityUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(assignDosageToNotificationQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          assignDosageToNotificationQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: addDosagePropertyValue,
    isLoading: isAddingDosagePropertyValue,
  } = useMutation(
    (
      payload: IAddDosagePropertyValuePayload,
    ): Promise<IResponseData<boolean>> =>
      authClient(assignDosagePropertyToDosage, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(addDosagePropertyValueQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          addDosagePropertyValueQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: addDosageBatchItem,
    isLoading: isAddingDosageBatchItem,
  } = useMutation(
    (payload: IAddDosageBatchItemPayload): Promise<IResponseData<boolean>> =>
      authClient(addDosageBatchItemUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(addDosageBatchItemQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          addDosageBatchItemQueryKey,
        );
      },
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const { mutateAsync: createDosage, isLoading: isCreatingDosage } =
    useMutation(
      (payload: IDosagePayload): Promise<IResponseData<IDosageEntity>> =>
        authClient(createDosageUrl, {
          method: 'POST',
          data: payload,
        }),
      {
        retry: false,
        onMutate: async () => {
          await queryClient.cancelQueries(createDosageQueryKey);
          return queryClient.getQueryData<IResponseData<any>>(
            createDosageQueryKey,
          );
        },
        onError: (error: IError) => {
          openModal(error);
        },
      },
    );

  const useDosagesQuery = (queryKey: string) => {
    return useQuery<IResponseData<IDosageEntity[]>, IError>(
      [queryKey, { queryParameters }],
      async () => {
        return await authClient(`${getDosagesUrl}`, {
          queryParameters: {
            DosageName: queryParameters?.query,
          },
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          openModal(error);
        },
      },
    );
  };

  const {
    data: dosage,
    mutateAsync: fetchDosage,
    isLoading: isFetchingDosage,
    reset: clearDosage,
  } = useMutation(
    (payload: {
      dosageId?: string;
    }): Promise<IResponseData<IDosageEntityFull>> =>
      authClient(getDosageUrl, {
        method: 'GET',
        queryParameters: payload,
      }),
    {
      retry: false,
      onMutate: async variables => {
        await queryClient.cancelQueries([dosageQueryKey, variables.dosageId]);
        return queryClient.getQueryData<IResponseData<any>>([
          dosageQueryKey,
          variables.dosageId,
        ]);
      },
    },
  );

  const {
    data: assignedDosages,
    mutateAsync: fetchAssignedDosages,
    isLoading: isFetchingAssignedDosages,
    reset: clearAssignedDosages,
  } = useMutation(
    (payload: {
      notificationEntityId?: string;
    }): Promise<IResponseData<IAssignedDosages[]>> =>
      authClient(getAssignedDosageUrl, {
        method: 'GET',
        queryParameters: payload,
      }),
    {
      retry: false,
      onMutate: async variables => {
        await queryClient.cancelQueries([
          assignedDosagesQueryKey,
          variables.notificationEntityId,
        ]);
        return queryClient.getQueryData<IResponseData<any>>([
          assignedDosagesQueryKey,
          variables.notificationEntityId,
        ]);
      },
    },
  );

  const useDosagesPropertiesQuery = (queryKey: string) => {
    return useQuery<IResponseData<IDosagePropertyEntity[]>, IError>(
      [queryKey, { queryParameters }],
      async () => {
        return await authClient(`${getDosagesPropertiesUrl}`, {
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          openModal(error);
        },
      },
    );
  };

  const {
    refetch: removeDosageFromEntity,
    isFetching: isRemovingDosageFromEntity,
  } = useQuery<IResponseData<boolean>, IError>(
    removeDosageFromNotificationQueryKey,
    async () => {
      return await authClient(`${removeDosageFromNotificationEntityUrl}`, {
        queryParameters: {
          DosageId: queryParameters?.dosageId,
          NotificationEntityId: queryParameters?.notificationEntityId,
          NotificationEntityType: queryParameters?.notificationEntityType,
        },
        method: 'DELETE',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const {
    data: dosages,
    refetch: fetchDosages,
    isFetching: isFetchingDosages,
  } = useDosagesQuery(dosagesQueryKey);

  const {
    data: dosagesProperties,
    refetch: fetchDosagesProperties,
    isFetching: isFetchingDosagesProperties,
  } = useDosagesPropertiesQuery(dosagesPropertiesQueryKey);

  return {
    dosages,
    fetchDosages,
    isFetchingDosages,
    dosagesProperties,
    fetchDosagesProperties,
    isFetchingDosagesProperties,
    dosage,
    fetchDosage,
    isFetchingDosage,
    createDosage,
    isCreatingDosage,
    removeDosageFromEntity,
    isRemovingDosageFromEntity,
    assignDosageToNotificationEntity,
    isAssigningDosageToNotificationEntity,
    clearDosage,
    addDosagePropertyValue,
    isAddingDosagePropertyValue,
    removeDosagePropertyValue,
    isRemovingDosagePropertyValue,
    updateDosagePropertyValue,
    isUpdateDosagePropertyValue,
    addDosageBatchItem,
    isAddingDosageBatchItem,
    updateDosageBatchItem,
    isUpdateDosageBatchItem,
    removeDosageBatchItem,
    isRemovingDosageBatchItem,
    assignedDosages,
    fetchAssignedDosages,
    isFetchingAssignedDosages,
    clearAssignedDosages,
  };
}
