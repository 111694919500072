import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  INotification,
  IMetaData,
  ITeamAssignments,
  IUpdateNotificationStatus,
  IUpdateNotificationProperty,
  IEnableNotification,
  IEnableNotificationAllGroup,
  IChangeUserNotificationStatusPayload,
  IUpdateNotificationStatusAllGroup,
  IUserNotificationSettings,
  NotificationEntityType,
} from 'types';

import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const notificationSettingsQueryKey = 'notificationSettingsGroup';
const teamAssignmentsQueryKey = 'notificationGroupTeamAssignments';
const userNotificationSettingsQueryKey = 'userNotificationSettingsGroup';
const notificationQueryKey = 'notificationGroup';
const changeUserNotificationStatusQueryKey =
  'changeUserNotificationGroupStatus';
const searchTeamAssignmentsQueryKey = 'searchTeamAssignments';

export function useNotificationSettingsGroupApi({
  queryParameters,
  organizationId,
  entityType,
}: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getNotificationSettingsEntityGroupURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/get-notification-settings`;
  }, [entityType, currentSupplierId]);

  const getNotificationSettingsAssignmentsGroupURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/get-notification-settings-assignments`;
  }, [entityType, currentSupplierId]);

  const changeNotificationStatusUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/change-status`;
  }, [entityType, currentSupplierId]);

  const changeNotificationPropertyUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/change-property-value`;
  }, [currentSupplierId, entityType]);

  const changeUsersStatusOrgNotificationUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/change-notification-assignment-status`;
  }, [currentSupplierId, entityType]);

  const changeNotificationStatusAllGroupUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/change-status-all-group`;
  }, [currentSupplierId, entityType]);

  const enableNotificationUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/enable`;
  }, [entityType, currentSupplierId]);

  const enableNotificationAllUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/enable-all-group`;
  }, [currentSupplierId, entityType]);

  const getUserNotificationSettingsAssignmentsURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${entityType}/get-user-notification-settings-assignments`;
  }, [entityType, currentSupplierId]);

  const {
    data: notificationSettingsEntityGroupResponse,
    refetch: fetchNotificationSettingsEntityGroup,
    isFetching: isFetchingNotificationSettingsEntityGroup,
    error: fetchNotificationSettingsEntityGroupError,
  } = useQuery<IResponseData<INotification[]>, IError>(
    [notificationSettingsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationSettingsEntityGroupURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityGroupNotificationSettings,
          error,
          {
            getNotificationSettingsEntityGroupURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: userGroupNotificationSettingsResponse,
    refetch: fetchUserGroupNotificationSettings,
    isFetching: isFetchingUserGroupNotificationSettings,
    error: fetchUserGroupNotificationSettingsError,
  } = useQuery<IResponseData<IUserNotificationSettings>, IError>(
    [userNotificationSettingsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getUserNotificationSettingsAssignmentsURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getUserGroupNotificationSettings,
          error,
          {
            getUserNotificationSettingsAssignmentsURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: notificationSettingsGroupAssignmentsResponse,
    refetch: fetchNotificationSettingsGroupAssignments,
    isFetching: isFetchingNotificationSettingsGroupAssignments,
    error: fetchNotificationSettingsGroupAssignmentsError,
  } = useQuery<IResponseData<ITeamAssignments[]>, IError>(
    [teamAssignmentsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationSettingsAssignmentsGroupURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityGroupUserNotificationAssignments,
          error,
          {
            getNotificationSettingsAssignmentsGroupURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: changeNotificationStatusEntityGroup,
    isLoading: isChangingNotificationStatusEntityGroup,
    error: changeNotificationStatusEntityGroupError,
  } = useMutation(
    (payload: IUpdateNotificationStatus) =>
      authClient(changeNotificationStatusUrl, {
        method: 'PATCH',
        queryParameters,
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityGroupNotificationStatus,
          error,
          {
            changeNotificationStatusUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: enableNotificationEntityGroup,
    isLoading: isEnablingNotificationEntityGroup,
    error: enableNotificationEntityGroupError,
  } = useMutation(
    (payload: IEnableNotification) =>
      authClient(enableNotificationUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.enableEntityNotification,
          error,
          {
            enableNotificationUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: enableNotificationAllGroup,
    isLoading: isEnablingNotificationAllGroup,
  } = useMutation(
    (payload: IEnableNotificationAllGroup) =>
      authClient(enableNotificationAllUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationSettingsQueryKey);
        return queryClient.getQueryData<IResponseData<INotification[]>>(
          notificationSettingsQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification[]>>(
            notificationSettingsQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          queryParameters?.description
            ? errors.enableEntityNotificationAllGroup
            : errors.enableEntityNotificationAll,
          error,
          {
            enableNotificationAllUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: changeNotificationStatusAllGroup,
    isLoading: isChangingNotificationStatusAllGroup,
  } = useMutation(
    (payload: IUpdateNotificationStatusAllGroup) =>
      authClient(changeNotificationStatusAllGroupUrl, {
        method: 'PATCH',
        data: payload,
        queryParameters,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationSettingsQueryKey);
        return queryClient.getQueryData<IResponseData<INotification[]>>(
          notificationSettingsQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification[]>>(
            notificationSettingsQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityGroupNotificationStatusAllGroup,
          error,
          {
            changeNotificationStatusAllGroupUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    mutateAsync: changeNotificationPropertyEntityGroup,
    isLoading: isChangingNotificationPropertyEntityGroup,
    error: changeNotificationPropertyEntityGroupError,
  } = useMutation(
    (payload: IUpdateNotificationProperty) =>
      authClient(changeNotificationPropertyUrl, {
        method: 'PATCH',
        queryParameters,
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(notificationQueryKey);
        return queryClient.getQueryData<IResponseData<INotification>>(
          notificationQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<INotification>>(
            notificationQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityGroupNotificationPropertyValue,
          error,
          {
            changeNotificationPropertyUrl,
            ...variables,
          },
        );
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(notificationQueryKey);
      },
    },
  );

  const {
    data: searchTeamAssignmentsGroupResponse,
    refetch: fetchSearchTeamAssignmentsGroup,
    isFetching: isFetchingSearchTeamAssignmentsGroup,
    error: fetchSearchTeamAssignmentsGroupError,
  } = useQuery<IResponseData<ITeamAssignments[]>, IError>(
    [searchTeamAssignmentsQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getNotificationSettingsAssignmentsGroupURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getEntityGroupUserNotificationAssignments,
          error,
          {
            getNotificationSettingsAssignmentsGroupURL,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: changeUsersStatusNotificationGroupResponse,
    mutateAsync: changeUsersStatusNotificationGroup,
    isLoading: isChangingUsersStatusNotificationGroup,
    error: changeUsersStatusNotificationGroupError,
    isSuccess: isChangingUsersStatusNotificationGroupSuccess,
  } = useMutation(
    (payload: {
      users: IChangeUserNotificationStatusPayload[];
      type?: NotificationEntityType;
      groupId?: string;
    }) =>
      authClient(
        payload.type
          ? `/api/v1/${currentSupplierId}/NotificationSettingsGroup/${payload.type}/change-notification-assignment-status`
          : changeUsersStatusOrgNotificationUrl,
        {
          method: 'POST',
          data: payload.users,
          queryParameters: {
            ...queryParameters,
            groupId: payload.groupId || queryParameters?.groupId,
          },
        },
      ),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(changeUserNotificationStatusQueryKey);
        return queryClient.getQueryData<IResponseData<any>>(
          changeUserNotificationStatusQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<any>>(
            changeUserNotificationStatusQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(
          errors.changeEntityGroupUserNotificationAssignments,
          error,
          {
            changeUserNotificationStatusQueryKey,
            variables: JSON.stringify(variables),
          },
        );
        queryClient.refetchQueries(teamAssignmentsQueryKey);
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(changeUserNotificationStatusQueryKey);
      },
    },
  );

  const notificationSettingsEntityGroup = useMemo<INotification[] | undefined>(
    () => notificationSettingsEntityGroupResponse?.data,
    [notificationSettingsEntityGroupResponse],
  );

  const notificationSettingsAssignments = useMemo<
    ITeamAssignments[] | undefined
  >(
    () => notificationSettingsGroupAssignmentsResponse?.data,
    [notificationSettingsGroupAssignmentsResponse],
  );

  const notificationSettingsAssignmentsMetadata = useMemo<
    IMetaData | undefined
  >(
    () => notificationSettingsGroupAssignmentsResponse?.metadata,
    [notificationSettingsGroupAssignmentsResponse],
  );

  const searchTeamAssignmentsGroup = useMemo<ITeamAssignments[] | undefined>(
    () => searchTeamAssignmentsGroupResponse?.data,
    [searchTeamAssignmentsGroupResponse],
  );

  const userGroupNotificationSettings = useMemo<
    IUserNotificationSettings | undefined
  >(
    () => userGroupNotificationSettingsResponse?.data,
    [userGroupNotificationSettingsResponse],
  );

  return {
    notificationSettingsEntityGroup,
    fetchNotificationSettingsEntityGroup,
    isFetchingNotificationSettingsEntityGroup,
    fetchNotificationSettingsEntityGroupError,
    fetchNotificationSettingsGroupAssignments,
    isFetchingNotificationSettingsGroupAssignments,
    fetchNotificationSettingsGroupAssignmentsError,
    notificationSettingsAssignments,
    notificationSettingsAssignmentsMetadata,
    changeNotificationStatusEntityGroup,
    isChangingNotificationStatusEntityGroup,
    changeNotificationStatusEntityGroupError,
    changeNotificationPropertyEntityGroup,
    isChangingNotificationPropertyEntityGroup,
    changeNotificationPropertyEntityGroupError,
    changeUsersStatusNotificationGroupResponse,
    changeUsersStatusNotificationGroup,
    isChangingUsersStatusNotificationGroup,
    changeUsersStatusNotificationGroupError,
    isChangingUsersStatusNotificationGroupSuccess,
    fetchSearchTeamAssignmentsGroup,
    isFetchingSearchTeamAssignmentsGroup,
    fetchSearchTeamAssignmentsGroupError,
    searchTeamAssignmentsGroup,
    changeNotificationStatusAllGroup,
    isChangingNotificationStatusAllGroup,
    fetchUserGroupNotificationSettings,
    isFetchingUserGroupNotificationSettings,
    fetchUserGroupNotificationSettingsError,
    userGroupNotificationSettings,
    enableNotificationEntityGroup,
    isEnablingNotificationEntityGroup,
    enableNotificationEntityGroupError,
    enableNotificationAllGroup,
    isEnablingNotificationAllGroup,
  };
}
