import { makeStyles } from '@material-ui/core';
import { theme, themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  footerContainer: {
    boxSizing: 'border-box',
    height: themeLayout.footerHeight,
    width: '100%',
    backgroundColor: themeColors.white,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '39px 39px 39px 0',
    marginTop: `-${themeLayout.footerHeight}`,
    [theme.breakpoints.down('sm')]: {
      zIndex: 200,
    },
    [theme.breakpoints.up('sm')]: {
      zIndex: 200,
    },
  },
  link: {
    cursor: 'pointer',
    paddingLeft: '32px',
    textDecoration: 'none',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    zIndex: 8,
    paddingLeft: 39,
    height: themeLayout.footerHeight,
    width: `${themeLayout.sideNavWidth}px`,
    backgroundColor: themeColors.white,
  },
  logo: {
    height: '30px',
    width: '84px',
    zIndex: 9,
  },
  textWrapper: {
    marginBottom: 44,
  },
});
