import { IHistoryRecord } from 'types';
import { useStyles } from './BatchList.styles';
import { Popover } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import briefcase from 'assets/briefcase.svg';
import project_id_icon from 'assets/project_id_icon.svg';
import plant_icon from 'assets/plant.svg';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

export enum PopoverType {
  INFO,
  MIX_ID,
}

interface IPopoverItemProps {
  rowData: IHistoryRecord | null;
  open: boolean;
  anchor: HTMLElement | null;
  type?: PopoverType;
}

export const PopoverItem = ({
  rowData,
  open,
  anchor,
  type = PopoverType.INFO,
}: IPopoverItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      className={classes.popover}
      disableRestoreFocus
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: type === PopoverType.INFO ? 'right' : 'center',
      }}
    >
      {type === PopoverType.INFO ? (
        <div className={classes.projectCardBody}>
          <div className={classes.nameContainer}>
            <CustomTypography variant="mobileHeader6" bold color="greyscale1">
              {rowData?.projectName || '-'}
            </CustomTypography>
          </div>

          <div className={classes.textContainer}>
            <img src={project_id_icon} className={classes.icon} />
            <CustomTypography variant="bodySmall" color="greyscale1">
              {`${t(translations.notificationEntityCardCard.projectId)}: ${
                rowData?.projectId ? rowData?.projectId : '-'
              }`}
            </CustomTypography>
          </div>

          <div className={classes.textContainer}>
            <img src={briefcase} className={classes.icon} />
            <CustomTypography variant="bodySmall" color="greyscale1">
              {rowData?.customerName ? rowData?.customerName : '-'}
            </CustomTypography>
          </div>

          <div className={classes.textContainer}>
            <img src={plant_icon} className={classes.icon} />

            <CustomTypography variant="bodySmall" color="greyscale1">
              {`${t(translations.notificationEntityCardCard.plant)}: ${
                rowData?.plantOriginDescription || '-'
              }`}
            </CustomTypography>
          </div>
        </div>
      ) : (
        <div className={classes.mixIdCardBody}>
          <CustomTypography
            variant="bodySmall"
            color="greyscale1"
            style={{ whiteSpace: 'initial' }}
          >
            {`${rowData?.externalProductId} / ${rowData?.externalProductName}`}
          </CustomTypography>
        </div>
      )}
    </Popover>
  );
};
