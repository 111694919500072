import React, { useCallback, useMemo, useState } from 'react';
import { useStyles } from './RenameModal.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ClickAwayListener, Modal } from '@material-ui/core';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalHeader } from 'app/shared/components/generic-ui/ModalHeader/ModalHeader';
import { TextInput } from 'app/shared/components/generic-ui/TextInput/TextInput';

interface IRenameModalProps {
  isOpen: boolean;
  name: string;
  closeHandler: () => void;
  onSubmit: (data: any) => void;
  header: string;
  placeholder?: string;
  label?: string;
}

const maxSymbols = 200;

export const RenameModal = ({
  isOpen,
  name,
  closeHandler,
  onSubmit,
  header,
  placeholder,
  label,
}: IRenameModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isNameEmpty, setIsNameEmpty] = useState(true);

  const formDefaultValues = useMemo(() => {
    return {
      name: name,
    };
  }, [name]);

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(
        maxSymbols,
        `${t(translations.errors.thisFieldCanNotExceed)} ${maxSymbols} ${t(
          translations.errors.characters,
        )}.`,
      ),
  });

  const handleClickAway = () => {
    trigger('name');
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const closeModalHandler = useCallback(() => {
    reset();
    setIsNameEmpty(true);
    closeHandler();
  }, [closeHandler, reset]);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.small}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader header={header} closeHandler={closeModalHandler} />

          <div className={classes.body}>
            <div className={classes.fieldContainer}>
              <div className={classes.label}>
                <CustomTypography variant="caption1" bold color="greyscale1">
                  {label || ''}
                </CustomTypography>
              </div>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <TextInput
                    placeholder={placeholder || ''}
                    name="name"
                    control={control}
                    onChangeHandler={e => {
                      if (e && !e.target.value) {
                        setIsNameEmpty(true);
                      } else {
                        setIsNameEmpty(false);
                      }
                    }}
                    error={!!errors?.name}
                    maxLength={maxSymbols}
                    helperText={errors?.name?.message}
                  />
                </div>
              </ClickAwayListener>
            </div>
          </div>

          <div className={classes.saveButton}>
            <Button type="submit" variant="primary" disabled={isNameEmpty}>
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.renamePourEventModal.save)}
              </CustomTypography>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
