import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { TeammatesList } from './components/TeammatesList/TeammatesList';
import { Button } from '../../shared/components/generic-ui/Button/Button';
import { useStyles } from './TeammatesPage.styles';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import {
  CellAlignment,
  CellSize,
  IPaginationUrlParams,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Routes } from 'types';
import { useTeammatesApi } from 'app/shared/hooks/useTeammatesApi';
import { InviteTeammateModal } from './components/InviteTeammateModal/InviteteammateModal';
import { useSearchHelper } from 'app/shared/utils';
import { firebaseAnalytics } from 'app/shared/analytics';

const skipOnePage = 1;
const emptyTableDataCount = 0;

export const TeammatesPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { page, perPage, orderType }: IPaginationUrlParams = useParams();
  const { organizationId }: { organizationId: string } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refetchTeammates, setRefetchTeammates] = useState(false);

  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();

  const columns = [
    {
      id: 'name',
      label: t(translations.teammatesPage.name),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'roleNames',
      label: t(translations.teammatesPage.role),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const paginationAndSearch = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        take: perPage,
        skip: `${(+page - skipOnePage) * +perPage}`,
        sortBy: 'firstname,lastname',
        sortAsc: orderType === 'asc',
        query: prepareSearchQueryParameter(searchQuery),
      },
    };
  }, [
    orderType,
    organizationId,
    page,
    perPage,
    prepareSearchQueryParameter,
    searchQuery,
  ]);

  const searchQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        take: '10',
        skip: '0',
        sortBy: 'firstname,lastname',
        sortAsc: true,
        query: prepareSearchQueryParameter(search),
      },
    };
  }, [organizationId, prepareSearchQueryParameter, search]);

  const { fetchTeammates, teammates, teammatesMetadata, isFetchingTeammates } =
    useTeammatesApi(paginationAndSearch);

  const {
    fetchTeammates: fetchSearchedTeammates,
    teammates: searchedTeammatesList,
    isFetchingTeammates: isSearchingTeammates,
  } = useTeammatesApi(searchQueryParams);

  useEffect(() => {
    fetchTeammates();
  }, [fetchTeammates, paginationAndSearch, refetchTeammates]);

  useEffect(() => {
    fetchSearchedTeammates();
  }, [fetchSearchedTeammates, searchQueryParams]);

  const sortHandler = useCallback(
    (id: string) => {
      if (id === 'name') {
        const searchParams = new URLSearchParams({
          ['search']: searchQuery || '',
        });

        firebaseAnalytics.logPressEvent(
          Routes.Teammates,
          `Sort teammates by name ${orderType === 'asc' ? 'desc' : 'asc'}`,
        );

        history.push({
          pathname: Routes.Teammates.replace(
            ':organizationId/:page/:perPage/:orderType',
            `${organizationId}/${page}/${perPage}/${
              orderType === 'asc' ? 'desc' : 'asc'
            }`,
          ),
          search: `${searchParams}`,
        });

        setSearch(searchQuery || '');
      }
    },
    [history, orderType, organizationId, page, perPage, searchQuery],
  );

  const onSearchTeammates = useCallback(() => {
    firebaseAnalytics.logPressEvent(Routes.Teammates, 'Search teammates');

    fetchTeammates();
  }, [fetchTeammates]);

  const handleInviteTeammateClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(Routes.Teammates, 'Invite teammate button');

    setIsModalOpen(true);
  }, []);

  return (
    <div className={classes.wrapper}>
      {isModalOpen && (
        <InviteTeammateModal
          isOpen={isModalOpen}
          closeHandler={() => setIsModalOpen(false)}
        />
      )}

      <div className={classes.header}>
        <div className={classes.headerText}>
          <CustomTypography variant="header3" bold color="accentDark">
            {t(translations.teammatesPage.currentTeammates)}
          </CustomTypography>
        </div>

        <Button variant="primary" onClick={handleInviteTeammateClick}>
          <CustomTypography variant="header6" bold color="white">
            {t(translations.teammatesPage.inviteTeammate)}
          </CustomTypography>
        </Button>
      </div>

      <Search
        columns={columns}
        entries={searchedTeammatesList}
        onChange={setSearch}
        onSearch={onSearchTeammates}
        isLoading={isSearchingTeammates}
        placeholder={t(translations.teammatesPage.searchByNameOrRole)}
        onClickRow={value => {
          setSearch(`${value?.firstName} ${value?.lastName}`);
          const searchParams = new URLSearchParams({
            ['search']: `${value?.firstName} ${value?.lastName}`,
          });
          history.push({
            pathname: location.pathname,
            search: `${searchParams}`,
          });
        }}
        getRowId={'userId'}
        analyticsReference={Routes.Teammates}
      />

      <div className={classes.teammateTable}>
        <TeammatesList
          teammates={teammates}
          rowsTotal={teammatesMetadata?.totalCount || emptyTableDataCount}
          onClickSearchRow={onSearchTeammates}
          isLoading={isFetchingTeammates}
          refetchTeammates={() => setRefetchTeammates(prevState => !prevState)}
          sortHandler={sortHandler}
        />
      </div>
    </div>
  );
};
