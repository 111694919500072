import { makeStyles } from '@material-ui/core';
import { themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: 1,

    padding: '0px 30px 160px 30px',
  },
  header: {
    marginBottom: '24px',
    display: 'flex',
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerText: {
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      marginBottom: 20,
    },
  },
  teammateTable: {
    marginTop: '20px',
  },
}));
