import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { NotificationEntityType, Routes } from 'types';
import { NotificationEntitySection } from './components/NotificationEntitySection';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useStyles } from './NotificationEntityPage.styles';
import { CreateProjectModal } from './components/CreateProjectModal/CreateProjectModal';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/store/slices/layoutSlice';
import {
  useOrganizationsApi,
  useScreen,
  useNotificationEntityApi,
} from 'app/shared/hooks';
import { firebaseAnalytics } from 'app/shared/analytics';
import { getNotificationEntityName, useSearchHelper } from 'app/shared/utils';
import { ViewButtons } from './components/ViewButtons/ViewButtons';
import { ViewMode } from './types';
import { Grid } from 'app/shared/components/generic-ui';
import { RootState } from 'app/store/types';
import { useAuth } from 'app/shared/providers';
import { FilterBlock } from './components/FilterBlock';

export enum View {
  CARD_VIEW = 'cardView',
  LIST_VIEW = 'listView',
}

const cardViewPerPage = 8;
const listViewPerPage = 10;
const breakpoint = 600;

export const NotificationEntityPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { supplierId, isMember, userId } = useAuth();
  const { isMobile } = useScreen(breakpoint);

  const history = useHistory();
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const {
    organizationId,
    entityType,
  }: { organizationId: string; entityType: string } = useParams();
  const { prepareSearchQueryParameter } = useSearchHelper();
  const entityName = getNotificationEntityName(Number(entityType));

  const [isFilterOpened, setIsFilterOpened] = useState(true);
  const { page } = useSelector(
    (state: RootState) => state.layout.notificationEntitySection,
  );
  const view = useSelector((state: RootState) => state.layout.view);
  const from = localStorage.getItem(`${entityType}:from`);
  const to = localStorage.getItem(`${entityType}:to`);
  const userAssigned = localStorage.getItem(`${entityType}:userAssigned`);

  const [startDate, setStartDate] = useState<Date | undefined | null>(
    from === 'null' ? new Date() : from ? new Date(from) : null,
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    !to || to === 'null' ? null : new Date(to),
  );
  const [userAssignedTickets, setUserAssignedTickets] = useState<boolean>(
    !!userAssigned,
  );

  const currentOrganizationId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const queryParams = useMemo(() => {
    const params = {
      entityType: Number(entityType),
      organizationId: currentOrganizationId,
      queryParameters: {
        skip: '0',
        take: '10',
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        query: prepareSearchQueryParameter(search),
      },
    };
    if (userAssignedTickets) {
      //@ts-ignore
      params.queryParameters.userId = userId;
    }
    return params;
  }, [
    entityType,
    currentOrganizationId,
    prepareSearchQueryParameter,
    search,
    userAssignedTickets,
    userId,
  ]);

  const columns = [
    {
      id: 'name',
      label: t(translations.notificationEntityPage.name),
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const {
    fetchNotificationEntityCards,
    notificationEntityCards,
    isFetchingNotificationEntityCards,
  } = useNotificationEntityApi(queryParams);

  const { organizationDetails } = useOrganizationsApi({
    organizationId: currentOrganizationId || '',
  });

  useEffect(() => {
    fetchNotificationEntityCards();
  }, [fetchNotificationEntityCards, queryParams]);

  useEffect(() => {
    dispatch(
      actions.setNotificationEntityPagination({
        page,
        perPage:
          view === ViewMode.CARD_VIEW ? cardViewPerPage : listViewPerPage,
      }),
    );
  }, [dispatch, view, search, page]);

  const handleAddProjectClick = useCallback(() => {
    setIsModalOpen(true);

    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      'Add project button',
    );
  }, []);

  const applyDateHandler = useCallback(
    (
      from?: Date | null | undefined,
      to?: Date | null | undefined,
      userAssigned?: boolean,
    ) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationEntityPage,
        `Apply date range from ${entityName}`,
        JSON.stringify({
          startDate: from,
          endDate: to,
        }),
      );

      dispatch(actions.setNotificationEntitySectionDefaultState());
      setStartDate(from);
      setEndDate(to);
      setUserAssignedTickets(!!userAssigned);
      setRefetchData(prevState => !prevState);
    },
    [dispatch, entityName],
  );

  const onSearch = useCallback(() => {
    return dispatch(actions.setNotificationEntitySectionDefaultState());
  }, [dispatch]);

  return (
    <div className={classes.wrapper}>
      {isModalOpen && (
        <CreateProjectModal
          isOpen={isModalOpen}
          closeHandler={() => {
            setIsModalOpen(false);
          }}
          setRefetchData={setRefetchData}
        />
      )}

      <div className={classes.header}>
        <div className={classes.headerText}>
          <CustomTypography variant="header3" bold color="accentDark">
            {`${organizationDetails?.supplierName || ''} ${entityName}s`}
          </CustomTypography>
        </div>

        {!isMember && Number(entityType) === NotificationEntityType.PROJECT && (
          <Button variant="primary" onClick={handleAddProjectClick}>
            <CustomTypography variant="header6" bold color="white">
              {t(translations.notificationEntityPage.addProject)}
            </CustomTypography>
          </Button>
        )}
      </div>

      <div className={classes.searchContainer}>
        <Grid container md={12} lg={12}>
          <Grid container item xs={10} sm={8} md={9} lg={11} spacing={1}>
            <Grid item md={12} lg={5} className={classes.gridLeft}>
              <div className={classes.search}>
                <Search
                  columns={columns}
                  entries={notificationEntityCards}
                  onChange={setSearch}
                  onSearch={onSearch}
                  isLoading={isFetchingNotificationEntityCards}
                  placeholder={t(
                    translations.notificationEntityPage.searchByNameOrId,
                    {
                      entityType: entityName.toLowerCase(),
                    },
                  )}
                  onClickRow={value => {
                    history.push(
                      generatePath(Routes.NotificationEntityPourEventsPage, {
                        entityType,
                        notificationEntityId: value.internalId,
                        organizationId,
                        page: 1,
                        perPage: 20,
                        name: value.name,
                        externalId: value.externalId,
                      }),
                    );
                  }}
                  getRowId={'projectId'}
                  analyticsReference={Routes.NotificationEntityPage}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={3} lg={1} className={classes.gridRight}>
            <Button
              onClick={() => {
                setIsFilterOpened(prevState => !prevState);
              }}
              variant={
                !isMobile
                  ? isFilterOpened
                    ? 'filterActive'
                    : 'filter'
                  : isFilterOpened
                  ? 'filterSmallActive'
                  : 'filterSmall'
              }
              icon="filter"
              active={isFilterOpened}
            >
              {!isMobile ? (
                <div className={classes.labelContainer}>
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color={isFilterOpened ? 'white' : 'mbsBlue'}
                  >
                    {t(translations.notificationEntityPage.filter)}
                  </CustomTypography>
                </div>
              ) : undefined}
            </Button>

            {!isMobile && (
              <div className={classes.viewButtons}>
                <ViewButtons />
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      {isFilterOpened && <FilterBlock applyHandler={applyDateHandler} />}

      <NotificationEntitySection
        view={view}
        refetchData={refetchData}
        startDate={startDate?.toISOString()}
        endDate={endDate?.toISOString()}
        userAssignedTickets={userAssignedTickets}
        header={
          isMember
            ? t(translations.notificationEntityPage.myEntities, {
                entityType: entityName,
              })
            : ''
        }
      />
    </div>
  );
};
