import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 134,
    width: 155,
    backgroundColor: themeColors.white,
    padding: '0px 6px 0px 10px',
    boxSizing: 'border-box',
    borderLeft: `1px solid ${themeColors.greyscale3}`,
    margin: '12px 0px 18px 0px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 42,
  },
  button: {
    display: 'flex',
    justifyContent: 'end',
    height: 20,
  },
  actions: {
    float: 'left',
  },
  action: {
    minWidth: 150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  select: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  selectOutlined: {
    paddingRight: 0,
  },
  formControl: {
    width: 50,
    height: '32px',
    paddingLeft: '5px !important',
    paddingRight: '10px !important',
    backgroundColor: themeColors.white,
  },

  icon: {
    height: 20,
  },
  outlined: {
    marginRight: '-6px',
    marginTop: '1px',
  },
  root: {
    padding: '0px 0px 0px 5px',
  },
  rootDisabled: {
    width: 50,
    height: '32px',
    paddingLeft: '5px',
    backgroundColor: themeColors.greyscale4,
  },
  rootOutlined: {
    borderRadius: 2,
  },
  menuList: {
    maxHeight: 150,
    width: 50,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  label: {
    padding: '6px 10px',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    backgroundColor: themeColors.greyscale4_50,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },
  closeButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  loading: {
    display: 'flex',
    width: 534,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchTrack: {
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  switchBase: {
    opacity: 1,
    height: 16,
    left: 4,
    top: 5,
    '& .Mui-checked': {
      transform: 'translateX(27px)',
    },
  },
  toggle: {
    '& .Mui-checked': {
      color: themeColors.white,
      transform: 'translateX(27px)',
    },
  },
  hideIconPadding: {
    '& .MuiSelect-outlined': {
      paddingRight: '0px',
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },
  viewMore: {
    cursor: 'pointer',
  },
  thumb: {
    width: 16,
    height: 16,
  },
  rootSwitch: {
    width: 70,
  },
  emptyCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 145,
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 25,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '11px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    width: 250,
  },
  info: {
    marginRight: 3,
  },
  actionLabels: {
    minWidth: 60,
  },
});
