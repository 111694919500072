import React, { useCallback } from 'react';
import { useStyles } from './NotificationsSetup.styles';
import {
  IEnableNotificationAllGroup,
  INotification,
  IUpdateNotificationProperty,
  IUpdateNotificationStatusAllGroup,
  NotificationEntityType,
} from 'types';
import { NotificationItem } from '../NotificationItem';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useNotificationSettings } from 'app/shared/providers';

interface INotificationsSetupProps {
  notifications: Array<INotification | undefined>;
  isLoading: boolean;
  description: string;
  changeNotificationProperty: (
    notification: IUpdateNotificationProperty,
  ) => void;
  changeNotificationStatus: (notification: {
    notificationTypeId?: string;
    active: boolean;
    projectId?: string;
    plantNotificationId?: string;
  }) => void;
  changeNotificationsStatusAll: (
    group: IUpdateNotificationStatusAllGroup,
  ) => void;
  enableNotificationsAll?: (group: IEnableNotificationAllGroup) => void;
  enableNotification?: (notification: {
    notificationTypeId?: string;
    enable: boolean;
  }) => void;
}

export const NotificationsSetup = ({
  notifications,
  isLoading,
  description,
  changeNotificationProperty,
  changeNotificationStatus,
  changeNotificationsStatusAll,
  enableNotificationsAll,
  enableNotification,
}: INotificationsSetupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { entityType } = useNotificationSettings();
  const handleActivateAllClick = useCallback(async () => {
    changeNotificationsStatusAll({
      active: true,
      description: description,
    });
  }, [changeNotificationsStatusAll, description]);

  const handleDeactivateAllClick = useCallback(async () => {
    changeNotificationsStatusAll({
      active: false,
      description: description,
    });
  }, [changeNotificationsStatusAll, description]);

  const handleEnableAllClick = useCallback(async () => {
    enableNotificationsAll &&
      enableNotificationsAll({
        enable: true,
        description: description,
      });
  }, [enableNotificationsAll, description]);

  const handleDisableAllClick = useCallback(async () => {
    enableNotificationsAll &&
      enableNotificationsAll({
        enable: false,
        description: description,
      });
  }, [enableNotificationsAll, description]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <CustomTypography variant="subtitle2" bold color="greyscale1">
          {t(translations.notificationSettings.notifications).toUpperCase()}
        </CustomTypography>
        <div className={classes.buttons}>
          <div className={classes.activateAll} onClick={handleActivateAllClick}>
            <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
              {t(translations.notificationSettings.activateAll)}
            </CustomTypography>
          </div>

          <div
            className={classes.deactivateAll}
            onClick={handleDeactivateAllClick}
          >
            <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
              {t(translations.notificationSettings.deactivateAll)}
            </CustomTypography>
          </div>
          {entityType !== NotificationEntityType.ORGANIZATION && (
            <>
              <div className={classes.enableAll} onClick={handleEnableAllClick}>
                <CustomTypography
                  variant="buttonTextSmall"
                  bold
                  color="mbsBlue"
                >
                  {t(translations.notificationSettings.enableAll)}
                </CustomTypography>
              </div>

              <div
                className={classes.disableAll}
                onClick={handleDisableAllClick}
              >
                <CustomTypography
                  variant="buttonTextSmall"
                  bold
                  color="mbsBlue"
                >
                  {t(translations.notificationSettings.disableAll)}
                </CustomTypography>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={classes.settings}>
        {!isLoading ? (
          notifications?.map((notification, index) => (
            <div key={index}>
              <NotificationItem
                notification={notification}
                changeNotificationProperty={changeNotificationProperty}
                changeNotificationStatus={changeNotificationStatus}
                enableNotification={enableNotification}
              />
            </div>
          ))
        ) : (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};
