import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useProfileApi } from 'app/shared/hooks';
import { useStyles } from './ProfilePage.styles';
import { ProfileSection } from './ProfileSection/ProfileSection';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Routes } from 'types';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { useScreen } from 'app/shared/hooks';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store/slices';
import { DownloadAtlasAppSection } from './DownloadAtlasAppSection/DownloadAtlasAppSection';
import { AvatarSection } from './AvatarSection/AvatarSection';
import { firebaseAnalytics } from 'app/shared/analytics';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useSearchHelper } from 'app/shared/utils';
import { useAuth } from 'app/shared/providers';

export function ProfilePage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useScreen();
  const { searchQuery } = useSearchHelper('id');
  const dispatch = useDispatch();
  const { organizationId }: { organizationId: string } = useParams();
  const { supplierId } = useAuth();

  const currentOrganizationId = useMemo(() => {
    return location.pathname.includes('my-profile')
      ? supplierId
      : organizationId;
  }, [location.pathname, organizationId, supplierId]);

  const profileQueryParams = useMemo(() => {
    return searchQuery
      ? {
          organizationId: currentOrganizationId,
          queryParameters: {
            userId: searchQuery,
          },
        }
      : {
          organizationId: currentOrganizationId,
        };
  }, [currentOrganizationId, searchQuery]);

  const {
    fetchProfile,
    profile,
    isFetchingProfile,
    fetchUserProfile,
    userProfile,
    isFetchingUserProfile,
  } = useProfileApi(profileQueryParams);

  useEffect(() => {
    dispatch(actions.setIsSideNavOpen(false));
  }, [dispatch]);

  useEffect(() => {
    if (searchQuery) {
      fetchUserProfile();
    } else {
      fetchProfile();
    }
  }, [fetchProfile, fetchUserProfile, searchQuery]);

  useEffect(() => {
    setIsLoading(isFetchingProfile || isFetchingUserProfile);
  }, [isFetchingProfile, isFetchingUserProfile]);

  const handleEditDetailsClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(Routes.Profile, 'Edit details button');

    if (searchQuery) {
      const searchParams = new URLSearchParams({
        ['id']: `${searchQuery}`,
      });
      history.push({
        pathname: Routes.EditProfile.replace(':organizationId', organizationId),
        search: `${searchParams}`,
      });
    } else {
      history.push(
        Routes.EditProfile.replace(':organizationId', organizationId),
      );
    }
  }, [history, organizationId, searchQuery]);

  return (
    <div>
      {profile && (
        <div
          className={!isMobile ? classes.wrapper : classes.wrapperMobile}
          style={{
            marginBottom:
              location.pathname === Routes.EditProfile ? '160px' : '0px',
          }}
        >
          {!isLoading ? (
            <>
              {!isMobile && (
                <AvatarSection
                  isCurrentUser={!searchQuery}
                  profile={searchQuery && userProfile ? userProfile : profile}
                />
              )}

              <ProfileSection
                userId={
                  location.pathname.includes('edit')
                    ? searchQuery
                      ? userProfile?.userId
                      : profile.userId
                    : undefined
                }
                organizationId={currentOrganizationId}
                isCurrentUser={!searchQuery}
                isMobile={isMobile}
                profile={searchQuery && userProfile ? userProfile : profile}
              />
              {location.pathname.includes('profile') &&
                !location.pathname.includes('edit') && (
                  <div className={isMobile ? classes.editMobile : ''}>
                    <Button
                      variant="primaryProfile"
                      onClick={handleEditDetailsClick}
                    >
                      <CustomTypography
                        variant="subtitle2"
                        bold
                        color="mbsBlue"
                      >
                        {t(translations.profilePage.editDetails)}
                      </CustomTypography>
                    </Button>
                  </div>
                )}
            </>
          ) : (
            <div className={classes.loading}>
              <CircularProgress withLabel />
            </div>
          )}
        </div>
      )}
      <DownloadAtlasAppSection isLoading={isLoading} />
    </div>
  );
}
