import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IResponseData, IProfile, IMetaData, IError, IApiParams } from 'types';
import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const teammatesQueryKey = 'getTeammates';

export function useTeammatesApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getTeammatesUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/users/get-teammates-paged`;
  }, [currentSupplierId]);

  const {
    data: teammatesResponse,
    refetch: fetchTeammates,
    isLoading: isFetchingTeammates,
    error: fetchTeammatesError,
  } = useQuery<IResponseData<IProfile[]>, IError>(
    [teammatesQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getTeammatesUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getTeammates, error, {
          getTeammatesUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const teammates = useMemo<IProfile[] | undefined>(
    () => teammatesResponse?.data,
    [teammatesResponse],
  );
  const teammatesMetadata = useMemo<IMetaData | undefined>(
    () => teammatesResponse?.metadata,
    [teammatesResponse],
  );

  return {
    teammatesMetadata,
    teammates,
    teammatesResponse,
    fetchTeammates,
    isFetchingTeammates,
    fetchTeammatesError,
  };
}
