import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { useReportConfig } from '../hooks';
import { useReportsApi } from 'app/shared/hooks';
import { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { sortInAlphabeticalOrder } from 'app/shared/utils';
import { sortNumbersInOrder } from 'app/shared/utils/sortNumbersInOrder';
import { convertMinutesFraction } from '../utils/convertMinutesFraction';

export const SuppliersReport = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    renderSupplierReportCell,
    supplierReportColumns,
    refetchSupplierReports,
  } = useReportConfig();
  const sortType = new URLSearchParams(location.search).get('sort') || 'desc';
  const sortColumn =
    new URLSearchParams(location.search).get('column') || 'providerAvgDelay';

  const { fetchSupplierReports, supplierReports, isFetchingSupplierReports } =
    useReportsApi();

  const sortHandler = useCallback(
    (id: string) => {
      const params = new URLSearchParams({
        ['sort']: sortType === 'asc' ? 'desc' : 'asc',
        ['column']: id,
      });

      history.push({
        pathname: location.pathname,
        search: `${params}`,
      });
    },
    [history, sortType],
  );

  const sortedSupplierReports = useMemo(() => {
    let sorted = [];
    switch (sortColumn) {
      case 'supplierName':
        sorted = supplierReports
          ? sortInAlphabeticalOrder(
              sortType || 'desc',
              supplierReports,
              'supplierName',
            )
          : [];
        break;
      case 'totalTickets':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'totalTickets',
            )
          : [];
        break;
      case 'totalEvents':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'totalEvents',
            )
          : [];
        break;
      case 'providerAvgDelay':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'providerDelayData',
              'avgDelayInMin',
            )
          : [];
        break;
      case 'providerMaxDelay':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'providerDelayData',
              'maxDelayInMin',
            )
          : [];
        break;
      case 'queueAvgDelay':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'queueDelayData',
              'avgDelayInMin',
            )
          : [];
        break;
      case 'queueMaxDelay':
        sorted = supplierReports
          ? sortNumbersInOrder(
              sortType || 'desc',
              supplierReports,
              'queueDelayData',
              'maxDelayInMin',
            )
          : [];
        break;
      default:
        break;
    }

    return sorted.map(el => {
      return {
        ...el,
        providerAvgDelay: el.providerDelayData?.avgDelayInMin
          ? convertMinutesFraction(el.providerDelayData?.avgDelayInMin)
          : undefined,
        providerMaxDelay: el.providerDelayData?.maxDelayInMin
          ? convertMinutesFraction(el.providerDelayData?.maxDelayInMin)
          : undefined,
        queueAvgDelay: el.queueDelayData?.avgDelayInMin
          ? convertMinutesFraction(el.queueDelayData?.avgDelayInMin)
          : undefined,
        queueMaxDelay: el.queueDelayData?.maxDelayInMin
          ? convertMinutesFraction(el.queueDelayData?.maxDelayInMin)
          : undefined,
      };
    });
  }, [supplierReports, sortType, sortColumn]);

  useEffect(() => {
    fetchSupplierReports({});
  }, [fetchSupplierReports]);

  useEffect(() => {
    if (refetchSupplierReports.get) {
      fetchSupplierReports({});
      refetchSupplierReports.set(false);
    }
  }, [fetchSupplierReports, refetchSupplierReports]);

  return (
    <div>
      <SectionSpoiler
        header={t(translations.reportPage.suppliersReport)}
        withIcons={false}
      >
        <Table
          columns={supplierReportColumns}
          tableData={sortedSupplierReports}
          withCheckbox={false}
          renderCellWithIndexOfRow={renderSupplierReportCell}
          withColoredRows={true}
          withPagination={false}
          isLoading={isFetchingSupplierReports}
          emptyTableText={t(translations.reportPage.noDataAvailable)}
          headRowsCount={2}
          withHover={true}
          sortHandler={sortHandler}
        />
      </SectionSpoiler>
    </div>
  );
};
