import { Control } from 'react-hook-form';
import { IHeadCell } from '../Table/Table.types';

export enum PopoverSize {
  BIG,
  SMALL,
}

export interface IOption {
  label: string;
  value: string;
}

export interface IMenuProps {
  valueId?: string;
  labelId?: string;
  options: any;
  selectedValues?: string[];
  isLoading?: boolean;
  popoverSize?: PopoverSize;
  withPagination?: boolean;
  isLoadingNext?: boolean;
  showLoader?: boolean;
  value?: string;
  isMultiSelect?: boolean;
  error?: boolean;
  control?: Control<any, any>;
  helperText?: string;
  name?: string;
  className?: string;
  selectedItems?: { id: string; label: string }[];
  popoverClassName?: string;
  searchQuery?: string;
  columns?: IHeadCell[];
  renderCell?: (
    rowData: any,
    cellId: string,
    isHovered?: boolean | undefined,
    label?: string | undefined,
  ) => any;
  customValueHandler?: (value: string) => void;
  onChangeSelectedValues?: (
    values: string[],
    items?: { id: string; label: string }[],
  ) => void;
  onSearchHandler?: (value: string) => void;
  loadMoreHandler?: () => void;
  onChangeSelectedValue?: (value: string) => void;
  clickHandler?: () => void;
}

export interface ICustomSelectProps {
  valueId?: string;
  labelId?: string;
  options: any;
  selectAllButtonText?: string;
  selectedValues?: string[];
  isLoading?: boolean;
  popoverSize?: PopoverSize;
  withPagination?: boolean;
  isLoadingNext?: boolean;
  showLoader?: boolean;
  value?: string;
  isMultiSelect?: boolean;
  error?: boolean;
  control?: Control<any, any>;
  helperText?: string;
  name?: string;
  columns: IHeadCell[];
  selectedItems?: string[];
  renderCell?: (
    rowData: any,
    cellId: string,
    isHovered?: boolean,
    label?: string,
  ) => any;

  changeSelectedState?: (id: string[]) => void;
  selectedItemsHandler?: (ids: string[]) => void;
  customValueHandler?: (value: string) => void;
  onChangeSelectedValues?: (values: string[]) => void;
  onSearchHandler?: (value: string) => void;
  loadMoreHandler?: () => void;
  onChangeSelectedValue?: (value: string) => void;
  selectAllHandler?: () => void;
}
