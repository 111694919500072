import jwt_decode from 'jwt-decode';
import { ITokenData } from 'types';

const milliMultiplier = 1000;

export function getTokenData(accessToken: string) {
  return jwt_decode<ITokenData>(accessToken);
}

export function isJwtExpired(jwt?: string): boolean {
  if (!jwt) {
    return true;
  }

  const token = getTokenData(jwt);

  return Date.now() >= token?.exp * milliMultiplier;
}
