import { Checkbox, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { useStyles } from './Select.styles';
import { CustomTypography } from '../Typography/Typography';
import { Select as MuiSelect } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { CircularProgress } from '../CircularProgress';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

interface ISelectProps {
  valueId: string;
  labelId: string;
  value?: string | number | boolean;
  onChange: (e: any) => void;
  label: string;
  disabled?: boolean;
  values?: any[];
  className?: string;
  height?: number;
  multiSelect?: boolean;
  selectedValues?: string[];
  isLoading?: boolean;
  deleteHandler?: () => void;
  fullWidth?: boolean;
}

const defaultHeight = 35;
const negativeNumber = -1;

export const Select = ({
  valueId,
  labelId,
  value,
  values,
  label,
  disabled,
  onChange,
  className,
  height,
  multiSelect,
  selectedValues,
  isLoading,
  deleteHandler,
  fullWidth,
}: ISelectProps) => {
  const classes = useStyles();

  const renderValueHandler = useCallback(() => {
    const selectedValue = values?.find(el => {
      return (
        el[valueId]?.toString() === value?.toString() || el[valueId] === value
      );
    });

    return multiSelect || value === undefined || !selectedValue
      ? ''
      : selectedValue[labelId];
  }, [labelId, multiSelect, value, valueId, values]);

  const currentValue = useMemo(() => {
    return values ? value : '';
  }, [value, values]);

  return (
    <div style={{ width: fullWidth ? '100%' : 'unset' }}>
      <FormControl
        variant="outlined"
        className={className || classes.formControl}
      >
        {value && deleteHandler ? (
          <div onClick={deleteHandler} className={classes.deleteButton}>
            <CloseIcon />
          </div>
        ) : null}

        {(value === undefined ||
          !values?.find(el => el[valueId]?.toString() === value?.toString()) ||
          multiSelect) && (
          <InputLabel
            shrink={false}
            disableAnimation={true}
            classes={{
              outlined: height ? classes.labelRootBigger : classes.labelRoot,
            }}
          >
            <CustomTypography variant="bodySmall" color="accentDark">
              {label}
            </CustomTypography>
          </InputLabel>
        )}

        <MuiSelect
          displayEmpty
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={multiSelect ? selectedValues : currentValue}
          variant="outlined"
          disabled={disabled}
          onChange={onChange}
          defaultValue=""
          name="select"
          multiple={multiSelect}
          renderValue={renderValueHandler}
          className={classes.select}
          style={{
            height: height || defaultHeight,
            top: 0,
          }}
          MenuProps={{
            getContentAnchorEl: null,
            autoFocus: true,
            disableAutoFocusItem: false,
            variant: 'menu',
            classes: {
              paper: multiSelect ? classes.menu : classes.defaultMenu,
            },
          }}
        >
          {!isLoading && values?.length ? (
            values?.map((item, index) => (
              <MenuItem
                value={item[valueId]}
                key={index}
                classes={{ root: classes.menuItem, selected: classes.menuItem }}
                disableRipple={true}
              >
                {multiSelect && selectedValues && (
                  <Checkbox
                    disableRipple
                    size="small"
                    classes={{
                      root: classes.checkbox,
                      colorSecondary: classes.icon,
                      checked: classes.checkedIcon,
                    }}
                    checked={
                      selectedValues.indexOf(item[valueId]) > negativeNumber
                    }
                  />
                )}

                <CustomTypography variant="bodySmall" color="accentDark">
                  {item[labelId]}
                </CustomTypography>
              </MenuItem>
            ))
          ) : (
            <MenuItem className={isLoading ? classes.loading : classes.empty}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <CustomTypography variant="bodySmall" color="greyscale3">
                  No Data
                </CustomTypography>
              )}
            </MenuItem>
          )}
        </MuiSelect>
      </FormControl>
    </div>
  );
};
