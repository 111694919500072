import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  formControl: {
    minWidth: '67px',
    minHeight: '38px',
    marginLeft: '10px',
    backgroundColor: themeColors.white,
  },

  select: {
    padding: '9px 12px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    color: themeColors.accentDark,
    paddingTop: '32px',
    backgroundColor: themeColors.greyscale5,
  },

  icon: {
    height: '16px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pages: {
    margin: '0px 22px 0px 22px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
