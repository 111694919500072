import { Grow } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useStyles } from './SectionSpoiler.styles';
import { ReactComponent as MinusSquare } from 'assets/minus_square.svg';
import { ReactComponent as PlusSquare } from 'assets/plus_square.svg';
import { CustomTypography } from '../Typography/Typography';
import { ViewMode } from 'app/pages/projects/types';
import {
  ActionButton,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';

interface ISubHeader {
  value?: string;
  element?: JSX.Element;
  onClickHandler?: () => void;
}
interface ISpoilerProps {
  header?: string;
  subHeaders?: ISubHeader[];
  withIcons?: boolean;
  margins?: number;
  isFlex?: boolean;
  view?: ViewMode;
  withBackground?: boolean;
  handleSpoilerClick?: () => void;
  actions?: IAction[];
  isActionsDisabled?: boolean;
  isOpenState?: boolean;
}

const timeoutOpen = 100;
const timeoutClose = 300;
const firstItemIndex = 0;

export const SectionSpoiler = ({
  header,
  subHeaders,
  withIcons = true,
  margins,
  isFlex,
  view,
  children,
  withBackground,
  actions,
  isActionsDisabled,
  isOpenState = true,
  handleSpoilerClick,
}: PropsWithChildren<ISpoilerProps>) => {
  const classes = useStyles();
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(isOpenState ?? true);
  const [containerClass, setContainerClass] = useState(
    isOpenState ? classes.container : classes.containerClosed,
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isOpen) {
      if (isFlex) {
        setContainerClass(classes.flex);
      } else {
        setContainerClass(classes.container);
      }

      if (withIcons) {
        timeoutId = setTimeout(() => {
          setIsReady(true);
        }, timeoutOpen);
      } else {
        setIsReady(true);
      }
    } else {
      if (withIcons) {
        setIsReady(false);
      }

      timeoutId = setTimeout(() => {
        setContainerClass(classes.containerClosed);
      }, timeoutClose);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [classes, isFlex, isOpen, withIcons]);

  const spoilerClickHandler = useCallback(() => {
    setIsOpen(prevState => !prevState);
    handleSpoilerClick && handleSpoilerClick();
  }, [handleSpoilerClick]);

  return (
    <>
      {header && (
        <div
          className={
            withBackground
              ? classes.alternativeHeader
              : view === ViewMode.CARD_VIEW
              ? classes.subheader
              : classes.subheaderList
          }
          data-testid="subheader"
        >
          <CustomTypography
            variant={withBackground ? 'subtitle2' : 'header4'}
            bold
            color={withBackground ? 'greyscale1' : 'accentDark'}
          >
            {header}
          </CustomTypography>

          <div className={subHeaders && classes.alternativeHeaderRight}>
            {isOpen &&
              subHeaders &&
              subHeaders.map((item, index) => (
                <div
                  key={index}
                  className={
                    index !== firstItemIndex ? classes.iconContainer : ''
                  }
                >
                  {item.element ? (
                    item.element
                  ) : (
                    <div
                      className={classes.linkContainer}
                      onClick={item.onClickHandler}
                    >
                      <CustomTypography
                        variant="buttonTextSmall"
                        bold
                        color="mbsBlue"
                      >
                        {item.value}
                      </CustomTypography>
                    </div>
                  )}
                </div>
              ))}

            {isOpen && withIcons && (
              <div className={subHeaders && classes.iconContainer}>
                <MinusSquare
                  stroke={themeColors.greyscale1}
                  className={classes.minusIcon}
                  data-testid="minus"
                  onClick={spoilerClickHandler}
                />
              </div>
            )}
            {!isOpen && withIcons && (
              <div className={subHeaders && classes.iconContainer}>
                <PlusSquare
                  stroke={themeColors.greyscale1}
                  className={classes.plusIcon}
                  data-testid="plus"
                  onClick={spoilerClickHandler}
                />
              </div>
            )}

            {actions && (
              <div className={classes.actionButton}>
                <ActionButton options={actions} disabled={isActionsDisabled} />
              </div>
            )}
          </div>
        </div>
      )}

      <Grow in={isReady}>
        <div
          className={containerClass}
          style={{
            marginLeft: margins ? `-${margins}px` : '',
            marginRight: margins ? `-${margins}px` : '',
            marginBottom: isOpen ? '10px' : '0px',
          }}
        >
          {children}
        </div>
      </Grow>
    </>
  );
};
