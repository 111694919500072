import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  pourEventCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  sentNotificationsLink: {
    cursor: 'pointer',
  },
});
