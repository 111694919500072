const valuesEqualFlag = 0;
const valueIsBiggerFlag = 1;
const valueIsLessFlag = -1;

export const sortNumbersInOrder = (
  orderType: string,
  values: any[],
  label: string,
  labelNestedProperty?: string,
) => {
  const sort = values.sort((valueA: any, valueB: any): number => {
    let first: number = valuesEqualFlag;
    let second: number = valuesEqualFlag;

    if (labelNestedProperty) {
      first = valueA[label][labelNestedProperty];
      second = valueB[label][labelNestedProperty];
    } else {
      first = valueA[label];
      second = valueB[label];
    }

    return orderType === 'asc'
      ? first < second
        ? valueIsLessFlag
        : first > second
        ? valueIsBiggerFlag
        : valuesEqualFlag
      : second < first
      ? valueIsLessFlag
      : second > first
      ? valueIsBiggerFlag
      : valuesEqualFlag;
  });

  return sort;
};
