export const validationHelper = () => {
  const emailRegex =
    /^[_a-zA-Z0-9-]+([.a-zA-Z0-9-]+)@[a-zA-Z0-9-]+([.a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
  return emailRegex;
};

export const teamsEmailValidationHelper = () => {
  const teamsEmailRegex =
    /^[_a-zA-Z0-9-]+([.a-zA-Z0-9-]+)@[a-zA-Z0-9-]*.teams.ms\b/;
  return teamsEmailRegex;
};
