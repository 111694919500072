import { translations } from 'app/locales/i18n';
import {
  CellAlignment,
  CellSize,
  IHeadCell,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from '../Report.styles';
import { HealthCheckStatus, Routes } from 'types';
import { firebaseAnalytics } from 'app/shared/analytics';

const defaultMargin = 0;

export const useReportConfig = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [refetchBatches, setRefetchBatches] = useState(false);
  const [refetchTickets, setRefetchTickets] = useState(false);
  const [refetchEvents, setRefetchEvents] = useState(false);
  const [refetchSupplierReports, setRefetchSupplierReports] = useState(false);

  const button = useMemo(() => {
    return (
      <div className={classes.refreshButton}>
        <RefreshIcon className={classes.refreshIcon} />
        <CustomTypography variant="subtitle2" bold color="mbsBlue">
          {t(translations.reportPage.refresh)}
        </CustomTypography>
      </div>
    );
  }, [classes.refreshButton, classes.refreshIcon, t]);

  const supplierReportColumns: IHeadCell[] = useMemo(() => {
    return [
      {
        id: 'empty',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        colSpan: 3,
        buttons: [
          {
            button: button,
            onClick: () => {
              firebaseAnalytics.logPressEvent(
                Routes.Reports,
                'Refresh Supplier Report',
              );

              setRefetchSupplierReports(true);
            },
          },
        ],
      },
      {
        id: 'supplierName',
        label: t(translations.reportPage.supplierName),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'totalTickets',
        label: t(translations.reportPage.totalTickets),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'totalEvents',
        label: t(translations.reportPage.totalEvents),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'providerDelay',
        label: t(translations.reportPage.provideDelay),
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        colSpan: 2,
        rowNumber: 1,
      },
      {
        id: 'providerAvgDelay',
        label: t(translations.reportPage.avgDelay),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'providerMaxDelay',
        label: t(translations.reportPage.maxDelay),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'queueDelay',
        label: t(translations.reportPage.queueDelay),
        alignment: CellAlignment.CENTER,
        size: CellSize.MEDIUM,
        colSpan: 2,
        rowNumber: 1,
      },
      {
        id: 'queueAvgDelay',
        label: t(translations.reportPage.avgDelay),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
      {
        id: 'queueMaxDelay',
        label: t(translations.reportPage.maxDelay),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
        sortable: true,
      },
    ];
  }, [button, t]);

  const ticketColumns: IHeadCell[] = useMemo(() => {
    return [
      {
        id: 'empty',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        colSpan: 2,
        buttons: [
          {
            button: <div>{button}</div>,
            onClick: () => {
              firebaseAnalytics.logPressEvent(
                Routes.Reports,
                'Refresh Tickets health check',
              );

              setRefetchTickets(true);
            },
          },
        ],
      },
      {
        id: 'ticketNumber',
        label: t(translations.reportPage.ticketNumber),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
      {
        id: 'crn',
        label: t(translations.reportPage.crn),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
    ];
  }, [button, t]);

  const batchColumns: IHeadCell[] = useMemo(() => {
    return [
      {
        id: 'empty',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        colSpan: 3,
        buttons: [
          {
            button: <div>{button}</div>,
            onClick: () => {
              firebaseAnalytics.logPressEvent(
                Routes.Reports,
                'Refresh Batches health check',
              );

              setRefetchBatches(true);
            },
          },
        ],
      },
      {
        id: 'crn',
        label: t(translations.reportPage.crn),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
      {
        id: 'id',
        label: t(translations.reportPage.id),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
      {
        id: 'status',
        label: t(translations.reportPage.status),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
    ];
  }, [button, t]);

  const eventColumns: IHeadCell[] = useMemo(() => {
    return [
      {
        id: 'empty',
        label: '',
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 1,
        colSpan: 3,
        buttons: [
          {
            button: <div>{button}</div>,
            onClick: () => {
              firebaseAnalytics.logPressEvent(
                Routes.Reports,
                'Refresh Events health check',
              );

              setRefetchEvents(true);
            },
          },
        ],
      },
      {
        id: 'crn',
        label: t(translations.reportPage.crn),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
      {
        id: 'ticketNumber',
        label: t(translations.reportPage.ticketNumber),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
      {
        id: 'eventStatuses',
        label: t(translations.reportPage.ticketStatus),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        rowNumber: 2,
      },
    ];
  }, [button, t]);

  const renderTicketCell = useCallback((rowData: any, cellId: string) => {
    return <div>{rowData[cellId] || ''}</div>;
  }, []);

  const renderEventCell = useCallback((rowData: any, cellId: string) => {
    if (cellId === 'eventStatuses') {
      return <div>{rowData[cellId]?.join(', ')?.replaceAll('_', ' ')}</div>;
    } else {
      return <div>{rowData[cellId] || ''}</div>;
    }
  }, []);

  const renderBatchCell = useCallback(
    (rowData: any, cellId: string) => {
      return (
        <div>
          {cellId === 'status'
            ? rowData[cellId] === HealthCheckStatus.MISSING
              ? t(translations.reportPage.missing)
              : t(translations.reportPage.invalid)
            : rowData[cellId] || ''}
        </div>
      );
    },
    [t],
  );

  const renderSupplierReportCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId.toLocaleLowerCase().endsWith('delay')) {
        if (!rowData[cellId]) {
          return <div>{'-'}</div>;
        }
        const minutes = rowData[cellId].minutes;
        const seconds = rowData[cellId].seconds;
        const milliseconds = rowData[cellId].milliseconds;

        return (
          <div
            style={{
              marginLeft: defaultMargin,
            }}
          >
            {minutes} min {seconds} sec {milliseconds} ms
          </div>
        );
      }
      return <div>{rowData[cellId] || '-'}</div>;
    },
    [],
  );

  return {
    ticketColumns,
    batchColumns,
    eventColumns,
    supplierReportColumns,
    renderSupplierReportCell,
    renderBatchCell,
    renderTicketCell,
    renderEventCell,
    refetchBatches: { get: refetchBatches, set: setRefetchBatches },
    refetchEvents: { get: refetchEvents, set: setRefetchEvents },
    refetchTickets: { get: refetchTickets, set: setRefetchTickets },
    refetchSupplierReports: {
      get: refetchSupplierReports,
      set: setRefetchSupplierReports,
    },
  };
};
