export const resetLocalStorage = () => {
  localStorage.setItem('batchesFrom', 'null');
  localStorage.setItem('batchesTo', '');
  localStorage.setItem('batchesPlantIds', '');
  localStorage.setItem('batchesTruckIds', '');
  localStorage.setItem('projectGID', '');
  localStorage.setItem('plantIds', '');
  localStorage.setItem('PLANT_GROUP_ID', '');

  localStorage.setItem('from', 'null');
  localStorage.setItem('mixIds', '');
  localStorage.setItem('ticketId', '');
  localStorage.setItem('to', '');
  localStorage.setItem('notificationState', '');

  localStorage.setItem('status1', '');
  localStorage.setItem('status2', '');
  localStorage.setItem('status3', '');
  localStorage.setItem('status4', '');

  localStorage.setItem('property1', '');
  localStorage.setItem('property2', '');
  localStorage.setItem('property3', '');
  localStorage.setItem('property4', '');

  localStorage.setItem('1', 'null');
  localStorage.setItem('2', 'null');
  localStorage.setItem('3', 'null');
  localStorage.setItem('4', 'null');
  localStorage.setItem('5', 'null');
  localStorage.setItem('6', 'null');
  localStorage.setItem('0', 'null');
};
