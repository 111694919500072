import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  search: {
    marginRight: 20,
    width: '100%',
  },
  gridLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  gridRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  labelContainer: {
    marginLeft: 5,
  },
  viewButtons: {
    marginLeft: 20,
  },
  subheader: {
    margin: '30px 0px 17px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  pourEvents: {
    marginTop: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  button: {
    color: themeColors.greyscale1,
    padding: 0,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  icon: { height: '34px', width: '24px' },
  iconClicked: {
    height: '34px',
    width: '24px',
    backgroundColor: themeColors.greyscale4,
  },
  span: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  empty: {
    paddingTop: 10,
  },
});
