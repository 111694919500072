import { Select } from 'app/shared/components/generic-ui';
import {
  InputSize,
  TextInput,
} from 'app/shared/components/generic-ui/TextInput/TextInput';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useStyles } from './AddCompressiveStrengthModal.styles';
import { IProbeRanges, Routes } from 'types';
import { themeColors } from 'app/shared/theme/theme';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useManualProbeApi, useProbeDataFormatter } from 'app/shared/hooks';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  ProgressType,
} from 'app/shared/components/generic-ui/CircularProgress';
import { SuccessPage } from '../SuccessPage';
import { useCompressiveStrengthValidation } from '../hooks/useCompressiveStrengthValidation';
import { firebaseAnalytics } from 'app/shared/analytics';

interface IAddCompressiveStrengthModalProps {
  probeRanges: IProbeRanges;
  crn: string;
  closeHandler: () => void;
}

const defaultAgeType = 0;
const hoursForOneDay = 24;
const oneHour = 1;

const formDefaultValues = {
  age: '',
  compressiveStrength: '',
};

export const AddCompressiveStrengthModal = ({
  probeRanges,
  crn,
  closeHandler,
}: IAddCompressiveStrengthModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [age, setAge] = useState('');
  const [compressiveStrength, setCompressiveStrength] = useState('');
  const { organizationId }: { organizationId: string } = useParams();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const { validationSchema, ageType, setAgeType } =
    useCompressiveStrengthValidation(probeRanges);
  const { getTranslatedUnit } = useProbeDataFormatter();

  const { createManualProbe, isCreateManualProbe } = useManualProbeApi({
    organizationId: organizationId,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const onSubmit = useCallback(
    async (data: any) => {
      const payload = {
        crn: crn,
        compressiveStrength: {
          measurement: probeRanges.compressiveStrRange.measurement,
          value: data.compressiveStrength,
        },
        hoursAfterPourEvent:
          ageType === defaultAgeType ? data.age * hoursForOneDay : data.age,
      };

      createManualProbe(payload).then(res => {
        setIsSuccessful(!!res?.data);

        firebaseAnalytics.logPressEvent(
          Routes.SinglePourEvent,
          'Add compressive strength',
        );
      });

      return null;
    },
    [
      ageType,
      createManualProbe,
      crn,
      probeRanges.compressiveStrRange.measurement,
    ],
  );

  const ageTypeOptions = useMemo(() => {
    return [
      { value: 0, label: t(translations.addCompressiveStrength.days) },
      { value: 1, label: t(translations.addCompressiveStrength.hours) },
    ];
  }, [t]);

  const ageOptions = useMemo(() => {
    return ageType === defaultAgeType
      ? probeRanges?.probeDays
      : Array.from({ length: hoursForOneDay }, (v, k) => k + oneHour);
  }, [ageType, probeRanges?.probeDays]);

  const changeAgeHandler = useCallback(
    (value: string) => {
      setValue('age', value);
      clearErrors('age');
      setAge(value);
    },
    [clearErrors, setValue],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      {!isCreateManualProbe && !isSuccessful ? (
        <div className={classes.wrapper}>
          <div className={classes.inputs}>
            <div className={classes.row}>
              <div className={classes.input}>
                <div>
                  <CustomTypography variant="caption1" color="greyscale1">
                    {t(translations.addCompressiveStrength.type)}
                  </CustomTypography>
                </div>

                <Select.Base
                  onChange={e => {
                    setAgeType(e.target.value);
                  }}
                  value={ageType}
                  values={ageTypeOptions}
                  valueId="value"
                  labelId="label"
                  label={'Days'}
                  height={44}
                  multiSelect={false}
                />
              </div>

              <div className={classes.input}>
                <div>
                  <CustomTypography variant="caption1" color="greyscale1">
                    {t(translations.addCompressiveStrength.age)}
                  </CustomTypography>
                </div>

                <Select.Search
                  name={'age'}
                  control={control}
                  value={age}
                  error={!!errors.age}
                  helperText={errors.age?.message || ''}
                  options={ageOptions}
                  onChangeSelectedValue={changeAgeHandler}
                  customValueHandler={setAge}
                  isMultiSelect={false}
                />
              </div>
            </div>

            <div className={classes.input}>
              <div>
                <CustomTypography variant="caption1" color="greyscale1">
                  {`${t(
                    translations.addCompressiveStrength.value,
                  )} (${getTranslatedUnit(
                    probeRanges.compressiveStrRange.measurement,
                  )})`}
                </CustomTypography>
              </div>

              <TextInput
                name="compressiveStrength"
                control={control}
                error={!!errors.compressiveStrength}
                helperText={errors.compressiveStrength?.message || ''}
                customSize={InputSize.BIG}
                onChangeHandler={e =>
                  setCompressiveStrength(e?.target.value || '')
                }
                currentValue={compressiveStrength}
                autoComplete="off"
                placeholder="Type here"
                borderRadius={2}
              />
            </div>
          </div>

          <div className={classes.infoWrapper}>
            <div className={classes.info}>
              <div>
                <InfoIcon
                  stroke={themeColors.mbsBlue}
                  className={classes.icon}
                />
              </div>

              <CustomTypography variant="bodyRegular" color="accentLight">
                {t(translations.addCompressiveStrength.keepInMind)}
              </CustomTypography>
            </div>

            <div className={classes.button}>
              <Button variant="primarySave" type="submit">
                <CustomTypography variant="buttonText" bold color="white">
                  {t(translations.addCompressiveStrength.saveData)}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </div>
      ) : !isSuccessful ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <SuccessPage
          buttonText={t(translations.batchDetailsModal.backToBatchInfo)}
          backHandler={closeHandler}
        />
      )}
    </form>
  );
};

export const AddCompressiveStrengthModalWrapper = (
  props: Omit<IAddCompressiveStrengthModalProps, 'probeRanges'>,
) => {
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const { fetchProbeRanges, probeRanges } = useManualProbeApi({
    organizationId: organizationId,
    queryParameters: {
      crn: props.crn,
    },
  });

  useEffect(() => {
    fetchProbeRanges();
  }, [fetchProbeRanges]);

  if (probeRanges)
    return <AddCompressiveStrengthModal {...props} probeRanges={probeRanges} />;

  return (
    <div className={classes.loading}>
      <CircularProgress withLabel size={40} type={ProgressType.LIGHT} />
    </div>
  );
};
