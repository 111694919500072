import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  labelRoot: {
    transform: 'translate(8px,8px) scale(1)',
  },
  labelRootBigger: {
    transform: 'translate(12px,12px) scale(1)',
  },
  root: {
    border: `1px solid ${themeColors.greyscale3}`,
  },
  select: {
    borderRadius: 2,
    '&:not(.Mui-disabled):hover::before': {
      borderColor: themeColors.greyscale3,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: themeColors.greyscale3,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: themeColors.mbsBlue,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: themeColors.greyscale3,
    },
  },
  formControl: {
    width: '100%',
    minHeight: 35,
    backgroundColor: themeColors.white,
    position: 'relative',
  },
  selectContainer: {
    marginTop: 0,
  },
  labelContainer: {
    marginLeft: 10,
  },
  emptyDropdown: {
    padding: 10,
  },
  menu: {
    marginTop: 50,
    maxHeight: '175px !important',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  defaultMenu: {
    marginTop: 42,
    maxHeight: '175px !important',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },

  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 14px 5px 0px',
  },
  menuItem: {
    backgroundColor: `${themeColors.white} !important`,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&:focus': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
    '&.Mui-active': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  empty: {
    display: 'flex',
    backgroundColor: `${themeColors.white} !important`,
  },
  deleteButton: {
    position: 'absolute',
    right: 30,
    height: 20,
    top: 12,
    width: 20,
    zIndex: 9999,
    cursor: 'pointer',
  },
});
