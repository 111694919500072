import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  disabled: {
    color: `${themeColors.greyscale3} !important`,
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  dotIcon: {
    color: themeColors.mbsBlue,
  },
  dotIconDisabled: {
    color: themeColors.greyscale3,
  },
  dotWrapper: {
    display: 'flex',
    cursor: 'pointer',
  },
  dotWrapperDisabled: {
    cursor: 'auto',
  },
  checkboxHeader: {
    width: 153,
    marginLeft: '0px',
    boxSizing: 'border-box',
  },
  checkboxHeaderVariant: {
    width: 100,
    marginLeft: '0px',
    boxSizing: 'border-box',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    justifyContent: 'left',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 15,
  },
  roles: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 250,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '10px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  zIndex: { zIndex: 9999 },
  label: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 14,
    textTransform: 'none',
  },
  button: { width: '100%' },
});
