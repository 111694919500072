import { Modal } from '@material-ui/core';
import {
  CircularProgress,
  ProgressType,
} from 'app/shared/components/generic-ui/CircularProgress';
import { useStyles } from './LoadingScreen.styles';

interface ILoadingScreenProps {
  text?: string;
}

export const LoadingScreen = ({ text }: ILoadingScreenProps) => {
  const classes = useStyles();

  return (
    <Modal open={true} className={classes.container}>
      <>
        <CircularProgress
          withLabel
          size={40}
          type={ProgressType.LIGHT}
          text={text}
        />
      </>
    </Modal>
  );
};
