import React from 'react';
import { useStyles } from './CircularProgress.styles';
import { CircularProgress as Progress } from '@material-ui/core';
import { CustomTypography } from '../Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

interface ICircularProgress {
  size?: number;
  withLabel?: boolean;
  type?: ProgressType;
  text?: string;
}

const defaultSize = 25;

export enum ProgressType {
  LIGHT,
  DARK,
}

export const CircularProgress = ({
  text,
  withLabel,
  size = defaultSize,
  type = ProgressType.DARK,
}: ICircularProgress) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Progress
        size={size}
        color={type === ProgressType.LIGHT ? 'primary' : 'secondary'}
      />
      {withLabel && (
        <div className={classes.label}>
          <CustomTypography
            variant="caption1"
            color={type === ProgressType.LIGHT ? 'white' : 'greyscale2'}
          >{`${t(translations.global.loading)}...`}</CustomTypography>

          {text && (
            <CustomTypography
              variant="caption1"
              color={type === ProgressType.LIGHT ? 'white' : 'greyscale2'}
            >
              {text}
            </CustomTypography>
          )}
        </div>
      )}
    </div>
  );
};
