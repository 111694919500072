import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 932,
  },
  settings: {
    display: 'flex',
    flexDirection: 'row',
    height: 160,

    backgroundColor: themeColors.white,
    marginLeft: 1,
    // marginBottom: 25,
  },
  header: {
    padding: '16px 16px 8px 0px',
    width: '100%',
    backgroundColor: themeColors.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${themeColors.greyscale3}`,
    boxSizing: 'border-box',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  activateAll: {
    cursor: 'pointer',
    paddingRight: 10,
    borderRight: `1px solid ${themeColors.greyscale3}`,
  },
  deactivateAll: {
    cursor: 'pointer',
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: `1px solid ${themeColors.greyscale3}`,
  },
  enableAll: {
    cursor: 'pointer',
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: `1px solid ${themeColors.greyscale3}`,
  },
  disableAll: {
    cursor: 'pointer',
    paddingLeft: 10,
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: 171,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
