import React, { useCallback, useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ExactBanner.styles';
import { ReactComponent as ArrowButton } from 'assets/arrow_button.svg';
import exactLogo from 'assets/exact_logo.png';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useScreen } from 'app/shared/hooks';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { externalLinksConfig } from 'app/shared/config/config';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';

const widthForMobileView = 500;

export const ExactBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMobile } = useScreen(widthForMobileView);

  const leftPart = useMemo(() => {
    return (
      <div className={isMobile ? classes.mobileWrapper : classes.wrapper}>
        {!isMobile && (
          <div className={classes.buttonContainer}>
            <ArrowButton height={30} width={30} />
          </div>
        )}
        <div
          className={isMobile ? classes.mobileTextWrapper : classes.textWrapper}
        >
          <CustomTypography variant="subtitle1" color="accentDark">
            <b>{t(translations.exactBanner.clickHere)}</b>{' '}
            {t(translations.exactBanner.forLive)}
          </CustomTypography>
        </div>
      </div>
    );
  }, [
    classes.buttonContainer,
    classes.mobileTextWrapper,
    classes.mobileWrapper,
    classes.textWrapper,
    classes.wrapper,
    isMobile,
    t,
  ]);

  const rightPart = useMemo(() => {
    return (
      <div className={isMobile ? classes.mobileWrapper : classes.wrapper}>
        <div className={classes.poweredBy}>
          <CustomTypography
            variant="caption1"
            color="accentDark"
            style={{ whiteSpace: 'nowrap' }}
          >
            <em>{t(translations.exactBanner.poweredBy)}</em>
          </CustomTypography>
        </div>

        <img src={exactLogo} className={classes.logo} />
      </div>
    );
  }, [
    classes.logo,
    classes.mobileWrapper,
    classes.poweredBy,
    classes.wrapper,
    isMobile,
    t,
  ]);

  const renderBanner = useMemo(() => {
    return isMobile ? (
      <>
        {rightPart} {leftPart}
      </>
    ) : (
      <>
        {leftPart} {rightPart}
      </>
    );
  }, [isMobile, leftPart, rightPart]);

  const handleOnClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Exact banner button',
    );

    return window.open(
      externalLinksConfig.exact,
      '_blank',
      'noopener,noreferrer',
    );
  }, []);

  return (
    <div
      className={isMobile ? classes.mobileContainer : classes.container}
      onClick={() => (isMobile ? null : handleOnClick())}
    >
      {renderBanner}

      {isMobile && (
        <Button variant="clickHere" onClick={handleOnClick}>
          <CustomTypography bold variant="buttonTextSmall" color="mbsBlue">
            {t(translations.exactBanner.clickHere)}
          </CustomTypography>
        </Button>
      )}
    </div>
  );
};
