import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme';

export const useStyles = makeStyles({
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  disabled: {
    color: `${themeColors.greyscale3} !important`,
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  checkboxCell: {
    padding: '0px 15px',
  },
  checkboxHeader: {
    width: 157,
    marginLeft: '0px',
    padding: '0px 15px',
    boxSizing: 'border-box',
  },
  checkboxHeaderVariant: {
    width: 50,
    marginLeft: '0px',
    padding: '0px 15px',
    boxSizing: 'border-box',
  },
  buttons: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: themeShadows.shadowSmall,
    padding: '5px 0px',
  },
  cancel: {
    paddingRight: 10,
    borderRight: `1px solid ${themeColors.greyscale3}`,
  },
  save: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  wrapper: {
    marginTop: 24,
  },
  info: {
    width: 20,
    marginRight: 3,
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 25,
    padding: 16,
  },
  errorContainer: { width: 70, display: 'flex', flexDirection: 'row' },
  errorText: { padding: '8px 16px' },
  mixId: {
    margin: '0px -16px',
    padding: '0px 12px',
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: themeColors.white,
  },
  mixIdHovered: {
    margin: '0px -16px',
    padding: '0px 12px',
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: themeColors.highlightBlue,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  modalFooter: {
    borderTop: `2px solid ${themeColors.greyscale4}`,
    display: 'flex',
    justifyContent: 'end',
    margin: '20px -24px 0px -24px',
    padding: '24px 24px 5px 24px',
  },
  tableWrapper: {
    display: 'flex',
    width: '100%',
    height: '75vh',
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  columnsSections: {
    backgroundColor: themeColors.white,
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
  },
});
