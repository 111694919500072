import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthenticationResult } from '@azure/msal-common';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { errors, loginRequest } from '../config';
import { InteractionStatus } from '@azure/msal-browser';
import { getTokenData, resetLocalStorage } from '../utils';
import { addSeconds, fromUnixTime } from 'date-fns';
import { firebaseAnalytics } from '../analytics';
import { EventsEnum, IError } from 'types';

const AuthContext = createContext<IAuthContext | null>(null);

AuthContext.displayName = 'AuthContext';

export enum UserRoles {
  MEMBER = 'member',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
}

export interface IAuthContext {
  isReady: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  userId: string;
  tenantId: string;
  supplierId: string;
  isSuperAdmin: boolean;
  isMember: boolean;
  isAdmin: boolean;
  roles: UserRoles[];
  getAccessToken: () => Promise<string | undefined>;
  isTokenExpired: () => boolean;
  signIn: () => Promise<void>;
  logout: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const { instance, inProgress: msalStatus, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [isMsalReady, setIsMsalReady] = useState<boolean>(false);
  const [authData, setAuthData] = useState<AuthenticationResult | null>(null);
  const [userId, setUserId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [tenantId, setTenantId] = useState('');
  const [supplierId, setSupplierId] = useState('');

  const isSuperAdmin = useMemo(() => {
    return roles.includes(UserRoles.SUPER_ADMIN);
  }, [roles]);

  const isAdmin = useMemo(() => {
    return (
      roles.includes(UserRoles.ADMIN) && !roles.includes(UserRoles.SUPER_ADMIN)
    );
  }, [roles]);

  const isMember = useMemo(() => {
    return (
      !roles.includes(UserRoles.ADMIN) && !roles.includes(UserRoles.SUPER_ADMIN)
    );
  }, [roles]);

  useEffect(() => {
    if (msalStatus === InteractionStatus.None && !isMsalReady) {
      setIsMsalReady(true);
    }
  }, [msalStatus, isMsalReady, setIsMsalReady]);

  const changeAuthData = useCallback(
    newAuthData => {
      let newUserId = '';
      let organizationId = '';

      if (newAuthData) {
        const { sub, appTenantId, supplierId } = getTokenData(
          newAuthData.accessToken,
        );

        newUserId = sub;
        organizationId = appTenantId;
        setSupplierId(supplierId);
        setRoles(newAuthData.idTokenClaims.roles);
      }

      firebaseAnalytics.setUserId(newUserId);

      setTenantId(organizationId);
      setUserId(newUserId);
      setAuthData(newAuthData);
    },
    [setUserId, setAuthData],
  );

  const isTokenExpired = useCallback(() => {
    const expirationDate = authData?.expiresOn;
    let result = false;

    if (expirationDate) {
      const currentDate = new Date();

      result = currentDate >= expirationDate;
    }

    return result;
  }, [authData]);

  const isRefreshTokenExpired = useCallback(() => {
    let result = false;

    if (authData) {
      const parameters: { [name: string]: string } =
        // @ts-ignore
        authData[
          authData.hasOwnProperty('tokenAdditionalParameters')
            ? 'tokenAdditionalParameters'
            : 'additionalParameters'
        ] ?? {};
      const startTimestamp = parameters?.not_before;
      const expiresIn = parameters?.refresh_token_expires_in;

      const expirationDate = addSeconds(
        fromUnixTime(+startTimestamp),
        +expiresIn,
      );
      const currentDate = new Date();

      result = currentDate >= expirationDate;
    }

    return result;
  }, [authData]);

  const signIn = useCallback(async () => {
    setIsLoading(true);
    resetLocalStorage();

    try {
      await instance.loginRedirect(loginRequest);

      firebaseAnalytics.logEvent(EventsEnum.signIn, {
        auth_type: 'loginRedirect',
      });
    } catch (error: any) {
      const { message } = error;

      console.log('unexpected error during sign in: ', message);
      firebaseAnalytics.logErrorEvent(errors.signIn, error);
    } finally {
      setIsLoading(false);
    }
  }, [instance, setIsLoading]);

  const logout = useCallback(async () => {
    setIsLoading(true);

    return new Promise<void>((resolve, reject) => {
      return instance
        .logoutRedirect({
          postLogoutRedirectUri: '/',
        })
        .then(() => resolve())
        .catch(() => {
          //We may not show anything in case of error during token revoke
          reject();
        })
        .finally(() => {
          changeAuthData(null);
          setIsLoading(false);
        });
    });
  }, [instance, changeAuthData, setIsLoading]);

  const sendRefreshTokenRequest = useCallback(async () => {
    try {
      const newAuthData = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts.length ? accounts[0] : undefined,
      });

      const newToken = newAuthData.accessToken;

      changeAuthData(newAuthData);

      firebaseAnalytics.logEvent(EventsEnum.signIn, {
        auth_type: 'refresh_token',
      });

      return newToken;
    } catch (error) {
      console.log('refresh token error', error);
      firebaseAnalytics.logErrorEvent(errors.refreshToken, error as IError);
      logout();
    }
  }, [instance, accounts, changeAuthData, logout]);

  useEffect(() => {
    if (isAuthenticated && isMsalReady && !authData) {
      sendRefreshTokenRequest();
    }
  }, [isAuthenticated, isMsalReady, authData, sendRefreshTokenRequest]);

  const getAccessToken = useCallback(async () => {
    let result = authData?.accessToken;

    if (authData && isTokenExpired()) {
      if (isRefreshTokenExpired()) {
        await logout();
      } else {
        result = await sendRefreshTokenRequest();
      }
    }

    return result;
  }, [
    authData,
    isTokenExpired,
    isRefreshTokenExpired,
    sendRefreshTokenRequest,
    logout,
  ]);

  const contextValue = useMemo(() => {
    return {
      isReady: isMsalReady && (!isAuthenticated || !!authData),
      isAuthenticated: isMsalReady && isAuthenticated && !!authData,
      isLoading,
      userId,
      roles,
      tenantId,
      getAccessToken,
      isTokenExpired,
      signIn,
      isSuperAdmin,
      isMember,
      isAdmin,
      supplierId,
      logout,
    };
  }, [
    isMsalReady,
    isAuthenticated,
    authData,
    isLoading,
    userId,
    roles,
    tenantId,
    getAccessToken,
    isTokenExpired,
    signIn,
    isSuperAdmin,
    isMember,
    isAdmin,
    supplierId,
    logout,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('AuthProvider must be in scope when useAuth is used.');
  }

  return context;
}
