import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

export const useStylesDark = makeStyles({
  button: {
    color: themeColors.greyscale1,
    padding: 0,
    borderRadius: 2,
    border: `1px solid ${themeColors.mbsBlue}`,
    '&:hover': {
      backgroundColor: themeColors.highlightBlue,
    },
  },
  icon: { height: '34px', width: '24px', color: themeColors.mbsBlue },
  iconDisabled: {
    height: '34px',
    width: '24px',
    color: themeColors.accentLight,
  },
  iconClicked: {
    height: '34px',
    width: '24px',
    backgroundColor: themeColors.mbsBlue,
    borderRadius: 2,
    color: themeColors.white,
  },
  span: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },

  menuList: { marginTop: 4 },
});

export const useStylesLight = makeStyles({
  paper: {
    width: '230px',
    marginTop: '45px',
    backgroundColor: themeColors.white,
    boxShadow: themeShadows.shadowMedium,
    borderRadius: '2px',
    zIndex: 1000,
  },
  button: {
    color: themeColors.mbsBlue,
    padding: 0,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: themeColors.highlightBlue,
    },
  },
  buttonDisabled: {
    color: themeColors.mbsBlue,
    padding: 0,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: themeColors.greyscale5,
    },
  },
  icon: { height: '34px', width: '24px' },
  iconClicked: {
    height: '34px',
    width: '24px',
    backgroundColor: themeColors.highlightBlue,
  },
  span: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  iconDisabled: {
    height: '34px',
    width: '24px',
    color: themeColors.accentLight,
  },
  menuList: { marginTop: 4 },
  zIndex: { zIndex: 9999 },
});
