import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  greyCircle: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    marginRight: '6px',
    marginBottom: '1px',
    backgroundColor: themeColors.greyscale3,
    border: `1px solid ${themeColors.greyscale3}`,
  },
  greenCircle: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    marginRight: '6px',
    marginBottom: '1px',
    backgroundColor: themeColors.systemGreen,
    border: `1px solid ${themeColors.systemGreen}`,
  },
  blueCircle: {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    marginRight: '6px',
    marginBottom: '1px',
    backgroundColor: themeColors.systemBlue,
    border: `1px solid ${themeColors.systemGreen}`,
  },
});
