import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 48,
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  tab: { padding: '16px 32px 14px 32px', cursor: 'pointer' },
  tabActive: {
    borderBottom: `4px solid ${themeColors.mbsBlue}`,
    padding: '16px 32px 14px 32px',
    cursor: 'pointer',
  },
});
