import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './CreateProjectModal.styles';
import { ShortFormBody } from './ShortFormBody/ShortFormBody';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { FullFormBody } from './FullFormBody/FullFormBody';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ClientProjectMemberRoles,
  ICreateProject,
  IProjectDetails,
  IProjectInformation,
  Routes,
} from 'types';
import { useProjectsApi } from 'app/shared/hooks/useProjectsApi';
import { getFormattedPhoneNumber, validationHelper } from 'app/shared/utils';
import { ModalHeader } from 'app/shared/components/generic-ui/ModalHeader/ModalHeader';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useParams } from 'react-router';

interface ICreateProjectModalProps {
  isOpen: boolean;
  closeHandler: () => void;
  project?: IProjectDetails;
  setRefetchData?: (value: boolean) => void;
}

const maxName = 200;
const maxEmail = 100;
const maxPhone = 20;
const maxNotes = 2000;

export enum FieldsMaxLength {
  maxNameLength = maxName,
  maxEmailLength = maxEmail,
  maxPhoneLength = maxPhone,
  maxNotesLength = maxNotes,
}

export const CreateProjectModal = ({
  isOpen,
  closeHandler,
  project,
  setRefetchData,
}: ICreateProjectModalProps) => {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(!!project);
  const { t } = useTranslation();
  const phoneRegExp = /^[0-9]{11}$/;
  const projectRefRegExp = /(\w+)(.*)\/(.*)(\w+)/gi;
  const { organizationId }: { organizationId: string } = useParams();

  const { createProject, updateProject, isProjectCreating, isProjectUpdating } =
    useProjectsApi({ organizationId: organizationId });

  const modalHeader = useMemo(() => {
    return project ? t(translations.projectModal.editProject) : undefined;
  }, [project, t]);

  const preparedProjectMembers = useMemo(
    () =>
      project?.clientProjectMembers.reduce<
        Record<ClientProjectMemberRoles, { name: string; id: string }>
      >((result, member) => {
        result[member.role] = {
          name: member.name || '',
          id: member.clientProjectMemberId || '0',
        };

        return result;
      }, {} as any),
    [project],
  );

  const formDefaultValues: IProjectInformation = useMemo(() => {
    return {
      projectId: project?.projectRefId || '',
      clientContactName: project?.clientContactName
        ? project.clientContactName
        : '',
      email: project?.clientEmail ? project.clientEmail : '',
      phoneNumber: project?.clientPhone
        ? getFormattedPhoneNumber(project.clientPhone)
        : '',
      generalContractor: preparedProjectMembers
        ? preparedProjectMembers[ClientProjectMemberRoles.CONTRACTOR]?.name
        : '',
      architect: preparedProjectMembers
        ? preparedProjectMembers[ClientProjectMemberRoles.ARCHITECT]?.name
        : '',
      engineerOfRecord: preparedProjectMembers
        ? preparedProjectMembers[ClientProjectMemberRoles.ENGINEER]?.name
        : '',
      finisher: preparedProjectMembers
        ? preparedProjectMembers[ClientProjectMemberRoles.FINISHER]?.name
        : '',
      subContractor: preparedProjectMembers
        ? preparedProjectMembers[ClientProjectMemberRoles.SUB_CONTRACTOR]?.name
        : '',
      notes: project?.description ? project.description : '',
    };
  }, [preparedProjectMembers, project]);

  const validationSchema = yup.object().shape({
    projectId: yup
      .string()
      .matches(projectRefRegExp, {
        message: `${t(translations.errors.invalidProjectId)}`,
        excludeEmptyString: true,
      })
      .required(`${t(translations.errors.thisFieldMustBePopulated)}`),

    email: yup
      .string()
      .matches(validationHelper(), {
        message: `${t(translations.errors.invalidEmailAddress)}`,
        excludeEmptyString: true,
      })
      .max(
        FieldsMaxLength.maxEmailLength,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          FieldsMaxLength.maxEmailLength
        } ${t(translations.errors.characters)}.`,
      ),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, {
        message: `${t(translations.errors.invalidPhoneNumber)}`,
        excludeEmptyString: true,
      })
      .max(
        FieldsMaxLength.maxPhoneLength,
        `${t(translations.errors.thisFieldCanNotExceed)} ${
          FieldsMaxLength.maxPhoneLength
        } ${t(translations.errors.characters)}.`,
      ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formDefaultValues,
    reValidateMode: 'onChange',
  });

  const [showError, setShowError] = useState(false);
  const [projectIdValue, setProjectIdValue] = useState(
    formDefaultValues.projectId,
  );

  useEffect(() => {
    setProjectIdValue(formDefaultValues.projectId);
  }, [formDefaultValues.projectId, isOpen]);

  useEffect(() => {
    setShowError(prevState => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const isDisabled = useMemo(() => {
    return isProjectCreating || isProjectUpdating || !projectIdValue;
  }, [isProjectCreating, isProjectUpdating, projectIdValue]);

  const onSubmit = async (data: any) => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      'Project modal save button',
    );

    const requestData: ICreateProject = {
      projectRef: data.projectId,
      clientRef: data.clientName,
      clientContactName: data.clientContactName,
      email: data.email || null,
      phoneNumber: data.phoneNumber,
      title: data.title,
      clientProjectMembers: [
        {
          role: ClientProjectMemberRoles.CONTRACTOR,
          name: data.generalContractor,
        },
        {
          role: ClientProjectMemberRoles.ARCHITECT,
          name: data.architect,
        },
        {
          role: ClientProjectMemberRoles.ENGINEER,
          name: data.engineerOfRecord,
        },
        {
          role: ClientProjectMemberRoles.FINISHER,
          name: data.finisher,
        },
        {
          role: ClientProjectMemberRoles.SUB_CONTRACTOR,
          name: data.subContractor,
        },
      ],
      description: data.notes,
    };

    const editProjectData = {
      ...requestData,
      clientProjectMembers: [
        {
          clientProjectMemberId: preparedProjectMembers
            ? preparedProjectMembers[ClientProjectMemberRoles.CONTRACTOR]?.id
            : '0',
          role: ClientProjectMemberRoles.CONTRACTOR,
          name: data.generalContractor || null,
        },
        {
          clientProjectMemberId: preparedProjectMembers
            ? preparedProjectMembers[ClientProjectMemberRoles.ARCHITECT]?.id
            : '0',
          role: ClientProjectMemberRoles.ARCHITECT,
          name: data.architect || null,
        },
        {
          clientProjectMemberId: preparedProjectMembers
            ? preparedProjectMembers[ClientProjectMemberRoles.ENGINEER]?.id
            : '0',
          role: ClientProjectMemberRoles.ENGINEER,
          name: data.engineerOfRecord || null,
        },
        {
          clientProjectMemberId: preparedProjectMembers
            ? preparedProjectMembers[ClientProjectMemberRoles.FINISHER]?.id
            : '0',
          role: ClientProjectMemberRoles.FINISHER,
          name: data.finisher || null,
        },
        {
          clientProjectMemberId: preparedProjectMembers
            ? preparedProjectMembers[ClientProjectMemberRoles.SUB_CONTRACTOR]
                ?.id
            : '0',
          role: ClientProjectMemberRoles.SUB_CONTRACTOR,
          name: data.subContractor || null,
        },
      ],
      projectId: project?.projectId,
    };

    const request = !project
      ? await createProject(requestData)
      : await updateProject(editProjectData);

    if (!request.messages) {
      setRefetchData && setRefetchData(true);
      setIsExpand(!!project);
      setProjectIdValue('');
      reset();
      closeHandler();
    }
  };

  const onClose = useCallback(() => {
    setIsExpand(!!project);
    setProjectIdValue('');
    reset();
    closeHandler();
  }, [closeHandler, project, reset]);

  const handleFormSpoilerClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationEntityPage,
      'Project modal form spoiler',
    );

    setIsExpand(prevState => !prevState);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={isExpand ? classes.big : classes.small}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.container}>
            <ModalHeader
              header={
                modalHeader || t(translations.notificationEntityPage.addProject)
              }
              closeHandler={onClose}
            />
            <div
              className={classes.body}
              onScroll={() => {
                (document.activeElement as HTMLElement).blur();
              }}
            >
              <ShortFormBody
                errors={errors}
                control={control}
                trigger={trigger}
                setProjectIdValue={setProjectIdValue}
                isEdit={!!project}
              />
              <div
                className={classes.expandButton}
                onClick={handleFormSpoilerClick}
              >
                {isExpand ? (
                  <>
                    <CustomTypography variant="bodySmall" color="greyscale1">
                      {t(translations.projectModal.showLess)}
                    </CustomTypography>
                    <ExpandLessIcon className={classes.expandIcon} />
                  </>
                ) : (
                  <>
                    <CustomTypography variant="bodySmall" color="greyscale1">
                      {t(translations.projectModal.addAdditionalProjectDetails)}
                    </CustomTypography>
                    <ExpandMoreIcon className={classes.expandIcon} />
                  </>
                )}
              </div>
              {isExpand && (
                <FullFormBody
                  control={control}
                  errors={errors}
                  showError={showError}
                />
              )}
            </div>
            <div
              className={
                isExpand ? classes.saveButtonExpanded : classes.saveButton
              }
            >
              {!!project && (
                <Button type="button" variant="primaryCancel" onClick={onClose}>
                  <CustomTypography
                    variant="buttonText"
                    bold
                    color="accentDark"
                  >
                    {t(translations.projectModal.cancel)}
                  </CustomTypography>
                </Button>
              )}
              <Button
                type="submit"
                variant="primarySave"
                disabled={isDisabled}
                onClick={() => {
                  if (Object.keys(errors).length) {
                    setIsExpand(true);
                  }
                }}
              >
                <CustomTypography variant="buttonText" bold color="white">
                  {project
                    ? t(translations.projectModal.save)
                    : t(translations.projectModal.saveProject)}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
