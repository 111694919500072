import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 24px 0px 24px',
    paddingBottom: 124,
  },
  multilineContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 60,
  },
  subtitle: {
    padding: '24px 0 13px 0',
  },
  subtitleVariant: {
    padding: '16px 0 13px 0',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0 ',
  },
  subSection: {
    paddingBottom: 14,
    borderBottom: `2px solid ${themeColors.greyscale4}`,
  },
  fieldsWrapperShort: {
    display: 'flex',
    flexDirection: 'column',
    width: 356,
  },
  label: {
    padding: '0px 10px 8px 10px',
  },
  input: {
    padding: '8px 10px',
  },
  root: {
    '&.Mui-focused ': {
      borderColor: 'white !important',
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'blue ',
  },
  focused: {
    borderColor: 'white !important',
  },
  contained: {
    marginLeft: '10px',
    color: themeColors.greyscale2,
  },
});
