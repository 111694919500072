import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IError,
  IApiParams,
  IPlant,
  IChangePlantNotificationStateRequest,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const plantsKey = 'getPlants';
const defaultSkip = 0;

export function usePlantApi({
  queryParameters,
  organizationId,
  isInfiniteScrolling = true,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();
  const queryClient = useQueryClient();
  const [plants, setPlants] = useState<IPlant[]>([]);

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getPlantsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Plant/get-plants`;
  }, [currentSupplierId]);

  const changePlantNotificationsStateUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Plant/change-enable-plants`;
  }, [currentSupplierId]);

  const {
    data: plantsResponse,
    refetch: fetchPlants,
    isFetching: isFetchingPlants,
    isFetched,
  } = useQuery<IResponseData<IPlant[]>, IError>(
    [plantsKey, { queryParameters }],
    async () => {
      return await authClient(`${getPlantsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      keepPreviousData: true,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getPlantsList, error, {
          getPlantsUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: changePlantNotificationState,
    isLoading: isChangingPlantNotificationState,
    isSuccess: isChangingPlantNotificationStateSuccess,
    error: changePlantNotificationStateError,
  } = useMutation(
    (payload: IChangePlantNotificationStateRequest[]) =>
      authClient(changePlantNotificationsStateUrl, {
        method: 'PATCH',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(plantsKey);
        return queryClient.getQueryData<boolean>(plantsKey);
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<boolean>(plantsKey, context);
        }

        firebaseAnalytics.logErrorEvent(
          errors.changePlantNotificationState,
          error,
          {
            changePlantNotificationsStateUrl,
          },
        );
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(plantsKey);
        await queryClient.refetchQueries(plantsKey);
      },
    },
  );

  useEffect(() => {
    if (!isFetchingPlants) {
      setPlants((prevState: IPlant[]) => {
        return queryParameters?.skip !== defaultSkip && isInfiniteScrolling
          ? plantsResponse?.data
            ? [...prevState, ...plantsResponse.data]
            : prevState
          : plantsResponse?.data || [];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantsResponse?.data]);

  return {
    plantsResponse,
    fetchPlants,
    isFetchingPlants,
    plants,
    isChangingPlantNotificationState,
    isChangingPlantNotificationStateSuccess,
    changePlantNotificationStateError,
    changePlantNotificationState,
    isFetched,
  };
}
