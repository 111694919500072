import React from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './Pages.styles';
import { actions } from 'app/store/slices/layoutSlice';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';

interface IPages {
  numPages: number;
  page: number;
  perPage: number;
  onClick: () => void;
}

const firstPage = 1;
const secondPage = 2;
const threePages = 3;
const twoPages = 2;

export const Pages = ({ numPages, page, perPage, onClick }: IPages) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (value: number) => {
    dispatch(
      actions.setNotificationEntityPagination({
        page: value,
        perPage: perPage,
      }),
    );
  };

  const otherPage =
    +page === firstPage && numPages > twoPages
      ? secondPage
      : page === numPages && numPages > twoPages
      ? page - firstPage
      : null;

  return (
    <>
      <div
        onClick={() => {
          onClick();
          page !== firstPage && handleChange(firstPage);
        }}
        className={page === firstPage ? classes.selectedPage : classes.regular}
      >
        <CustomTypography
          variant="subtitle1"
          bold={page === firstPage}
          color={page === firstPage ? 'white' : 'greyscale2'}
        >
          1
        </CustomTypography>
      </div>
      {+page - firstPage > firstPage && numPages > threePages && (
        <h5 className={classes.dots}>...</h5>
      )}
      {otherPage && numPages !== firstPage && (
        <div
          onClick={() => {
            onClick();
            page !== otherPage && handleChange(otherPage);
          }}
          className={classes.regular}
        >
          <CustomTypography
            variant="subtitle1"
            color={page === otherPage ? 'white' : 'greyscale2'}
          >
            {`${otherPage}`}
          </CustomTypography>
        </div>
      )}
      {page !== firstPage && page !== numPages && (
        <div className={classes.selectedPage}>
          <CustomTypography
            variant="subtitle1"
            bold={page !== firstPage && page !== numPages}
            color={
              page !== firstPage && page !== numPages ? 'white' : 'greyscale2'
            }
          >
            {`${page}`}
          </CustomTypography>
        </div>
      )}
      {page + firstPage < numPages && numPages > threePages && (
        <CustomTypography variant="subtitle1" color="greyscale2">
          ...
        </CustomTypography>
      )}
      {numPages !== firstPage && (
        <div
          onClick={() => {
            onClick();
            page !== numPages && handleChange(numPages);
          }}
          className={page === numPages ? classes.selectedPage : classes.regular}
        >
          <CustomTypography
            variant="subtitle1"
            bold={page === numPages}
            color={page === numPages ? 'white' : 'greyscale2'}
          >
            {`${numPages}`}
          </CustomTypography>
        </div>
      )}
    </>
  );
};
