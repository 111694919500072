import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useStyles } from './SwitchNotificationType.styles';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { NotificationCode } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

interface ISwitchNotificationTypeProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchNotificationType = ({
  value,
  onChange,
}: ISwitchNotificationTypeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <CustomTypography variant="caption1" color="accentDark" bold>
          {t(translations.batchEventCard.concreteState).toUpperCase()}
        </CustomTypography>
      </div>

      <div className={classes.label}>
        <CustomTypography variant="subtitle1" color="accentDark">
          {`${t(translations.batchEventCard.markAs)}:`}
        </CustomTypography>
      </div>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          className={classes.radioGroup}
          value={value}
          onChange={onChange}
        >
          <FormControlLabel
            value={NotificationCode.RETURNED_CONCRETE}
            control={
              <Radio
                checkedIcon={<RadioIcon />}
                icon={<RadioEmpty />}
                color="primary"
              />
            }
            label={t(translations.batchEventCard.returned)}
          />
          <FormControlLabel
            value={NotificationCode.REJECTED_LOAD}
            control={
              <Radio
                checkedIcon={<RadioIcon />}
                icon={<RadioEmpty />}
                color="primary"
              />
            }
            label={t(translations.batchEventCard.rejected)}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
