import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import {
  CellAlignment,
  CellSize,
  PaginationType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './GroupsSection.styles';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { RenameModal } from 'app/shared/components/generic-ui/RenameModal';
import { IAction } from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { IAssignedDosages, IResponseData, Routes } from 'types';
import { firebaseAnalytics } from 'app/shared/analytics';
import { CustomTeamModal } from 'app/shared/components/generic-ui';
import {
  useNotificationEntityApi,
  useNotificationEntityGroupApi,
} from 'app/shared/hooks';
import { useSearchParams } from '../hooks';
import { useAuth, useNotificationSettings } from 'app/shared/providers';

const firstPage = 1;
const firstElement = 0;
const onePage = 1;
const minArrayLength = 1;

interface Props {
  addDosageHandler: () => void;
  isFetchingDosage: boolean;
  assignedDosages: IResponseData<IAssignedDosages[]> | undefined;
}

export const GroupsSection = ({
  addDosageHandler,
  isFetchingDosage,
  assignedDosages,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isMember, isSuperAdmin } = useAuth();

  const {
    organizationId,
    tab,
    page,
    perPage,
    alternativePage,
    alternativePerPage,
  }: {
    organizationId: string;
    tab: string;
    page: string;
    perPage: string;
    alternativePage: string;
    alternativePerPage: string;
  } = useParams();
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isAddPlantModalOpen, setIsPlantModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const history = useHistory();

  const {
    changeSelectedEntityHandler,
    selectedEntity,
    entityType,
    resetSelectedEntityHandler,
    deleteGroupHandler,
  } = useNotificationSettings();

  const { searchQuery, searchParams } = useSearchParams();

  const groupQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.notificationEntityGroupId]);

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        sortBy: 'lastTicketDispatchDateTimeUtc',
        sortAsc: false,
        query: searchQuery || '',
        skip: 0,
      },
    };
  }, [entityType, organizationId, searchQuery]);

  const deleteGroupsParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        notificationEntityGroupId:
          selectedEntity?.notificationEntityGroupId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.notificationEntityGroupId]);

  const {
    addNotificationEntitiesToGroup,
    updateNotificationEntityGroup,
    removeNotificationEntityFromGroup,
    createNotificationEntityGroup,
  } = useNotificationEntityGroupApi({
    organizationId: organizationId,
    entityType: entityType,
  });

  const {
    fetchUnassignedNotificationEntities,
    isFetchingUnassignedNotificationEntities,
    fetchNotificationEntities,
    unassignedNotificationEntities,
  } = useNotificationEntityApi(queryParams);

  const { deleteNotificationEntityGroup } =
    useNotificationEntityGroupApi(deleteGroupsParams);

  const {
    fetchNotificationEntityGroup: fetchPlantGroup,
    notificationEntityGroup: plantGroup,
    isFetchingNotificationEntityGroup,
  } = useNotificationEntityGroupApi(groupQueryParams);

  useEffect(() => {
    if (isAddPlantModalOpen || isCreateGroupModalOpen) {
      fetchUnassignedNotificationEntities();
    }
  }, [
    fetchUnassignedNotificationEntities,
    isAddPlantModalOpen,
    isCreateGroupModalOpen,
    queryParams,
  ]);

  useEffect(() => {
    if (selectedEntity?.notificationEntityGroupId) {
      fetchPlantGroup();
    }
  }, [fetchPlantGroup, selectedEntity]);

  const plantsToShow = useMemo(() => {
    const fromElement =
      +alternativePage === firstPage
        ? firstElement
        : (+alternativePage - onePage) * +alternativePerPage;
    return plantGroup?.notificationEntities?.slice(
      fromElement,
      fromElement + +alternativePerPage,
    );
  }, [alternativePage, alternativePerPage, plantGroup?.notificationEntities]);

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  const columns = useMemo(() => {
    return [
      {
        id: 'externalId',
        label: t(translations.notificationSettingsPage.id),
        alignment: CellAlignment.LEFT,
        size: isMember ? CellSize.SMALL : CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'name',
        label: t(translations.notificationSettingsPage.name, {
          entity: entityName,
        }),
        alignment: CellAlignment.LEFT,
        size: isMember ? CellSize.SMALL : CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [entityName, isMember, t]);

  const plantActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.plantGroupSection.removePlant),
        onClick: (row: any) => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Remove ${entityName} from group action`,
          );

          plantGroup &&
            selectedEntity?.notificationEntityGroupId &&
            removeNotificationEntityFromGroup({
              notificationEntityGroupId:
                +selectedEntity?.notificationEntityGroupId,
              internalIds: [row.internalId],
            })
              .then(() => {
                if (
                  plantsToShow?.length &&
                  plantsToShow.length <= minArrayLength &&
                  +page > firstPage
                )
                  history.push(
                    Routes.NotificationSettings.replace(
                      ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
                      `${organizationId}/${tab}/${page}/${perPage}/${
                        +alternativePage - onePage
                      }/${alternativePerPage}`,
                    ),
                  );
              })
              .then(() => {
                fetchPlantGroup();
              });
        },
      },
    ];
  }, [
    t,
    entityName,
    plantGroup,
    selectedEntity?.notificationEntityGroupId,
    removeNotificationEntityFromGroup,
    plantsToShow?.length,
    page,
    history,
    organizationId,
    tab,
    perPage,
    alternativePage,
    alternativePerPage,
    fetchPlantGroup,
  ]);

  const closeModalHandler = useCallback(() => {
    if (searchQuery) {
      history.push({
        pathname: location.pathname,
        search: '',
      });
    }

    setIsCreateGroupModalOpen(false);
    setIsPlantModalOpen(false);
    setIsRenameModalOpen(false);
  }, [history, searchQuery]);

  const groupActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.plantGroupSection.renameGroup),
        onClick: () => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Rename ${entityName} group action`,
          );

          setIsRenameModalOpen(true);
        },
      },
      {
        label: t(translations.notificationSettingsPage.removeGroup, {
          entity: entityName,
        }),
        onClick: () => {
          firebaseAnalytics.logPressEvent(
            Routes.NotificationSettings,
            `Remove ${entityName} group action`,
          );

          deleteNotificationEntityGroup().then(() => {
            resetSelectedEntityHandler(entityType);
            deleteGroupHandler();
          });
        },
      },
    ];
  }, [
    deleteGroupHandler,
    deleteNotificationEntityGroup,
    entityName,
    entityType,
    resetSelectedEntityHandler,
    t,
  ]);

  const addEntityClickHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.NotificationSettings,
      `Add ${entityName} to group action`,
    );

    fetchUnassignedNotificationEntities();
    setIsPlantModalOpen(true);
  }, [entityName, fetchUnassignedNotificationEntities]);

  const headerButton = useMemo(() => {
    return plantGroup ? (
      <div className={classes.button} onClick={addEntityClickHandler}>
        <CustomTypography variant="buttonTextSmall" bold color="mbsBlue">
          {t(translations.notificationSettingsPage.add, { entity: entityName })}
        </CustomTypography>
      </div>
    ) : undefined;
  }, [addEntityClickHandler, classes.button, entityName, plantGroup, t]);

  const createNotificationEntityGroupHandler = useCallback(
    (ids: string[], name?: string) => {
      if (name) {
        firebaseAnalytics.logPressEvent(
          Routes.NotificationSettings,
          `Create ${entityName} group`,
        );

        createNotificationEntityGroup({
          notificationEntityGroupName: name,
          internalIds: unassignedNotificationEntities
            ? unassignedNotificationEntities
                .filter(el => ids.includes(el.externalId))
                .map(el => {
                  return el.internalId;
                })
            : [],
        }).then((res: any) => {
          fetchNotificationEntities().then(() => {
            changeSelectedEntityHandler(
              entityType,
              res?.data?.notificationEntities[0],
            );
          });
        });
      }
    },
    [
      entityName,
      createNotificationEntityGroup,
      unassignedNotificationEntities,
      fetchNotificationEntities,
      changeSelectedEntityHandler,
      entityType,
    ],
  );

  const addEntityToGroupHandler = useCallback(
    (ids: string[]) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Add ${entityName}entity to group`,
      );

      plantGroup?.notificationEntityGroupId &&
        addNotificationEntitiesToGroup({
          notificationEntityGroupId: plantGroup.notificationEntityGroupId,
          internalIds: unassignedNotificationEntities
            ? unassignedNotificationEntities
                .filter(el => ids.includes(el.externalId))
                .map(el => {
                  return el.internalId;
                })
            : [],
        }).then(() => {
          fetchPlantGroup();
        });

      closeModalHandler();
    },
    [
      addNotificationEntitiesToGroup,
      closeModalHandler,
      entityName,
      fetchPlantGroup,
      plantGroup?.notificationEntityGroupId,
      unassignedNotificationEntities,
    ],
  );

  const updatePlantGroupHandler = useCallback(
    (data: any) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Update ${entityName} group name`,
      );

      selectedEntity?.notificationEntityGroupId &&
        updateNotificationEntityGroup({
          notificationEntityGroupId: +selectedEntity.notificationEntityGroupId,
          notificationEntityGroupName: data.name,
        }).then(() => fetchPlantGroup());

      closeModalHandler();
    },
    [
      closeModalHandler,
      entityName,
      fetchPlantGroup,
      selectedEntity?.notificationEntityGroupId,
      updateNotificationEntityGroup,
    ],
  );

  const createGroupHandler = useCallback(() => {
    fetchNotificationEntities();
    setIsCreateGroupModalOpen(true);
  }, [fetchNotificationEntities]);

  return (
    <div className={classes.container}>
      {isRenameModalOpen && (
        <RenameModal
          header={t(translations.notificationSettingsPage.renameGroup, {
            entity: entityName,
          })}
          placeholder={t(translations.notificationSettingsPage.groupName)}
          label={t(translations.notificationSettingsPage.groupName)}
          onSubmit={updatePlantGroupHandler}
          isOpen={isRenameModalOpen}
          closeHandler={closeModalHandler}
          name={plantGroup?.notificationEntityGroupName || ''}
        />
      )}

      {(isAddPlantModalOpen || isCreateGroupModalOpen) && (
        <CustomTeamModal
          tableRowId="externalId"
          isOpen={isAddPlantModalOpen || isCreateGroupModalOpen}
          closeHandler={closeModalHandler}
          isLoading={isFetchingUnassignedNotificationEntities}
          header={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.createEntityGroup, {
                  entity: entityName,
                })
              : t(translations.notificationSettingsPage.addToGroup, {
                  entity: entityName,
                })
          }
          onAddItem={addEntityToGroupHandler}
          createItem={createNotificationEntityGroupHandler}
          withRemoveButton={false}
          withSearch={true}
          columns={columns}
          plants={unassignedNotificationEntities}
          emptyTableText={`${t(translations.notificationSettingsPage.noData, {
            entity: entityName,
          })}.`}
          withTableHeader={true}
          withTextField={isCreateGroupModalOpen}
          buttonText={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.createGroup)
              : t(translations.notificationSettingsPage.add, {
                  entity: entityName,
                })
          }
          textFieldHeader={t(translations.notificationSettingsPage.groupName)}
          searchHeader={
            isCreateGroupModalOpen
              ? t(translations.notificationSettingsPage.add, {
                  entity: entityName,
                })
              : ''
          }
        />
      )}

      <div className={classes.groupsContainer}>
        {isSuperAdmin && !!selectedEntity && !isFetchingDosage ? (
          <div className={classes.headerContainer}>
            <Button variant="primaryProfile" onClick={addDosageHandler}>
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {assignedDosages?.data.length
                  ? t(translations.notificationSettingsPage.editDosage)
                  : t(translations.notificationSettingsPage.addDosage)}
              </CustomTypography>
            </Button>
          </div>
        ) : null}
        {!isMember ? (
          <div className={classes.headerContainer}>
            <Button variant="primaryProfile" onClick={createGroupHandler}>
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.notificationSettingsPage.createGroup)}
              </CustomTypography>
            </Button>
          </div>
        ) : null}
      </div>

      {selectedEntity?.notificationEntityGroupId || '' ? (
        <div className={classes.wrapper}>
          <SectionSpoiler
            header={plantGroup?.notificationEntityGroupName || ''}
            withIcons={false}
            actions={!isMember ? groupActions : undefined}
          >
            <Table
              withHover={true}
              withPagination={true}
              columns={columns}
              tableData={plantsToShow}
              withCheckbox={false}
              isLoading={isFetchingNotificationEntityGroup}
              actions={!isMember ? plantActions : undefined}
              headerButton={!isMember ? headerButton : undefined}
              emptyTableText={`${t(
                translations.notificationSettingsPage.noData,
                {
                  entity: entityName,
                },
              )}.`}
              baseURL={Routes.NotificationSettings.replace(
                ':organizationId/:tab',
                `${organizationId}/${tab}`,
              )}
              searchParams={searchParams()}
              rowsTotal={plantGroup?.notificationEntities?.length}
              paginationType={PaginationType.SECOND_LEVEL}
            />
          </SectionSpoiler>
        </div>
      ) : null}
    </div>
  );
};
