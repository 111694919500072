import { translations } from 'app/locales/i18n';
import {
  CellAlignment,
  CellSize,
  IHeadCell,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useScreen } from 'app/shared/hooks';
import { themeLayout } from 'app/shared/theme';
import { sortInAlphabeticalOrder } from 'app/shared/utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum ColumnSections {
  SEGMENT = 'Segment',
  PROJECT_TYPE = 'Project Type',
  PLACEMENT = 'Placement & Finishing',
  PERFORMANCE = 'Performance',
  ENHANCEMENTS = 'Enhancements',
}

export const useMixIdColumns = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreen(themeLayout.breakpointSmall);
  const defaultSelectedSections = useMemo(() => {
    return [
      {
        id: ColumnSections.SEGMENT,
        columns: [
          {
            id: 'residential',
            label: t(translations.mixIdTable.residential),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'nonResidential',
            label: t(translations.mixIdTable.nonResidential),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'dotPublicInfrastructure',
            label: t(translations.mixIdTable.dotPublic),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'other',
            label: t(translations.mixIdTable.other),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
        ],
      },
      {
        id: ColumnSections.PROJECT_TYPE,
        columns: [
          {
            id: 'interior',
            label: t(translations.mixIdTable.interior),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'exterior',
            label: t(translations.mixIdTable.exterior),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'horizontalOnGround',
            label: t(translations.mixIdTable.horizontalOnGround),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'horizontalElevated',
            label: t(translations.mixIdTable.horizontalElevated),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'vertical',
            label: t(translations.mixIdTable.vertical),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'architectural',
            label: t(translations.mixIdTable.architectural),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'structural',
            label: t(translations.mixIdTable.structural),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
        ],
      },
      {
        id: ColumnSections.PLACEMENT,
        columns: [
          {
            id: 'pumpable',
            label: t(translations.mixIdTable.pumpable),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'hardTrowelFinish',
            label: t(translations.mixIdTable.hardTrowel),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
        ],
      },
      {
        id: ColumnSections.PERFORMANCE,
        columns: [
          {
            id: 'hesAge',
            label: t(translations.mixIdTable.hesAge),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'hesStrength',
            label: t(translations.mixIdTable.hesStrength),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'd28Strength',
            label: t(translations.mixIdTable.dStrength),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'workabilityMin',
            label: t(translations.mixIdTable.workabilityMin),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'workabilityMax',
            label: t(translations.mixIdTable.workabilityMax),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
        ],
      },
      {
        id: ColumnSections.ENHANCEMENTS,
        columns: [
          {
            id: 'frc',
            label: t(translations.mixIdTable.frc),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
            borderLeft: true,
          },
          {
            id: 'sustainable',
            label: t(translations.mixIdTable.sustainable),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
          {
            id: 'valueAdded',
            label: t(translations.mixIdTable.valueAdded),
            alignment: CellAlignment.LEFT,
            size: CellSize.MEDIUM,
            sortable: false,
          },
        ],
      },
    ];
  }, [t]);

  const [selectedColumnSections, setSelectedColumnSections] = useState(
    defaultSelectedSections,
  );

  const changeColumnsHandler = useCallback(
    (label: ColumnSections) => {
      setSelectedColumnSections(prevState => {
        const columns = prevState.find(el => el.id === label)
          ? prevState.filter(item => item.id !== label)
          : [...prevState, defaultSelectedSections.find(el => el.id === label)];

        return sortInAlphabeticalOrder('desc', columns, 'id');
      });
    },
    [defaultSelectedSections],
  );

  const columns = useMemo(() => {
    const currentColumns: IHeadCell[] = [
      {
        id: 'mixId',
        label: t(translations.mixIdTable.mixId),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
        sticky: !isMobile,
      },
    ];

    selectedColumnSections.map(section => {
      const sectionColumns = section
        ? defaultSelectedSections.find(item => item.id === section.id)?.columns
        : undefined;

      sectionColumns && currentColumns.push(...sectionColumns);
    });

    return currentColumns;
  }, [defaultSelectedSections, isMobile, selectedColumnSections, t]);

  return {
    columns,
    changeColumnsHandler,
    selectedColumnSections,
    columnSections: defaultSelectedSections,
  };
};
