import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  wrapper: {
    marginLeft: 8,
  },
  dateContainer: {
    height: 44,
    maxWidth: 200,
    border: `1px solid ${themeColors.greyscale3}`,
    backgroundColor: themeColors.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    marginLeft: 8,
    paddingTop: 20,
  },
  calendarIcon: {
    height: 28,
    width: 28,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: 2,
    border: 'none',
  },
  closeButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    width: '100%',
  },
  root: {
    width: 17,
  },
  label: {
    width: 55,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 18,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 8px',
  },
  dateLabel: {
    marginBottom: 3,
  },
  dateLabelMobile: {
    marginTop: 20,
    marginBottom: 3,
  },
  dash: {
    paddingTop: 21,
  },
}));
