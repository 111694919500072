import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Modal } from '@material-ui/core';
import { useStyles } from './CustomReturnedConcreteModal.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import {
  IAssignedDosages,
  IDosageEntity,
  IDosagePropertyEntity,
  IResponseData,
  NotificationEntityType,
} from 'types';
import {
  CellAlignment,
  CellSize,
  TableHeaderType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { Search } from 'app/shared/components/generic-ui/Search/Search';
import { useScreen } from 'app/shared/hooks';
import {
  ModalHeader,
  ModalVariant,
} from 'app/shared/components/generic-ui/ModalHeader/ModalHeader';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useDosagesApi } from 'app/shared/hooks/useDosageApi';
import { useParams } from 'react-router-dom';
import { IAction } from '../ActionButton/ActionButton';
import { ReactComponent as PlusSquare } from 'assets/plus_square_fill.svg';
import { themeColors } from 'app/shared/theme';
import { Select, useSelectWithSearch } from '..';
import { TextInput } from '../TextInput/TextInput';
import { useBatchItemApi } from 'app/shared/hooks/useBatchItemApi';

interface ICustomModal {
  tableRowId?: string;
  isOpen: boolean;
  closeHandler: () => void;
  header?: string;
  isLoading?: boolean;
  withSearch?: boolean;
  withTableHeader?: boolean;
  withTextField?: boolean;
  textFieldHeader?: string;
  searchHeader?: string;
  dosages?: IDosageEntity[];
  internalId?: string;
  entityType: NotificationEntityType;
  refetchDosage: (payload: { notificationEntityId: string }) => void;
  assignedDosages: IResponseData<IAssignedDosages[]> | undefined;
}

const rowHeight = 44;
const footerAndHeaderHeight = 159;
const headerHeight = 73;
const paddingOfTableContainer = 25;
const maxModalHeight = 708;
const bodyHeight = 635;
const footerHeight = 104;
const searchContainerHeight = 90;
const textFieldContainerHeight = 168;
const loadingHeight = 170;
const emptyLines = 4;
const zero = 0;
const margin = 24;
const skipOnePage = 1;
const dropdownPerPage = 50;
const two = 2;

enum Variant {
  LOADING_WITH_SEARCH = 'loadingWithSearch',
  LOADING = 'loading',
  WITH_SEARCH = 'withSearch',
  WITHOUT_SEARCH = 'withoutSearch',
  WITH_SEARCH_AND_TEXT_FIELD = 'withSearchAndTextField',
}

const defaultDosageProperty = {
  value: 0,
  dosageTemperaturePropertyModel: '',
  dosageBatchAgePropertyModel: '',
};

const defaultBatchItem = {
  rangeMin: 0,
  rangeMax: 0,
  itemId: '',
  itemType: '',
  itemDescription: '',
};

export const CustomReturnedConcreteModal = ({
  tableRowId,
  isOpen,
  closeHandler,
  header,
  isLoading: loading,
  withSearch,
  withTableHeader,
  withTextField,
  searchHeader,
  dosages,
  internalId,
  entityType,
  refetchDosage,
  assignedDosages,
}: ICustomModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const mounted = useRef(false);
  const searchQuery = new URLSearchParams(location.search).get('search');
  const [createDosageProperty, setCreateDosageProperty] = React.useState(false);
  const [editDosageProperty, setEditDosageProperty] = React.useState(false);
  const [createBatchItem, setCreateBatchItem] = React.useState(false);
  const [editBatchItem, setEditBatchItem] = React.useState(false);
  const [showBackButton, setShowBackButton] = React.useState(false);
  const [activeDosagePropertyIndex, setActiveDosagePropertyIndex] =
    useState<number>();
  const [activeBatchItemIndex, setActiveBatchItemIndex] = useState<number>();
  const [isDosageAssigned, setIsDosageAssigned] = useState(false);

  const [dosagePropertyValues, setDosagePropertyValues] = React.useState(
    defaultDosageProperty,
  );
  const [batchItemValues, setBatchItemValues] =
    React.useState(defaultBatchItem);
  const [dosageId, setDosageId] = React.useState<string>();
  const createDosageNameRef = useRef(searchQuery || '');
  const [isCreateButtonEnabled, setIsCreateButtonEnabled] = useState(
    !createDosageNameRef.current,
  );
  const { screenHeight } = useScreen();
  const {
    organizationId,
  }: {
    organizationId: string;
  } = useParams();

  const {
    loadMoreHandler,
    searchHandler,
    page: batchItemsPage,
    searchQuery: batchItemSearchQuery,
  } = useSelectWithSearch();

  const batchItemsQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        unassignedOnly: false,
        sortBy: 'name',
        sortAsc: true,
        query: batchItemSearchQuery,
        skip: (batchItemsPage - skipOnePage) * dropdownPerPage,
        take: dropdownPerPage,
      },
    };
  }, [batchItemSearchQuery, batchItemsPage, organizationId]);

  const { batchItems, fetchBatchItems, isFetchingBatchItems } = useBatchItemApi(
    batchItemsQueryParams,
  );

  useEffect(() => {
    if (mounted.current) {
      fetchBatchItems();
    }
    mounted.current = true;
  }, [fetchBatchItems, createBatchItem, editBatchItem, batchItemsQueryParams]);

  const {
    fetchDosagesProperties,
    isFetchingDosagesProperties,
    dosagesProperties,
    dosage,
    fetchDosage,
    isFetchingDosage,
    createDosage,
    isCreatingDosage,
    isRemovingDosageFromEntity,
    removeDosageFromEntity,
    assignDosageToNotificationEntity,
    clearDosage,
    isAssigningDosageToNotificationEntity,
    isAddingDosagePropertyValue,
    addDosagePropertyValue,
    isRemovingDosagePropertyValue,
    removeDosagePropertyValue,
    isUpdateDosagePropertyValue,
    updateDosagePropertyValue,
    isAddingDosageBatchItem,
    addDosageBatchItem,
    isUpdateDosageBatchItem,
    updateDosageBatchItem,
    isRemovingDosageBatchItem,
    removeDosageBatchItem,
  } = useDosagesApi({
    organizationId,
    queryParameters: {
      dosageId,
      notificationEntityId: internalId,
      notificationEntityType: entityType,
    },
  });

  const isLoading = React.useMemo(
    () =>
      loading ||
      isFetchingDosagesProperties ||
      isFetchingDosage ||
      isCreatingDosage ||
      isRemovingDosageFromEntity ||
      isAssigningDosageToNotificationEntity ||
      isAddingDosagePropertyValue ||
      isRemovingDosagePropertyValue ||
      isUpdateDosagePropertyValue ||
      isAddingDosageBatchItem ||
      isUpdateDosageBatchItem ||
      isRemovingDosageBatchItem,
    [
      loading,
      isFetchingDosagesProperties,
      isFetchingDosage,
      isCreatingDosage,
      isRemovingDosageFromEntity,
      isAssigningDosageToNotificationEntity,
      isAddingDosagePropertyValue,
      isRemovingDosagePropertyValue,
      isUpdateDosagePropertyValue,
      isAddingDosageBatchItem,
      isUpdateDosageBatchItem,
      isRemovingDosageBatchItem,
    ],
  );

  React.useEffect(() => {
    if (!dosagesProperties && dosageId) {
      fetchDosagesProperties();
    }
  }, [dosagesProperties, dosageId, fetchDosagesProperties]);

  const dosagesPropertiesFormatted = React.useMemo(() => {
    return dosagesProperties?.data.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.dosagePropertyName]: [
          ...(prev[curr.dosagePropertyName] || []),
          curr,
        ],
      };
    }, {} as Record<string, IDosagePropertyEntity[]>);
  }, [dosagesProperties?.data]);

  React.useEffect(() => {
    if (dosageId && !showBackButton) {
      fetchDosage({
        dosageId,
      })
        .then(data => {
          setIsDosageAssigned(
            data.data?.dosageNotificationEntities.some(
              entity => entity.internalId === internalId,
            ),
          );
          setShowBackButton(true);
        })
        .catch(() => {
          setShowBackButton(false);
          setDosageId('');
        });
    }
  }, [dosageId, showBackButton, fetchDosage, internalId]);

  const createDosageHandler = useCallback(async () => {
    if (internalId) {
      const data = await createDosage({
        dosageName: createDosageNameRef.current,
        notificationEntityId: internalId,
        notificationEntityType: entityType,
      });
      setDosageId(data.data.dosageId);
      refetchDosage({ notificationEntityId: internalId });
    }
  }, [entityType, createDosage, refetchDosage, internalId]);

  const assignDosageHandler = useCallback(async () => {
    if (internalId && dosageId) {
      const data = await assignDosageToNotificationEntity({
        dosageId,
        notificationEntityId: internalId,
        notificationEntityType: entityType,
      });
      if (data.data) {
        setShowBackButton(false);
        refetchDosage({ notificationEntityId: internalId });
      }
    }
  }, [
    internalId,
    assignDosageToNotificationEntity,
    entityType,
    dosageId,
    refetchDosage,
  ]);

  const removeDosageFromEntityHandler = useCallback(async () => {
    const data = await removeDosageFromEntity();
    if (data.data?.data) {
      setDosageId('');
      setShowBackButton(false);
      clearDosage();
      if (internalId) {
        refetchDosage({ notificationEntityId: internalId });
      }
    }
  }, [removeDosageFromEntity, clearDosage, internalId, refetchDosage]);

  const createDosagePropertyHandler = useCallback(async () => {
    if (dosage?.data.dosageId) {
      const data = await addDosagePropertyValue({
        dosageBatchAgePropertyId:
          dosagePropertyValues.dosageBatchAgePropertyModel,
        dosageId: dosage?.data.dosageId,
        dosageTemperaturePropertyId:
          dosagePropertyValues.dosageTemperaturePropertyModel,
        value: dosagePropertyValues.value,
      });
      if (data.data) {
        setCreateDosageProperty(false);
        setActiveDosagePropertyIndex(undefined);
        if (dosage?.data.dosageId) {
          fetchDosage({ dosageId: dosage?.data.dosageId });
        }
        setDosagePropertyValues(defaultDosageProperty);
      }
    }
  }, [
    addDosagePropertyValue,
    dosage?.data.dosageId,
    dosagePropertyValues.dosageBatchAgePropertyModel,
    dosagePropertyValues.dosageTemperaturePropertyModel,
    dosagePropertyValues.value,
    fetchDosage,
  ]);

  const createBatchItemHandler = useCallback(async () => {
    if (dosage?.data.dosageId) {
      const data = await addDosageBatchItem({
        ...batchItemValues,
        dosageId: dosage?.data.dosageId,
      });
      if (data.data) {
        setCreateBatchItem(false);
        setActiveBatchItemIndex(undefined);
        if (dosage?.data.dosageId) {
          fetchDosage({ dosageId: dosage?.data.dosageId });
        }
        setBatchItemValues(defaultBatchItem);
      }
    }
  }, [addDosageBatchItem, batchItemValues, dosage?.data.dosageId, fetchDosage]);

  const updateDosagePropertyHandler = useCallback(async () => {
    if (dosage?.data.dosageId) {
      const data = await updateDosagePropertyValue({
        dosageBatchAgePropertyId:
          dosagePropertyValues.dosageBatchAgePropertyModel,
        dosageId: dosage?.data.dosageId,
        dosageTemperaturePropertyId:
          dosagePropertyValues.dosageTemperaturePropertyModel,
        value: dosagePropertyValues.value,
      });
      if (data.data) {
        setEditDosageProperty(false);
        setActiveDosagePropertyIndex(undefined);
        if (dosage?.data.dosageId) {
          fetchDosage({ dosageId: dosage?.data.dosageId });
        }
        setDosagePropertyValues(defaultDosageProperty);
      }
    }
  }, [
    dosage?.data.dosageId,
    updateDosagePropertyValue,
    dosagePropertyValues.dosageBatchAgePropertyModel,
    dosagePropertyValues.dosageTemperaturePropertyModel,
    dosagePropertyValues.value,
    fetchDosage,
  ]);

  const updateBatchItemValuesHandler = useCallback(async () => {
    if (dosage?.data.dosageId) {
      const data = await updateDosageBatchItem({
        ...batchItemValues,
        dosageId: dosage?.data.dosageId,
      });
      if (data.data) {
        setEditBatchItem(false);
        setActiveBatchItemIndex(undefined);
        if (dosage?.data.dosageId) {
          fetchDosage({ dosageId: dosage?.data.dosageId });
        }
        setBatchItemValues(defaultBatchItem);
      }
    }
  }, [
    dosage?.data.dosageId,
    updateDosageBatchItem,
    batchItemValues,
    fetchDosage,
  ]);

  const columns = useMemo(() => {
    return [
      {
        id: 'dosageName',
        label: t(translations.notificationSettingsPage.name, {
          entity: 'Dosage',
        }),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [t]);

  const assignedDosageColumns = useMemo(() => {
    return [
      {
        id: 'dosageName',
        label: t(translations.notificationSettingsPage.name, {
          entity: 'Assigned Dosage',
        }),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [t]);

  const entityColumns = useMemo(() => {
    return [
      {
        id: 'externalId',
        label: t(translations.notificationSettingsPage.id),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'name',
        label: t(translations.dosageModal.name),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [t]);

  const propertyColumns = useMemo(() => {
    return [
      {
        id: 'dosageTemperaturePropertyModel',
        label: t(translations.dosageModal.temperature),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'dosageBatchAgePropertyModel',
        label: t(translations.dosageModal.batchAge),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'value',
        label: t(translations.dosageModal.value),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [t]);

  const batchItemsColumns = useMemo(() => {
    return [
      {
        id: 'itemId',
        label: t(translations.dosageModal.name),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'itemType',
        label: t(translations.dosageModal.type),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'itemDescription',
        label: t(translations.dosageModal.description),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'rangeMin',
        label: t(translations.dosageModal.minValue),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
      {
        id: 'rangeMax',
        label: t(translations.dosageModal.maxValue),
        alignment: CellAlignment.LEFT,
        size: CellSize.MEDIUM,
        sortable: false,
      },
    ];
  }, [t]);

  const modalVariant = useMemo(() => {
    return withTextField
      ? Variant.WITH_SEARCH_AND_TEXT_FIELD
      : (isLoading && withSearch) || (withSearch && !dosages)
      ? Variant.LOADING_WITH_SEARCH
      : (isLoading && !withSearch) || (!withSearch && !dosages)
      ? Variant.LOADING
      : !isLoading && withSearch
      ? Variant.WITH_SEARCH
      : Variant.WITHOUT_SEARCH;
  }, [isLoading, dosages, withSearch, withTextField]);

  const genericElementsHeight = useMemo(() => {
    switch (modalVariant) {
      case Variant.WITH_SEARCH_AND_TEXT_FIELD: {
        return (
          footerAndHeaderHeight +
          searchContainerHeight +
          textFieldContainerHeight +
          paddingOfTableContainer +
          margin
        );
      }
      case Variant.WITH_SEARCH:
      case Variant.LOADING_WITH_SEARCH: {
        return (
          footerAndHeaderHeight +
          searchContainerHeight +
          paddingOfTableContainer +
          margin
        );
      }

      case Variant.LOADING:
      case Variant.WITHOUT_SEARCH: {
        return headerHeight + paddingOfTableContainer + margin;
      }
      default: {
        return zero;
      }
    }
  }, [modalVariant]);

  const rowsHeight = useMemo(() => {
    return isLoading
      ? loadingHeight
      : withTableHeader
      ? (dosages?.length ? dosages.length : emptyLines) * rowHeight + rowHeight
      : (dosages?.length ? dosages.length : emptyLines) * rowHeight;
  }, [isLoading, dosages?.length, withTableHeader]);

  const containerHeight = useMemo(() => {
    return genericElementsHeight + rowsHeight > maxModalHeight
      ? maxModalHeight
      : genericElementsHeight + rowsHeight;
  }, [genericElementsHeight, rowsHeight]);

  const containerStyles = useMemo<React.CSSProperties>(() => {
    return {
      height: containerHeight < screenHeight ? containerHeight : screenHeight,
    };
  }, [containerHeight, screenHeight]);

  const batchItemActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.dosageModal.editBatchItemValues),
        onClick: (data, index) => {
          setEditBatchItem(true);
          setBatchItemValues({
            ...data,
          });
          setActiveBatchItemIndex(index);
        },
      },
      {
        label: t(translations.dosageModal.removeBatchItem),
        onClick: async data => {
          if (dosage?.data.dosageId) {
            const resp = await removeDosageBatchItem({
              ...data,
              dosageId: dosage?.data.dosageId,
            });
            if (resp.data && dosage?.data.dosageId) {
              fetchDosage({ dosageId: dosage?.data.dosageId });
            }
          }
        },
      },
    ];
  }, [dosage?.data.dosageId, fetchDosage, removeDosageBatchItem, t]);

  const dosageActions: IAction[] = useMemo(() => {
    return [
      {
        label: t(translations.dosageModal.editDosageProperty),
        onClick: (data, index) => {
          setEditDosageProperty(true);
          setDosagePropertyValues({
            dosageBatchAgePropertyModel:
              data.dosageBatchAgePropertyModel.dosagePropertyId,
            dosageTemperaturePropertyModel:
              data.dosageTemperaturePropertyModel.dosagePropertyId,
            value: data.value,
          });
          setActiveDosagePropertyIndex(index);
        },
      },
      {
        label: t(translations.dosageModal.removeDosageProperty),
        onClick: async data => {
          if (dosage?.data.dosageId) {
            const resp = await removeDosagePropertyValue({
              dosageId: dosage?.data.dosageId,
              dosageBatchAgePropertyId:
                data.dosageBatchAgePropertyModel.dosagePropertyId,
              dosageTemperaturePropertyId:
                data.dosageTemperaturePropertyModel.dosagePropertyId,
              value: data.value,
            });
            if (resp.data && dosage?.data.dosageId) {
              fetchDosage({ dosageId: dosage?.data.dosageId });
            }
          }
        },
      },
    ];
  }, [dosage?.data.dosageId, fetchDosage, removeDosagePropertyValue, t]);

  const bodyStyles = useMemo<React.CSSProperties>(() => {
    return {
      top:
        withSearch && !withTextField
          ? headerHeight + searchContainerHeight
          : withTextField
          ? headerHeight + searchContainerHeight + textFieldContainerHeight
          : headerHeight,
      maxHeight: !withSearch
        ? bodyHeight
        : !withTextField
        ? bodyHeight - footerHeight - searchContainerHeight
        : bodyHeight -
          footerHeight -
          searchContainerHeight -
          textFieldContainerHeight,
      height:
        containerHeight < screenHeight
          ? !withSearch && !isLoading
            ? `${rowsHeight + paddingOfTableContainer}px`
            : isLoading && !withSearch
            ? loadingHeight
            : dosages
            ? `${rowsHeight + paddingOfTableContainer}px`
            : ''
          : screenHeight - genericElementsHeight + rowHeight,
    };
  }, [
    containerHeight,
    genericElementsHeight,
    isLoading,
    rowsHeight,
    screenHeight,
    dosages,
    withSearch,
    withTextField,
  ]);

  const renderDosageCell = useCallback(
    (rowData: any, cellId: string) => {
      return (
        <div
          className={classes.cell}
          onClick={() => {
            const dosageId = dosages?.find(
              item => item.dosageName === rowData[cellId],
            )?.dosageId;
            if (dosageId) {
              setDosageId(dosageId);
            }
          }}
        >
          {cellId === 'dosageName' && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
        </div>
      );
    },
    [classes.cell, classes.withoutPadding, dosages],
  );

  const renderAssignedDosageCell = useCallback(
    (rowData: any, cellId: string) => {
      return (
        <div
          className={classes.cell}
          onClick={() => {
            const dosageId = assignedDosages?.data?.find(
              item => item.dosageName === rowData[cellId],
            )?.dosageId;
            if (dosageId) {
              setDosageId(dosageId);
            }
          }}
        >
          {cellId === 'dosageName' && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
        </div>
      );
    },
    [classes.cell, classes.withoutPadding, assignedDosages?.data],
  );

  const renderEntityCell = useCallback(
    (rowData: any, cellId: string) => {
      return (
        <div className={classes.cellEntity}>
          {(cellId === 'externalId' || cellId === 'name') && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
        </div>
      );
    },
    [classes.cellEntity, classes.withoutPadding],
  );

  const renderBatchItemPropertyCell = useCallback(
    (rowData: any, cellId: string, index: number) => {
      return (
        <div className={classes.cellEntity}>
          {cellId === 'itemId' &&
            (index !== activeBatchItemIndex ||
              (editBatchItem && index === activeBatchItemIndex)) && (
              <div className={classes.withoutPadding}>
                {rowData[cellId] || '-'}
              </div>
            )}
          {(cellId === 'rangeMin' || cellId === 'rangeMax') &&
            index !== activeBatchItemIndex && (
              <div className={classes.withoutPadding}>
                {rowData[cellId] || '-'}
              </div>
            )}
          {(cellId === 'itemType' || cellId === 'itemDescription') && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
          {cellId === 'itemId' &&
            index === activeBatchItemIndex &&
            !editBatchItem && (
              <Select.Search
                isMultiSelect={false}
                className={classes.select}
                onChangeSelectedValues={(values: string[]) => {
                  const item = batchItems?.data.find(
                    i => i.itemId === values[0],
                  );
                  if (item) {
                    setBatchItemValues(cur => ({ ...cur, ...item }));
                  } else {
                    setBatchItemValues({ ...defaultBatchItem });
                  }
                }}
                popoverClassName={classes.popover}
                valueId="itemId"
                labelId="itemName"
                value={batchItemValues[cellId]}
                isLoading={isFetchingBatchItems}
                showLoader={false}
                loadMoreHandler={loadMoreHandler}
                withPagination={true}
                onSearchHandler={searchHandler}
                options={batchItems?.data.map(item => ({
                  ...item,
                  itemName: [item.itemId, item.itemType, item.itemDescription],
                }))}
              />
            )}
          {(cellId === 'rangeMin' || cellId === 'rangeMax') &&
            index === activeBatchItemIndex && (
              <TextInput
                placeholder={'Value' || ''}
                backgroundColor="white"
                name={cellId}
                currentValue={batchItemValues[cellId].toString()}
                onChangeHandler={e => {
                  setBatchItemValues(prev => ({
                    ...prev,
                    [cellId]: e?.target.value
                      ? Number(e.target.value) || zero
                      : zero,
                  }));
                }}
              />
            )}
        </div>
      );
    },
    [
      activeBatchItemIndex,
      batchItemValues,
      batchItems?.data,
      classes.cellEntity,
      classes.popover,
      classes.select,
      classes.withoutPadding,
      editBatchItem,
      isFetchingBatchItems,
      loadMoreHandler,
      searchHandler,
    ],
  );

  const renderDosagePropertyCell = useCallback(
    (rowData: any, cellId: string, index: number) => {
      return (
        <div className={classes.cellEntity}>
          {(cellId === 'dosageTemperaturePropertyModel' ||
            cellId === 'dosageBatchAgePropertyModel') &&
            (index !== activeDosagePropertyIndex ||
              (editDosageProperty && index === activeDosagePropertyIndex)) && (
              <div className={classes.withoutPadding}>
                {`${rowData[cellId].rangeMin} - ${rowData[cellId].rangeMax}` ||
                  '-'}
              </div>
            )}
          {cellId === 'value' && index !== activeDosagePropertyIndex && (
            <div className={classes.withoutPadding}>
              {rowData[cellId] || '-'}
            </div>
          )}
          {(cellId === 'dosageTemperaturePropertyModel' ||
            cellId === 'dosageBatchAgePropertyModel') &&
            index === activeDosagePropertyIndex &&
            !editDosageProperty && (
              <Select.Base
                className={classes.select}
                onChange={e =>
                  setDosagePropertyValues(prev => ({
                    ...prev,
                    [cellId]: e.target.value,
                  }))
                }
                values={
                  dosagesPropertiesFormatted?.[
                    cellId === 'dosageBatchAgePropertyModel'
                      ? 'batchAge'
                      : 'temperature'
                  ]
                }
                valueId="dosagePropertyId"
                labelId="dosagePropertyRange"
                label={t(translations.filterBlock.select)}
                height={44}
                value={dosagePropertyValues[cellId]}
                fullWidth
              />
            )}
          {cellId === 'value' && index === activeDosagePropertyIndex && (
            <TextInput
              placeholder={'Value' || ''}
              backgroundColor="white"
              name="value"
              currentValue={dosagePropertyValues.value.toString()}
              onChangeHandler={e => {
                setDosagePropertyValues(prev => ({
                  ...prev,
                  value: e?.target.value
                    ? Number(e.target.value) || zero
                    : zero,
                }));
              }}
            />
          )}
        </div>
      );
    },
    [
      activeDosagePropertyIndex,
      classes.cellEntity,
      classes.select,
      classes.withoutPadding,
      dosagePropertyValues,
      dosagesPropertiesFormatted,
      editDosageProperty,
      t,
    ],
  );

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.container} style={containerStyles}>
        <ModalHeader
          className={classes.header}
          backClickHandler={
            createDosageProperty ||
            editDosageProperty ||
            createBatchItem ||
            editBatchItem ||
            !dosageId
              ? undefined
              : () => {
                  setShowBackButton(false);
                  setDosageId('');
                  clearDosage();
                }
          }
          variant={ModalVariant.SMALL}
          header={dosage?.data.dosageName || header || ''}
          closeHandler={closeHandler}
        />

        {withSearch && !dosageId && (
          <div
            className={withTextField ? classes.searchWrapper : classes.wrapper}
          >
            {searchHeader && (
              <div className={classes.subheader}>
                <CustomTypography variant="header6" bold color="accentDark">
                  {searchHeader}
                </CustomTypography>
              </div>
            )}

            <div className={classes.searchContainer}>
              <Search
                placeholder={t(translations.dosageModal.searchOrCreateByName)}
                valueRef={createDosageNameRef}
                onValueChange={value => setIsCreateButtonEnabled(!value)}
              />
            </div>
          </div>
        )}
        {!!dosage && (
          <div
            style={{
              overflow: 'auto',
              maxHeight:
                (containerHeight < screenHeight
                  ? containerHeight
                  : // eslint-disable-next-line no-magic-numbers
                    screenHeight) - 160,
            }}
          >
            <div
              className={classes.subheader}
              style={{
                paddingLeft: '10px',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <CustomTypography variant="header6" bold color="accentDark">
                {t(translations.dosageModal.batchItems)}
              </CustomTypography>
              {!createDosageProperty &&
                !editDosageProperty &&
                !createBatchItem &&
                !editBatchItem &&
                isDosageAssigned && (
                  <PlusSquare
                    stroke={themeColors.mbsBlue}
                    fill={themeColors.mbsBlue}
                    className={classes.plusIcon}
                    data-testid="plus"
                    onClick={() => {
                      setCreateBatchItem(true);
                      setActiveBatchItemIndex(
                        dosage.data.dosageBatchItems.length,
                      );
                    }}
                  />
                )}
              {(createBatchItem || editBatchItem) && (
                <Button
                  type="submit"
                  variant="primarySave"
                  onClick={
                    createBatchItem
                      ? createBatchItemHandler
                      : updateBatchItemValuesHandler
                  }
                  className={classes.actionButtons}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="white"
                  >
                    {t(translations.dosageModal.save)}
                  </CustomTypography>
                </Button>
              )}

              {(createBatchItem || editBatchItem) && (
                <Button
                  type="submit"
                  variant="primaryProfile"
                  onClick={() => {
                    setCreateBatchItem(false);
                    setActiveBatchItemIndex(undefined);
                    setEditBatchItem(false);
                  }}
                  className={classes.actionButtons}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="mbsBlue"
                  >
                    {t(translations.dosageModal.cancel)}
                  </CustomTypography>
                </Button>
              )}
            </div>
            <div className={classes.body} style={{ position: 'unset' }}>
              <Table
                columns={batchItemsColumns}
                emptyTableText={t(translations.dosageModal.noBatchItems)}
                tableData={
                  dosage.data.dosageBatchItems
                    ? [
                        ...dosage.data.dosageBatchItems,
                        ...(createBatchItem ? [{ ...batchItemValues }] : []),
                      ]
                    : undefined
                }
                withCheckbox={false}
                rowsTotal={
                  dosage.data.dosageBatchItems
                    ? [
                        ...dosage.data.dosageBatchItems,
                        ...(createBatchItem
                          ? [
                              {
                                ...batchItemValues,
                              },
                            ]
                          : []),
                      ]?.length
                    : zero
                }
                renderCellWithIndexOfRow={renderBatchItemPropertyCell}
                headerType={TableHeaderType.DARK}
                withBorders={false}
                rowId={tableRowId}
                isLoading={isLoading}
                isScrollable={false}
                overflow="visible"
                actions={
                  createDosageProperty ||
                  editDosageProperty ||
                  createBatchItem ||
                  editBatchItem ||
                  !isDosageAssigned
                    ? undefined
                    : batchItemActions
                }
              />
            </div>
            <div
              className={classes.subheader}
              style={{
                paddingLeft: '10px',
                paddingTop: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <CustomTypography variant="header6" bold color="accentDark">
                {t(translations.dosageModal.dosageProperties)}
              </CustomTypography>
              {!createBatchItem &&
                !editBatchItem &&
                !createDosageProperty &&
                !editDosageProperty &&
                isDosageAssigned && (
                  <PlusSquare
                    stroke={themeColors.mbsBlue}
                    fill={themeColors.mbsBlue}
                    className={classes.plusIcon}
                    data-testid="plus"
                    onClick={() => {
                      setCreateDosageProperty(true);
                      setActiveDosagePropertyIndex(
                        dosage.data.dosagePropertyValues.length,
                      );
                    }}
                  />
                )}
              {(createDosageProperty || editDosageProperty) && (
                <Button
                  type="submit"
                  variant="primarySave"
                  onClick={
                    createDosageProperty
                      ? createDosagePropertyHandler
                      : updateDosagePropertyHandler
                  }
                  className={classes.actionButtons}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="white"
                  >
                    {t(translations.dosageModal.save)}
                  </CustomTypography>
                </Button>
              )}

              {(createDosageProperty || editDosageProperty) && (
                <Button
                  type="submit"
                  variant="primaryProfile"
                  onClick={() => {
                    setCreateDosageProperty(false);
                    setActiveDosagePropertyIndex(undefined);
                    setEditDosageProperty(false);
                    setDosagePropertyValues(defaultDosageProperty);
                  }}
                  className={classes.actionButtons}
                >
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color="mbsBlue"
                  >
                    {t(translations.dosageModal.cancel)}
                  </CustomTypography>
                </Button>
              )}
            </div>
            <div className={classes.body} style={{ position: 'unset' }}>
              <Table
                columns={propertyColumns}
                emptyTableText={t(translations.dosageModal.noDosageProperties)}
                tableData={
                  dosage.data.dosagePropertyValues
                    ? [
                        ...dosage.data.dosagePropertyValues,
                        ...(createDosageProperty
                          ? [
                              {
                                dosageTemperaturePropertyModel: {
                                  dosagePropertyRange: '',
                                },
                                dosageBatchAgePropertyModel: {
                                  dosagePropertyRange: '',
                                },
                                value: 0,
                              },
                            ]
                          : []),
                      ]
                    : undefined
                }
                withCheckbox={false}
                rowsTotal={
                  dosage.data.dosagePropertyValues
                    ? [
                        ...dosage.data.dosagePropertyValues,
                        ...(createDosageProperty
                          ? [
                              {
                                dosageTemperaturePropertyModel: {
                                  dosagePropertyRange: '',
                                },
                                dosageBatchAgePropertyModel: {
                                  dosagePropertyRange: '',
                                },
                                value: 0,
                              },
                            ]
                          : []),
                      ]?.length
                    : zero
                }
                renderCellWithIndexOfRow={renderDosagePropertyCell}
                headerType={TableHeaderType.DARK}
                withBorders={false}
                rowId={tableRowId}
                isLoading={isLoading}
                isScrollable={false}
                overflow="visible"
                actions={
                  createBatchItem ||
                  editBatchItem ||
                  createDosageProperty ||
                  editDosageProperty ||
                  !isDosageAssigned
                    ? undefined
                    : dosageActions
                }
              />
            </div>
            {isDosageAssigned && (
              <div
                className={classes.subheader}
                style={{ paddingLeft: '10px' }}
              >
                <CustomTypography variant="header6" bold color="accentDark">
                  {t(translations.dosageModal.assignedEntitiesToDosage)}
                </CustomTypography>
              </div>
            )}
            {isDosageAssigned && (
              <div className={classes.body} style={{ position: 'unset' }}>
                <Table
                  columns={entityColumns}
                  emptyTableText={t(
                    translations.dosageModal.noDosageProperties,
                  )}
                  tableData={dosage.data.dosageNotificationEntities}
                  withCheckbox={false}
                  rowsTotal={
                    dosage.data.dosageNotificationEntities?.length || zero
                  }
                  withHover={false}
                  renderCell={renderEntityCell}
                  headerType={TableHeaderType.DARK}
                  withBorders={false}
                  rowId={tableRowId}
                  isLoading={isLoading}
                  isScrollable={false}
                  overflow="visible"
                />
              </div>
            )}
          </div>
        )}
        {!dosageId && (
          <div className={classes.body} style={bodyStyles}>
            <div
              style={{
                maxHeight: `${
                  Number(bodyStyles.height?.toString().replace(/\D/g, '')) /
                  (assignedDosages?.data.length ? two : skipOnePage)
                }px`,
                overflow: 'auto',
                marginBottom: '16px',
              }}
            >
              <Table
                withHover={withSearch}
                columns={columns}
                emptyTableText={
                  t(
                    translations.notificationSettingsPage
                      .noDataReturnedConcrete,
                    {
                      searchTerm: searchQuery,
                    },
                  ) || undefined
                }
                tableData={dosages?.filter(
                  item =>
                    !assignedDosages ||
                    !assignedDosages.data.some(
                      i => i.dosageId === item.dosageId,
                    ),
                )}
                withCheckbox={false}
                rowsTotal={dosages?.length || zero}
                renderCell={renderDosageCell}
                headerType={TableHeaderType.DARK}
                withBorders={false}
                rowId={tableRowId}
                isLoading={isLoading}
                isScrollable={false}
                overflow="visible"
              />
            </div>

            {!!assignedDosages?.data.length && (
              <div
                style={{
                  maxHeight: `${
                    Number(bodyStyles.height?.toString().replace(/\D/g, '')) /
                    two
                  }px`,
                  overflow: 'auto',
                  marginBottom: '16px',
                }}
              >
                <Table
                  columns={assignedDosageColumns}
                  tableData={assignedDosages?.data}
                  withCheckbox={false}
                  rowsTotal={assignedDosages?.data?.length || zero}
                  renderCell={renderAssignedDosageCell}
                  headerType={TableHeaderType.ACTIVE}
                  withBorders={false}
                  rowId={tableRowId}
                  isLoading={isLoading}
                  isScrollable={false}
                  overflow="visible"
                />
              </div>
            )}
          </div>
        )}
        <div className={classes.saveButton}>
          {!dosage && (
            <Button
              type="submit"
              disabled={isCreateButtonEnabled}
              variant="primarySave"
              isLoading={isLoading}
              onClick={createDosageHandler}
            >
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.dosageModal.createDosage)}
              </CustomTypography>
            </Button>
          )}
          {!isDosageAssigned && dosage && (
            <Button
              type="submit"
              variant="primarySave"
              isLoading={isLoading}
              onClick={assignDosageHandler}
            >
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.dosageModal.assignDosage)}
              </CustomTypography>
            </Button>
          )}
          {isDosageAssigned && dosage && (
            <Button
              type="submit"
              variant="primarySave"
              isLoading={isLoading}
              disabled={createDosageProperty || editDosageProperty}
              onClick={removeDosageFromEntityHandler}
            >
              <CustomTypography variant="buttonText" bold color="white">
                {t(translations.dosageModal.unassignDosage)}
              </CustomTypography>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
