import { useQuery } from 'react-query';
import { useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { IApiParams, IBatchItem, IError, IResponseData } from 'types';
import { useMemo } from 'react';

const batchItemsQueryKey = 'getBatchItems';

export function useBatchItemApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const { openModal } = useError();

  const getBatchItemsUrl = useMemo(() => {
    return `/api/v1/${organizationId}/BatchItem/search-batch-items`;
  }, [organizationId]);

  const useBatchItemsQuery = (queryKey: string) => {
    return useQuery<IResponseData<IBatchItem[]>, IError>(
      [queryKey, { queryParameters }],
      async () => {
        return await authClient(`${getBatchItemsUrl}`, {
          queryParameters,
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          openModal(error);
        },
      },
    );
  };

  const {
    data: batchItems,
    refetch: fetchBatchItems,
    isFetching: isFetchingBatchItems,
  } = useBatchItemsQuery(batchItemsQueryKey);

  return {
    batchItems,
    fetchBatchItems,
    isFetchingBatchItems,
  };
}
