import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IProjectDetails,
  IProjectMembers,
  IProjectMemberRequest,
  IError,
  IApiParams,
} from 'types';

import { useAuthClient } from './useAuthClient';
import { useAuth, useError } from '../providers';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const projectMembersQueryKey = 'projectMembers';

export function useProjectMembersApi({
  queryParameters,
  organizationId,
}: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const addProjectMembersURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/project/add-users-to-project`;
  }, [currentSupplierId]);

  const getProjectMembersURL = useMemo(() => {
    return `/api/v1/${currentSupplierId}/users/get-project-members`;
  }, [currentSupplierId]);

  const deleteProjectMembers = useMemo(() => {
    return `/api/v1/${currentSupplierId}/project/remove-user-from-project`;
  }, [currentSupplierId]);

  const {
    data: projectMembersResponse,
    refetch: fetchProjectMembers,
    isFetching: isFetchingProjectMembers,
    error: fetchProjectMembersError,
  } = useQuery<IResponseData<IProjectMembers[]>, IError>(
    [projectMembersQueryKey, { queryParameters }],
    async () => {
      return await authClient(`${getProjectMembersURL}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getProjectMembersList, error, {
          getProjectMembersURL,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: addProjectMember,
    isLoading: isAddingProjectMember,
    error: addProjectMemberError,
  } = useMutation(
    (payload: IProjectMemberRequest[]) =>
      authClient(addProjectMembersURL, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(projectMembersQueryKey);
        return queryClient.getQueryData<IResponseData<IProjectDetails>>(
          projectMembersQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IProjectDetails>>(
            projectMembersQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.addProjectMembers, error, {
          addProjectMembersURL,
          variables: JSON.stringify(variables),
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(projectMembersQueryKey);
      },
    },
  );

  const {
    mutateAsync: deleteProjectMember,
    isLoading: isDeletingProjectMember,
    error: deleteProjectMemberError,
  } = useMutation(
    (payload: IProjectMemberRequest) =>
      authClient(deleteProjectMembers, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(projectMembersQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          projectMembersQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            projectMembersQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.removeProjectMembers, error, {
          deleteProjectMembersUrl: deleteProjectMembers,
          ...variables,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(projectMembersQueryKey);
      },
    },
  );

  const projectMembers = useMemo<IProjectMembers[] | undefined>(
    () => projectMembersResponse?.data,
    [projectMembersResponse],
  );
  return {
    addProjectMember,
    isAddingProjectMember,
    projectMembers,
    fetchProjectMembers,
    isFetchingProjectMembers,
    deleteProjectMember,
    isDeletingProjectMember,
    fetchProjectMembersError,
    addProjectMemberError,
    deleteProjectMemberError,
  };
}
