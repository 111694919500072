import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    overflowY: 'auto',
  },
  valueContainer: {
    paddingTop: 9,
    paddingBottom: 36,
  },
  inputContainer: {
    paddingTop: 8,
    paddingBottom: 0,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 40,
  },
  button: {
    marginRight: 20,
  },
  formControl: {
    width: '100%',
    minHeight: '35px',
    backgroundColor: themeColors.white,
  },
  select: {
    padding: '8px 10px',
  },
  paper: {
    marginTop: 45,
  },
});
