import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  switchTrack: {
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  switchBase: {
    opacity: 1,
    height: 16,
    left: 4,
    top: 5,
    '& .Mui-checked': {
      transform: 'translateX(27px)',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  rootSwitch: {
    width: 70,
    marginBottom: 5,
    marginRight: '-12px',
  },
  toggle: {
    '& .Mui-checked': {
      color: themeColors.white,
      transform: 'translateX(27px)',
    },
  },
  filterContainer: {
    cursor: 'pointer',
    padding: '0px 10px 0px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  linkContainer: {
    cursor: 'pointer',
    padding: '0px 10px 0px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderLeft: `1px solid ${themeColors.greyscale3}`,
  },
  filter: {
    marginRight: 9,
  },
  valueContainer: {
    backgroundColor: themeColors.highlightBlue,
    color: themeColors.systemBlue,
    width: 'fit-content',
    padding: '5px 10px',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  alertText: {
    backgroundColor: themeColors.greyscale4_50,
    padding: 10,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    marginLeft: 5,
    cursor: 'pointer',
  },
  actions: {
    height: 40,
    backgroundColor: themeColors.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    padding: '11px 0px 11px 0px',
    boxSizing: 'border-box',
  },
});
