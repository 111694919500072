import React from 'react';
import { useStyles } from './CustomPhoneInput.styles';
import PhoneInput from 'react-phone-input-2';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Control, Controller } from 'react-hook-form';

interface ICustomPhoneInputProps {
  fieldName: string;
  setIsShowError: (data: Record<string, boolean>) => void;
  isShowError: Record<string, boolean>;
  errors: FieldErrors;
  placeholder?: string;
  control: Control<any, any>;
}

const availableCountries = ['us', 'ca'];
const initialCountry = 'us';

export const CustomPhoneInput = ({
  fieldName,
  setIsShowError,
  isShowError,
  placeholder,
  errors,
  control,
}: ICustomPhoneInputProps) => {
  const classes = useStyles();
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <div className={classes.phoneComponentWrapper}>
          <PhoneInput
            placeholder={placeholder}
            country={initialCountry}
            onlyCountries={availableCountries}
            value={value}
            onChange={phone => {
              setIsShowError({
                ...isShowError,
                [fieldName]: false,
              });
              onChange(phone);
            }}
            isValid={() => !isShowError[fieldName] || !errors[fieldName]}
            inputClass={classes.phoneInput}
            containerClass={classes.phoneContainer}
            dropdownStyle={{
              overflowY: 'hidden',
              margin: '4px 0px',
            }}
          />
          {errors[fieldName]?.message && isShowError[fieldName] ? (
            <p className={classes.error}>{errors[fieldName]?.message}</p>
          ) : null}
        </div>
      )}
      name={fieldName}
      control={control}
    />
  );
};
