import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  pourEventHistoryContainer: {
    marginTop: '10px',
    width: '100%',
  },
  empty: {
    paddingBottom: '14px',
  },
  cellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  ageContainerDark: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.accentLight,
  },
  ageContainerLight: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.greyscale4_50,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  valueContainerDark: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: themeColors.accentLight,
    width: 'fit-content',
    color: themeColors.white,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 20,
    boxSizing: 'border-box',
  },
});
