import { makeStyles } from '@material-ui/core';
import { theme, themeColors, themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    outline: 'none !important',
    position: 'relative',
    backgroundColor: themeColors.white,
  },
  body: {
    width: 561,
    bottom: 86,
    boxSizing: 'border-box',
    margin: '12px 0px',

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },

    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '7px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  disabled: {
    color: `${themeColors.greyscale3} !important`,
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  contentWrapper: {
    padding: '16px 16px 0px 16px',
    width: '529px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 15,
    color: themeColors.accentDark,
  },
  roles: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  label: {
    backgroundColor: themeColors.greyscale4_50,
    padding: '8px 12px',
    borderRadius: '30px',
    fontSize: '0.875rem',
    color: themeColors.accentDark,
  },
  description: {
    color: themeColors.accentDark,
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px 16px',
    alignItems: 'center',
  },
});
