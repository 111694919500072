import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9000,
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  body: {
    paddingTop: '3px',
    marginTop: 17,
    padding: '0px 24px 0px 24px',
    maxHeight: 90,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 160,
    },
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  small: {
    width: 561,
    height: 272,
    [theme.breakpoints.up('xs')]: {
      width: 561,
      height: 272,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 350,
    },

    margin: 'auto',
    position: 'relative',
    outline: 'none !important',
    backgroundColor: themeColors.white,
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '34px 24px',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    height: '86px',
    width: '561px',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    [theme.breakpoints.up('xs')]: {
      width: 561,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    borderTop: `2px solid ${themeColors.greyscale4}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '26px 27px 22px 24px',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: themeColors.white,
    zIndex: 200,
    [theme.breakpoints.down('sm')]: {
      width: 375,
    },
    [theme.breakpoints.down('xs')]: {
      width: '375px',
    },
    width: '561px',
  },
  closeButton: {
    cursor: 'pointer',
    zIndex: 201,
  },
}));
