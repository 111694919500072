import React from 'react';
import { Typography } from '@material-ui/core';
import { styles } from './Typography.styles';
import { themeColors } from 'app/shared/theme/theme';

enum Variant {
  header3 = 'h1',
  header4 = 'h2',
  header5 = 'subtitle1',
  header6 = 'body2',
  caption1 = 'h3',
  caption2 = 'h3',
  subtitle1 = 'subtitle2',
  subtitle2 = 'h5',
  bodySmall = 'body1',
  bodyRegular = 'body2',
  bodyExtraLarge = 'h2',
  buttonText = 'body1',
  buttonTextSmall = 'h5',
  headerExtraLarge = 'caption',
  bodyLarge = 'button',
  buttonTabText = 'h6',
  mobileHeader6 = 'h4',
  mobileBodyRegular = 'h6',
}

type TypographyProps = {
  variant: keyof typeof Variant;
  color: keyof typeof themeColors;
  children: any;
  bold?: boolean;
  style?: any;
};

const fontWeightBold = 700;
const fontWeightRegular = 400;

export const CustomTypography = ({
  variant,
  color,
  bold,
  children,
  style,
}: TypographyProps) => {
  const classes = styles();

  return (
    <Typography
      variant={Variant[variant]}
      className={classes[color]}
      style={
        style
          ? style
          : { fontWeight: bold ? fontWeightBold : fontWeightRegular }
      }
    >
      {children}
    </Typography>
  );
};
