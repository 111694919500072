export enum EscapeAction {
  DECODE,
  ENCODE,
}

export const escapePercentSign = (
  action: EscapeAction,
  value: string | null,
) => {
  if (action === EscapeAction.ENCODE) {
    return value ? value.replace(/%/g, '~~pct~~') : '';
  } else {
    return value ? value.replace(/~~pct~~/g, '%') : '';
  }
};
