import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'app/locales/i18n';
import { IProfile, IProjectMemberRequest, IProjectMembers, IRole } from 'types';
import { CircularProgress } from '../CircularProgress/CircularProgress';
import { SectionSpoiler } from '../SectionSpoiler';
import { CustomTypography } from '../Typography/Typography';
import { TeamAssignmentsCard } from './components/TeamAssignmentsCard';
import { IUsersAssigned } from 'app/pages/organizationDetails/OrganizationTeamAssignments';
import { CellAlignment, CellSize } from '../Table/Table.types';
import { useHistory, useParams } from 'react-router-dom';
import { useTeammatesApi } from 'app/shared/hooks/useTeammatesApi';
import { useSearchHelper } from 'app/shared/utils';
import { useStyles } from './RoleAssignments.styles';
import { CustomTeamModal } from '../CustomTeamModal';

interface IRoleAssignmentsProps {
  header?: string;
  roles?: IRole[];
  members?: IProjectMembers[];
  usersAssigned?: IUsersAssigned | undefined;
  plantGroupId?: number;
  isLoading?: boolean;
  modalHeader?: string;
  deleteMemberHandler: (user: IProjectMemberRequest) => void;
  addMembersHandler: (members: IProjectMemberRequest[]) => void;
  isTeamAssignmentsVisible?: boolean;
  isFetchingProjectMembers?: boolean;
}

interface ICardProps {
  id: number;
  title: string;
  roleName: string;
  team: IProfile[] | undefined;
}

const columns = [
  {
    id: 'name',
    label: 'Name',
    alignment: CellAlignment.LEFT,
    size: CellSize.MEDIUM,
  },
  {
    id: 'roleNames',
    label: 'Roles',
    alignment: CellAlignment.RIGHT,
    size: CellSize.MEDIUM,
  },
];

export const RoleAssignments = ({
  header,
  roles,
  members,
  usersAssigned,
  plantGroupId,
  isLoading,
  deleteMemberHandler,
  addMembersHandler,
  modalHeader,
  isTeamAssignmentsVisible = true,
  isFetchingProjectMembers,
}: IRoleAssignmentsProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { id, organizationId }: { id: string; organizationId: string } =
    useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [roleName, setRoleName] = useState('');
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const { searchQuery, prepareSearchQueryParameter } = useSearchHelper();

  const apiParams = useMemo(() => {
    return {
      queryParameters: {
        sortBy: 'firstname,lastname',
        sortAsc: true,
        query: prepareSearchQueryParameter(searchQuery),
      },
      organizationId: organizationId || undefined,
    };
  }, [organizationId, prepareSearchQueryParameter, searchQuery]);

  const { fetchTeammates, teammates, isFetchingTeammates } =
    useTeammatesApi(apiParams);

  const teamAssignmentsCardsProps = useMemo(() => {
    const cards: ICardProps[] = [];
    roles?.map((role, key) =>
      cards.push({
        id: key,
        title: role.roleName,
        roleName: role.roleName,
        team: usersAssigned ? usersAssigned[role.roleName] : [],
      }),
    );
    return cards;
  }, [roles, usersAssigned]);

  const closeModalHandler = useCallback(() => {
    setIsModalOpen(false);
    setIsTeamModalOpen(false);

    history.push({
      pathname: location.pathname,
      search: '',
    });
  }, [history]);

  const currentRole = useMemo(() => {
    return roles?.find(el => el.roleName === roleName);
  }, [roleName, roles]);

  const onRemoveProjectMember = useCallback(
    async (userIdToRemove: string, role?: string) => {
      role && setRoleName(role);
      const userRole = roles?.find(el => el.roleName === role);

      const user: IProjectMemberRequest = {
        projectId: id || undefined,
        userId: userIdToRemove,
        roleId: userRole?.roleId || currentRole?.roleId,
        plantGroupId: plantGroupId,
      };

      deleteMemberHandler(user);
    },
    [currentRole?.roleId, deleteMemberHandler, id, plantGroupId, roles],
  );

  const onAddUsersToProject = useCallback(
    async (userIds: string[]) => {
      const teamMembers = userIds.map(el => {
        return {
          projectId: id,
          userId: el,
          roleId: currentRole?.roleId || '',
        };
      });
      addMembersHandler && addMembersHandler(teamMembers);
    },
    [addMembersHandler, currentRole?.roleId, id],
  );

  const teammatesToShow = useMemo(() => {
    return isTeamModalOpen
      ? members?.filter(
          (teammate: IProjectMembers) =>
            teammate.roleNames?.find(el => el === roleName) === roleName,
        )
      : teammates;
  }, [isTeamModalOpen, members, roleName, teammates]);

  useEffect(() => {
    fetchTeammates();
  }, [fetchTeammates, searchQuery, isModalOpen]);

  return (
    <div>
      {header && <SectionSpoiler header={header} withIcons={false} />}

      {(isTeamModalOpen || isModalOpen) && (
        <CustomTeamModal
          isOpen={isTeamModalOpen || isModalOpen}
          closeHandler={closeModalHandler}
          team={teammatesToShow}
          isLoading={
            isFetchingTeammates || isLoading || isFetchingProjectMembers
          }
          header={modalHeader}
          roleName={roleName}
          onRemoveProjectMember={onRemoveProjectMember}
          onAddItem={onAddUsersToProject}
          withRemoveButton={isTeamModalOpen}
          withSearch={isModalOpen}
          columns={isModalOpen ? columns : undefined}
        />
      )}
      <div className={classes.section}>
        {teamAssignmentsCardsProps.length || !isTeamAssignmentsVisible ? (
          isTeamAssignmentsVisible ? (
            teamAssignmentsCardsProps.map(cardProp => {
              return (
                <TeamAssignmentsCard
                  key={cardProp.id}
                  team={cardProp.team}
                  title={cardProp.title}
                  onRemoveProjectMember={onRemoveProjectMember}
                  onAddTeammate={setIsModalOpen}
                  changeCurrentRoleHandler={() =>
                    setRoleName(cardProp.roleName)
                  }
                  showTeamModal={setIsTeamModalOpen}
                  isLoading={
                    cardProp.roleName === currentRole?.roleName
                      ? isLoading || false
                      : false
                  }
                />
              );
            })
          ) : (
            <div className={classes.emptyContainer}>
              <CustomTypography variant="bodyRegular" color="greyscale2">
                {t(translations.teamAssignments.noTeamAssignments)}
              </CustomTypography>
            </div>
          )
        ) : (
          <div className={classes.progress}>
            <CircularProgress withLabel />
          </div>
        )}
      </div>
    </div>
  );
};
