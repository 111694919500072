import React, { useCallback, useMemo } from 'react';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import { CustomTypography } from '../../../Typography/Typography';
import { IPaginationUrlParams } from '../../Table.types';
import { createPaginationUrl } from '../../utils';
import { useStyles } from './TablePages.styles';

interface ITablePages {
  numPages: number;
  baseURL?: string;
  searchParams?: URLSearchParams;
  currentPage: string;
  currentPerPage: string;
  isBasePaginationWithAlternative: boolean;
  isSecondLevelPagination: boolean;
  paginationChangeHandler?: () => Promise<boolean>;
  tableScrollHandler?: () => void;
}

const firstPage = 1;
const secondPage = 2;
const threePages = 3;
const twoPages = 2;

export const TablePages = ({
  numPages,
  baseURL,
  searchParams,
  currentPage,
  isSecondLevelPagination,
  paginationChangeHandler,
  tableScrollHandler,
}: ITablePages) => {
  const classes = useStyles();
  const history = useHistory();
  const searchQuery = new URLSearchParams(useLocation().search).get('search');

  const currentSearchParams = useMemo(() => {
    return (
      searchParams ||
      new URLSearchParams({
        ['search']: searchQuery || '',
      })
    );
  }, [searchParams, searchQuery]);

  const {
    page,
    perPage,
    alternativePage,
    alternativePerPage,
    orderType,
  }: IPaginationUrlParams = useParams();

  const otherPage = useMemo(() => {
    return +currentPage === firstPage && numPages > twoPages
      ? secondPage
      : +currentPage === numPages && numPages > twoPages
      ? +currentPage - firstPage
      : null;
  }, [currentPage, numPages]);

  const onClickPageHandler = useCallback(
    (selectedPage: number) => {
      const pathName = createPaginationUrl(
        baseURL || '',
        isSecondLevelPagination ? page : selectedPage,
        perPage,
        isSecondLevelPagination ? selectedPage : alternativePage,
        alternativePerPage,
        orderType,
      );

      tableScrollHandler && tableScrollHandler();

      paginationChangeHandler
        ? paginationChangeHandler().then(res => {
            if (res) {
              history.push({
                pathname: pathName,
                search: `${currentSearchParams}`,
              });
            }
          })
        : history.push({
            pathname: pathName,
            search: `${currentSearchParams}`,
          });
    },
    [
      baseURL,
      isSecondLevelPagination,
      page,
      perPage,
      alternativePage,
      alternativePerPage,
      orderType,
      tableScrollHandler,
      paginationChangeHandler,
      history,
      currentSearchParams,
    ],
  );

  return (
    <>
      <div
        onClick={() => {
          +currentPage !== firstPage && onClickPageHandler(firstPage);
        }}
        className={
          +currentPage === firstPage ? classes.selectedPage : classes.regular
        }
      >
        <CustomTypography
          variant="buttonTabText"
          bold={+currentPage === firstPage}
          color={+currentPage === firstPage ? 'white' : 'greyscale2'}
        >
          1
        </CustomTypography>
      </div>
      {+currentPage - firstPage > firstPage && numPages > threePages && (
        <div className={classes.dots}>
          <CustomTypography variant="subtitle1" color="greyscale2">
            ...
          </CustomTypography>
        </div>
      )}
      {otherPage && numPages !== firstPage && (
        <div
          onClick={() => {
            +currentPage !== otherPage && onClickPageHandler(otherPage);
          }}
          className={classes.regular}
        >
          <CustomTypography variant="buttonTabText" color="greyscale2">
            {`${otherPage}`}
          </CustomTypography>
        </div>
      )}
      {+currentPage !== firstPage && +currentPage !== numPages && (
        <Link to="#" className={classes.selectedPage}>
          <CustomTypography variant="buttonTabText" bold color="white">
            {`${currentPage}`}
          </CustomTypography>
        </Link>
      )}
      {+currentPage + firstPage < numPages && numPages > threePages && (
        <div className={classes.dots}>
          <CustomTypography variant="subtitle1" color="greyscale2">
            ...
          </CustomTypography>
        </div>
      )}
      {numPages !== firstPage && (
        <div
          onClick={() => {
            +currentPage !== numPages && onClickPageHandler(numPages);
          }}
          className={
            +currentPage === numPages ? classes.selectedPage : classes.regular
          }
        >
          <CustomTypography
            variant="buttonTabText"
            bold={+currentPage === numPages}
            color={+currentPage === numPages ? 'white' : 'greyscale2'}
          >
            {`${numPages}`}
          </CustomTypography>
        </div>
      )}
    </>
  );
};
