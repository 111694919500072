import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '0px 30px 160px 30px',
  },
  header: {
    marginBottom: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backArrow: {
    marginRight: 13,
    cursor: 'pointer',
  },
  status: {
    marginLeft: 25,
    marginTop: 3,
  },
  subheader: {
    margin: '32px 0px 10px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 356,
    maxHeight: 50,
  },
  label: {
    marginBottom: 8,
  },
  dropzone: {
    maxHeight: 150,
  },
  progress: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editButton: {
    cursor: 'pointer',
    paddingTop: 8,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 120,
  },
  textareaContainer: {
    width: 356,
  },
  inputContainer: {
    marginBottom: 15,
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      width: 292,
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      [theme.breakpoints.down(themeLayout.mobileWidth)]: {
        width: 292,
      },
      [theme.breakpoints.up(themeLayout.mobileWidth)]: {
        width: 356,
      },
    },
  },
  email: {
    marginLeft: '-16px',
  },
  grid: {
    maxWidth: 900,
  },
}));
