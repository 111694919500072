import React, { useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Routes } from 'types';
import { useParams } from 'react-router-dom';
import { Pages, TabIds } from './TabNavigation.types';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { Link } from 'react-router-dom';
import { useStyles } from './TabNavigation.styles';
import { useAuth } from 'app/shared/providers';

type TabOptionProps = {
  value: string;
  id: string;
  page: Pages;
  path: string;
  forMembers?: boolean;
};

type TabsProps = {
  pageType: string;
  tab: string;
  id?: string;
};

const emptyArrayLength = 0;

export const Tabs = ({ pageType, tab }: TabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organizationId, id }: { organizationId: string; id: string } =
    useParams();
  const { isMember, isSuperAdmin } = useAuth();

  const tabsOptions: TabOptionProps[] = useMemo(() => {
    const options = [
      {
        value: t(translations.organizationDetailsPage.organizationInfo),
        id: TabIds.ORGANIZATION_INFO,
        page:
          pageType === Pages.ORGANIZATION_PAGE
            ? Pages.ORGANIZATION_PAGE
            : Pages.MY_ORGANIZATION,
        path:
          pageType === Pages.ORGANIZATION_PAGE
            ? Routes.OrganizationDetails
            : Routes.MyOrganization,
      },
      {
        value: t(translations.organizationDetailsPage.plantAssignments),
        id: TabIds.PLANT_TEAM,
        page:
          pageType === Pages.ORGANIZATION_PAGE
            ? Pages.ORGANIZATION_PAGE
            : Pages.MY_ORGANIZATION,
        path:
          pageType === Pages.ORGANIZATION_PAGE
            ? Routes.OrganizationDetails
            : Routes.MyOrganization,
      },
      {
        value: t(translations.organizationDetailsPage.truckSettings),
        id: TabIds.TRUCK_SETTINGS,
        page:
          pageType === Pages.ORGANIZATION_PAGE
            ? Pages.ORGANIZATION_PAGE
            : Pages.MY_ORGANIZATION,
        path:
          pageType === Pages.ORGANIZATION_PAGE
            ? Routes.OrganizationDetails
            : Routes.MyOrganization,
      },
      {
        value: t(translations.organizationDetailsPage.mixIdCategorization),
        id: TabIds.MIX_ID_CATEGORIZATION,
        page:
          pageType === Pages.ORGANIZATION_PAGE
            ? Pages.ORGANIZATION_PAGE
            : Pages.MY_ORGANIZATION,
        path:
          pageType === Pages.ORGANIZATION_PAGE
            ? Routes.OrganizationDetails
            : Routes.MyOrganization,
      },
      {
        value: t(translations.organizationDetailsPage.roleAssignments),
        id: TabIds.ORGANIZATION_ROLES,
        page:
          pageType === Pages.ORGANIZATION_PAGE
            ? Pages.ORGANIZATION_PAGE
            : Pages.MY_ORGANIZATION,
        path:
          pageType === Pages.ORGANIZATION_PAGE
            ? Routes.OrganizationDetails
            : Routes.MyOrganization,
      },
      {
        value: t(translations.reportPage.suppliersReport),
        id: TabIds.SUPPLIER_REPORT,
        page: Pages.REPORT,
        path: Routes.Reports,
        forSupperAdmins: true,
      },
      {
        value: t(translations.reportPage.healthCheck),
        id: TabIds.HEALTH_CHECK,
        page: Pages.REPORT,
        path: Routes.Reports,
        forSupperAdmins: true,
      },
      {
        value: t(translations.notificationSettingsPage.organization),
        id: TabIds.ORGANIZATION,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.plant),
        id: TabIds.PLANT,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.customer),
        id: TabIds.CUSTOMER,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.mixDesign),
        id: TabIds.MIX_DESIGN,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.vehicle),
        id: TabIds.VEHICLE,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.driver),
        id: TabIds.DRIVER,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.project),
        id: TabIds.PROJECT,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
      {
        value: t(translations.notificationSettingsPage.pourEvent),
        id: TabIds.POUR_EVENT,
        page: Pages.NOTIFICATION_SETTINGS,
        path: Routes.NotificationSettings,
        forMembers: true,
      },
    ];

    return options.filter(
      el =>
        el.page === pageType &&
        (isMember ? el.forMembers === true : true) &&
        (!isSuperAdmin ? el?.forSupperAdmins !== true : true),
    );
  }, [isMember, isSuperAdmin, pageType, t]);

  const activeTab: TabOptionProps | undefined = useMemo(() => {
    if (tab === undefined && tabsOptions.length > emptyArrayLength) {
      return tabsOptions[0];
    } else {
      return tabsOptions.find(el => el.id === tab);
    }
  }, [tab, tabsOptions]);

  return (
    <>
      {tabsOptions.map(el => {
        return (
          <div key={el.value} className={classes.borderContainer}>
            <div
              className={
                activeTab === el ? classes.tabElementActive : classes.tabElement
              }
              key={el.value}
            >
              <Link
                to={el.path.replace(
                  el.path.includes(':id')
                    ? el.path.includes(':page')
                      ? ':organizationId/:id/:page/:perPage'
                      : ':organizationId/:id'
                    : el.path.includes(':page/:perPage')
                    ? el.path.includes(':alternativePage')
                      ? ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage'
                      : ':organizationId/:tab/:page/:perPage'
                    : ':organizationId/:tab',
                  el.path.includes(':id')
                    ? el.path.includes(':page')
                      ? el.path.includes('project/team')
                        ? `${organizationId}/${id}/1/20`
                        : `${organizationId}/${id}/1/20`
                      : `${organizationId}/${id}`
                    : `${organizationId}/${el.id}${
                        el.path.includes(':page/:perPage')
                          ? el.path.includes(':alternativePage')
                            ? '/1/50/1/50'
                            : '/1/20'
                          : ''
                      }`,
                )}
                style={{ textDecoration: 'none' }}
                key={el.value}
              >
                <CustomTypography
                  variant="bodySmall"
                  bold={activeTab === el}
                  color={activeTab === el ? 'accentDark' : 'greyscale1'}
                >
                  {el.value}
                </CustomTypography>
              </Link>
            </div>

            {activeTab === el && <span className={classes.border}></span>}
          </div>
        );
      })}
    </>
  );
};
