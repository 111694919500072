import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  formControl: {
    width: 300,
    marginTop: 8,
  },
  selectContainer: {
    marginTop: 15,
  },
  labelContainer: {
    marginLeft: 10,
  },
});
