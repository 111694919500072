import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const styles = makeStyles({
  mbsBlue: {
    color: themeColors.mbsBlue,
  },
  mbsOrange: {
    color: themeColors.mbsOrange,
  },
  accentDark: {
    color: themeColors.accentDark,
    textOverflow: 'ellipsis',
  },
  accentLight: {
    color: themeColors.accentLight,
  },
  systemRed: {
    color: themeColors.systemRed,
  },
  systemGreen: {
    color: themeColors.systemGreen,
  },
  systemBlue: {
    color: themeColors.systemBlue,
  },
  greyscale1: { color: themeColors.greyscale1, textOverflow: 'ellipsis' },
  greyscale2: { color: themeColors.greyscale2 },
  greyscale3: { color: themeColors.greyscale3 },
  greyscale4: { color: themeColors.greyscale4 },
  greyscale5: { color: themeColors.greyscale5 },
  white: { color: themeColors.white, textOverflow: 'ellipsis' },
  highlightBlue: { color: themeColors.highlightBlue },
  greyscale4_50: { color: themeColors.greyscale4_50 },
  greyscale: { color: themeColors.greyscale },
  white16: { color: themeColors.white16 },
  white50: { color: themeColors.white50 },
  primaryNavy: { color: themeColors.primaryNavy },
  accentDark60: { color: themeColors.accentDark60 },
  greyscale6: { color: themeColors.greyscale6 },
  mobileBackground: {
    color: themeColors.mobileBackground,
  },
  systemGreen20: { color: themeColors.systemGreen20 },
  accentDark14: { color: themeColors.accentDark14 },
  systemWarning: {
    color: themeColors.systemWarning,
  },
});
