import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IProfile,
  IProfileUpdate,
  IError,
  IApiParams,
} from 'types';
import { useAuth, useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';
import { IQueryParams } from '../services';

const profileQueryKey = 'getProfile';
const userProfileQueryKey = 'getUserProfile';

export function useProfileApi({ queryParameters, organizationId }: IApiParams) {
  const { userId, supplierId } = useAuth();
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getProfileUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Users/get-user-profile`;
  }, [currentSupplierId]);

  const updateProfileUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Users/update-user-profile`;
  }, [currentSupplierId]);

  const useProfileQuery = (queryKey: string, queryParams?: IQueryParams) => {
    return useQuery<IResponseData<IProfile>, IError>(
      [queryKey, { queryParams }],
      async () => {
        return await authClient(`${getProfileUrl}`, {
          queryParameters: queryParams || { userId: userId },
          method: 'GET',
        });
      },
      {
        enabled: false,
        refetchOnWindowFocus: false,
        onError: error => {
          firebaseAnalytics.logErrorEvent(errors.getProfile, error, {
            getProfileUrl,
          });
          openModal(error);
        },
      },
    );
  };

  const {
    data: userProfileResponse,
    refetch: fetchUserProfile,
    isLoading: isFetchingUserProfile,
    error: fetchingUserProfileError,
  } = useProfileQuery(userProfileQueryKey, queryParameters);

  const {
    data: profileResponse,
    refetch: fetchProfile,
    isLoading: isFetchingProfile,
    error: fetchingProfileError,
  } = useProfileQuery(profileQueryKey);

  const {
    mutateAsync: mutateProfile,
    isLoading: isProfileUpdating,
    error: mutateProfileError,
  } = useMutation(
    (payload: IProfileUpdate) =>
      authClient(updateProfileUrl, { method: 'PATCH', data: payload }),
    {
      retry: false,
      onMutate: async (payload: IProfileUpdate) => {
        await queryClient.cancelQueries(
          queryParameters ? userProfileQueryKey : profileQueryKey,
        );
        const previousProfileData = queryClient.getQueryData<
          IResponseData<IProfile>
        >(queryParameters ? userProfileQueryKey : profileQueryKey);

        if (previousProfileData) {
          const newData: IResponseData<IProfile> = { ...previousProfileData };

          newData.data = {
            ...newData.data,
            ...payload,
          };

          queryClient.setQueryData<IResponseData<IProfile>>(
            queryParameters ? userProfileQueryKey : profileQueryKey,
            newData,
          );
        }

        return { previousProfile: previousProfileData };
      },
      onError: (error: IError, variables, context) => {
        if (context?.previousProfile) {
          queryClient.setQueryData<IResponseData<IProfile>>(
            profileQueryKey,
            context.previousProfile,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.updateProfile, error, {
          updateProfileUrl,
          ...variables,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(profileQueryKey);
      },
    },
  );

  const profile = useMemo<IProfile | undefined>(
    () => profileResponse?.data,
    [profileResponse],
  );

  const userProfile = useMemo<IProfile | undefined>(
    () => userProfileResponse?.data,
    [userProfileResponse?.data],
  );

  return {
    profile,
    profileResponse,
    fetchProfile,
    isFetchingProfile,
    mutateProfile,
    mutateProfileError,
    isProfileUpdating,
    fetchingProfileError,
    userProfile,
    fetchUserProfile,
    isFetchingUserProfile,
    fetchingUserProfileError,
  };
}
