import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme';

export const useStyles = makeStyles({
  container: {
    marginTop: '10px',
    width: '100%',
    transformOrigin: '0 0 0',
  },
  containerClosed: {
    height: 0,
    transformOrigin: '0 0 0',
  },
  flexClosed: {
    height: 0,
    transformOrigin: '0 0 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flex: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  alternativeHeader: {
    padding: '8px 12px 8px 16px',
    backgroundColor: themeColors.greyscale4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  alternativeHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subheader: {
    margin: '32px 0px 10px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  subheaderList: {
    margin: '32px 0px 16px 0px',
    borderBottom: `1.5px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
  },
  minusIcon: {
    cursor: 'pointer',
    height: '15px',
  },
  plusIcon: {
    cursor: 'pointer',
    height: '15px',
    marginRight: '-2px',
  },
  iconContainer: {
    paddingLeft: 12,
    marginLeft: 12,
    borderLeft: `1.5px solid ${themeColors.greyscale3}`,
  },
  linkContainer: {
    cursor: 'pointer',
  },
  actionButton: {
    marginRight: 10,
  },
});
