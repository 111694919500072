import React from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './NotificationEntityCardInfoSection.styles';

interface INotificationEntityCardInfoSection {
  value: string;
  label: string;
  icon: string;
}

export const NotificationEntityCardInfoSection = ({
  value,
  label,
  icon,
}: INotificationEntityCardInfoSection) => {
  const classes = useStyles();

  return (
    <div className={classes.projectCardBody}>
      <div className={classes.textContainer}>
        <img src={icon} className={classes.icon} />
        <CustomTypography variant="bodySmall" color="greyscale1">
          {`${label}: ${value ? value : '-'}`}
        </CustomTypography>
      </div>
    </div>
  );
};
