import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  textContainer: {
    paddingTop: '51px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  images: {
    height: '370px',
    width: '890px',
    position: 'relative',
  },
  trucks: {
    zIndex: 10,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  image404: {
    zIndex: 8,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '32px 0 113px 0',
  },
});
