import { useCallback, useState } from 'react';

const initialPage = 1;
const nextPage = 1;
const perPage = 50;

export const useSelectWithSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(initialPage);

  const loadMoreHandler = useCallback(() => {
    setPage(prevState => prevState + nextPage);
  }, []);

  const searchHandler = useCallback((value: string) => {
    setPage(initialPage);
    setSearchQuery(value);
  }, []);

  return {
    loadMoreHandler,
    searchHandler,
    setPage,
    searchQuery,
    page,
    perPage,
  };
};
