import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  selectedPage: {
    backgroundColor: themeColors.mbsBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '2px',
    margin: '0px 8px 0px 8px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  regular: {
    margin: '0px 8px 0px 8px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  dots: {
    margin: '0px 8px 0px 8px',
  },
});
