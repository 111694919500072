import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { EscapeAction, escapePercentSign } from 'app/shared/utils';

export const useSearchHelper = (searchQueryParamKey = 'search') => {
  const searchQuery = new URLSearchParams(useLocation().search).get(
    searchQueryParamKey,
  );

  const prepareSearchQueryParameter = useCallback(argument => {
    if (!argument) {
      return '';
    }
    return encodeURIComponent(escapePercentSign(EscapeAction.DECODE, argument));
  }, []);

  return {
    searchQuery,
    prepareSearchQueryParameter,
  };
};
