import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  organizationCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  organizationCardBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '12px',
  },
  icon: {
    marginRight: '10px',
  },
  administrators: {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  members: {
    paddingLeft: 5,
  },
  actionButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});
