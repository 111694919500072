import React from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './AvatarSection.styles';
import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import { getUserInitials } from 'app/shared/utils/getUserInitials';
import { IProfile } from 'types';

interface IAvatarSectionProps {
  profile: IProfile;
  isCurrentUser: boolean;
}

export const AvatarSection = ({
  profile,
  isCurrentUser,
}: IAvatarSectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.header}>
        <CustomTypography variant="header3" bold color="accentDark">
          {isCurrentUser
            ? t(translations.profilePage.myProfile)
            : t(translations.profilePage.profile)}
        </CustomTypography>
      </div>
      <div>
        <Avatar
          content={getUserInitials(profile?.firstName, profile?.lastName)}
          size={150}
        />
      </div>
    </>
  );
};
