import { Collapse, Paper, Popper } from '@material-ui/core';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './FilterModal.styles';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { ChangeEvent, useState, useCallback } from 'react';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';

interface IFilterModalProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  submitHandler: (value: boolean | '') => void;
  closeHandler: () => void;
  initialValue: boolean | '';
}

export const FilterModal = ({
  anchorEl,
  open,
  initialValue,
  submitHandler,
  closeHandler,
}: IFilterModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState<boolean | ''>(initialValue);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: string) => {
      setValue(value === 'false' ? false : value === 'true' ? true : '');
    },
    [],
  );

  const onSubmitHandler = useCallback(() => {
    submitHandler(value);
  }, [submitHandler, value]);

  const cancelHandler = useCallback(() => {
    closeHandler();
    setValue(initialValue);
  }, [closeHandler, initialValue]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      className={classes.popover}
      transition
    >
      {({ TransitionProps }) => (
        <Collapse {...TransitionProps}>
          <Paper>
            <div className={classes.filterCardBody}>
              <div className={classes.nameContainer}>
                <CustomTypography variant="caption1" color="accentDark">
                  {t(translations.truckListing.filterTrucksBy)}
                </CustomTypography>
              </div>

              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    className={classes.radioGroup}
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value={''}
                      control={
                        <Radio
                          checkedIcon={<RadioIcon />}
                          icon={<RadioEmpty />}
                          color="primary"
                        />
                      }
                      label={t(translations.truckListing.all)}
                    />

                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value={true}
                      control={
                        <Radio
                          checkedIcon={<RadioIcon />}
                          icon={<RadioEmpty />}
                          color="primary"
                        />
                      }
                      label={t(translations.truckListing.trucksWithProbe)}
                    />

                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value={false}
                      control={
                        <Radio
                          checkedIcon={<RadioIcon />}
                          icon={<RadioEmpty />}
                          color="primary"
                        />
                      }
                      label={t(translations.truckListing.trucksWithoutProbe)}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={classes.buttons}>
                <Button onClick={onSubmitHandler} variant={'secondaryDark'}>
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color={'white'}
                  >
                    {t(translations.truckListing.apply)}
                  </CustomTypography>
                </Button>

                <Button onClick={cancelHandler} variant={'secondaryLight'}>
                  <CustomTypography
                    variant="buttonTextSmall"
                    bold
                    color={'mbsBlue'}
                  >
                    {t(translations.truckListing.cancel)}
                  </CustomTypography>
                </Button>
              </div>
            </div>
          </Paper>
        </Collapse>
      )}
    </Popper>
  );
};
