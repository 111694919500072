import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 30px 160px 30px',
  },
  navContainer: {
    marginTop: 6,
  },
  section: {
    marginTop: '10px',
    marginLeft: '-10px',
    marginRight: '-10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  emptyContainer: {
    marginLeft: 10,
  },
  progress: {
    height: 150,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
