import React, { useCallback } from 'react';
import { INotificationEntity, Routes } from 'types';
import { useStyles } from './StatusSection.styles';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ReactComponent as NotificationSettingsIcon } from 'assets/settings.svg';
import { themeColors } from 'app/shared/theme';
import { getNotificationSettingsTab } from 'app/shared/utils';

interface IStatusSectionProps {
  notificationEntityBaseModel: INotificationEntity | null;
}

export const StatusSection = ({
  notificationEntityBaseModel,
}: IStatusSectionProps) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    organizationId,
    entityType,
  }: { organizationId: string; entityType: string } = useParams();
  const notificationSettingsClickHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      localStorage.setItem(
        Number(entityType).toString(),
        JSON.stringify(notificationEntityBaseModel),
      );

      history.push(
        Routes.NotificationSettings.replace(
          ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
          `${organizationId}/${getNotificationSettingsTab(
            Number(entityType),
          )}/1/20/1/20`,
        ),
      );
    },
    [history, notificationEntityBaseModel, organizationId, entityType],
  );

  return (
    <div className={classes.projectCardHeader}>
      <NotificationSettingsIcon
        stroke={themeColors.mbsBlue}
        className={classes.sentNotificationsLink}
        onClick={notificationSettingsClickHandler}
      />
    </div>
  );
};
