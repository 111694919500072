import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    backgroundColor: themeColors.greyscale5,
    overflow: 'hidden',
    zIndex: 1,
  },
  mainWrapperMobileProfile: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    backgroundColor: themeColors.white,
    overflow: 'hidden',
    zIndex: 1,
  },
  pageWrapper: {
    zIndex: 2,
    boxSizing: 'border-box',
    marginTop: themeLayout.headerHeight,

    paddingTop: '27px',
  },
});
