import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  clientAppSection: {
    boxSizing: 'border-box',
    position: 'relative',
  },
  clientAppSectionMobile: {
    boxSizing: 'border-box',
    paddingLeft: '1px',
    position: 'relative',
    backgroundColor: themeColors.white,
  },
  content: {
    position: 'absolute',
    maxWidth: 458,
    left: 29,
    top: 50,
  },
  contentMobile: {
    position: 'absolute',
    maxWidth: 458,
    top: 40,
    left: 16,
  },
  description: {
    margin: '16px 0',
  },
  descriptionMobile: {
    margin: '16px 16px 16px 0px',
    boxSizing: 'border-box',
  },
  cover: {
    objectFit: 'cover',
    height: 600,
    width: '100%',
    marginTop: 100,
    marginBottom: '76px',
    objectPosition: '10% 50%',
  },
  desktopContainer: {
    backgroundColor: themeColors.white,
    marginTop: 48,
    marginLeft: 2,
  },
  info: {
    backgroundColor: themeColors.greyscale5,
    padding: '14px 28px 14px 12px',
    margin: '30px 0px 30px -16px',
    textAlign: 'center',
  },

  coverMobile: {
    marginTop: 32,
    objectFit: 'cover',
    height: 334,
    float: 'right',
    marginRight: '-90px',
    marginBottom: '76px',
    objectPosition: '10% 50%',
  },

  qrContainer: {
    marginTop: 17,
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    marginLeft: '-16px',
  },
  button: {
    width: 157,
    height: 'auto',
    marginTop: 9,
  },
  paragraph: {
    paddingTop: 15,
  },
});
