import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  projectCardFooter: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
    backgroundColor: themeColors.white,
    alignItems: 'center',
  },
  avatarContainer: {
    marginLeft: '-8px',
  },
  more: {
    marginLeft: '16px',
  },
  icon: {
    marginRight: '10px',
  },
  teammates: {
    display: 'flex',
    flexDirection: 'row',
  },
  members: {
    paddingLeft: 5,
  },
  notifications: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchTrack: {
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  switchBase: {
    opacity: 1,
    height: 16,
    left: 4,
    top: 5,
    '& .Mui-checked': {
      transform: 'translateX(27px)',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  rootSwitch: {
    width: 70,
  },
  toggle: {
    '& .Mui-checked': {
      color: themeColors.white,
      transform: 'translateX(27px)',
    },
  },
});
