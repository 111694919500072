import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IResponseData,
  IHistoryRecordExtended,
  IBatchMixProps,
  IError,
  IApiParams,
} from 'types';
import { useAuth, useError } from '../providers';
import { getNotificationCategory } from '../utils';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const batchMixPropsKey = 'getBatchMixtureProportions';
const batchHistoryKey = 'getBatchHistory';
const batchStatusesKey = 'getBatchStatuses';
const batchHistoryItemKey = 'updateBatchHistoryItem';
const historyBatchStatusesKey = 'getHistoryBatchStatuses';
const tableMapHeaderKey = 'tableMapHeaderKey';
interface IAddNotePayload {
  historyRecordId: string;
  crn: string;
  noteText: string;
  addedItemId?: string;
  receiverIds: string[];
}

export function useBatchApi({ queryParameters, organizationId }: IApiParams) {
  const authClient = useAuthClient();
  const queryClient = useQueryClient();
  const { openModal } = useError();
  const { supplierId } = useAuth();

  const currentSupplierId = useMemo(() => {
    return organizationId || supplierId;
  }, [organizationId, supplierId]);

  const getBatchHistoryUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Batch/get-batch-history`;
  }, [currentSupplierId]);

  const getBatchMixtureProportionsUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Batch/get-batched-mixture-proportions`;
  }, [currentSupplierId]);

  const getBatchStatusesUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Batch/get-batch-statuses`;
  }, [currentSupplierId]);

  const updateNotificationTypeUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Batch/update-notification-type`;
  }, [currentSupplierId]);

  const getHistoryBatchStatusesUrl = useMemo(() => {
    return `/api/v1/${supplierId}/Batch/get-history-statuses`;
  }, [supplierId]);

  const addNoteUrl = useMemo(
    () => `/api/v1/${currentSupplierId}/Batch/add-note`,
    [currentSupplierId],
  );

  const getTableHeaderMapUrl = useMemo(() => {
    return `/api/v1/${currentSupplierId}/Batch/get-table-headers-map`;
  }, [currentSupplierId]);

  const { mutateAsync: addNote, isLoading: isAddingNote } = useMutation(
    (payload: IAddNotePayload) =>
      authClient(addNoteUrl, { method: 'POST', data: payload }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(batchHistoryKey);
      },
      onError: (error: Error) => {
        console.debug('Request error', 'POST note', error);
        firebaseAnalytics.logErrorEvent(errors.getBatchStatuses, error, {
          addNoteUrl,
        });
        openModal(error);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(batchHistoryKey);
      },
    },
  );

  const {
    data: mapHeaderResponse,
    refetch: fetchMapHeader,
    isLoading: isFetchingMapHeader,
  } = useQuery<Record<string, string[]>, IError>(
    [tableMapHeaderKey, { queryParameters }],
    async () => {
      return await authClient(`${getTableHeaderMapUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        openModal(error);
      },
    },
  );

  const {
    data: batchStatusesResponse,
    refetch: fetchBatchStatuses,
    isLoading: isFetchingBatchStatuses,
  } = useQuery<IResponseData<string[]>, IError>(
    [batchStatusesKey, { queryParameters }],
    async () => {
      return await authClient(`${getBatchStatusesUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getBatchStatuses, error, {
          getBatchStatusesUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: batchHistoryResponse,
    refetch: fetchBatchHistory,
    isFetching: isFetchingBatchHistory,
    error: fetchBatchHistoryError,
  } = useQuery<IResponseData<IHistoryRecordExtended>, IError>(
    [batchHistoryKey, { queryParameters }],
    async () => {
      return await authClient(`${getBatchHistoryUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getBatchHistory, error, {
          getBatchHistoryUrl,
          ...queryParameters,
        });
        openModal(error);
      },
    },
  );

  const {
    data: batchMixturePropsResponse,
    refetch: fetchBatchMixtureProps,
    isLoading: isFetchingBatchMixtureProps,
    error: fetchBatchMixturePropsError,
  } = useQuery<IResponseData<IBatchMixProps[]>, IError>(
    [batchMixPropsKey, { queryParameters }],
    async () => {
      return await authClient(`${getBatchMixtureProportionsUrl}`, {
        queryParameters,
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(
          errors.getBatchMixtureProportions,
          error,
          {
            getBatchMixtureProportionsUrl,
            ...queryParameters,
          },
        );
        openModal(error);
      },
    },
  );

  const {
    data: updateNotificationTypeResponse,
    mutateAsync: updateNotificationType,
    isLoading: isUpdatingNotificationType,
    isSuccess: isUpdatingNotificationTypeSuccess,
    error: updatingNotificationTypeError,
  } = useMutation(
    (payload: { historyDataId: string; notificationCode: string }) =>
      authClient(updateNotificationTypeUrl, {
        method: 'PUT',
        queryParameters: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(batchHistoryItemKey);
        return queryClient.getQueryData<IResponseData<IHistoryRecordExtended>>(
          batchHistoryItemKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<IHistoryRecordExtended>>(
            batchHistoryItemKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.updateNotificationType, error, {
          updateNotificationTypeUrl,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(batchHistoryItemKey);
      },
    },
  );

  const {
    data: historyBatchStatusesResponse,
    refetch: fetchHistoryBatchStatuses,
    isLoading: isFetchingHistoryBatchStatuses,
  } = useQuery<IResponseData<{ key: string; value: string }[]>, Error>(
    historyBatchStatusesKey,
    () =>
      authClient(getHistoryBatchStatusesUrl, {
        method: 'GET',
      }),
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (error: IError) => {
        openModal(error);
      },
    },
  );

  const batchHistory = useMemo<IHistoryRecordExtended | undefined>(() => {
    const data = batchHistoryResponse?.data;
    return data
      ? {
          ...data,
          batchHistoryDetails: data?.batchHistoryDetails.map(historyRecord => ({
            ...historyRecord,
            notificationCategory:
              getNotificationCategory(historyRecord.eventStatusCode) || null,
          })),
        }
      : undefined;
  }, [batchHistoryResponse]);

  const batchMixProps = useMemo<IBatchMixProps[] | undefined>(
    () => batchMixturePropsResponse?.data,
    [batchMixturePropsResponse],
  );

  const batchStatuses = useMemo<string[] | undefined>(
    () => batchStatusesResponse?.data,
    [batchStatusesResponse],
  );

  const mapHeader = useMemo<Record<string, string[]> | undefined>(
    () => mapHeaderResponse,
    [mapHeaderResponse],
  );

  const updatedHistoryRecord = useMemo(() => {
    const data = updateNotificationTypeResponse?.data;
    return data
      ? {
          ...data,
          notificationCategory:
            getNotificationCategory(data.eventStatusCode) || null,
        }
      : undefined;
  }, [updateNotificationTypeResponse]);

  return {
    batchHistoryResponse,
    fetchBatchHistory,
    batchHistory,
    isFetchingBatchHistory,
    batchMixProps,
    fetchBatchMixtureProps,
    isFetchingBatchMixtureProps,
    fetchBatchHistoryError,
    fetchBatchMixturePropsError,
    batchStatuses,
    fetchBatchStatuses,
    isFetchingBatchStatuses,
    updateNotificationType,
    isUpdatingNotificationType,
    isUpdatingNotificationTypeSuccess,
    updatingNotificationTypeError,
    updatedHistoryRecord,
    historyBatchStatusesResponse,
    fetchHistoryBatchStatuses,
    isFetchingHistoryBatchStatuses,
    isAddingNote,
    addNote,
    fetchMapHeader,
    isFetchingMapHeader,
    mapHeader,
  };
}
