import { makeStyles } from '@material-ui/core';
import { themeColors, theme, themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.accentDark60,
    overflowY: 'scroll',
    boxSizing: 'border-box',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
    outline: 'none !important',
    position: 'relative',
    backgroundColor: themeColors.white,
  },

  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    height: '86px',
    width: '561px',
    boxSizing: 'border-box',
    zIndex: 200,
    backgroundColor: themeColors.white,
    borderTop: `2px solid ${themeColors.greyscale4}`,

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },
  },
  body: {
    position: 'absolute',
    width: 561,
    bottom: 86,
    top: 138,
    boxSizing: 'border-box',
    margin: '12px 0px',

    [theme.breakpoints.down(themeLayout.breakpointBig)]: {
      width: 500,
    },

    [theme.breakpoints.down(themeLayout.breakpointSmall)]: {
      width: '100%',
    },

    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '7px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  deleteButton: {
    padding: '0px 0px 0px 8px',
    cursor: 'pointer',
  },
  nameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '-9px 0px',
    whiteSpace: 'nowrap',
  },
  cell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '-9px 0px',
  },
  label: {
    backgroundColor: themeColors.greyscale4_50,
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    minWidth: 67,
    padding: '0px 12px',
    whiteSpace: 'nowrap',
    marginLeft: 8,
  },
  searchContainer: {
    boxSizing: 'border-box',
    alignSelf: 'center',
    padding: '14px 0px 20px 0px',
    width: '100%',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
  },
  onlySearch: {
    boxSizing: 'border-box',
    alignSelf: 'center',
    padding: '0px 0px 20px 0px',
    width: '100%',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
  },
  textFieldContainer: {
    boxSizing: 'border-box',
    alignSelf: 'center',
    padding: '20px 0px',
    width: '100%',
  },
  textFieldLabel: {
    padding: '0px 16px 3px 16px',
  },
  subheader: {
    padding: '0px 0px 7px 0px',
    width: '100%',
    borderBottom: `2px solid ${themeColors.greyscale4}`,
  },
  subheaderWithoutBorder: {
    padding: '10px 0px 0px 0px',
    width: '100%',
  },
  wrapper: {
    height: 78,
    position: 'absolute',
    top: 73,
    width: '100%',
    boxSizing: 'border-box',
    padding: '10px 24px',
  },
  searchWrapper: {
    height: 78,
    position: 'absolute',
    top: 220,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px 24px',
  },
  withoutPadding: {
    padding: '0px',
  },
  negativeMargin: {
    marginLeft: '-16px',
  },
});
