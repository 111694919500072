import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  refreshButton: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  refreshIcon: {
    marginRight: 6,
  },
});
