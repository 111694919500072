import { makeStyles } from '@material-ui/core';
import { themeLayout } from 'app/shared/theme';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    zIndex: 1,
    boxSizing: 'border-box',
    padding: '0px 30px 160px 30px',
  },
  header: {
    marginBottom: '24px',
    display: 'flex',
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerText: {
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      marginBottom: 20,
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  search: {
    marginRight: 20,
    width: '100%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    width: 70,
    justifyContent: 'space-between',
  },
  gridLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  gridRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  labelContainer: {
    marginLeft: 5,
  },
  viewButtons: {
    marginLeft: 20,
  },
}));
