import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  paper: {
    width: '230px',
    marginTop: '45px',
    backgroundColor: themeColors.white,
    boxShadow: themeShadows.shadowMedium,
    borderRadius: '2px',
    zIndex: 1000,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 75,
    cursor: 'pointer',
  },
  buttonDisabled: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 75,
  },
  iconClicked: {
    height: '34px',
    width: '24px',
    backgroundColor: themeColors.highlightBlue,
  },
  span: {
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  icon: {
    height: '34px',
    width: '20px',
    color: themeColors.mbsBlue,
    marginLeft: '-5px',
  },
  iconDisabled: {
    height: '34px',
    width: '20px',
    color: themeColors.greyscale2,
    marginLeft: '-5px',
  },
  menuList: { marginTop: 4 },
  zIndex: { zIndex: 9999 },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    marginTop: 15,
    marginLeft: 10,
    zIndex: 9999,
  },
  wrapper: {
    width: 285,
    padding: '15px 16px 15px 10px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '10px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  notificationCode: {
    width: '100%',
    textAlign: 'left',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 14,
  },
  buttonWrapper: {
    width: 40,
    textAlign: 'left',
  },
});
