/* eslint-disable no-magic-numbers */
export const formatPhoneNumber = (entry = '') => {
  const match = entry
    .replace(/\D+/g, '')
    .replace(/^1/, '')
    .match(/^(\d{3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3];
  } else return entry;
};
