import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  IError,
  IResponseData,
  ISupplier,
  ITicketHealthCheck,
  IHealthCheckPayload,
  IEventHealthCheck,
  IBatchesHealthCheck,
  ISupplierReport,
  ISupplierReportRequest,
} from 'types';
import { useError } from '../providers';
import { useAuthClient } from './useAuthClient';
import { firebaseAnalytics } from '../analytics';
import { errors } from '../config';

const supplierReportsQueryKey = 'getSupplierReports';
const suppliersQueryKey = 'getSuppliers';
const ticketsHealthCheckQueryKey = 'getTicketsHealthCheck';
const eventsHealthCheckQueryKey = 'getEventsHealthCheck';
const batchesHealthCheckQueryKey = 'getBatchesHealthCheck';

const getSuppliersUrl = `/api/v1/health-check-suppliers`;
const getTicketsHealthCheckUrl = `/api/v1/health-check-tickets`;
const getBatchesHealthCheckUrl = `/api/v1/health-check-batches`;
const getEventsHealthCheckUrl = `/api/v1/health-check-events`;

const supplierReportUrl = '/api/v1/all-supplier-reports';

export function useReportsApi() {
  const legacyAuthClient = useAuthClient(true);

  const queryClient = useQueryClient();
  const { openModal } = useError();

  const {
    data: supplierReportsResponse,
    mutateAsync: fetchSupplierReports,
    isLoading: isFetchingSupplierReports,
  } = useMutation(
    (payload: ISupplierReportRequest) =>
      legacyAuthClient(supplierReportUrl, { method: 'POST', data: payload }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(supplierReportsQueryKey);
        return queryClient.getQueryData<IResponseData<ISupplierReport[]>>(
          supplierReportsQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<ISupplierReport[]>>(
            supplierReportsQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getSuppliersReport, error, {
          supplierReportUrl,
          variables: JSON.stringify(variables),
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(supplierReportsQueryKey);
      },
    },
  );

  const {
    data: suppliersResponse,
    refetch: fetchSuppliers,
    isLoading: isFetchingSuppliers,
    error: fetchSuppliersError,
  } = useQuery<IResponseData<{ supportedSuppliers: ISupplier[] }>, IError>(
    suppliersQueryKey,
    async () => {
      return await legacyAuthClient(`${getSuppliersUrl}`, {
        method: 'GET',
      });
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: error => {
        firebaseAnalytics.logErrorEvent(errors.getSupportedSuppliers, error, {
          url: getSuppliersUrl,
        });
        openModal(error);
      },
    },
  );

  const {
    mutateAsync: fetchTicketsHealthCheck,
    isLoading: isFetchingTicketsHealthCheck,
    data: ticketsHealthCheckResponse,
  } = useMutation(
    (payload: IHealthCheckPayload) =>
      legacyAuthClient(getTicketsHealthCheckUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(ticketsHealthCheckQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          ticketsHealthCheckQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            ticketsHealthCheckQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getTicketsHealthCheck, error, {
          ticketsHealthCheckQueryKey,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(ticketsHealthCheckQueryKey);
      },
    },
  );

  const {
    mutateAsync: fetchEventsHealthCheck,
    isLoading: isFetchingEventsHealthCheck,
    data: eventsHealthCheckResponse,
  } = useMutation(
    (payload: IHealthCheckPayload) =>
      legacyAuthClient(getEventsHealthCheckUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(eventsHealthCheckQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          eventsHealthCheckQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            eventsHealthCheckQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getEventsHealthCheck, error, {
          eventsHealthCheckQueryKey,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(eventsHealthCheckQueryKey);
      },
    },
  );

  const {
    mutateAsync: fetchBatchesHealthCheck,
    isLoading: isFetchingBatchesHealthCheck,
    data: batchesHealthCheckResponse,
  } = useMutation(
    (payload: IHealthCheckPayload) =>
      legacyAuthClient(getBatchesHealthCheckUrl, {
        method: 'POST',
        data: payload,
      }),
    {
      retry: false,
      onMutate: async () => {
        await queryClient.cancelQueries(batchesHealthCheckQueryKey);
        return queryClient.getQueryData<IResponseData<boolean>>(
          batchesHealthCheckQueryKey,
        );
      },
      onError: (error: IError, variables, context) => {
        if (context) {
          queryClient.setQueryData<IResponseData<boolean>>(
            batchesHealthCheckQueryKey,
            context,
          );
        }

        firebaseAnalytics.logErrorEvent(errors.getEventsHealthCheck, error, {
          batchesHealthCheckQueryKey,
        });
        openModal(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(batchesHealthCheckQueryKey);
      },
    },
  );

  const supplierReports = useMemo<ISupplierReport[] | undefined>(
    () => supplierReportsResponse?.data,
    [supplierReportsResponse],
  );

  const suppliers = useMemo<ISupplier[] | undefined>(
    () => suppliersResponse?.data?.supportedSuppliers,
    [suppliersResponse],
  );

  const ticketHealthCheck = useMemo<ITicketHealthCheck[] | undefined>(
    () => ticketsHealthCheckResponse?.data?.data,
    [ticketsHealthCheckResponse],
  );

  const eventHealthCheck = useMemo<IEventHealthCheck[] | undefined>(
    () => eventsHealthCheckResponse?.data?.data,
    [eventsHealthCheckResponse],
  );

  const batchesHealthCheck = useMemo<IBatchesHealthCheck[] | undefined>(
    () => batchesHealthCheckResponse?.data?.data,
    [batchesHealthCheckResponse?.data?.data],
  );

  return {
    supplierReports,
    supplierReportsResponse,
    fetchSupplierReports,
    isFetchingSupplierReports,
    suppliers,
    fetchSuppliers,
    isFetchingSuppliers,
    fetchSuppliersError,
    ticketHealthCheck,
    fetchTicketsHealthCheck,
    isFetchingTicketsHealthCheck,
    fetchEventsHealthCheck,
    isFetchingEventsHealthCheck,
    eventHealthCheck,
    batchesHealthCheck,
    fetchBatchesHealthCheck,
    isFetchingBatchesHealthCheck,
  };
}
