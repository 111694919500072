import React from 'react';
import { useStyles } from './ModalHeader.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import close from 'assets/x_square.svg';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as BackIcon } from 'assets/arrow_left.svg';

export enum ModalVariant {
  BIG,
  SMALL,
}

interface IModalHeaderProps {
  closeHandler: () => void;
  header: string;
  variant?: ModalVariant;
  color?: keyof typeof themeColors;
  backClickHandler?: () => void;
  className?: string;
}

export const ModalHeader = ({
  closeHandler,
  header,
  variant,
  color,
  backClickHandler,
  className,
}: IModalHeaderProps) => {
  const classes = useStyles();

  return (
    <div
      className={`${
        variant === ModalVariant.SMALL
          ? classes.containerSmall
          : classes.container
      } ${className || ''}`}
    >
      <div className={classes.title}>
        {backClickHandler ? (
          <div className={classes.back} onClick={backClickHandler}>
            <BackIcon />
          </div>
        ) : null}

        <CustomTypography variant="header4" bold color={color || 'accentDark'}>
          {header}
        </CustomTypography>
      </div>

      <img src={close} className={classes.closeButton} onClick={closeHandler} />
    </div>
  );
};
