import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BatchDetailsModal.styles';
import { Modal } from '@material-ui/core';
import { BatchMixtureProportions } from './BatchMixtureProportions';
import { BatchHistory } from './BatchHistory';
import { useParams } from 'react-router-dom';
import { useBatchApi } from 'app/shared/hooks';
import {
  ModalHeader,
  ModalVariant,
} from 'app/shared/components/generic-ui/ModalHeader/ModalHeader';
import { firebaseAnalytics } from 'app/shared/analytics';
import { Routes } from 'types';
import {
  CircularProgress,
  ProgressType,
} from 'app/shared/components/generic-ui/CircularProgress';
import { BatchInfo } from './BatchInfo';
import { AddCompressiveStrengthModalWrapper } from './AddCompressiveStrengthModal';
import { CompressiveStrengthList } from './CompressiveStrengthList';
import {
  BatchDetailsTabs,
  BatchHistoryTab,
} from './BatchDetailsTabs/BatchDetailsTabs';
import { AddManualTestDataWrapper } from './AddManualTestData';
import { ModalType } from '../PourEventHistorySection';

interface IBatchDetailsModal {
  isOpen: boolean;
  closeHandler: () => void;
  crn: string;
  modalType?: ModalType;
}

const focusCardOffset = 40;
const zero = 0;

export const BatchDetailsModal = ({
  isOpen,
  closeHandler,
  crn,
  modalType,
}: IBatchDetailsModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isMixDesignOpen, setIsMixDesignOpen] = useState(false);
  const [isAddComprStrModalOpen, setIsAddComprStrModalOpen] = useState(
    modalType === ModalType.COMPRESSIVE_STRENGTH,
  );
  const [isViewComprStrModalOpen, setIsViewComprStrModalOpen] = useState(false);
  const [isAddManualDataModalOpen, setIsAddManualDataModalOpen] = useState(
    modalType === ModalType.MANUAL_DATA,
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSentNotificationsModalOpen, setIsSentNotificationsModalOpen] =
    useState(false);

  const { id, organizationId }: { id: string; organizationId: string } =
    useParams();
  const historyRecordId = new URLSearchParams(location.search).get(
    'historyRecordId',
  );
  const refToScroll = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(
    historyRecordId ? BatchHistoryTab.HISTORY : BatchHistoryTab.INFO,
  );

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        crn: crn,
        projectId: id,
      },
    };
  }, [crn, id, organizationId]);

  const {
    fetchBatchHistory,
    batchHistory,
    fetchBatchHistoryError,
    isFetchingBatchHistory,
  } = useBatchApi(queryParams);

  useEffect(() => {
    fetchBatchHistory();
  }, [fetchBatchHistory, crn]);

  useEffect(() => {
    if (fetchBatchHistoryError && isOpen) {
      closeHandler();
    }
  }, [closeHandler, fetchBatchHistoryError, isOpen]);

  const infoSpoilerClickHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Batch modal info spoiler',
    );

    setIsMixDesignOpen(true);
  }, []);

  const addCompressiveStrengthHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Add compressive strength button',
    );

    setIsAddComprStrModalOpen(true);
  }, []);

  const addManualDataHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Add manual test data button',
    );

    setIsAddManualDataModalOpen(true);
  }, []);

  const viewCompressiveStrengthHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'View compressive strength button',
    );

    setIsViewComprStrModalOpen(true);
  }, []);

  const backClickHandler = useCallback(() => {
    fetchBatchHistory();
    setIsAddComprStrModalOpen(false);
    setIsMixDesignOpen(false);
    setIsViewComprStrModalOpen(false);
    setIsAddManualDataModalOpen(false);
    setIsSentNotificationsModalOpen(false);
  }, [fetchBatchHistory]);

  const focusTargetCard = useCallback((offset: number) => {
    return refToScroll.current?.scrollBy({
      top:
        offset < zero
          ? -(Math.abs(offset) - focusCardOffset)
          : Math.abs(offset) - focusCardOffset,
      behavior: 'smooth',
    });
  }, []);

  const batchMixPropsModalContent = useMemo(() => {
    return (
      <div className={classes.internalContent}>
        <BatchMixtureProportions crn={crn} />
      </div>
    );
  }, [classes.internalContent, crn]);

  const refetchBatchHistory = useCallback(() => {
    fetchBatchHistory();
  }, [fetchBatchHistory]);

  const modalContent = useMemo(() => {
    return batchHistory ? (
      <div>
        {tab === BatchHistoryTab.INFO ? (
          <BatchInfo
            batchHistory={batchHistory}
            infoSpoilerClickHandler={infoSpoilerClickHandler}
            addCompressiveStrengthHandler={addCompressiveStrengthHandler}
            viewCompressiveStrengthHandler={viewCompressiveStrengthHandler}
          />
        ) : (
          <div
            className={
              isConfirmationModalOpen
                ? classes.internalContent
                : isSentNotificationsModalOpen
                ? classes.sentNotificationModal
                : classes.body
            }
            ref={refToScroll}
          >
            <BatchHistory
              batchHistory={batchHistory}
              focusTargetCard={focusTargetCard}
              addManualDataHandler={addManualDataHandler}
              refetchBatchHistory={refetchBatchHistory}
              hideTabsHandler={setIsConfirmationModalOpen}
              isLoading={isFetchingBatchHistory}
              crn={crn}
              viewSentNotificationsHandler={() => {
                setIsSentNotificationsModalOpen(true);
              }}
              isSentNotificationsModalOpen={isSentNotificationsModalOpen}
            />
          </div>
        )}
      </div>
    ) : null;
  }, [
    addCompressiveStrengthHandler,
    addManualDataHandler,
    batchHistory,
    classes.body,
    classes.internalContent,
    classes.sentNotificationModal,
    focusTargetCard,
    infoSpoilerClickHandler,
    isConfirmationModalOpen,
    isFetchingBatchHistory,
    isSentNotificationsModalOpen,
    refetchBatchHistory,
    tab,
    viewCompressiveStrengthHandler,
  ]);

  const compressiveStrengthModalContent = useMemo(() => {
    return (
      <div className={classes.internalContent}>
        <AddCompressiveStrengthModalWrapper
          crn={crn}
          closeHandler={backClickHandler}
        />
      </div>
    );
  }, [backClickHandler, classes.internalContent, crn]);

  const compressiveStrengthHistoryModalContent = useMemo(() => {
    return batchHistory ? (
      <div className={classes.internalContent}>
        <CompressiveStrengthList
          compressiveStrengthMeasurements={
            batchHistory?.compressiveStrengthMeasurements
          }
        />
      </div>
    ) : null;
  }, [batchHistory, classes.internalContent]);

  const addManualDataModalContent = useMemo(() => {
    return (
      <div className={classes.internalContent}>
        <AddManualTestDataWrapper crn={crn} closeHandler={backClickHandler} />
      </div>
    );
  }, [backClickHandler, classes.internalContent, crn]);

  const modalHeader = useMemo(() => {
    return isAddComprStrModalOpen
      ? `${t(translations.batchDetailsModal.addingHeader)} #${
          batchHistory?.ticketNumber || '-'
        }`
      : isViewComprStrModalOpen
      ? `${t(translations.batchDetailsModal.compressiveStrengthHistory)} #${
          batchHistory?.ticketNumber || '-'
        }`
      : isAddManualDataModalOpen
      ? `${t(translations.batchDetailsModal.addManualTestDataFor)} #${
          batchHistory?.ticketNumber || '-'
        }`
      : isSentNotificationsModalOpen
      ? t(translations.batchDetailsModal.notifiedMembers)
      : `${t(translations.batchDetailsModal.ticketId)}: ${
          batchHistory?.ticketNumber || '-'
        }`;
  }, [
    batchHistory?.ticketNumber,
    isAddComprStrModalOpen,
    isAddManualDataModalOpen,
    isSentNotificationsModalOpen,
    isViewComprStrModalOpen,
    t,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        setIsMixDesignOpen(false);
        setIsAddComprStrModalOpen(false);
        closeHandler();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        {batchHistory ? (
          <div className={classes.container}>
            <ModalHeader
              header={modalHeader}
              closeHandler={closeHandler}
              variant={ModalVariant.SMALL}
              backClickHandler={
                isAddComprStrModalOpen ||
                isMixDesignOpen ||
                isViewComprStrModalOpen ||
                isAddManualDataModalOpen ||
                isSentNotificationsModalOpen
                  ? backClickHandler
                  : undefined
              }
            />

            {!isConfirmationModalOpen &&
              !isAddComprStrModalOpen &&
              !isMixDesignOpen &&
              !isViewComprStrModalOpen &&
              !isAddManualDataModalOpen &&
              !isSentNotificationsModalOpen && (
                <BatchDetailsTabs tab={tab} changeTabHandler={setTab} />
              )}

            {isAddComprStrModalOpen
              ? compressiveStrengthModalContent
              : isViewComprStrModalOpen
              ? compressiveStrengthHistoryModalContent
              : isAddManualDataModalOpen
              ? addManualDataModalContent
              : isMixDesignOpen
              ? batchMixPropsModalContent
              : modalContent}
          </div>
        ) : (
          <div className={classes.loading}>
            <CircularProgress withLabel size={40} type={ProgressType.LIGHT} />
          </div>
        )}
      </div>
    </Modal>
  );
};
