import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  pageWrapper: {
    boxSizing: 'border-box',
    margin: '-21px 30px 38px 38px',
  },
  text: {
    maxWidth: 430,
  },
  contacts: {
    marginTop: 24,
    maxWidth: 500,
  },
  link: {
    cursor: 'pointer',
  },
});
