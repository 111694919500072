import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  headerClickable: {
    padding: '8px 11px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderBottom: `1px solid ${themeColors.greyscale4}`,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    display: 'flex',

    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      flexDirection: 'row',
    },
  },
  arrowContainer: {
    width: 24,
    borderLeft: `2px solid ${themeColors.greyscale4}`,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  expandIcon: {
    width: 22,
    marginRight: '-8px',
    color: themeColors.mbsBlue,
    cursor: 'pointer',
  },
  paddingContainer: {
    padding: '0px 6px',
  },
  container: {
    padding: 11,
    borderBottom: `1px solid ${themeColors.greyscale4}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sentNotificationsLink: {
    marginLeft: 8,
    cursor: 'pointer',
  },
}));
