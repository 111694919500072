import { makeStyles } from '@material-ui/core';
import { themeColors, themeShadows } from 'app/shared/theme';

export const useStyles = makeStyles({
  card: {
    boxShadow: themeShadows.shadowMedium,
    margin: 16,
    padding: 16,
    flexDirection: 'row',
    display: 'flex',
    width: 350,
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flexDirection: 'column',
    display: 'flex',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '-10px',
  },
  switchTrack: {
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  switchBase: {
    opacity: 1,
    height: 16,
    left: 4,
    top: 5,
    '& .Mui-checked': {
      transform: 'translateX(27px)',
    },
  },
  toggle: {
    '& .Mui-checked': {
      color: themeColors.white,
      transform: 'translateX(27px)',
    },
  },
  hideIconPadding: {
    '& .MuiSelect-outlined': {
      paddingRight: '0px',
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },
  viewMore: {
    cursor: 'pointer',
  },
  thumb: {
    width: 16,
    height: 16,
  },
  rootSwitch: {
    width: 70,
  },
  emptyCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 25,
    marginTop: '-30px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '11px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    width: 220,
  },
  infoIcon: {
    width: 20,
    marginRight: 5,
    marginTop: 5,
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
  },
});
