import { PageHeader } from 'app/shared/components/generic-ui/PageHeader';
import {
  Pages,
  TabIds,
} from 'app/shared/components/layout/TabNavigation/TabNavigation.types';

import { useParams } from 'react-router-dom';
import { SuppliersReport } from './SuppliersReport';
import { HealthCheck } from './HealthCheck/HealthCheck';

export const Report = () => {
  const { tab }: { tab: string } = useParams();

  return (
    <PageHeader header={'Reports'} withActions={false} pageType={Pages.REPORT}>
      {tab === TabIds.SUPPLIER_REPORT ? <SuppliersReport /> : <HealthCheck />}
    </PageHeader>
  );
};
