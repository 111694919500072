export const getFormattedPhoneNumber = (phone: string | undefined) => {
  const code = '1';
  const zero = 0;
  const trimmedPhoneNumber = phone ? phone.replace(/[^0-9.]/g, '') : '';
  if (
    !trimmedPhoneNumber.startsWith(code) &&
    trimmedPhoneNumber.length > zero
  ) {
    return code + trimmedPhoneNumber;
  } else {
    return trimmedPhoneNumber;
  }
};
