import { makeStyles } from '@material-ui/core';
import { themeColors, themeLayout } from 'app/shared/theme/theme';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  dropzone: {
    display: 'flex',
    position: 'absolute',
    top: 1,
    left: 1,
    [theme.breakpoints.down(themeLayout.mobileWidth)]: {
      padding: '25px 24px 25px 24px',
    },
    [theme.breakpoints.up(themeLayout.mobileWidth)]: {
      padding: '20px 24px 20px 24px',
    },
    flexDirection: 'column',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themeColors.white,
    zIndex: 2,
  },
  textContainer: {
    margin: '5px 0px',
    textAlign: 'center',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  imageContainer: {
    height: '100%',
  },
  uploadedImage: {
    maxWidth: 356,
    maxHeight: 60,
    marginLeft: 10,
  },
  delete: {
    marginLeft: 8,
    marginTop: 10,
    cursor: 'pointer',
  },
  dropzoneContainer: {
    height: 200,
  },
}));
