import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  logEvent,
  setUserId,
  Analytics,
} from 'firebase/analytics';
import { IFirebaseAnalytics, IEvent } from '../models';
import { firebaseConfig } from 'app/shared/config';
import { IError, IErrorData, EventsEnum, Routes } from 'types';

const zeroIndex = 0;
const valueMaxLength = 100;
const maxParameters = 25;

class FirebaseAnalytics implements IFirebaseAnalytics {
  private readonly analytics: Analytics;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  public logPageView = (pageName: string, originalPath = '') => {
    const pagePath = Routes[pageName as keyof typeof Routes];

    return this.logEvent(EventsEnum.pageView, {
      pageName,
      pagePath: pagePath ? pagePath : originalPath,
      originalPath,
    });
  };

  public logEvent = (eventName: string, event?: IEvent) => {
    logEvent(this.analytics, eventName, this.preProcessEvent(event));
  };

  public logErrorEvent = (
    customData: IErrorData,
    error: Error | IError,
    additionalParams?: IEvent,
  ) => {
    this.logEvent(`Error_${customData.code}`, {
      customCode: customData.code,
      customMessage: customData.message,
      errorName: 'name' in error ? error.name : undefined,
      errorMessage: error.message,
      errorStack: 'stack' in error ? error.stack : undefined,
      statusCode: 'statusCode' in error ? error.statusCode : undefined,
      ...additionalParams,
    });
  };

  public logPressEvent = (
    pagePath: string,
    target: string,
    description?: string,
  ) => {
    return this.logEvent(EventsEnum.pressEvent, {
      pageName: this.getRouteName(pagePath),
      pagePath,
      target,
      description,
    });
  };

  public setUserId = (id: string) => {
    setUserId(this.analytics, id);
  };

  private preProcessEvent = (event?: IEvent) => {
    if (event === undefined) {
      return undefined;
    }

    const result: IEvent = {};
    const allKeys = Object.keys(event);

    for (let i = 0; i < maxParameters; i++) {
      const key = allKeys[i];

      result[key] = event[key]?.toString()?.substr(zeroIndex, valueMaxLength);
    }

    return result;
  };

  private getRouteName = (value: string) => {
    return Object.entries(Routes).find(([, val]) => val === value)?.[0];
  };
}

export const firebaseAnalytics = new FirebaseAnalytics();
